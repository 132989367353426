import {get, post} from "../utils/networkUtils";

/**
 * Submits a new symposium
 */
export function proposeSymposium(force, title, description, emphasisArea, secondaryEmphasisArea, organizers, onComplete) {
  const data = {
    force: force,
    title: title,
    description: description,
    emphasis_area: emphasisArea,
    secondary_emphasis_area: secondaryEmphasisArea,
    organizers: organizers,
  };
  post("symposium/create/", data, onComplete);
}

/**
 * Gets a list of all symposia available to the current user
 * @param userId if set this will get "my symposia" for the user (submitter or organizer)
 *        If no userId set then this will get all symposia the logged in user can access
 * @param onComplete callback
 */
export function getSymposia(userId, onComplete) {
  if (userId) {
    get("symposium/list/user/" + userId + "/", onComplete);
  } else {
    get("symposium/list/", onComplete);
  }
}

/**
 * Gets public symposia information in this conf (no archived ones)
 * NOTE: this has limited fields available - the brief version
 */
export function getSymposiaBrief(onComplete) {
  get("symposium/list/brief/", onComplete);
}

/**
 * Gets data for a symposium
 */
export function getSymposium(symposiumId, onComplete) {
  get("symposium/" + symposiumId + "/", onComplete);
}

/**
 * Gets an HTML fragment for a symposium
 */
export function getSymposiumHtml(symposiumId, onComplete) {
  get("symposium/" + symposiumId + "/html/", onComplete);
}

/**
 * Gets data for a symposium
 */
export function updateSymposium(force, symposiumId, status, sendApprovalEmail, symposiumNumber, title, description,
                                emphasisArea, secondaryEmphasisArea, organizers, adminNotes, onComplete) {
  const data = {
    force: force,
    symposium_id: symposiumId,
    status: status,
    send_approval_email: sendApprovalEmail,
    symposium_number: symposiumNumber,
    title: title,
    description: description,
    emphasis_area: emphasisArea,
    secondary_emphasis_area: secondaryEmphasisArea,
    organizers: organizers,
    admin_notes: adminNotes,
  };
  post("symposium/update/", data, onComplete);
}

/**
 * Sets the archived flag for a symposium
 * @param symposiumId  symposium to update
 * @param archived : true if archived, false to un-archive
 * @param onComplete callback
 */
export function setArchivedStatusForSymposium(symposiumId, archived, onComplete) {
  const data = {
    symposium_id: symposiumId,
    archived: archived,
  };
  post("symposium/update/archived/", data, onComplete);
}


