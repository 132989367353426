import {FloatingLabel, Form} from "react-bootstrap";
import React, {useState} from "react";
import {fieldHasErrors, getErrorMessageForField} from "../../utils/formUtils";
import {updateFieldAnswer} from "../../api/RegApi";
import {HelpIcon} from "../../shared/HelpIcon";
import {CountrySelect} from "../../shared/CountrySelect";

export function AddressFieldInput({field, onAnswerChange = null, setFields = null, setPay = null}) {
  const [refresh, setRefresh] = useState(0);
  const [dirty, setDirty] = useState(false);
  const controlId = 'address_input_' + field.id;
  const errors = field.errors || [];
  if (!field.answer) {
    field.answer = {};
  }

  /*
    Calculate the answer display value.  This will be stored in the DB and used as a human readable display of the data.
   */
  function getAnswerValue(answer) {
    if (!answer) {
      return '';
    }
    let val = '';
    if (answer.street1) {
      val += answer.street1;
    }
    if (answer.street2) {
      val += ' '+answer.street2;
    }
    if (answer.city) {
      if (val) {
        val += ', ';
      }
      val += answer.city;
    }
    if (answer.state) {
      val += ' '+answer.state;
    }
    if (answer.zip) {
      val += ' '+answer.zip;
    }
    if (answer.country) {
      if (val) {
        val += ', ';
      }
      val += answer.country;
    }
    return val.trim();
  }

  function callUpdateFieldAnswer() {
    updateFieldAnswer(field.id, field.answer, (code, data, errors) => {
      if (code === 200) {
        if (data) {
          if (setFields) {
            setFields(data.fields);
          }
          if (setPay) {
            setPay(data.pay);
          }
        }
      } else {
        field.errors = errors;
      }
      setDirty(false); // This will also refresh with errors
    });
  }

  /**
   *  Updates one field in the address (called on every keystroke)
   * @param fieldName Name of field in answer JSON 'street1', 'city' etc..
   * @param newValue new value to store
   */
  function updateAddressField(fieldName, newValue) {
    field.answer[fieldName] = newValue;
    field.answer.value = getAnswerValue(field.answer);
    setRefresh(refresh + 1);
    setDirty(true);
  }

  /**
   * Called when leaving a field to update the backend with appropriate data
   * @param forceDirty : boolean Force an update even if it looks like nothing changed.
   */
  function leavingField(forceDirty = false) {
    if (dirty || forceDirty) {
      if (onAnswerChange) {
        onAnswerChange(field.answer);
      } else {
        callUpdateFieldAnswer();
      }
    }
  }

  function countryChanged(countryCode) {
    updateAddressField('country', countryCode);
    // Need to forceDirty here because the setDirty(true) in updateAddressField won't take effect until the next render
    leavingField(true);
  }

  return (
    <div>
      <span className='fw-bold'>{field.name}</span>
      {field.required &&
        <span className='ms-2'>*</span>
      }
      {field.help_text &&
        <HelpIcon text={field.help_text} className='ms-2'/>
      }
      <div className='ms-3 mt-2'>
        <Form.Group controlId={controlId + 'street1'} className="mt-1">
          <FloatingLabel controlId={controlId + 'street1'} label='Street number and name'>
            <Form.Control type="text"
                          placeholder={field.name}
                          name={controlId + 'street1'}
                          {...(field.disabled ? {disabled: true} : {})}
                          value={field.answer?.street1 || ''}
                          onChange={e => updateAddressField('street1', e.target.value)}
                          onBlur={e => leavingField()}
                          isInvalid={fieldHasErrors(errors, '')}/>
            <Form.Control.Feedback type="invalid">
              {getErrorMessageForField(errors, '')}
            </Form.Control.Feedback>
          </FloatingLabel>
        </Form.Group>

        <Form.Group controlId={controlId + 'street2'} className="mt-2">
          <FloatingLabel controlId={controlId + 'street2'} label='Suite, Unit, etc.'>
            <Form.Control type="text"
                          placeholder={field.name}
                          name={controlId + 'street2'}
                          {...(field.disabled ? {disabled: true} : {})}
                          value={field.answer?.street2 || ''}
                          onChange={e => updateAddressField('street2', e.target.value)}
                          onBlur={e => leavingField()}
                          isInvalid={fieldHasErrors(errors, '')}/>
          </FloatingLabel>
        </Form.Group>

        <div className='d-flex'>
          <Form.Group controlId={controlId + 'city'} className="flex-grow-1 mt-2">
            <FloatingLabel controlId={controlId + 'city'} label='City'>
              <Form.Control type="text"
                            placeholder={field.name}
                            name={controlId + 'city'}
                            {...(field.disabled ? {disabled: true} : {})}
                            value={field.answer?.city || ''}
                            onChange={e => updateAddressField('city', e.target.value)}
                            onBlur={e => leavingField()}
                            isInvalid={fieldHasErrors(errors, '')}/>
            </FloatingLabel>
          </Form.Group>

          <Form.Group controlId={controlId + 'state'} className="flex-grow-1 mt-2 ms-3">
            <FloatingLabel controlId={controlId + 'state'} label='State / Province'>
              <Form.Control type="text"
                            placeholder={field.name}
                            name={controlId + 'state'}
                            {...(field.disabled ? {disabled: true} : {})}
                            value={field.answer?.state || ''}
                            onChange={e => updateAddressField('state', e.target.value)}
                            onBlur={e => leavingField()}
                            isInvalid={fieldHasErrors(errors, '')}/>
            </FloatingLabel>
          </Form.Group>

          <Form.Group controlId={controlId + 'zip'} className="flex-grow-1 mt-2 ms-3">
            <FloatingLabel controlId={controlId + 'zip'} label='Zip / Postal Code'>
              <Form.Control type="text"
                            placeholder={field.name}
                            name={controlId + 'zip'}
                            {...(field.disabled ? {disabled: true} : {})}
                            value={field.answer?.zip || ''}
                            onChange={e => updateAddressField('zip', e.target.value)}
                            onBlur={e => leavingField()}
                            isInvalid={fieldHasErrors(errors, '')}/>
            </FloatingLabel>
          </Form.Group>
        </div>

        <Form.Group controlId="country" className='mt-2'>
          <CountrySelect value={field.answer?.country || ''}
                         onChange={countryCode => countryChanged(countryCode)}
                         isInvalid={fieldHasErrors(errors, '')}/>
        </Form.Group>

      </div>
    </div>
  );
}

