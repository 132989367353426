import React, {useContext, useEffect, useState} from "react";
import {copyAllFieldsAndActions, getActions, getTemplateFields} from "../api/RegApi";
import {RegFieldEdit} from "./RegFieldEdit";
import {RegFieldSectionEdit} from "./RegFieldSectionEdit";
import {hasPermission} from "../api/UserApi";
import {PERMISSION_STAFF} from "../constants";
import {getAllConf} from "../api/ConfApi";
import {Button, FloatingLabel, Form, Modal} from "react-bootstrap";
import {getErrorMessageForAllErrors} from "../utils/formUtils";
import {showErrorToast, showSuccessToast} from "../utils/usacmUtils";
import {UsacmContext} from "../App";

export function RegFields() {
  const {conference} = useContext(UsacmContext);
  const [conf,] = conference;
  const [fields, setFields] = useState([]);
  const [selectedFieldData, setSelectedFieldData] = useState(null);
  const [actions, setActions] = useState([]);
  const [confList, setConfList] = useState([]);
  const [selectedConfId, setSelectedConfId] = useState('');
  const isStaff = hasPermission(PERMISSION_STAFF);
  // Allow copying if there are no fields, no actions and the user is staff (admin cannot access other conf)
  const showCopyFields = isStaff && !fields?.length && !actions.length && (confList.length > 1);
  const [showingCopyConfirm, setShowingCopyConfirm] = useState(false);
  const [errors, setErrors] = useState([]);
  let selectedConfName = '';

  // Lookup the selected conf name in the confList
  if (selectedConfId && confList.length) {
    selectedConfName = confList.find(conf => conf.id + '' === selectedConfId)?.short_name || '';
  }

  function loadTemplateFields() {
    getTemplateFields((status, data, newErrors) => {
      if (status === 200) {
        setFields(data);
      }
    });
  }

  function loadActions() {
    getActions((status, data, newErrors) => {
      if (status === 200) {
        setActions(data);
      }
    });
  }

  function loadConfList() {
    getAllConf((code, data, errors) => {
      if (code === 200) {
        setConfList(data);
      }
    });
  }

  function reloadAll() {
    loadTemplateFields();
    loadActions();
    loadConfList();
  }

  useEffect(() => {
    reloadAll();
  }, []);

  function doneEditing() {
    reloadAll();
    setSelectedFieldData(null);
  }

  function chooseConf(newConfId) {
    setErrors([]);
    setSelectedConfId(newConfId);
  }

  function showConfirmCopy() {
    if (selectedConfId) {
      setShowingCopyConfirm(true);
      reloadAll();
    } else {
      // This will clear and draw the error but only works if it's the only error message on the screen.
      setErrors([{'message': 'Choose a conf.', 'fields': ['conf_id']}]);
    }
  }

  function callCopyAllFieldsAndActions() {
    copyAllFieldsAndActions(selectedConfId, (code, data, errors) => {
      if (code === 200) {
        showSuccessToast('Fields and actions have been copied.');
      } else {
        showErrorToast('Error copying fields and actions. ' + getErrorMessageForAllErrors(errors));
      }
      reloadAll();
      setShowingCopyConfirm(false);
    });
  }

  return (
    <div>
      {showCopyFields &&
        <div className='border border-1 mt-3 p-3'>
          <div className='fw-bold text-center'>Copy Fields and Actions</div>
          <div className='mt-3'>Copy all fields and actions from another conf.</div>
          <div className='mt-3'>
            <Form.Group controlId="conf_select">
              <FloatingLabel controlId="conf_select" label="Conf">
                <Form.Control
                  className="form-select"
                  as="select"
                  value={selectedConfId || ''}
                  onChange={e => chooseConf(e.target.value)}
                  isInvalid={errors.length > 0}
                >
                  <option value='' key=''>Choose conf to copy from...</option>
                  {confList.filter(c => c.id !== conf.id).map(conf =>
                    <option value={conf.id + ''} key={conf.id}>{conf.short_name} ({conf.host})</option>
                  )}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {getErrorMessageForAllErrors(errors)}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </div>
          <div className='mt-3 usacm-button-row'>
            <Button type="button" onClick={() => showConfirmCopy()}>Copy All Fields and Actions</Button>
          </div>
        </div>}

      {!selectedFieldData &&
        <RegFieldSectionEdit parentFieldId={null}
                             parentFieldName=""
                             fields={fields}
                             setSelectedFieldData={setSelectedFieldData}
                             loadTemplateFields={loadTemplateFields}/>
      }

      {selectedFieldData &&
        <RegFieldEdit origFieldData={selectedFieldData} onClose={doneEditing}/>
      }

      <Modal show={showingCopyConfirm}
             onHide={() => setShowingCopyConfirm(false)}
             size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Confirm Copy</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className='mt-1'>Are you sure you want to copy all fields and actions from {selectedConfName}?</p>
          <p className='mt-1'>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowingCopyConfirm(false)}>Cancel</Button>
          <Button variant="primary" onClick={() => callCopyAllFieldsAndActions()}>Copy All Fields and Actions</Button>
        </Modal.Footer>
      </Modal>

    </div>
  );

}
