import {PageHeader} from "../menu/PageHeader";
import {Container} from "react-bootstrap";
import React, {useContext} from "react";
import {UsacmContext} from "../App";
import {CONF_LABEL_KEY_ORG_INFO_CONTENT, CONF_LABEL_KEY_SYMPOSIUM} from "../constants";
import {getConfLabel, titleCase} from "../utils/usacmUtils";

export function OrgInfo() {
  const context = useContext(UsacmContext);
  const [conf,] = context.conference;
  const symposiumLabel = titleCase(getConfLabel(conf, CONF_LABEL_KEY_SYMPOSIUM));
  const htmlContent = getConfLabel(conf, CONF_LABEL_KEY_ORG_INFO_CONTENT)

  return (
      <div>
        <PageHeader pageTitle= {titleCase(symposiumLabel)+ " Organizer Info"} />
        <Container fluid className="usacm-container-medium">
          <div className="" dangerouslySetInnerHTML={{__html: htmlContent}}/>
        </Container>
      </div>
  );
}
