import {Button, Col, Container, FloatingLabel, Form, Modal, Row} from "react-bootstrap";
import React, {useEffect, useRef, useState} from "react";
import {getPermissionName, showSuccessToast} from "../utils/usacmUtils";
import {changeUserPassword, deleteUser, hasPermission, setEmailVerified} from "../api/UserApi";
import {ALL_PERMISSIONS, CALCULATED_PERMISSIONS, PERMISSION_CONF_ADMIN, PERMISSION_STAFF} from "../constants";
import {getConfUser, updateConfUserPermissions} from "../api/ConfApi";
import "./ConfUserEdit.scss";
import {fieldHasErrors, getErrorMessageForField} from "../utils/formUtils";

export function ConfUserEdit({userId, onClose, reloadUserList}) {
  const isStaff = hasPermission(PERMISSION_STAFF);
  const isAdmin = hasPermission(PERMISSION_CONF_ADMIN);
  const isStaffOrAdmin = isStaff || isAdmin;
  const [userData, setUserData] = useState(null);
  const [permissions, setPermissions] = useState([]);
  const [errors, setErrors] = useState([]);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('');
  const [showingDeleteConfirm, setShowingDeleteConfirm] = useState(false);
  const componentMounted = useRef(false);
  const targetUserIsStaff = permissions?.filter(p => p.checked && p.key===PERMISSION_STAFF).length > 0;

  // Create a list of permissions objects from the user permissions
  function generatePermissions(userPerms) {
    return ALL_PERMISSIONS.map(key => {
      return {'key': key, 'name': getPermissionName(key), 'checked': userPerms.includes(key)};
    });
  }

  function loadUserData() {
    getConfUser(userId, (code, data, errors) => {
      if (code === 200) {
        setUserData(data);
        setPermissions(generatePermissions(data.permissions));
      } else {
        setErrors(errors);
      }
    });
  }

  useEffect(() => {
    if (!componentMounted.current) {
      loadUserData();
      componentMounted.current = true;
    }
  }, [userId]);

  function verifyEmail() {
    setEmailVerified(userId, (code, data, errors) => {
      if (code === 200) {
        showSuccessToast('User email has been marked as verified.');
        reloadUserList();
        // Need to refresh the user data to show the newly verified status
        loadUserData();
        setErrors([]);
      } else {
        setErrors(errors);
      }
    });
  }

  function updatePermissions() {
    const hasPermissions = permissions.filter(p => p.checked).map(p => p.key);
    updateConfUserPermissions(userId, hasPermissions, (code, data, errors) => {
      if (code === 200) {
        showSuccessToast('Updated user permissions.');
        // Update the parent list data (values have changed)
        reloadUserList();
        setErrors([]);
      } else {
        setErrors(errors);
      }
    });
  }

  function permissionChecked(perm, newVal) {
    if (isStaffOrAdmin) {
      // Mutate the object
      perm.checked = newVal;
      // Shallow copy the array (to trigger a new render)
      setPermissions([...permissions]);
    }
  }

  function permissionDisabled(permKey) {
    if (permKey === PERMISSION_STAFF) {
      return true;
    }
    return CALCULATED_PERMISSIONS.includes(permKey);
  }

  function beginChangePassword() {
    // Clear the passwords when opening the dialog
    setNewPassword('');
    setNewPasswordConfirm('');
    setShowPasswordModal(true);
  }

  function changePassword() {
    changeUserPassword(userId, newPassword, newPasswordConfirm, (code, data, errors) => {
      if (code === 200) {
        showSuccessToast('Password has been changed.');
        setShowPasswordModal(false);
        setErrors([]);
      } else {
        setErrors(errors);
      }
    });
  }

  function showDeleteUserModal() {
    setShowingDeleteConfirm(true);
    setErrors([]);
  }

  function hideDeleteUserModal() {
    setShowingDeleteConfirm(false);
    setErrors([]);
  }

  function callDeleteUser() {
    setErrors([]);
    deleteUser(userId, (code, data, errors) => {
      if (code === 200) {
        showSuccessToast('User has been deleted.');
        setShowingDeleteConfirm(false);
        onClose()
        reloadUserList();
      } else {
        setErrors(errors);
      }
    });
  }

  return (
      <Container fluid className="usacm-container-narrow">
        <Row className="mt-3 mb-3">
          <Col className="col-3 usacm-label">
            Name
          </Col>
          <Col className="col-9">
            {userData?.first_name} {userData?.last_name}
          </Col>
        </Row>

        <Row className="mt-3 mb-3">
          <Col className="col-3 usacm-label">
            Email
          </Col>
          <Col className="col-9">
            {(userData?.email_verified) ?
                <span>{userData?.email}</span>
                :
                <span style={{color: 'red'}}>{userData?.email}<br/>(not verified)</span>
            }
            {isStaff && !userData?.email_verified &&
            <div>
              <Button onClick={verifyEmail}>Set Email as Verified</Button>
            </div>
            }
          </Col>
        </Row>

        <Row className="mt-3 mb-3">
          <Col className="col-3 usacm-label">
            Username
          </Col>
          <Col className="col-9">
            {userData?.username}
          </Col>
        </Row>

        {isStaff && !targetUserIsStaff &&
        <Row className="mt-3 mb-3">
          <Col className="col-3 usacm-label">
            Password
          </Col>
          <Col className="col-9">
            <Button onClick={() => beginChangePassword()}>Change Password</Button>
          </Col>
        </Row>
        }

        <Row className="mt-3 mb-3">
          <Col className="col-3 usacm-label">
            Country
          </Col>
          <Col className="col-9">
            {userData?.country}
          </Col>
        </Row>

        <Row className="mt-3 mb-3">
          <Col className="col-3 usacm-label">
            Affiliation
          </Col>
          <Col className="col-9">
            {userData?.affiliation}
          </Col>
        </Row>

        <Row className="mt-3 mb-3">
          <Col className="col-3 usacm-label">
            Permissions
          </Col>
          <Col className="col-9">
            <div className="permission-container">
              {permissions.map(perm =>
                  <div className="permission-item" key={perm.key}>
                    <Form.Check
                        type='checkbox'
                        id={perm.key}
                        label={perm.name}
                        disabled={permissionDisabled(perm.key)}
                        checked={perm.checked}
                        onChange={(event) => permissionChecked(perm, event.target.checked)}
                    />
                  </div>
              )}
            </div>
          </Col>
        </Row>

        {fieldHasErrors(errors, '') &&
        <Row>
          <Col className="text-center mb-3 usacm-error-message">
            {getErrorMessageForField(errors, '')}
          </Col>
        </Row>
        }

        <Row>
          <Col className="usacm-button-row">
            {isStaff && !targetUserIsStaff &&
            <Button variant="danger" onClick={() => showDeleteUserModal(true)}>Delete</Button>
            }
            <Button variant="secondary" onClick={() => onClose()}>Back to User List</Button>
            {isStaffOrAdmin && !targetUserIsStaff &&
            <Button onClick={updatePermissions}>Update Permissions</Button>
            }
          </Col>
        </Row>

        {/* Change password dialog */}
        <Modal show={showPasswordModal}
               onHide={() => setShowPasswordModal(false)}
               size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Change Password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col className="mb-3">
                <FloatingLabel controlId="newPassword" label="New Password">
                  <Form.Control type="password"
                                placeholder="New Password"
                                name="newPassword"
                                required
                                value={newPassword}
                                onChange={e => setNewPassword(e.target.value)}
                                isInvalid={fieldHasErrors(errors, 'new_password')}/>
                  <Form.Control.Feedback type="invalid">
                    {getErrorMessageForField(errors, 'new_password')}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Col className="mb-3">
                <FloatingLabel controlId="newPasswordConfirm" label="Confirm New Password">
                  <Form.Control type="password"
                                placeholder="Confirm New Password"
                                name="newPasswordConfirm"
                                required
                                value={newPasswordConfirm}
                                onChange={e => setNewPasswordConfirm(e.target.value)}
                                isInvalid={fieldHasErrors(errors, 'new_password_confirm')}/>
                  <Form.Control.Feedback type="invalid">
                    {getErrorMessageForField(errors, 'new_password_confirm')}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowPasswordModal(false)}>Cancel</Button>
            <Button variant="primary" onClick={() => changePassword()}>Change Password</Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showingDeleteConfirm}
               onHide={() => hideDeleteUserModal(false)}
               size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Confirm Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-3">
              Are you sure you want to delete this user?
            </div>
            {fieldHasErrors(errors, '') &&
            <div className="usacm-error-message">
              {getErrorMessageForField(errors, '')}
            </div>
            }
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => hideDeleteUserModal(false)}>Cancel</Button>
            <Button variant="danger" onClick={() => callDeleteUser()}>Delete User</Button>
          </Modal.Footer>
        </Modal>

      </Container>
  );

}
