import {Button, Col, Container, FloatingLabel, Form, Row} from "react-bootstrap";
import React, {Fragment, useEffect, useState} from "react";
import {clearObject, deepCopy, showSuccessToast} from "../utils/usacmUtils";
import {anyFieldHasErrors, fieldHasErrors, getErrorMessageForAllFields, getErrorMessageForField} from "../utils/formUtils";
import {ForceUpdateButton} from "../shared/ForceUpdateButton";
import {upsertField} from "../api/RegApi";
import {
  FIELD_TYPE_ABSTRACT,
  FIELD_TYPE_ADDRESS,
  FIELD_TYPE_BOOLEAN,
  FIELD_TYPE_CHOICELIST,
  FIELD_TYPE_DATE,
  FIELD_TYPE_FILE_UPLOAD,
  FIELD_TYPE_LABEL,
  FIELD_TYPE_NUMBER,
  FIELD_TYPE_SECTION,
  FIELD_TYPE_TEXT,
  FIELD_TYPES
} from "../constants";
import {TextFieldEditor} from "./fields/TextFieldEditor";
import {ChoicelistFieldEditor} from "./fields/ChoicelistFieldEditor";
import {NumberFieldEditor} from "./fields/NumberFieldEditor";
import {LabelFieldEditor} from "./fields/LabelFieldEditor";
import {DateFieldEditor} from "./fields/DateFieldEditor";

export function RegFieldEdit({origFieldData, onClose}) {
  const [fieldData, setFieldData] = useState(deepCopy(origFieldData));
  const [name, setName] = useState('');
  const [fieldKey, setFieldKey] = useState('');
  const [helpText, setHelpText] = useState('');
  const [required, setRequired] = useState(false);
  const [visible, setVisible] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [showInSummary, setShowInSummary] = useState(false);
  const [showOnInvoice, setShowOnInvoice] = useState(false);
  const [fieldType, setFieldType] = useState(FIELD_TYPE_TEXT);
  const [config, setConfig] = useState({});
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (origFieldData) {
      setFieldData(deepCopy(origFieldData));
      setName(origFieldData.name || '');
      setFieldKey(origFieldData.field_key || '');
      setHelpText(origFieldData.help_text || '');
      setRequired(!!origFieldData.required);
      setVisible(!!origFieldData.visible);
      setDisabled(!!origFieldData.disabled);
      setShowInSummary(!!origFieldData.show_in_summary);
      setShowOnInvoice(!!origFieldData.show_on_invoice);
      setFieldType(origFieldData.field_type || FIELD_TYPE_TEXT);
      setConfig(deepCopy(origFieldData.config) || {});
    }
  }, [origFieldData]);

  function callUpsertField(force = false) {
    // We will wait until all the JS events are handled (focus-lost in particular)
    setTimeout(() =>
        upsertField(fieldData?.id, fieldData.parent_field_id, name, fieldKey, helpText, required, visible, disabled, showInSummary,
          showOnInvoice, fieldType, config, fieldData?.field_order, (code, data, errors) => {
            if (code === 200) {
              showSuccessToast('Updated field.');
              onClose();
            } else {
              setErrors(errors);
            }
          })
      , 0);
  }

  function updateCheckbox(newRequired, newVisible, newDisabled) {
    setRequired(newRequired);
    setVisible(newVisible);
    setDisabled(newDisabled);
  }

  function getFieldEditorJsx() {
    if (fieldType === FIELD_TYPE_TEXT) {
      return <TextFieldEditor config={config}/>
    } else if (fieldType === FIELD_TYPE_CHOICELIST) {
      return <ChoicelistFieldEditor config={config}/>
    } else if (fieldType === FIELD_TYPE_NUMBER) {
      return <NumberFieldEditor config={config}/>
    } else if (fieldType === FIELD_TYPE_LABEL) {
      return <LabelFieldEditor config={config}/>
    } else if (fieldType === FIELD_TYPE_FILE_UPLOAD) {
      // TODO : file upload - maybe allowed-extensions or mime-types, max-size
      return <Fragment/>;
    } else if (fieldType === FIELD_TYPE_DATE) {
      return <DateFieldEditor config={config}/>
    } else if ([FIELD_TYPE_SECTION, FIELD_TYPE_BOOLEAN, FIELD_TYPE_ABSTRACT, FIELD_TYPE_ADDRESS].includes(fieldType)) {
      // No extra data or settings for these field types
      return <Fragment/>;
    }
    console.warn('Unable to get field editor for fieldType=' + fieldType);
    return <Fragment/>;
  }

  function changeFieldType(newFieldType) {
    setFieldType(newFieldType);
    clearObject(config); // Config has to match the field type, when you change fieldType the old config is useless
  }

  return (
    <Container fluid className="usacm-container-medium">

      <Row className="mb-3">
        <Col className="col-12 text-center fw-bold">
          {fieldData?.id ? 'Update' : 'Add'} {fieldType === FIELD_TYPE_SECTION ? 'Section' : 'Field'}
        </Col>
      </Row>

      <Row className="mb-3">
        <Col className="col-12">
          <Form.Group controlId="name">
            <FloatingLabel controlId="name" label="Field Name">
              <Form.Control type="text"
                            placeholder="Field Name"
                            name="name"
                            value={name}
                            onChange={e => setName(e.target.value)}
                            isInvalid={fieldHasErrors(errors, 'name')}/>
              <Form.Control.Feedback type="invalid">
                {getErrorMessageForField(errors, 'name')}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col className="col-12">
          <Form.Group controlId="field_key">
            <FloatingLabel controlId="field_key" label="Field Key">
              <Form.Control type="text"
                            placeholder="Field Key"
                            name="field_key"
                            value={fieldKey}
                            onChange={e => setFieldKey(e.target.value)}
                            isInvalid={fieldHasErrors(errors, 'field_key')}/>
              <Form.Control.Feedback type="invalid">
                {getErrorMessageForField(errors, 'field_key')}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col className="col-12">
          <Form.Group controlId="help-text">
            <FloatingLabel controlId="help-text" label="Help Text">
              <Form.Control type="text"
                            placeholder="Help Text"
                            name="help_text"
                            value={helpText}
                            onChange={e => setHelpText(e.target.value)}
                            isInvalid={fieldHasErrors(errors, 'help_text')}/>
              <Form.Control.Feedback type="invalid">
                {getErrorMessageForField(errors, 'help_text')}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col className="col-12 d-flex justify-content-evenly mt-2">
          {fieldType !== FIELD_TYPE_SECTION &&
            <Form.Check
              type="checkbox"
              label="Required"
              id="required"
              checked={required}
              onChange={e => updateCheckbox(e.target.checked, visible, disabled)}
            />
          }
          <Form.Check
            type="checkbox"
            label="Visible"
            id="visible"
            checked={visible}
            onChange={e => updateCheckbox(required, e.target.checked, disabled)}
          />
          {fieldType !== FIELD_TYPE_SECTION &&
            <Form.Check
              type="checkbox"
              label="Disabled"
              id="disabled"
              checked={disabled}
              onChange={e => updateCheckbox(required, visible, e.target.checked)}
            />
          }
          {fieldType !== FIELD_TYPE_SECTION &&
            <Form.Check
              type="checkbox"
              label="Show in Summary"
              id="show_in_summary"
              checked={showInSummary}
              onChange={e => setShowInSummary(e.target.checked)}
            />
          }
          {fieldType !== FIELD_TYPE_SECTION &&
            <Form.Check
              type="checkbox"
              label="Show on Invoice"
              id="show_on_invoice"
              checked={showOnInvoice}
              onChange={e => setShowOnInvoice(e.target.checked)}
            />
          }
        </Col>
      </Row>

      <Row className="mb-3">
        <Col className="col-12 d-flex">
          <div className='me-3'>Field Type</div>
          {fieldType !== FIELD_TYPE_SECTION &&
            <Form.Group controlId="field-type">
              <Form.Control
                className="form-select"
                as="select"
                value={fieldType || FIELD_TYPE_TEXT}
                onChange={e => changeFieldType(e.target.value)}
                isInvalid={fieldHasErrors(errors, 'field_type')}
              >
                {FIELD_TYPES.filter(ft => ft.field_type !== FIELD_TYPE_SECTION).map(ft => {
                  return <option value={ft.field_type} key={ft.field_type}>{ft.display}</option>
                })}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {getErrorMessageForField(errors, 'field_type')}
              </Form.Control.Feedback>
            </Form.Group>
          }
          {fieldType === FIELD_TYPE_SECTION &&
            <div>Section</div>
          }
        </Col>
      </Row>

      {getFieldEditorJsx()}

      {anyFieldHasErrors(errors, ['']) &&
        <Row>
          <Col className="text-center mb-3 usacm-error-message">
            {getErrorMessageForAllFields(errors, [''])}
          </Col>
        </Row>
      }

      <Row>
        <Col className="usacm-button-row">
          <Button variant="secondary" onClick={() => onClose()}>Cancel</Button>
          <Button
            onClick={() => callUpsertField(false)}> Save </Button>
          <ForceUpdateButton errors={errors} onClick={() => callUpsertField(true)}/>
        </Col>
      </Row>

    </Container>
  );
}

