import {Button, Col, FloatingLabel, Form, Modal, Row} from "react-bootstrap";
import React, {Fragment, useRef, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {fieldHasErrors, getErrorMessageForField} from "../../utils/formUtils";
import {HtmlEditor} from "../../shared/HtmlEditor";

export function ChoicelistFieldEditor({config}) {
  const [refresh, setRefresh] = useState(0);

  // Options
  const [showingOptionDialog, setShowingOptionDialog] = useState(false);
  const [optionId, setOptionId] = useState(null);
  const [optionValue, setOptionValue] = useState('');
  const [optionVisible, setOptionVisible] = useState(true);
  const [optionDesc, setOptionDesc] = useState('');
  const optionDescEditorRef = useRef(null);
  const [optionErrors, setOptionErrors] = useState([]);

  // Setup default values
  if (typeof config.useRadio === "undefined") {
    config.useRadio = false;
  }
  if (typeof config.showOther === "undefined") {
    config.showOther = false;
  }

  function updateConfig(key, value) {
    config[key] = !!value;
    setRefresh(refresh + 1);
  }

  /**
   * Creates a new ID unique in config.options
   */
  function getNewId() {
    if (!config.options || !config.options.length) {
      return 1;
    }
    return Math.max(...config.options.map(d => d.id || 0)) + 1;
  }

  function addOption() {
    if (!config.options) {
      config.options = []
    }
    setOptionId(null);
    setOptionValue('');
    setOptionVisible(true);
    setOptionDesc('');
    setShowingOptionDialog(true);
  }

  function startEditingOption(option) {
    setOptionId(option.id);
    setOptionValue(option.value || '');
    setOptionVisible(!!option.visible);
    setOptionDesc(option.desc || '');
    setShowingOptionDialog(true);
  }

  function deleteOption(option) {
    if (!config.options) {
      return;
    }
    const index = config.options.map(o => o.id).indexOf(option.id);
    if (index < 0) {
      console.warn('Cannot delete option, could not find option ', option, ' in ', config.options);
      return;
    }
    config.options.splice(index, 1);
    setRefresh(refresh + 1);
  }

  function swapSortOrder(indexA, indexB) {
    const a = config.options[indexA];
    const b = config.options[indexB];
    config.options.splice(indexA, 1, b);
    config.options.splice(indexB, 1, a);
    setRefresh(refresh + 1);
  }

  function doneEditingOption() {
    setOptionErrors([]);
    if (!optionValue) {
      // Don't add blank options
      return;
    }
    if (config.options.find(o => o.value === optionValue && o.id !== optionId)) {
      setOptionErrors([{'message': 'There is already an option with that value.', 'fields': ['option_value']}])
      return;
    }
    if (!optionDescEditorRef.current) {
      console.warn("Unable to get editor ref component");
      return;
    }
    const newOptionDesc = optionDescEditorRef.current.getContent();
    if (!optionId) {
      config.options.push({
        id: getNewId(),
        value: optionValue,
        visible: optionVisible,
        desc: newOptionDesc,
      });
    } else {
      const option = config.options.find(o => o.id === optionId);
      if (option) {
        option.value = optionValue;
        option.visible = optionVisible;
        option.desc = newOptionDesc;
      } else {
        console.warn('Unable to find option with id ' + optionId + ' in ' + config.options);
      }
    }
    setShowingOptionDialog(false);
  }

  return (
      <Fragment>
        <Row className="mb-2">
          <Col className="col-12 mt-2 d-flex justify-content-evenly">
            <Form.Check
                type="checkbox"
                label="Use Radio Buttons"
                id="useRadio"
                checked={config.useRadio || false}
                onChange={e => updateConfig('useRadio', e.target.checked)}
            />

            <Form.Check
                type="checkbox"
                label="Show Other"
                id="showOther"
                checked={config.showOther || false}
                onChange={e => updateConfig('showOther', e.target.checked)}
            />

          </Col>
        </Row>

        <Row className="mb-3">
          <Col className="col-12">
            <div className="fw-bold">Options</div>
            {(config.options || []).map((option, index) =>
                <div key={option.id} className="d-flex align-items-start mt-3 mb-3 ms-3">
                  <Button size='sm' onClick={() => deleteOption(option)} className="text-nowrap me-2">Delete</Button>
                  <Button size='sm' onClick={() => startEditingOption(option)} className="text-nowrap me-2">Edit</Button>
                  <Button type="button" size="sm" className="me-1"
                          onClick={() => swapSortOrder(index, index - 1)}
                          disabled={index === 0}>
                    <FontAwesomeIcon icon="fa-angle-up"/>
                  </Button>
                  <Button type="button" size="sm" className="me-3"
                          onClick={() => swapSortOrder(index, index + 1)}
                          disabled={index + 1 === config.options.length}>
                    <FontAwesomeIcon icon="fa-angle-down"/>
                  </Button>
                  <div>
                    {option.value} ({option.visible ? 'visible' : 'not visible'})
                    {option.desc &&
                    <div className="" dangerouslySetInnerHTML={{__html: option.desc}}/>
                    }
                  </div>
                </div>
            )}
          </Col>
        </Row>

        <Row className="mb-3">
          <Col className="col-12 d-flex">
            <Button onClick={() => addOption()} className="text-nowrap ms-2">Add Option</Button>
          </Col>
        </Row>

        <Modal show={showingOptionDialog}
               onHide={() => setShowingOptionDialog(false)}
               data-bs-focus="false"
               enforceFocus={false}  // This will prevent bootstrap from messing with the focus and breaking tinyMCE
               size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Edit Option</Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-3">
            <div className="mb-3">
              <FloatingLabel controlId="option_value" label="Option Value">
                <Form.Control type="text"
                              placeholder="Option Value"
                              name="option_value"
                              value={optionValue}
                              onChange={e => setOptionValue(e.target.value)}
                              isInvalid={fieldHasErrors(optionErrors, 'option_value')}/>
                <Form.Control.Feedback type="invalid">
                  {getErrorMessageForField(optionErrors, 'option_value')}
                </Form.Control.Feedback>
              </FloatingLabel>
            </div>

            <div className="mb-3">
              Option Description. This will show below the option value when in radio mode.
              <HtmlEditor
                  initialValue={optionDesc || ''}
                  onInit={(evt, editor) => optionDescEditorRef.current = editor}
              />
            </div>
            <div className='ms-2'>
              <Form.Check
                  type="checkbox"
                  label="Default Visible"
                  id="option_visible"
                  checked={!!optionVisible}
                  onChange={e => setOptionVisible(e.target.checked)}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowingOptionDialog(false)}>Cancel</Button>
            <Button variant="primary" onClick={() => doneEditingOption()}>Done</Button>
          </Modal.Footer>
        </Modal>

      </Fragment>
  );
}

