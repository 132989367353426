import {PageHeader} from "../menu/PageHeader";
import {Container} from "react-bootstrap";
import React, {useContext} from "react";
import {UsacmContext} from "../App";
import {CONF_LABEL_KEY_FAQ_CONTENT} from "../constants";
import {getConfLabel} from "../utils/usacmUtils";

export function Faq() {
  const context = useContext(UsacmContext);
  const [conf,] = context.conference;
  const faqHtml = getConfLabel(conf, CONF_LABEL_KEY_FAQ_CONTENT)

  return (
      <div>
        <PageHeader pageTitle= "Frequently Asked Questions" />
        <Container fluid className="usacm-container-medium">
          <div className="" dangerouslySetInnerHTML={{__html: faqHtml}}/>
        </Container>
      </div>
  );
}
