import {Button, Container, FloatingLabel, Form} from "react-bootstrap";
import React, {useState} from "react";
import {showSuccessToast} from "../utils/usacmUtils";
import {regFieldSync} from "../api/AdminApi";
import {fieldHasErrors, getErrorMessageForField} from "../utils/formUtils";

const SYNC_MODE_ADD = 'add';
const SYNC_MODE_UPDATE = 'update';

export function RegFields() {
  const [regId, setRegId] = useState('');
  const [errors, setErrors] = useState([]);

  function callRegFieldSync(mode) {
    setErrors([]);
    regFieldSync(regId, mode, (code, data, errors) => {
      if (code === 200) {
        if (mode === SYNC_MODE_ADD) {
          if (data['fields_added']?.length > 0) {
            const fieldNames = data['fields_added'].map(f => f.name).join(', ');
            showSuccessToast('Missing reg fields have been added : ' + fieldNames);
          } else {
            showSuccessToast('No missing fields found');
          }
        } else if (mode === SYNC_MODE_UPDATE) {
          if (data['fields_updated']?.length > 0) {
            const fieldNames = data['fields_updated'].map(f => f.name).join(', ');
            showSuccessToast('Updated reg fields : ' + fieldNames);
          } else {
            showSuccessToast('No reg fields need updating.');
          }
        }
      } else {
        setErrors(errors);
      }
    });
  }

  return (
    <Container fluid className="usacm-container-medium">
      <div className='mt-3'>
        When registration fields or sections are added any reg that has already been created will be missing the new fields and their
        settings.
      </div>
      <div className='mt-3'>
        <b>Create Missing Reg Fields</b> : Will create fields in the reg to match the current field list.
      </div>
      <div className='mt-3'>
        <b>Update Reg Fields</b> : Will update all the fields in the reg to match the current field settings. This will add missing
        choicelist options, as well as field-type specific settings like : "Show as Radio", "Show Other", "Long Text", "Min", "Max" etc.
      </div>

      <div className='mt-3 d-flex align-items-center'>
        <Form.Group controlId="reg-id">
          <FloatingLabel controlId="reg-id" label="Reg ID">
            <Form.Control
              type="number"
              placeholder="Reg ID"
              name="reg_id"
              value={regId}
              onChange={e => setRegId(e.target.value)}
              isInvalid={fieldHasErrors(errors, '')}
            />
            <Form.Control.Feedback type="invalid">
              {getErrorMessageForField(errors, '')}
            </Form.Control.Feedback>
          </FloatingLabel>
        </Form.Group>
        <div className='ms-3 usacm-button-row'>
          <Button type="button" onClick={() => callRegFieldSync(SYNC_MODE_ADD)}>Create Missing Reg Fields</Button>
          <Button type="button" onClick={() => callRegFieldSync(SYNC_MODE_UPDATE)}>Update Reg Fields</Button>
        </div>
      </div>

    </Container>
  );
}
