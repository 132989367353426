import {get, post} from '../utils/networkUtils';

/**
 * Gets a list of sessions
 */
export function getSessions(onComplete) {
  get('schedule/session/list/', onComplete);
}

/**
 * Gets a single session
 */
export function getSession(sessionId, onComplete) {
  get(`schedule/session/${sessionId}/`, onComplete);
}

/**
 * Creates or updates a session
 */
export function upsertSession(sessionId, sessionNumber, name, date, onComplete) {
  const data = {
    session_id: sessionId,
    session_number: sessionNumber,
    name: name,
    date: date,
  };
  post('schedule/session/upsert/', data, onComplete);
}

/**
 * Deletes a session
 */
export function deleteSession(sessionId, onComplete) {
  const data = {
    session_id: sessionId,
  };
  post('schedule/session/delete/', data, onComplete);
}

/**
 * Creates or updates a timeslot
 */
export function upsertTimeslot(timeslotId, sessionId, startTime, endTime, onComplete) {
  const data = {
    timeslot_id: timeslotId,
    session_id: sessionId,
    start_time: startTime,
    end_time: endTime,
  };
  post('schedule/timeslot/upsert/', data, onComplete);
}

/**
 * Deletes a timeslot
 */
export function deleteTimeslot(timeslotId, onComplete) {
  const data = {
    timeslot_id: timeslotId,
  };
  post('schedule/timeslot/delete/', data, onComplete);
}

/**
 * Gets the schedule
 */
export function getScheduleRoomSessions(onComplete) {
  get('schedule/roomsession/list/', onComplete);
}

/**
 * Gets a list of roomSession for the symposium
 */
export function getRoomSessionsForSymposium(symposiumId, onComplete) {
  get('schedule/roomsession/symposium/' + symposiumId + '/list/', onComplete);
}

/**
 * Creates or updates a roomsession
 */
export function upsertRoomSession(roomId, sessionId, symposiumId, onComplete) {
  const data = {
    room_id: roomId,
    session_id: sessionId,
    symposium_id: symposiumId,
  };
  post('schedule/roomsession/upsert/', data, onComplete);
}

/**
 * Gets a list of chairs for a roomsession
 */
export function getRoomSessionChairs(roomSessionId, onComplete) {
  get('schedule/roomsession/' + roomSessionId + '/chair/list/', onComplete);
}

/**
 * Gets a list of timeslots for a symposium
 */
export function getRoomSessionTimeslotsForSymposium(symposiumId, onComplete) {
  get('schedule/roomsessiontimeslot/symposium/' + symposiumId + '/list/', onComplete);
}

/**
 * Updates roomSessionTimeslot entries
 * @param roomSessionTimeslots This is an array of custom objects to do the update/insert
 * @param force skip some validation on updating the timeslots
 * @param onComplete callback
 */
export function upsertRoomSessionTimeslots(roomSessionTimeslots, force, onComplete) {
  const data = {
    roomsessiontimeslots: roomSessionTimeslots,
    force: force,
  };
  post('schedule/roomsessiontimeslot/upsert/', data, onComplete);
}

/**
 * Updates roomSessionTimeslot entries
 * @param roomSessionId room session to update chairs in
 * @param chairs An array of chair objects (including a deleted flag)
 * @param onComplete callback
 */
export function updateChairs(roomSessionId, chairs, onComplete) {
  const data = {
    roomsession_id: roomSessionId,
    chairs: chairs,
  };
  post('schedule/roomsession/chair/update/', data, onComplete);
}

/**
 * Updates symposium.scheduling_complete
 * @param symposiumId id of symposium
 * @param schedulingComplete : true or false
 * @param onComplete callback
 */
export function updateSchedulingComplete(symposiumId, schedulingComplete, onComplete) {
  const data = {
    symposium_id: symposiumId,
    scheduling_complete: schedulingComplete,
  };
  post('schedule/scheduling_complete/update/', data, onComplete);
}

/**
 * Searches for roomSessionTimeslots that match the search term by abstract, author or symposium
 * @param searchTerm to search
 * @param onComplete callback the search results
 */
export function searchSchedule(searchTerm, onComplete) {
  const data = {
    search_term: searchTerm,
  };
  post('schedule/search/', data, onComplete);
}
