import React, {Fragment, useContext, useState} from "react";
import {Button, FloatingLabel, Form} from "react-bootstrap";
import {fieldHasErrors, getErrorMessageForField} from "../utils/formUtils";
import {updateRegAssignedUser} from "../api/RegApi";
import {UsacmContext} from "../App";
import {getUserEmail, hasPermission} from "../api/UserApi";
import {PERMISSION_CALC_HAS_REG, REG_STATUS_IN_PROGRESS} from "../constants";
import {ForceUpdateButton} from "../shared/ForceUpdateButton";

export function RegAssignUser({reg, setReg, onUserChange}) {
  const context = useContext(UsacmContext);
  const [conf,] = context.conference;
  const [email, setEmail] = useState('');
  const [showEmail, setShowEmail] = useState(false);
  const [errors, setErrors] = useState([]);
  const hasReg = hasPermission(PERMISSION_CALC_HAS_REG);
  const currUserCannotReg = hasReg && !conf?.reg_allow_multiple && reg?.assigned_user_email === getUserEmail();
  const reg_in_progress = reg?.reg_status === REG_STATUS_IN_PROGRESS;

  function setRegUser(force=false) {
    setErrors([]);
    updateRegAssignedUser(reg.id, email, force, (code, data, errors) => {
      if (code === 200) {
        setReg(data.reg);
        setShowEmail(false);
        setEmail('');
        if (onUserChange) {
          onUserChange();
        }
      } else {
        setErrors(errors);
      }
    });
  }

  function cancelChangeUser() {
    setShowEmail(false);
    setEmail('');
    setErrors([]);
  }

  return (
      <Fragment>
        <div className="d-flex mb-3">
          <div className='me-3 my-auto'>
            Registration for {reg?.assigned_user_name}, {reg?.assigned_user_email}
          </div>
          {!showEmail &&
          <Button onClick={() => setShowEmail(true)}> Change User </Button>
          }
        </div>

        {!showEmail && currUserCannotReg && reg_in_progress &&
        <div className='usacm-error-message'>
          This user already has a registration and cannot register again. Please choose a different user.
        </div>
        }

        <div className="d-flex mb-3">
          {showEmail &&
          <Fragment>
            <div className='flex-grow-1'>
              <Form.Group controlId="email">
                <FloatingLabel controlId="email" label="Enter user email">
                  <Form.Control type="text"
                                placeholder="Email"
                                name="code"
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                isInvalid={fieldHasErrors(errors, 'email')}/>
                  <Form.Control.Feedback type="invalid">
                    {getErrorMessageForField(errors, 'email')}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
            </div>
            <div className='ms-3 mt-2'>
              <Button variant='secondary' onClick={() => cancelChangeUser()} className='me-3'> Cancel </Button>
              <Button onClick={() => setRegUser(false)} className='me-3'> Change User </Button>
              <ForceUpdateButton errors={errors} onClick={() => setRegUser(true)}/>
            </div>
          </Fragment>
          }
        </div>

      </Fragment>
  );

}
