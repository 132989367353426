import {Form} from "react-bootstrap";
import React, {useState} from "react";
import {updateFieldAnswer} from "../../api/RegApi";
import {HelpIcon} from "../../shared/HelpIcon";
import {fieldHasErrors, getErrorMessageForField} from "../../utils/formUtils";

export function BooleanFieldInput({field, onAnswerChange = null, setFields = null, setPay = null}) {
  const [refresh, setRefresh] = useState(0);
  const controlId = 'boolean_input_' + field.id;

  if (!field.answer) {
    field.answer = {value: false};
    if (onAnswerChange) {
      // SetTimout to avoid updating the state in the parent component while rendering this one
      setTimeout(() => onAnswerChange(field.answer));
    } else {
      callUpdateFieldAnswer();
    }
  }

  function callUpdateFieldAnswer() {
    updateFieldAnswer(field.id, field.answer, (code, data, errors) => {
      if (code === 200) {
        // If there is new field data, that means something besides this field changed, so we need to update the entire reg
        if (data) {
          if (setFields) {
            setFields(data.fields);
          }
          if (setPay) {
            setPay(data.pay);
          }
        }
      } else {
        field.errors = errors;
        setRefresh(refresh + 1);
      }
    });
  }

  // The user clicked the box
  function userClicked(checked) {
    field.answer.value = checked;
    setRefresh(refresh + 1);
    if (onAnswerChange) {
      onAnswerChange(field.answer);
    } else {
      callUpdateFieldAnswer();
    }
  }

  return (
      <div>
        <div className="d-flex">
          <Form.Check
              className="mt-2"
              type="checkbox"
              label={field.name + (field.required ? ' *' : '')}
              id={controlId}
              checked={field.answer.value || false}
              onChange={e => userClicked(e.target.checked)}
              isInvalid={fieldHasErrors(field.errors, '')}
          />
          {field.help_text &&
          <HelpIcon text={field.help_text} className="ms-3"/>
          }
        </div>
        <div className="usacm-invalid-feedback">
          {getErrorMessageForField(field.errors, '')}
        </div>
      </div>
  );
}

