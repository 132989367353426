import React, {useState} from 'react';
import {Button, Col, Container, FloatingLabel, Form, Row} from "react-bootstrap";
import {resetEmailFieldForAbstract} from '../api/AbstractApi';
import {showSuccessToast} from "../utils/usacmUtils";
import {fieldHasErrors, getErrorMessageForField} from "../utils/formUtils";
import {FLAG_ABSTRACT_FINAL_APPROVED, FLAG_ABSTRACT_FINAL_DENIED} from "../constants";

export default function ToolsAbstractFinal() {
  const [abstractNum, setAbstractNum] = useState('');
  const [errors, setErrors] = useState([]);

  function AbstractNumOnChange(event) {
    setAbstractNum(event.target.value);
  }

  function callResetEmailFieldForAbstract(abstractAction) {
    // Clears previous errors
    setErrors([]);

    resetEmailFieldForAbstract(abstractNum, abstractAction, (code, data, errors) => {
      if (code === 200) {
        showSuccessToast('Reset email field for approved abstract successfully.');
        setAbstractNum('');
      } else {
        setErrors(errors);
      }
    })
  }

  return (
    <Container fluid className="usacm-container-medium">
      <Row>
        <Col>
          <div>
            This screen allows you to unset the "Final Approved" and "Final Denied" flags on abstracts.
          </div>
          <div className='mt-3'>
            Using this function will not send any email, but afterward you can set the flag again (on the Abstract List page)
            which will cause a second email to be sent to the user.
          </div>
          <div className='mt-3'>
            The abstract number you enter can optionally have the abstract number prefix and leading zeros. Eg.
            'PC2024004', '004' and '4' are all valid and indicate the same abstract.
          </div>
        </Col>
      </Row>

      <Row className="mt-3 pt-3">
        <Col>
          <Form.Group controlId="abstract-id">
            <FloatingLabel controlId="abstract-id" label="Abstract Number">
              <Form.Control
                type="text"
                placeholder="Abstract Number"
                name="abstract_number"
                value={abstractNum}
                onChange={AbstractNumOnChange}
                isInvalid={fieldHasErrors(errors, 'abstract_num')}
              />
              <Form.Control.Feedback type="invalid">
                {getErrorMessageForField(errors, 'abstract_num')}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>
        <Col className='d-flex align-items-center gap-2'>
          <Button onClick={() => callResetEmailFieldForAbstract(FLAG_ABSTRACT_FINAL_APPROVED)}>
            Unset Approved
          </Button>
          <Button onClick={() => callResetEmailFieldForAbstract(FLAG_ABSTRACT_FINAL_DENIED)}>
            Unset Denied
          </Button>
        </Col>
      </Row>

      {fieldHasErrors(errors, '') &&
        <Row>
          <Col className="text-center mb-3 usacm-error-message">
            {getErrorMessageForField(errors, '')}
          </Col>
        </Row>
      }
    </Container>
  )
}