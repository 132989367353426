import React from 'react';
import {Editor} from "@tinymce/tinymce-react";

export const TINYMCE_DEFAULT_INIT = {
  plugins: 'code lists link',
  menubar: 'edit insert format tools',
  toolbar: 'undo redo | bold italic numlist bullist outdent indent',
  content_style: 'p, ol, ul, dl {margin-top:0; margin-bottom:0}',
};

export function HtmlEditor({initialValue, onInit, disabled = false, onBlur = null, onChange = null}) {
  return (
      <Editor
          initialValue={initialValue}
          onInit={onInit}
          init={TINYMCE_DEFAULT_INIT}
          disabled={disabled}
          apiKey='7g7o8k0fhtpc7ewx5fr7nuhke3bi57um9xq08s5evcfcb6ej'
          onBlur={onBlur}
          onEditorChange={onChange}
      />
  );
}
