import {Container, Tab, Tabs} from "react-bootstrap";
import {PageHeader} from "../menu/PageHeader";
import React from "react";
import ToolsAbstractFinal from "./ToolsAbstractFinal";
import {ToolsCache} from "./ToolsCache";
import {UserTransfer} from "./UserTransfer";
import {RegFields} from "./RegFields";

export function ToolsTabs() {

  return (
    <div>
      <PageHeader pageTitle={"Tools"}/>
      <Container fluid className="usacm-container-wide">
        <Tabs
          defaultActiveKey="cache"
          id="tools-tabs"
          className="mb-3"
          mountOnEnter={true}
          unmountOnExit={true}
        >
          <Tab eventKey="cache" title="Cache">
            <ToolsCache/>
          </Tab>
          <Tab eventKey="final" title="Reset Final">
            <ToolsAbstractFinal/>
          </Tab>
          <Tab eventKey="user" title="User Transfer">
            <UserTransfer/>
          </Tab>
          <Tab eventKey="reg-fields" title="Reg Fields">
            <RegFields/>
          </Tab>
        </Tabs>
      </Container>
    </div>
  );

}
