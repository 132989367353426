import {Link, useLocation} from "react-router-dom";
import React, {Fragment, useContext, useEffect, useState} from "react";
import "./MainMenu.scss"
import {UsacmContext} from "../App";
import {canViewAbstracts, canViewSymposia, hasPermission, logout} from "../api/UserApi";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  CONF_LABEL_KEY_CONFERENCE,
  CONF_LABEL_KEY_FAQ_CONTENT,
  CONF_LABEL_KEY_ORG_INFO_CONTENT,
  CONF_LABEL_KEY_SYMPOSIUM,
  LOGO_SHAPE_SQUARE,
  LOGO_SHAPE_WIDE,
  PERMISSION_ATTENDANCE_ENTRY,
  PERMISSION_CALC_ABSTRACT_PRESENTING_AUTHOR,
  PERMISSION_CALC_HAS_REG,
  PERMISSION_CALC_SYMPOSIUM_ORGANIZER,
  PERMISSION_CONF_ADMIN,
  PERMISSION_CONF_ORGANIZER,
  PERMISSION_STAFF
} from "../constants";
import {getConfLabel, scrollToTop, titleCase} from "../utils/usacmUtils";
import {isForceLogout} from "../utils/networkUtils";
import {useNavigate} from "react-router";

export function MainMenu() {
  const navigate = useNavigate();
  const context = useContext(UsacmContext);
  const [userLoggedIn, setUserLoggedIn] = context.loggedIn;
  const [conf,] = context.conference;
  const [, setNavChanged] = context.navigationChanged;
  const [emailVerified,] = context.verified;
  const [burgerExpanded, setBurgerExpanded] = useState(false);
  const isConfOrganizer = hasPermission(PERMISSION_CONF_ORGANIZER);
  const isStaff = hasPermission(PERMISSION_STAFF);
  const isAdmin = hasPermission(PERMISSION_CONF_ADMIN);
  const isStaffAdminOrg = isStaff || isAdmin || isConfOrganizer;
  const symposiumOrganizer = hasPermission(PERMISSION_CALC_SYMPOSIUM_ORGANIZER)
  const isSymposiumViewer = canViewSymposia();
  const isAbstractViewer = canViewAbstracts();
  const location = useLocation();
  const currentPath = location.pathname;
  const confLabel = titleCase(getConfLabel(conf, CONF_LABEL_KEY_CONFERENCE));
  const symposiumLabel = titleCase(getConfLabel(conf, CONF_LABEL_KEY_SYMPOSIUM));
  const attendanceEntry = hasPermission(PERMISSION_ATTENDANCE_ENTRY) || isStaff;
  const hasFaq = !!getConfLabel(conf, CONF_LABEL_KEY_FAQ_CONTENT);
  const hasOrgInfo = !!getConfLabel(conf, CONF_LABEL_KEY_ORG_INFO_CONTENT);
  const showOrgInfo = hasOrgInfo && (isStaffAdminOrg || symposiumOrganizer);
  const useSymposia = conf?.use_symposia === null ? true : conf?.use_symposia;
  const showProposeSymposium = isStaff || isAdmin || (
    conf?.deadlines?.past_symposium_proposal_deadline === false || !conf?.hide_propose_symposium_after_deadline);
  const useAbstracts = conf?.use_abstracts === null ? true : conf?.use_abstracts;
  const showSubmitAbstract = isStaff || isAdmin || (
    (conf?.deadlines?.before_abstract_submit_start === false) &&
    (conf?.deadlines?.past_abstract_submit_end === false || !conf?.hide_submit_abstract_after_end_date));
  const canSearchSchedule = (conf?.deadlines?.before_searchable_schedule_start === false);
  const useRegistration = conf?.use_registration === null ? true : conf?.use_registration;
  const showReg = isStaffAdminOrg || hasPermission(PERMISSION_CALC_HAS_REG) || !conf?.deadlines?.before_reg_start;
  const regUseAttendance = !!conf?.reg_use_attendance;
  const wideLogo = conf?.theme?.logo_url && conf?.theme?.logo_shape === LOGO_SHAPE_WIDE;
  const squareLogo = conf?.theme?.logo_url && conf?.theme?.logo_shape === LOGO_SHAPE_SQUARE;
  const isPresentingAuthor = hasPermission(PERMISSION_CALC_ABSTRACT_PRESENTING_AUTHOR);
  const showExtendedAbstract = conf?.use_extended_abstract && isPresentingAuthor &&
    (conf?.deadlines?.before_extended_abstract_start === false);

  useEffect(() => {
    if (isForceLogout()) {
      callLogout(); // this will reset the forceLogout flag
    }
    if (burgerExpanded) {
      scrollToTop();
    }
    setBurgerExpanded(false);
  }, [location]);

  function toggleBurger() {
    scrollToTop();
    setBurgerExpanded(!burgerExpanded)
  }

  // These are children of the parent path - used for marking the left menu selected
  // The child paths are prefixes so child /a will match /asdf
  const childPaths = {
    '/conf-user-list': ['/conf-edit-user/'],
    '/room-list': ['/room-list/upsert'],
  };

  function getClassNames(path, mobileOnly = false) {
    let classNames = "menu-item "
    if (mobileOnly) {
      classNames += "menu-mobile-only "
    }
    // If path matches, or if the currentPath is a child we highlight
    if ((path === currentPath) || childPaths[path]?.find(p => currentPath.startsWith(p))) {
      classNames += "menu-selected-path "
    }
    return classNames
  }

  function callLogout() {
    logout(navigate, setUserLoggedIn)
  }

  // This will handle navigating on a link clicked - This will setNavChanged so components can listen for the change and update their state
  // I'm not certain, but it seemed that using the to='url' instead of navigate() caused an extra render
  function gotoUrl(e, toUrl) {
    e.preventDefault(); // stops the to='url' from running
    setNavChanged(toUrl + '?rand=' + Math.random());
    navigate(toUrl);
  }

  // This returns the link attributes - this is pretty standard for most of the links
  // If the link needs a custom onClick or some custom classes you may not want to use this.
  function getLinkAttrs(url, mobileOnly = false) {
    return {
      className: getClassNames(url, mobileOnly),
      to: 'not-used', // we will use history.push instead
      onClick: (e) => gotoUrl(e, url),
    };
  }

  return (
    <div id='menu-top' className={"main-menu " + (burgerExpanded ? "burger-expanded" : "burger-collapsed")}>
      {wideLogo &&
        <div className='conf-logo-wide'>
          <img src={conf?.theme?.logo_url} alt='logo'/>
        </div>
      }
      <div className="menu-title">
        <FontAwesomeIcon icon="fa-bars" className="menu-burger" onClick={() => toggleBurger()}/>
        {squareLogo &&
          <div className='conf-logo-square'>
            <img src={conf?.theme?.logo_url} alt='logo'/>
          </div>
        }
        <div className="conf-title mx-auto my-auto">{conf?.short_name}</div>
      </div>

      {userLoggedIn &&
        <Fragment>
          <Link {...getLinkAttrs('/')}>
            <FontAwesomeIcon icon="fa-house"/>Dashboard
          </Link>
          <Link {...getLinkAttrs('/profile', true)}>
            <FontAwesomeIcon icon="fa-user"/>Profile
          </Link>
          <Link {...getLinkAttrs('/change-password', true)}>
            <FontAwesomeIcon icon="fa-key"/>Change Password
          </Link>
          {!emailVerified &&
            <Link {...getLinkAttrs('/edit-profile')}>
              <FontAwesomeIcon icon="fa-scroll"/>Change Email
            </Link>
          }
        </Fragment>
      }

      {userLoggedIn && emailVerified && showSubmitAbstract && useAbstracts &&
        <Link {...getLinkAttrs('/submit-abstract')}>
          <FontAwesomeIcon icon="fa-scroll"/>Submit Abstract
        </Link>
      }
      {userLoggedIn && emailVerified && showProposeSymposium && useSymposia &&
        <Link {...getLinkAttrs('/symposium-propose')}>
          <FontAwesomeIcon icon="fa-person-chalkboard"/>Propose {symposiumLabel}
        </Link>
      }

      {userLoggedIn && emailVerified && isSymposiumViewer && useSymposia &&
        <Link {...getLinkAttrs('/symposium-list')}>
          <FontAwesomeIcon icon="fa-person-chalkboard"/>{symposiumLabel} List
        </Link>
      }

      {userLoggedIn && emailVerified && isAbstractViewer && useAbstracts &&
        <Link {...getLinkAttrs('/abstract-list')}>
          <FontAwesomeIcon icon="fa-scroll"/>Abstract List
        </Link>
      }

      {userLoggedIn && emailVerified && useRegistration && showReg &&
        <Link {...getLinkAttrs('/reg-list')}>
          <FontAwesomeIcon icon="fa-id-card"/>Registration
        </Link>
      }

      {userLoggedIn && emailVerified && showExtendedAbstract &&
        <Link {...getLinkAttrs('/extended-abstract')}>
          <FontAwesomeIcon icon="fa-file"/>Extended Abstract
        </Link>
      }

      {userLoggedIn && emailVerified && canSearchSchedule && useAbstracts &&
        <Link {...getLinkAttrs('/search-schedule')}>
          <FontAwesomeIcon icon="fa-magnifying-glass"/>Search Schedule
        </Link>
      }

      {userLoggedIn && attendanceEntry && regUseAttendance &&
        <Link {...getLinkAttrs('/attendance-entry')}>
          <FontAwesomeIcon icon="fa-clipboard-user"/>Attendance Entry
        </Link>
      }

      {userLoggedIn && emailVerified && hasFaq &&
        <Link {...getLinkAttrs('/faq')}>
          <FontAwesomeIcon icon="fa-clipboard-question"/>FAQ
        </Link>
      }

      {userLoggedIn && emailVerified && showOrgInfo &&
        <Link {...getLinkAttrs('/org-info')}>
          <FontAwesomeIcon icon="fa-clipboard-question"/>Organizer Info
        </Link>
      }

      {userLoggedIn &&
        <div className={getClassNames("/logout", true)} onClick={callLogout}>
          <FontAwesomeIcon icon="fa-right-from-bracket"/>Logout
        </div>
      }

      {userLoggedIn && emailVerified && isStaffAdminOrg &&
        <Fragment>
          <div className="menu-heading"> {confLabel} Admin</div>
          <Link {...getLinkAttrs('/conf-user-list')}>
            <FontAwesomeIcon icon="fa-user-group"/>Users
          </Link>
          {useSymposia &&
            <Link {...getLinkAttrs('/room-list')}>
              <FontAwesomeIcon icon="fa-person-shelter"/>Rooms
            </Link>
          }
          {useSymposia &&
            <Link {...getLinkAttrs('/session-list')}>
              <FontAwesomeIcon icon="fa-user-clock"/>Sessions
            </Link>
          }
          {useSymposia &&
            <Link {...getLinkAttrs('/schedule')}>
              <FontAwesomeIcon icon="fa-calendar-days"/>Schedule
            </Link>
          }
          <Link {...getLinkAttrs('/reports')}>
            <FontAwesomeIcon icon="fa-file"/>Reports
          </Link>
          {useRegistration &&
            <Link {...getLinkAttrs('/reg-settings')}>
              <FontAwesomeIcon icon="fa-users-gear"/>Registration Settings
            </Link>
          }
          <Link {...getLinkAttrs('/conf-settings')}>
            <FontAwesomeIcon icon="fa-gear"/>{confLabel} Settings
          </Link>
        </Fragment>
      }

      {userLoggedIn && isStaff &&
        <Fragment>
          <div className="menu-heading"> Admin</div>
          <Link {...getLinkAttrs('/conf-list')}>
            <FontAwesomeIcon icon="fa-people-roof"/>Conferences
          </Link>
          <Link {...getLinkAttrs('/tracking')}>
            <FontAwesomeIcon icon="fa-chart-line"/>Tracking
          </Link>
          <Link {...getLinkAttrs('/tools')}>
            <FontAwesomeIcon icon="fa-wrench"/>Tools
          </Link>
        </Fragment>
      }

      {!userLoggedIn &&
        <Fragment>
          <Link {...getLinkAttrs('/')}>
            <FontAwesomeIcon icon="fa-right-to-bracket"/>Login
          </Link>
          <Link {...getLinkAttrs('/create-account')}>
            <FontAwesomeIcon icon="fa-user-plus"/>Create Account
          </Link>
        </Fragment>
      }
    </div>
  );
}
