import {Button, Col, Container, FloatingLabel, Form, Row} from "react-bootstrap";
import {PageHeader} from "../menu/PageHeader";
import React, {useContext, useEffect, useState} from "react";
import {UsacmContext} from "../App";
import {useNavigate} from "react-router";
import {getRoom, upsertRoom} from "../api/RoomApi";
import {fieldHasErrors, getErrorMessageForField} from "../utils/formUtils";
import {getConfLabel, showSuccessToast, titleCase} from "../utils/usacmUtils";
import {CONF_LABEL_KEY_LOCATION} from "../constants";

const MODE_EDIT = 'edit';
const MODE_CREATE = 'create';

export function RoomUpsert() {
  const navigate = useNavigate();
  const context = useContext(UsacmContext);
  const [conf,] = context.conference;
  const [roomId, setRoomId] = useState('new');
  const [roomNumber, setRoomNumber] = useState('');
  const [name, setName] = useState('');
  const [location, setLocation] = useState('');
  const [capacity, setCapacity] = useState('');
  const [errors, setErrors] = useState([]);
  const [mode, setMode] = useState(MODE_CREATE);
  const locationLabel = titleCase(getConfLabel(conf, CONF_LABEL_KEY_LOCATION));

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const roomParamId = params.get('roomid');
    if (roomParamId && roomParamId !== 'new') {
      setMode(MODE_EDIT);
      setRoomId(roomParamId);
      getRoom(roomParamId, (code, data, errors) => {
        if (code === 200) {
          setRoomNumber(data.room_number || '');
          setName(data.name||'');
          setLocation(data.location||'');
          setCapacity(data.capacity||'');
        }
      });
    }
  }, []);

  function callUpsertRoom() {
    upsertRoom(roomId, roomNumber, name, location, capacity, (status, data, newErrors) => {
          if (status === 200) {
            setErrors([]);
            showSuccessToast("Room "+ (mode === MODE_EDIT ? "updated" : "created"));
            navigate('/room-list')
          } else {
            setErrors(newErrors);
          }
        });
  }

  return (
      <div>
        <PageHeader pageTitle="Rooms" />
        <Container fluid className="usacm-container-narrow">

          <Row>
            <Col className="mb-3">
              <Form.Group controlId="roomnumber">
                <FloatingLabel controlId="roomnumber" label="Room Number">
                  <Form.Control type="text"
                                placeholder="Number"
                                name="room_number"
                                value={roomNumber}
                                onChange={e => setRoomNumber(e.target.value)}
                                isInvalid={fieldHasErrors(errors, 'room_number')}/>
                  <Form.Control.Feedback type="invalid">
                    {getErrorMessageForField(errors, 'room_number')}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col className="mb-3">
              <Form.Group controlId="name">
                <FloatingLabel controlId="name" label="Room Name">
                  <Form.Control type="text"
                                placeholder="Name"
                                name="name"
                                value={name}
                                onChange={e => setName(e.target.value)}
                                isInvalid={fieldHasErrors(errors, 'name')}/>
                  <Form.Control.Feedback type="invalid">
                    {getErrorMessageForField(errors, 'name')}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col className="mb-3">
              <Form.Group controlId="location">
                <FloatingLabel controlId="location" label={locationLabel}>
                    <Form.Control
                        className="form-select"
                        as="select"
                        value={location || ''}
                        onChange={e => setLocation(e.target.value)}
                    >
                      <option value="">Choose Location...</option>
                      {(conf?.locations||[]).map(f => {
                        return <option value={f} key={f}>{f}</option>
                      })}
                    </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {getErrorMessageForField(errors, 'location')}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col className="mb-3">
              <Form.Group controlId="capacity">
                <FloatingLabel controlId="capacity" label="Capacity">
                  <Form.Control type="number"
                                step="1"
                                placeholder="Capacity"
                                name="capacity"
                                value={capacity}
                                onChange={e => setCapacity(e.target.value)}
                                isInvalid={fieldHasErrors(errors, 'capacity')}/>
                  <Form.Control.Feedback type="invalid">
                    {getErrorMessageForField(errors, 'capacity')}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
            </Col>
          </Row>

          {fieldHasErrors(errors, '') &&
          <Row>
            <Col className="text-center mb-3 usacm-error-message">
              {getErrorMessageForField(errors, '')}
            </Col>
          </Row>
          }

          <Row>
            <Col className="usacm-button-row">
              <Button variant="secondary" onClick={() => navigate('/room-list')}>Room List</Button>
              <Button onClick={() => callUpsertRoom()}>{mode === MODE_EDIT ? "Update" : "Create"} Room</Button>
            </Col>
          </Row>

        </Container>
      </div>
);
}
