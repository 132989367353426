import {get, post} from "../utils/networkUtils";

/**
 * Gets tracking data
 */
export function getTracking(showAllConf, filterUserId, showPageLoads, dateFilterStart, dateFilterEnd,
                            typeFilter, targetId, pageNum, pageSize, toEmail, subjectBodySearch, onComplete) {
  const data = {
    'show_all_conf': showAllConf,
    'filter_user_id': filterUserId,
    'show_page_loads': showPageLoads,
    'date_filter_start': dateFilterStart,
    'date_filter_end': dateFilterEnd,
    'type_filter': typeFilter,
    'target_id': targetId,
    'page_num': pageNum,
    'page_size': pageSize,
    'to_email': toEmail,
    'subject_body_search': subjectBodySearch,
  };
  post("admin/tracking/", data, onComplete);
}

/**
 * Gets all the tracking types in the DB
 */
export function getTrackingTypes(onComplete) {
  get("admin/tracking/types/", onComplete);
}

/**
 * Clear a cache
 * If cacheName is null then all caches will be cleared.
 */
export function clearCache(cacheName, onComplete) {
  const data = {
    'cache_name': cacheName,
  };
  post("admin/cache/clear/", data, onComplete);
}

/**
 * Gets all the users this user has permissions to view
 */
export function transferUserData(isPreview, fromUserId, toUserId, transferSymposia, transferAbstracts, transferRegs, onComplete) {
  const data = {
    'is_preview': isPreview,
    'from_user_id': fromUserId,
    'to_user_id': toUserId,
    'transfer_symposia': transferSymposia,
    'transfer_abstracts': transferAbstracts,
    'transfer_regs': transferRegs,
  }
  post("admin/user/transfer/", data, onComplete);
}

/**
 * Syncs all the reg fields with the template
 */
export function regFieldSync(regId, mode, onComplete) {
  const data = {
    'reg_id': regId,
    'mode': mode,
  }
  post("admin/reg-field-sync/", data, onComplete);
}
