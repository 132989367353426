import {Container, Tab, Tabs} from "react-bootstrap";
import {PageHeader} from "../menu/PageHeader";
import {RegFields} from "./RegFields";
import {Register} from "./Register";
import {RegActionList} from "./RegActionList";
import {RegSettings} from "./RegSettings";
import {RegCoupons} from "./RegCoupons";

export function RegSettingsTabs() {

  return (
      <div>
        <PageHeader pageTitle={"Registration Settings"}/>
        <Container fluid className="usacm-container-wide">
          <Tabs
              defaultActiveKey="settings"
              id="reg-settings-tabs"
              className="mb-3"
              mountOnEnter={true}
              unmountOnExit={true}
          >
            <Tab eventKey="settings" title="Settings">
              <RegSettings/>
            </Tab>
            <Tab eventKey="fields" title="Fields">
              <RegFields/>
            </Tab>
            <Tab eventKey="actions" title="Actions">
              <RegActionList/>
            </Tab>
            <Tab eventKey="coupons" title="Coupons">
              <RegCoupons />
            </Tab>
            <Tab eventKey="preview" title="Preview">
              <Register preview={true} regId={null} onFinished={null} showCheckout={true}/>
            </Tab>
          </Tabs>
        </Container>
      </div>
  );

}
