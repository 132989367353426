import React, {useState} from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import {Button} from "react-bootstrap";
import {TIMEZONES} from './timezones';
import './TimezoneSelect.scss';

// The component display
// Dropdown needs forwardRef access to the DOM node in order to position the Menu
const TimezoneSelectToggle = React.forwardRef(({children, onClick}, ref) => (
    <Button
        className="usacm-button-as-input"
        type="button"
        ref={ref}
        onClick={(e) => {
          onClick(e);
        }}
    >
      &#x25bc; &nbsp; {children}
    </Button>
));


// forwardRef required as Dropdown needs access to the DOM of the Menu to measure it
const TimezoneSelectMenu = React.forwardRef(
    ({children, style, className, 'aria-labelledby': labeledBy}, ref) => {
      const [value, setValue] = useState('');

      return (
          <div
              ref={ref}
              style={style}
              className={(className + " timezone-select-menu")}
              aria-labelledby={labeledBy}
          >
            <Form.Control
                autoFocus
                name="filter-input"
                type="text"
                className="timezone-select-filter-input"
                placeholder="Type to filter..."
                onChange={(e) => setValue(e.target.value)}
                value={value}
            />
            <ul className="list-unstyled">
              {React.Children.toArray(children).filter(
                  (child) =>
                      !value || child.props.children.toLowerCase().includes(value.toLowerCase()),
              )}
            </ul>
          </div>
      );
    },
);

export function TimezoneSelect({value, onChange, isInvalid}) {
  const selectedTimezone = TIMEZONES.find(t => t.zone === value);
  const selectedTimezoneName = selectedTimezone ? selectedTimezone.zone+' '+selectedTimezone.gmt : 'Choose timezone...';
  // This will mark the object so we get a red border and the form.control.feedback will show up
  const classes = isInvalid ? "form-control is-invalid" : "";

  return (
      <Dropdown onSelect={newValue => onChange(newValue)} className={classes}>
        <Dropdown.Toggle as={TimezoneSelectToggle} id="timezone-select-id">
          {selectedTimezoneName}
        </Dropdown.Toggle>
        <Dropdown.Menu as={TimezoneSelectMenu}>
          <Dropdown.Item key='' eventKey='' className="timezone-select-item">
            No Timezone
          </Dropdown.Item>
          {TIMEZONES.map(timezone => {
            return <Dropdown.Item key={timezone.zone} eventKey={timezone.zone} className="timezone-select-item">
              {timezone.zone+' '+timezone.gmt}
            </Dropdown.Item>
          })}
        </Dropdown.Menu>
      </Dropdown>
  );
}
