import {Button, Col, Container, Form, Modal, Row} from "react-bootstrap";
import React, {Fragment, useEffect, useState} from "react";
import {deleteAction, getActions, getTemplateFields, swapActionOrder} from "../api/RegApi";
import {RegActionEdit} from "./RegActionEdit";
import {deepCopy, showSuccessToast} from "../utils/usacmUtils";
import {actionToString} from "./regUtils";
import {fieldHasErrors, getErrorMessageForField} from "../utils/formUtils";
import {hasPermission} from "../api/UserApi";
import {PERMISSION_CONF_ADMIN, PERMISSION_STAFF} from "../constants";

const ACTION_TEMPLATE = {
  id: null,
  condition_and: false,
  action_order: 0,
  custom_action: true,
  conditions: [],
  results: [],
};

export function RegActionList() {
  const [actions, setActions] = useState([]);
  const [fields, setFields] = useState([]);
  const [selectedAction, setSelectedAction] = useState(null);
  const [showingDeleteActionConfirm, setShowingDeleteActionConfirm] = useState(false);
  const [actionToDelete, setActionToDelete] = useState(null);
  const [errors, setErrors] = useState([]);
  const isStaffOrAdmin = hasPermission(PERMISSION_STAFF) || hasPermission(PERMISSION_CONF_ADMIN);

  function loadActions() {
    setErrors([]);
    getActions((status, data, newErrors) => {
      if (status === 200) {
        setActions(data);
      }
    });
  }

  function loadFields() {
    getTemplateFields((status, data, newErrors) => {
      if (status === 200) {
        setFields(data);
      }
    });
  }

  useEffect(() => {
    loadActions();
    loadFields();
  }, []);

  function showAddAction(newActionOrder) {
    const newAction = deepCopy(ACTION_TEMPLATE);
    newAction.action_order = newActionOrder;
    setSelectedAction(newAction);
  }

  function doneEditing() {
    loadActions();
    setSelectedAction(null);
    setErrors([]);
  }

  function editAction(action) {
    setSelectedAction(action);
    setErrors([]);
  }

  function showDeleteConfirmModal(action) {
    setShowingDeleteActionConfirm(true);
    setActionToDelete(action);
    setErrors([]);
  }

  function callDeleteAction() {
    if (!actionToDelete) {
      console.warn('Need to set actionToDelete before callDeleteAction');
      return;
    }
    deleteAction(actionToDelete.id, (status, data, newErrors) => {
      if (status === 200) {
        setShowingDeleteActionConfirm(false);
        setActionToDelete(null);
        showSuccessToast('Deleted action.');
        loadActions();
      }
    });
  }

  function callSwapActionOrder(indexA, indexB) {
    const actionA = actions[indexA];
    const actionB = actions[indexB];
    if (!actionA || !actionB) {
      console.warn('Unable to swap... cannot find items at index a=', indexA, ' b=', indexB);
      return;
    }
    swapActionOrder(actionA.id, actionB.id, (status, data, newErrors) => {
      if (status === 200) {
        loadActions();
      } else {
        setErrors(newErrors);
      }
    });
  }

  return (
      <div>
        {!selectedAction &&
        <Container fluid className="usacm-container-wide">

          {actions.map((action, index) =>
              <Row className="mt-3" key={index}>
                <Col className="d-flex align-items-start">
                  {isStaffOrAdmin &&
                  <Fragment>
                    <div style={{minWidth:'150px'}} className='me-2'>
                    <Form.Control className='form-select'
                                  as='select'
                                  value=''
                      onChange={e => {
                        const selectedItem = e.target.value;
                        if (selectedItem === "move-up") {
                          callSwapActionOrder(index, index - 1);
                        }
                        if (selectedItem === "move-down") {
                          callSwapActionOrder(index, index + 1);
                        }
                        if (selectedItem === "delete") {
                          showDeleteConfirmModal(action);
                        }
                        if (selectedItem === "add-action") {
                          showAddAction(action.action_order + 1);
                        }
                      }}
                    >
                      <option value=''>Choose...</option>
                      <option value='move-up' disabled={index === 0}>Move Up</option>
                      <option value='move-down' disabled={index + 1 === actions.length}>Move Down</option>
                      <option value='delete'>Delete</option>
                      <option value='add-action'>Add Action</option>
                    </Form.Control>
                    </div>
                    <Button size="sm" className="me-3" onClick={() => editAction(action)}> Edit </Button>
                  </Fragment>
                  }
                  <div>
                    {actionToString(fields, action)}
                  </div>
                </Col>
              </Row>
          )}

          {isStaffOrAdmin &&
          <Row className="mt-3">
            <Col className="usacm-button-row">
              <Button onClick={() => showAddAction(
                actions.length ? (Math.max(...actions.map(a => a.action_order || 0)) + 1) : 1)}> Add Action </Button>
            </Col>
          </Row>
          }

          {fieldHasErrors(errors, '') &&
          <Row className="mb-3">
            <Col className="text-center usacm-error-message">
              {getErrorMessageForField(errors, '')}
            </Col>
          </Row>
          }

        </Container>
        }

        {selectedAction &&
        <RegActionEdit origAction={selectedAction} fields={fields} onClose={doneEditing}/>
        }

        <Modal show={showingDeleteActionConfirm}
               onHide={() => setShowingDeleteActionConfirm(false)}
               size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Confirm Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to delete this action?</p>
            <p className='mt-3'> {actionToString(fields, actionToDelete)} </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowingDeleteActionConfirm(false)}>Cancel</Button>
            <Button variant="danger" onClick={() => callDeleteAction()}>Delete Action</Button>
          </Modal.Footer>
        </Modal>

      </div>
  );


}
