import React from "react";
import {centsToDollarStrWSign} from "../utils/usacmUtils";

export function RegInvoiceDisplay({pay, titlePrefixText = "Total"}) {
  const visibleInvoiceItems = (pay?.invoice_items || []).filter(i => i.visible);
  const totalAmount = pay?.amount || 0;

  return (
      <div className="section-container">
        <div className="section-header"> {titlePrefixText} {centsToDollarStrWSign(totalAmount)} </div>
        {visibleInvoiceItems.map((invoiceItem) =>
            <div key={invoiceItem.id}>
              <span className='fw-bold d-inline-block' style={{width: '75px'}}>
                {invoiceItem.invoice_percent ? invoiceItem.invoice_percent + '%' : centsToDollarStrWSign(invoiceItem.invoice_amount)}
              </span>
              &nbsp; {invoiceItem.invoice_text}
            </div>
        )}
        <div className='mt-3 fw-bold'>
          Total: {centsToDollarStrWSign(totalAmount)}
        </div>
      </div>
  );

}
