import {Button, Col, Container, FloatingLabel, Form, Row} from "react-bootstrap";
import {useNavigate} from "react-router";
import {useState} from "react";
import {changePassword, getUserData} from "../api/UserApi";
import {fieldHasErrors, getErrorMessageForField} from "../utils/formUtils";
import {PageHeader} from "../menu/PageHeader";


export function ChangePassword() {
  const navigate = useNavigate();
  const userData = getUserData();
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('');
  const [errors, setErrors] = useState([]);

  function callChangePassword(event) {
    event.preventDefault();
    changePassword(oldPassword, newPassword, newPasswordConfirm, (status, data, newErrors) => {
      if (status === 200) {
        navigate("/profile");
      } else {
        setErrors(newErrors);
      }
    });
  }

  return (
      <div>
        <PageHeader pageTitle= "Change Password" />
        <form onSubmit={callChangePassword}>
          <Container fluid className="usacm-container-narrow">
            <Row className="mt-3 mb-3">
              <Col className="col-3"> </Col>
              <Col className="col-3 usacm-label">
                Username
              </Col>
              <Col className="col-6">
                {userData.username}
              </Col>
            </Row>

            <Row>
              <Col className="mb-3">
                <FloatingLabel controlId="oldPassword" label="Old Password">
                  <Form.Control type="password"
                                placeholder="Old Password"
                                name="oldPassword"
                                required
                                value={oldPassword}
                                onChange={e => setOldPassword(e.target.value)}
                                isInvalid={fieldHasErrors(errors,'old_password')}/>
                  <Form.Control.Feedback type="invalid">
                    {getErrorMessageForField(errors, 'old_password')}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
            </Row>

            <Row>
              <Col className="mb-3">
                <FloatingLabel controlId="newPassword" label="New Password">
                  <Form.Control type="password"
                                placeholder="New Password"
                                name="newPassword"
                                required
                                value={newPassword}
                                onChange={e => setNewPassword(e.target.value)}
                                isInvalid={fieldHasErrors(errors,'new_password')}/>
                  <Form.Control.Feedback type="invalid">
                    {getErrorMessageForField(errors, 'new_password')}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
            </Row>

            <Row>
              <Col className="mb-3">
                <FloatingLabel controlId="newPasswordConfirm" label="Confirm New Password">
                  <Form.Control type="password"
                                placeholder="Confirm New Password"
                                name="newPasswordConfirm"
                                required
                                value={newPasswordConfirm}
                                onChange={e => setNewPasswordConfirm(e.target.value)}
                                isInvalid={fieldHasErrors(errors,'new_password_confirm')}/>
                  <Form.Control.Feedback type="invalid">
                    {getErrorMessageForField(errors, 'new_password_confirm')}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
            </Row>

            {fieldHasErrors(errors,'') &&
            <Row>
              <Col className="text-center mb-3 usacm-error-message" >
                {getErrorMessageForField(errors, '')}
              </Col>
            </Row>
            }

            <Row>
              <Col className="text-center mb-3">
                <Button variant="secondary" onClick={() => {navigate("/profile")}} className="ms-3 me-3">Cancel</Button>
                <Button type="submit" className="ms-3 me-3">Change Password</Button>
              </Col>
            </Row>

          </Container>
        </form>
      </div>
  );
}
