import {Button, Col, Container, Form, Modal, Row} from "react-bootstrap";
import {PageHeader} from "../menu/PageHeader";
import {AgGridReact} from "ag-grid-react";
import React, {Fragment, useCallback, useContext, useEffect, useRef, useState} from "react";
import {UsacmContext} from "../App";
import {
  ABSTRACT_FILTER_DEFAULT_STATE,
  COLOR_APPROVED,
  CONF_LABEL_KEY_SYMPOSIUM,
  FLAG_ABSTRACT_FINAL_APPROVED,
  FLAG_ABSTRACT_FINAL_DENIED,
  MODE_APPROVE,
  MODE_EDIT,
  MODE_LIST,
  MODE_VIEW,
  PERMISSION_CONF_ADMIN,
  PERMISSION_STAFF
} from "../constants";
import {deepCopy, getConfLabel, showErrorToast, titleCase} from "../utils/usacmUtils";
import {naturalSort, naturalSortValues, toolTipRenderer} from "../utils/gridUtils";
import {AbstractEdit} from "./AbstractEdit";
import {getAbstracts, setArchivedStatusForAbstract, setFinalStatusForAbstracts} from "../api/AbstractApi";
import {getAuthorName, getPresentingAuthor} from "./abstractUtils";
import {AbstractView} from "./AbstractView";
import {TABLE_FILTER_MODE_ABSTRACT, TableFilter} from "../shared/TableFilter";
import {
  calcAbstractStatusCounts,
  filterAbstracts,
  getIconForAbstractStatus,
  getIconForRegStatus,
  getNameForAbstractStatus,
  getNameForRegStatus,
  scoreAbstractStatus
} from "../utils/displayUtils";
import {useNavigate} from "react-router";
import {hasPermission} from "../api/UserApi";
import useWindowDimensions from "../shared/useWindowDImensions";
import {getErrorMessageForField} from "../utils/formUtils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


export function AbstractList({symposiumId = null, userId = null, showHeader = true}) {
  const navigate = useNavigate();
  const context = useContext(UsacmContext);
  const [conf,] = context.conference;
  const [navChanged,] = context.navigationChanged;
  const isStaffOrAdmin = hasPermission(PERMISSION_STAFF) || hasPermission(PERMISSION_CONF_ADMIN);
  const [abstractList, setAbstractList] = useState([]);
  const [mode, setMode] = useState(MODE_LIST);
  const [checkedAbstracts, setCheckedAbstracts] = useState([]);
  const [selectedAbstractId, setSelectedAbstractId] = useState(null);
  const [showingConfirmArchiveModal, setShowingConfirmArchiveModal] = useState(false);
  const [archiveAbstractId, setArchiveAbstractId] = useState(null);
  const [archiveAbstractNumber, setArchiveAbstractNumber] = useState('');
  const gridRef = useRef();
  const excelFilename = 'Abstract_List.xlsx';
  const [filters, setFilters] = useState(deepCopy(ABSTRACT_FILTER_DEFAULT_STATE));
  const [statusCounts, setStatusCounts] = useState({}); // contains {'pending':3, 'approved':0,...}
  const [windowWidth,] = useWindowDimensions();
  const filteredAbstractList = filterAbstracts(abstractList, filters);
  const symposiumLabel = titleCase(getConfLabel(conf, CONF_LABEL_KEY_SYMPOSIUM));
  const anyMoveRequested = (filteredAbstractList || []).some(a => a.move_requested);
  const hasFewRows = (filteredAbstractList || []).length < 12;
  const statusColWidth = 80 + (anyMoveRequested ? 20 : 0);
  const showCheckboxes = showHeader && isStaffOrAdmin;
  const [actionColWidth, actionColShowButtons] = getActionColWidth();
  const hasAnyReg = (filteredAbstractList || []).some(a => a.reg_status); // if the whole column is blank we'll hide it
  const [archiveErrors, setArchiveErrors] = useState([]);
  const hasAnyScheduledAbstracts = (filteredAbstractList || []).some(a => a.is_scheduled);

  // When the navUrl changes it means the left menu was clicked - we want to reset the component state
  // NOTE: this should be defined before the other useEffect so it runs first and can be overridden
  useEffect(() => {
    setSelectedAbstractId(null);
    setMode(MODE_LIST);
  }, [navChanged]);

  // Check for parameters (to pre-load a particular abstract in view/edit)
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const mode = params.get('mode');
    const abstractId = params.get('abstractid');
    if (mode === MODE_EDIT && abstractId) {
      showEditAbstract(abstractId);
    } else if (mode === MODE_VIEW && abstractId) {
      showViewAbstract(abstractId);
    }
  }, []);

  function getActionColWidth() {
    const canEditAny = (filteredAbstractList || []).some(a => a.can_edit);
    const canApproveAny = (filteredAbstractList || []).some(a => a.can_approve);
    const canArchiveAny = isStaffOrAdmin;
    let actionColShowButtons = (!canEditAny && !canApproveAny && !canArchiveAny) || (windowWidth > 1500);
    let actionColWidth = 150;
    if (actionColShowButtons) {
      // Calculate width to fit all the buttons
      actionColWidth = 100 + (canEditAny ? 50 : 0) + (canApproveAny ? 80 : 0) + (canArchiveAny ? 90 : 0);
    }
    return [actionColWidth, actionColShowButtons];
  }

  // Reload all the user data (after we know something changed)
  function reloadAbstractList() {
    getAbstracts(symposiumId, userId, (code, data, errors) => {
      if (code === 200) {
        setAbstractList(data);
        setStatusCounts(calcAbstractStatusCounts(data));
      }
    });
  }

  function showList() {
    setSelectedAbstractId(null);
    setMode(MODE_LIST);
    navigate(`/abstract-list?mode=${MODE_LIST}`, {replace: true});
  }

  function showEditAbstract(abstractId) {
    if (!showHeader) {
      // We are in an embedded view (use a link to jump to the abstract list)
      navigate("/abstract-list?mode=edit&abstractid=" + abstractId);
    } else {
      setSelectedAbstractId(abstractId);
      setMode(MODE_EDIT);
    }
  }

  function showViewAbstract(abstractId) {
    if (!showHeader) {
      // We are in an embedded view (use a link to jump to the abstract list)
      navigate("/abstract-list?mode=view&abstractid=" + abstractId);
    } else {
      setSelectedAbstractId(abstractId);
      setMode(MODE_VIEW);
    }
  }

  useEffect(() => {
    reloadAbstractList();
  }, [symposiumId]);

  function confirmArchive(abstractId, abstractNumber) {
    setShowingConfirmArchiveModal(true);
    setArchiveAbstractId(abstractId);
    setArchiveAbstractNumber(abstractNumber);
    setArchiveErrors([]);
  }

  function closeConfirmArchive() {
    setShowingConfirmArchiveModal(false);
    setArchiveAbstractId(null);
    setArchiveAbstractNumber('');
  }

  function setAbstractArchived(abstractId, archived) {
    setArchiveErrors([]);
    setArchivedStatusForAbstract(abstractId, archived, (code, data, errors) => {
      if (code === 200) {
        setCheckedAbstracts([]);
        reloadAbstractList();
        closeConfirmArchive();
      } else {
        setArchiveErrors(errors);
      }
    });
  }

  function showApprove(symposiumId, abstractId) {
    // We are in an embedded view (use a link to jump to the symposium list)
    navigate(`/symposium-list?mode=${MODE_APPROVE}&symposiumid=${symposiumId}&approveabstractid=${abstractId}`);
  }

  function setFinalStatus(status) {
    if (![FLAG_ABSTRACT_FINAL_APPROVED, FLAG_ABSTRACT_FINAL_DENIED].includes(status)) {
      showErrorToast(`Unable to set final status. "${status}" is not a final status.`);
      return;
    }
    setFinalStatusForAbstracts(checkedAbstracts, status, (code, data, errors) => {
      if (code === 200) {
        setCheckedAbstracts([]);
        reloadAbstractList();
      }
    });
  }

  function changeFilters(newFilterValue) {
    // Clear all the checked abstracts when the filters change, as otherwise some checked items will be non-visible
    setCheckedAbstracts([]);
    setFilters(newFilterValue);
  }

  function searchChanged(newSearch) {
    setCheckedAbstracts([]);
  }

  function checkboxClicked(abstract, checked) {
    if (checked) {
      checkedAbstracts.push(abstract.id);
    } else {
      const index = checkedAbstracts.indexOf(abstract.id);
      if (index >= 0) {
        checkedAbstracts.splice(index, 1);
      }
    }
    setCheckedAbstracts([...checkedAbstracts]);
  }

  function isAbstractChecked(abstract) {
    return checkedAbstracts.includes(abstract.id);
  }

  function setAllChecked(checked) {
    if (checked) {
      setCheckedAbstracts(filteredAbstractList.map(a => a.id));
    } else {
      setCheckedAbstracts([]);
    }
  }

  // Resize the grid to fit the screen
  const onGridReady = useCallback((params) => {
    // What a strange and difficult way to set the default sort!
    const columnState = {state: [{colId: 'abstract_number_full', sort: 'asc',}]}
    params.api.applyColumnState(columnState);
  }, []);

  // Column setup
  const colDefs = [
    ...(showCheckboxes ? [{
      headerName: '',
      headerTooltip: 'Select for Final Approve / Deny',
      minWidth: 50,
      maxWidth: 50,
      width: 50,
      cellRenderer: params => {
        return <span>
            <Form.Check
              type="checkbox"
              label=""
              style={{paddingTop: '8px'}}  // checkbox seems to be out of alignment (too high)
              id={"checked_" + params.data.id}
              checked={isAbstractChecked(params.data)}
              onChange={e => checkboxClicked(params.data, e.target.checked)}
            />
          </span>;
      },
    }] : []),
    {
      field: 'abstract_number_full',
      headerName: 'Number',
      headerTooltip: 'Abstract Number',
      width: 110, minWidth: 110,
      comparator: naturalSort,
    },
    {
      headerName: symposiumLabel,
      minWidth: 120,
      flex: 5,
      valueGetter: params => (params.data.symposium_number || '') + ' ' + params.data.symposium_name,
      cellRenderer: toolTipRenderer,
      comparator: naturalSort,
    },
    {
      field: 'title',
      headerName: 'Title',
      cellRenderer: toolTipRenderer,
      comparator: naturalSort,
      minWidth: 120, flex: 5,
    },
    {
      field: 'presentation_type',
      headerName: 'Type',
      headerTooltip: 'Presentation Type',
      width: 100, minWidth: 100,
      comparator: naturalSort,
    },
    {
      field: 'create_user_name',
      cellRenderer: toolTipRenderer,
      headerName: 'Submitter',
      headerTooltip: 'Submitter Name',
      minWidth: 100, flex: 2,
      comparator: naturalSort,
    },
    ...(hasAnyScheduledAbstracts ? [{
      field: 'is_scheduled',
      headerName: 'Scheduled',
      headerTooltip: 'Scheduled',
      minWidth: 50, flex: 1,
      cellRenderer: params => {
        if (!params.data.is_scheduled) {
          return '';
        }
        return <span title='Scheduled' key='Scheduled'>
          <FontAwesomeIcon icon='calendar-days' size="xl" color={COLOR_APPROVED}/>
        </span>
      },
    }] : []),
    {
      headerName: 'Presenting Author',
      headerTooltip: 'Presenting Author',
      minWidth: 100, flex: 2,
      cellRenderer: params => {
        const names = getAuthorName(getPresentingAuthor(params.data));
        return <span title={names}>{names}</span>;
      },
      getQuickFilterText: params => {
        return getAuthorName(getPresentingAuthor(params.data));
      },
      comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
        return naturalSortValues(
          getAuthorName(getPresentingAuthor(nodeA.data)),
          getAuthorName(getPresentingAuthor(nodeB.data)),
          isInverted);
      },
    },
    ...(hasAnyReg ? [{
      headerName: 'Reg',
      headerTooltip: 'Registration Status',
      filter: false,
      width: 80,
      cellRenderer: params => {
        return getIconForRegStatus(params.data);
      },
      getQuickFilterText: params => {
        return getNameForRegStatus(params.data);
      },
      comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
        return naturalSortValues(
          getNameForRegStatus(nodeA.data),
          getNameForRegStatus(nodeB.data),
          isInverted);
      },
    }] : []),
    {
      headerName: 'Status',
      headerTooltip: 'Status',
      filter: false,
      width: statusColWidth,
      cellRenderer: params => {
        return getIconForAbstractStatus(conf, params.data);
      },
      comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
        return scoreAbstractStatus(nodeA.data) - scoreAbstractStatus(nodeB.data);
      },
      getQuickFilterText: params => {
        return getNameForAbstractStatus(conf, params.data);
      }
    },
    {
      headerName: 'Action',
      sortable: false,
      filter: false,
      maxWidth: actionColWidth,
      minWidth: actionColWidth,
      width: actionColWidth,
      cellRenderer: params => {
        const abstract = params.data;
        const abstractId = abstract.id;
        const abstractNumber = abstract.abstract_number_full;
        const symposiumId = abstract.symposium_id;
        if (actionColShowButtons) {
          return <Fragment>
            <Button type="button" onClick={() => showViewAbstract(abstractId)} size="sm" className="me-2">View</Button>
            {abstract.can_edit &&
              <Button type="button" onClick={() => showEditAbstract(abstractId)} size="sm" className="me-2">Edit</Button>}
            {isStaffOrAdmin && !abstract.archived &&
              <Button type="button" onClick={() => confirmArchive(abstractId, abstractNumber)} size="sm" className="me-2">Archive</Button>
            }
            {isStaffOrAdmin && abstract.archived &&
              <Button type="button" onClick={() => setAbstractArchived(abstractId, false)} size="sm" className="me-2">UnArchive</Button>
            }
            {abstract.can_approve &&
              <Button type="button" onClick={() => showApprove(symposiumId, abstractId)} size="sm" className="me-2">Approve</Button>}
          </Fragment>;
        } else {
          // We have a small window, we won't use buttons
          return <Fragment>
            <Form.Control
              className='form-select'
              as='select'
              defaultValue=''
              onChange={e => {
                const action = e.target.value;
                if (action === "view") {
                  showViewAbstract(abstractId);
                }
                if (action === "edit") {
                  showEditAbstract(abstractId);
                }
                if (action === "archive") {
                  confirmArchive(abstractId, abstractNumber);
                }
                if (action === "unarchive") {
                  setAbstractArchived(abstractId, false);
                }
                if (action === "approve") {
                  showApprove(symposiumId, abstractId);
                }
              }}
            >
              <option value=''>Choose...</option>
              <option value='view'>View</option>
              {abstract.can_edit && <option value='edit'>Edit</option>}
              {isStaffOrAdmin && !abstract.archived && <option value='archive'>Archive</option>}
              {isStaffOrAdmin && abstract.archived && <option value='unarchive'>UnArchive</option>}
              {abstract.can_approve && <option value='approve'>Approve</option>}
            </Form.Control>
          </Fragment>;
        }
      },
    }
  ];

  const defaultColDefs = {
    sortable: true,
    filter: false,
    resizable: true,
  }

  function getXlsUrl() {
    if (symposiumId) {
      return 'symposium/' + symposiumId + '/abstracts/excel/';
    }
    if (userId) {
      return `abstract/list/user/${userId}/excel/`;
    }
    return 'abstract/list/excel/';
  }

  return (
    <div className="mb-3">
      {showHeader && <PageHeader pageTitle="Abstracts"/>}
      <Container fluid className="usacm-container-wide" style={{display: mode === MODE_LIST ? 'block' : 'none'}}>

        <TableFilter conf={conf}
                     mode={TABLE_FILTER_MODE_ABSTRACT}
                     showFilters={showHeader}
                     filters={filters}
                     setFilters={changeFilters}
                     showDownload={!userId}
                     downloadUrl={getXlsUrl()}
                     downloadFilename={excelFilename}
                     gridApi={gridRef?.current?.api}
                     statusCounts={statusCounts}
                     onSearchChanged={searchChanged}
                     showOtherButton={false}
                     otherButtonText=''
                     otherButtonOnclick={null}
        />

        <Row>
          <Col>
            <div className="ag-theme-alpine" style={{width: '100%', ...(hasFewRows ? {} : {height: '700px'})}}>
              <AgGridReact
                ref={gridRef}
                rowData={filteredAbstractList}
                columnDefs={colDefs}
                defaultColDef={defaultColDefs}
                gridOptions={{enableCellTextSelection: true, ensureDomOrder: true}} // enable cell selection
                onGridReady={onGridReady}
                domLayout={hasFewRows ? "autoHeight" : "normal"}
                getRowId={(params) => params.data.id} // prevent re-rendering the whole table when data changes
              />
            </div>
          </Col>
        </Row>

        {showCheckboxes &&
          <Row>
            <Col className="mt-3">
              <div className="d-flex align-items-center">
                <div className="text-nowrap">
                  <Form.Check
                    type="checkbox"
                    label="All"
                    id="all_checked"
                    checked={checkedAbstracts.length === filteredAbstractList.length}
                    onChange={e => setAllChecked(e.target.checked)}
                  />
                  {checkedAbstracts.length} selected
                </div>
                <div className="ms-3 mb-3">
                  <Button type="button" onClick={() => setFinalStatus(FLAG_ABSTRACT_FINAL_APPROVED)} className="me-2 mt-1">Final
                    Approve</Button>
                  <Button type="button" onClick={() => setFinalStatus(FLAG_ABSTRACT_FINAL_DENIED)} className="ms-2 mt-1">Final Deny</Button>
                </div>
              </div>
            </Col>
          </Row>
        }

      </Container>

      {mode === MODE_EDIT && selectedAbstractId &&
        <AbstractEdit abstractId={selectedAbstractId} onClose={() => showList()}
                      reloadAbstractList={reloadAbstractList}/>
      }

      {mode === MODE_VIEW && selectedAbstractId &&
        <AbstractView abstractId={selectedAbstractId} onClose={() => showList()}/>
      }

      <Modal show={showingConfirmArchiveModal}
             onHide={() => closeConfirmArchive()}
             size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Confirm Archive</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p> Are you sure you want to archive abstract #{archiveAbstractNumber}? </p>
          <p className='mt-2'> An email will be sent to the submitter and presenting author. </p>
          <p className="mt-2 usacm-error-message">
            {getErrorMessageForField(archiveErrors, '')}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => closeConfirmArchive()}>Cancel</Button>
          <Button variant="primary" onClick={() => setAbstractArchived(archiveAbstractId, true)}>Archive</Button>
        </Modal.Footer>
      </Modal>

    </div>
  );

}
