// Utilities for working with ag-grid

  /**
 * Shows the ag-grid cell value inside a tooltip.
 * The column should have an ag-grid value (you probably want "field" set)
 * eg. {field: 'name', cellRenderer:toolTipRenderer},
 * You could also set a valueGetter instead of field (valueGetter sets a value)
 * valueGetter: params => (...), cellRenderer:toolTipRenderer
 */
export function toolTipRenderer(params) {
  return <span title={params.value}>{params.value}</span>;
}

/**
 * Checks if a variable has a value of null or undefined
 * NOTE: This should return true for "", 0 and []
 */
export function isNull(val) {
  return val === null || val === undefined;
}

/**
 * An ag-grid comparator (parameters match ag-grid's) that sorts a string
 *
 * Features:
 * - Handles numbers embedded in strings naturally  eg. '5x' < '10x'
 * - Case Insensitive
 *
 * NOTE: To use this you must have a valueGetter or field
 *       If you are using a cellRenderer then you probably want to use naturalSortValues() as value may be null
 * eg.  comparator: naturalSort,  (in colDefs context)
 */
export function naturalSort(valueA, valueB, nodeA, nodeB, isInverted) {
  if (isNull(valueA) && isNull(valueB)) {
    return 0;
  }
  if (isNull(valueA) && !isNull(valueB)) {
    return -1;
  }
  if (!isNull(valueA) && isNull(valueB)) {
    return 1;
  }
  return valueA.localeCompare(valueB, undefined, {numeric:true, sensitivity:'base'});
}

/**
 * A comparator for custom fields (drawn with cellRenderer likely)
 *
 * Features:
 * - Handles numbers embedded in strings naturally  eg. '5x' < '10x'
 * - Case Insensitive
 *
 * @param valueA custom value
 * @param valueB custom value
 * @param isInverted from ag-grid
 * @returns {number} for ag-grid sorting
 * eg. (in colDefs context)
 *   comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
 *         return naturalSortValues(
 *             customFunctionToFormatData(nodeA.data),
 *             customFunctionToFormatData(nodeB.data),
 *             isInverted);
 *       },
 */
export function naturalSortValues(valueA, valueB, isInverted) {
  if (isNull(valueA) && isNull(valueB)) {
    return 0;
  }
  if (isNull(valueA) && !isNull(valueB)) {
    return -1;
  }
  if (!isNull(valueA) && isNull(valueB)) {
    return 1;
  }
  return valueA.localeCompare(valueB, undefined, {numeric:true, sensitivity:'base'});
}
