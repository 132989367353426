import React, {forwardRef, Fragment, useEffect, useImperativeHandle, useState} from "react";
import {Button, Col, FloatingLabel, Form, Row} from "react-bootstrap";
import {
  anyFieldHasErrors,
  fieldHasErrors,
  getErrorMessageForAllFields,
  getErrorMessageForField,
  removeErrorsForFields
} from "../utils/formUtils";
import {titleCase} from "../utils/usacmUtils";
import {getUserData, hasPermission} from "../api/UserApi";
import {UserSelect} from "./UserSelect";
import {AFFILIATION_SEPARATOR, PERMISSION_CONF_ADMIN, PERMISSION_CONF_ORGANIZER, PERMISSION_STAFF} from "../constants";
import {CountrySelect} from "./CountrySelect";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {formatAffiliation} from "../utils/displayUtils";
import './AuthorOrganizer.scss';

export const TYPE_ORGANIZER = 'organizer';
export const TYPE_AUTHOR = 'author';

/** Allows editing a list of organizers on a symposium or a list of authors on an abstract
 * type should be 'organizer' or 'author'
 **/
export const AuthorOrganizerEditor = forwardRef(({organizers, setOrganizers, errors, setErrors, type}, ref) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [affiliations, setAffiliations] = useState([]);
  const [status, setStatus] = useState('');
  const [country, setCountry] = useState('');
  const [mainOrganizer, setMainOrganizer] = useState(true);
  const [editIndex, setEditIndex] = useState(-1); // index into organizers when editing

  const organizerTitle = titleCase(type);
  const mainOrganizerTitle = type === TYPE_ORGANIZER ? 'Main Organizer' : 'Presenting Author';
  const canViewUsers = hasPermission(PERMISSION_STAFF) || hasPermission(PERMISSION_CONF_ADMIN) || hasPermission(PERMISSION_CONF_ORGANIZER);
  const statuses = ['', 'Faculty', 'University Researcher', 'Graduate Student', 'Government/National Lab Professional',
    'Industry Professional', 'Other'];

  // Defaults the main Organizer flag (if organizers exist should default to false)
  useEffect(() => {
    setMainOrganizer(!organizers?.length);
    if (!validSortOrder()) {
      resetSortOrder();
      setOrganizers([...organizers]); // refresh
    }
  }, [organizers]);

  // This allows the parent to call this method
  useImperativeHandle(ref, () => ({
    /**
     * This will validate the organizers, saving any partial changes into the organizers array
     * @returns true if the array is in a valid state, false if there are errors on the page
     */
    hasValidOrganizers() {
      if (firstName || lastName || email || affiliations?.length) {
        return upsertOrganizer();
      }
      return true;
    },

    /**
     * This will reset the state of the organizer - making ready to begin fresh
     */
    resetOrganizers() {
      resetOrganizersInternal(true);
    }
  }));

  /**
   * Clears all the fields to start fresh
   * @param mainOrg if false default the checkbox to not-checked
   * NOTE: This will leave edit mode (if user is in edit mode)
   */
  function resetOrganizersInternal(mainOrg = true) {
    setFirstName('');
    setLastName('');
    setEmail('');
    setAffiliations([]);
    setMainOrganizer(mainOrg);
    setStatus('');
    setCountry('');
    setEditIndex(-1);
  }

  function removeValidationErrors() {
    removeErrorsForFields(errors, ['first_name', 'last_name', 'email', 'affiliation', 'status', 'country', 'authors', 'organizers']);
  }

  function getMaxSortOrder(organizers) {
    let maxSortOrder = 0;
    for (const organizer of organizers || []) {
      if (organizer.sort_order > maxSortOrder) {
        maxSortOrder = organizer.sort_order;
      }
    }
    return maxSortOrder;
  }

  function validSortOrder() {
    const foundSortOrders = [];
    let prevSortOrder = null;
    for (const org of organizers || []) {
      if (foundSortOrders.includes(org.sort_order)) {
        console.warn('Found duplicate author/organizer sort order');
        return false;
      }
      if (prevSortOrder && (prevSortOrder > org.sort_order)) {
        console.warn('Found out of order');
        return false;
      }
      prevSortOrder = org.sort_order;
      foundSortOrders.push(org.sort_order);
    } // for
    return true;
  }

  /**
   * Sets all the sort_order values based on their position in the array
   */
  function resetSortOrder() {
    let sort_order = 1;
    for (const org of organizers || []) {
      org.sort_order = sort_order;
      sort_order += 1;
    }
  }

  function getAffiliationStr() {
    if (!affiliations || !affiliations.length) {
      return "";
    }
    let affiliationStr = "";
    for (const affiliation of affiliations) {
      const affiliationClean = (affiliation||'').trim();
      if (affiliationClean) {
        if (affiliationStr) {
          affiliationStr += AFFILIATION_SEPARATOR;
        }
        affiliationStr += affiliationClean;
      }
    }
    return affiliationStr;
  }

  function getAffiliationArr(affiliationStr) {
    return affiliationStr.split(AFFILIATION_SEPARATOR);
  }

  function addAffiliation() {
    affiliations.push('');
    setAffiliations([...affiliations]); // refresh
  }

  function setAffiliation(index, value) {
    affiliations[index] = value;
    setAffiliations([...affiliations]); // refresh
  }

  /**
   * Adds/Updates an organizer in the array.
   * @returns {boolean} true if all went well, false if there are new organizer validation errors
   */
  function upsertOrganizer() {
    removeValidationErrors();
    let noNewErrors = true;
    if (!firstName.trim()) {
      errors.push({'message': 'You must enter a first name.', 'fields': ['first_name']});
      noNewErrors = false;
    }
    if (!lastName.trim()) {
      errors.push({'message': 'You must enter a last name.', 'fields': ['last_name']});
      noNewErrors = false;
    }
    if (!email.trim()) {
      errors.push({'message': 'You must enter an email.', 'fields': ['email']});
      noNewErrors = false;
    }
    const affiliation = getAffiliationStr();
    if (!affiliation.trim()) {
      errors.push({'message': 'You must enter an affiliation.', 'fields': ['affiliation']});
      noNewErrors = false;
    }
    if ((type === TYPE_AUTHOR) && mainOrganizer) {
      if (!status) {
        errors.push({'message': 'You must choose a status.', 'fields': ['status']});
        noNewErrors = false;
      }
      if (!country) {
        errors.push({'message': 'You must choose a country.', 'fields': ['country']});
        noNewErrors = false;
      }
    }
    if (!noNewErrors) {
      setErrors([...errors]);
      return false;
    }
    let organizer = (editIndex >= 0) ? organizers[editIndex] : {id: null, user_id: null, username: null};
    organizer.first_name = firstName;
    organizer.last_name = lastName;
    organizer.email = email;
    organizer.affiliation = affiliation;
    if (type === TYPE_ORGANIZER) {
      organizer.main_organizer = mainOrganizer;
    } else if (type === TYPE_AUTHOR) {
      organizer.presenting_author = mainOrganizer;
      organizer.status = status;
      organizer.country = country;
    }
    if (editIndex < 0) {
      organizer.sort_order = getMaxSortOrder(organizers) + 1;
      organizers.push(organizer);
    }
    resetOrganizersInternal(false);
    setOrganizers([...organizers]);
    return true;
  }

  function deleteOrganizer(index) {
    organizers.splice(index, 1);
    resetSortOrder();
    setOrganizers([...organizers]); // refresh
  }

  function editOrganizer(index) {
    removeValidationErrors();
    const organizer = organizers[index];
    setFirstName(organizer.first_name);
    setLastName(organizer.last_name);
    setEmail(organizer.email);
    setAffiliations(getAffiliationArr(organizer.affiliation));
    setStatus(organizer.status || '');
    setCountry(organizer.country || '');
    setMainOrganizer(type === TYPE_ORGANIZER ? organizer.main_organizer : organizer.presenting_author);
    setEditIndex(index);
  }

  function chooseMe() {
    chooseUser(getUserData());
  }

  function chooseUser(user) {
    if (!user) {
      console.warn('Unable to choose user without any user data');
      return;
    }
    removeValidationErrors();
    setFirstName(user.first_name);
    setLastName(user.last_name);
    setEmail(user.email);
    setAffiliations(getAffiliationArr(user.affiliation));
    setStatus('');
    setCountry(user.country || '');
  }

  function swapSortOrder(indexA, indexB) {
    if (organizers.length < indexA || indexA < 0 || organizers.length < indexB || indexB < 0) {
      console.warn('Cannot swap  - out of range');
      return;
    }
    const moveFrom = organizers[indexA];
    const moveTo = organizers[indexB];
    const fromSortOrder = moveFrom.sort_order;
    const toSortOrder = moveTo.sort_order;
    moveTo.sort_order = fromSortOrder
    moveFrom.sort_order = toSortOrder;
    organizers.sort((a, b) => a.sort_order - b.sort_order);
    setOrganizers([...organizers]); // refresh
  }

  return (
      <Fragment>
        <Row>
          <Col className="mb-3 mt-3">
            <div className="mb-2">{organizerTitle}s</div>
            {organizers.map((organizer, index) => {
              return (
                  <Row key={index} className="org-display-container">
                    <Col className="col-auto org-display-buttons ms-3 mb-3">
                      <Button type="button" size="sm" onClick={() => deleteOrganizer(index)} disabled={editIndex >= 0}>Delete</Button>
                      <Button type="button" size="sm" onClick={() => editOrganizer(index)} className="ms-3">Edit</Button>
                      <Button type="button" size="sm" className="ms-3"
                              onClick={() => swapSortOrder(index, index - 1)}
                              disabled={index === 0 || editIndex >= 0}>
                        <FontAwesomeIcon icon="fa-angle-up"/>
                      </Button>
                      <Button type="button" size="sm" className="ms-1"
                              onClick={() => swapSortOrder(index, index + 1)}
                              disabled={index + 1 === organizers.length || editIndex >= 0}>
                        <FontAwesomeIcon icon="fa-angle-down"/>
                      </Button>
                    </Col>
                    <Col className="mb-3 org-display-name">
                      {organizer.first_name} {organizer.last_name}, {organizer.email} ({formatAffiliation(organizer.affiliation)})
                      {(type === TYPE_ORGANIZER ? organizer.main_organizer : organizer.presenting_author) &&
                      <span className="ms-2" style={{color: 'green'}}>{mainOrganizerTitle}</span>
                      }
                    </Col>
                  </Row>
              )
            })}
          </Col>
        </Row>

        {anyFieldHasErrors(errors, ['authors', 'organizers']) &&
        <Row>
          <Col className="text-center mb-3 usacm-error-message">
            {getErrorMessageForAllFields(errors, ['authors', 'organizers'])}
          </Col>
        </Row>
        }

        <Row>
          <Col className="mb-3">
            <div className="mb-2">Add {organizerTitle}</div>
            <div className="ps-3 pe-3" style={{margin: '5px', border: '1px solid black', borderRadius: '5px'}}>

              <Row className="org-row">
                <Col className="mt-3 mb-3 col-6">
                  <Button type="button" onClick={chooseMe}>Choose Me</Button>
                  {canViewUsers &&
                    <div className="ms-3 mt-2 d-inline-block" style={{minWidth: '300px'}}>
                      <UserSelect onChange={chooseUser} usersInConf={true} />
                    </div>
                  }
                </Col>
                <Col className="mt-3 col-6 d-flex flex-row align-items-center">
                  <Form.Check
                    type="checkbox"
                    label={mainOrganizerTitle}
                    id="main_organizer_author"
                    checked={mainOrganizer}
                    onChange={e => setMainOrganizer(e.target.checked)}
                  />
                </Col>
              </Row>

              <Row className="org-row">
                <Col className="mb-3 mt-3">
                  <Form.Group controlId="firstName">
                    <FloatingLabel controlId="firstName" label="First Name">
                      <Form.Control type="text"
                                    placeholder="First Name"
                                    required
                                    name="firstName"
                                    value={firstName}
                                    onChange={e => setFirstName(e.target.value)}
                                    isInvalid={fieldHasErrors(errors, 'first_name')}/>
                      <Form.Control.Feedback type="invalid">
                        {getErrorMessageForField(errors, 'first_name')}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col className="mb-3 mt-3">
                  <Form.Group controlId="lastName">
                    <FloatingLabel controlId="lastName" label="Last Name">
                      <Form.Control type="text"
                                    placeholder="Last Name"
                                    required
                                    name="lastName"
                                    value={lastName}
                                    onChange={e => setLastName(e.target.value)}
                                    isInvalid={fieldHasErrors(errors, 'last_name')}/>
                      <Form.Control.Feedback type="invalid">
                        {getErrorMessageForField(errors, 'last_name')}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="org-row">
                <Col className="mb-3">
                  <Form.Group controlId="email">
                    <FloatingLabel
                        controlId="email"
                        label="Email"
                    >
                      <Form.Control
                          type="email"
                          name="email"
                          placeholder="name@example.com"
                          required
                          value={email}
                          onChange={e => setEmail(e.target.value)}
                          isInvalid={fieldHasErrors(errors, 'email')}/>
                      <Form.Control.Feedback type="invalid">
                        {getErrorMessageForField(errors, 'email')}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col className="mb-3">
                  {(affiliations?.length ? affiliations : ['']).map((affiliation, index) => (
                      <div className="d-flex mb-1" key={index}>
                        <Form.Group controlId="affiliation" className="flex-grow-1">
                          <FloatingLabel controlId="affiliation" label="Affiliation">
                            <Form.Control type="affiliation"
                                          placeholder="Affiliation"
                                          required
                                          name="affiliation"
                                          value={affiliation}
                                          onChange={e => setAffiliation(index, e.target.value)}
                                          isInvalid={fieldHasErrors(errors, 'affiliation')}/>
                            <Form.Control.Feedback type="invalid">
                              {getErrorMessageForField(errors, 'affiliation')}
                            </Form.Control.Feedback>
                          </FloatingLabel>
                        </Form.Group>
                        {(!affiliations?.length || (index === affiliations?.length - 1)) &&
                        <Button type="button" size="sm" className="usacm-icon-button" title="Add Affiliation"
                                onClick={() => addAffiliation()}>
                          <FontAwesomeIcon icon="fa-square-plus" size="3x" color="green"/>
                        </Button>
                        }
                      </div>
                  ))}
                </Col>
              </Row>

              {type === TYPE_AUTHOR &&
              <Row className="org-row">
                <Col className="mb-3">
                  <Form.Group controlId="status">
                    <FloatingLabel controlId="status" label="Status">
                      <Form.Control
                          className="form-select"
                          as="select"
                          value={status || ''}
                          onChange={e => setStatus(e.target.value)}
                          isInvalid={fieldHasErrors(errors, 'status')}
                      >
                        {statuses.map(s => {
                          return <option value={s} key={s}>{s || 'Choose Status...'}</option>
                        })}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {getErrorMessageForField(errors, 'status')}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col className="mb-3">
                  <Form.Group controlId="country">
                    <CountrySelect value={country}
                                   onChange={countryCode => setCountry(countryCode)}
                                   isInvalid={fieldHasErrors(errors, 'country')}/>
                    <Form.Control.Feedback type="invalid">
                      {getErrorMessageForField(errors, 'country')}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              }

              <Row>
                <Col className="mb-3 col-12 text-center">
                  {editIndex >= 0 &&
                  <Button className="me-3 btn-secondary" type="button"
                          onClick={() => resetOrganizersInternal(false)}>
                    Cancel Edit {organizerTitle}
                  </Button>
                  }
                  <Button type="button"
                          onClick={upsertOrganizer}>
                    {editIndex >= 0 ? 'Edit' : 'Add'} {organizerTitle}
                  </Button>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Fragment>
  );

});