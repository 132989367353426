import React, {Fragment, useEffect, useRef, useState} from "react";
import {getRegHistoryActions, getRegHistoryAll} from "../api/RegApi";
import {toolTipRenderer} from "../utils/gridUtils";
import {AgGridReact} from "ag-grid-react";
import {getHistoryActionDisplay, getPayStatusDisplay, getPayTypeDisplay} from "../reg/regUtils";
import {PAY_STATUS_IN_PROGRESS} from "../constants";
import {centsToDollarStrWSign, dateTimeToStr} from "../utils/usacmUtils";
import {Button, FloatingLabel, Form} from "react-bootstrap";
import {getErrorMessageForAllErrors} from "../utils/formUtils";
import {useLocation} from "react-router-dom";
import {getUser} from "../api/UserApi";

export function TrackingRegHistory() {
  const [historyList, setHistoryList] = useState([]);
  const gridRef = useRef();
  const {search} = useLocation();
  const searchParams = new URLSearchParams(search);
  const filterUserId = searchParams.get('userid') || '';
  const [filterUserData, setFilterUserData] = useState(null);
  const hasFewRows = (historyList || []).length < 12;
  const [showAllConf, setShowAllConf] = useState(false);
  const [dateFilterStart, setDateFilterStart] = useState('');
  const [dateFilterEnd, setDateFilterEnd] = useState('');
  const [regId, setRegId] = useState('');
  const [action, setAction] = useState('');
  const [actionList, setActionList] = useState([]);
  const [pageNum, setPageNum] = useState('1');
  const [pageSize, setPageSize] = useState('1000');
  const [quickFilter, setQuickFilter] = useState('');
  const [errors, setErrors] = useState([]);

  function loadHistory() {
    setErrors([]);
    getRegHistoryAll(showAllConf, filterUserId, dateFilterStart, dateFilterEnd, regId, action, pageNum, pageSize, (code, data, errors) => {
      if (code === 200) {
        setHistoryList(data);
      } else {
        setErrors(errors);
      }
    });
  }

  useEffect(() => {
    if (filterUserId) {
      getUser(filterUserId, (code, data, errors) => {
        if (code === 200) {
          setFilterUserData(data);
        }
      });
    }
    getRegHistoryActions((code, data, errors) => {
      if (code === 200) {
        setActionList(data);
      }
    })
    loadHistory();
  }, [filterUserId]);

  const colDefs = [
    {
      headerName: 'Date (browser timezone)',
      width: 200, minWidth: 200,
      // This will parse the GMT date string into a JS Date in the browser's timezone
      valueGetter: params => dateTimeToStr(new Date(Date.parse(params.data?.create_date))),
    },
    {
      headerName: 'User',
      minWidth: 200, flex: 3,
      valueGetter: params => {
        return params.data.create_user_id ? params.data.create_user_name + ' (' + params.data.create_user_email + ')' : 'Stripe';
      },
      cellRenderer: toolTipRenderer,
    },
    {
      field: 'reg_id',
      headerName: 'Reg ID',
      width: 80, minWidth: 80
    },
    {
      headerName: 'Assigned User',
      minWidth: 200, flex: 3,
      valueGetter: params => {
        return params.data.assigned_user_name + ' (' + params.data.assigned_user_email + ')';
      },
      cellRenderer: toolTipRenderer,
    },

    {
      headerName: 'Action', minWidth: 230, flex: 2,
      valueGetter: params => {
        return getHistoryActionDisplay(params.data.history_action);
      },
      cellRenderer: toolTipRenderer,
    },
    {
      headerName: 'Pay Status', minWidth: 120, flex: 2,
      valueGetter: params => {
        return getPayStatusDisplay(params.data.pay_status);
      },
      cellRenderer: toolTipRenderer,
    },
    {
      headerName: 'Pay Type', minWidth: 120, flex: 2,
      valueGetter: params => {
        return getPayTypeDisplay(params.data.pay_type);
      },
      cellRenderer: toolTipRenderer,
    },
    {
      headerName: 'Details', minWidth: 110, flex: 6,
      valueGetter: params => {
        const history = params.data;
        if (history.pay_id) {
          const amount = (history.amount !== null && history.pay_status !== PAY_STATUS_IN_PROGRESS) ?
            centsToDollarStrWSign(history.amount) : '';
          const details = history.detail_text ? ' - ' + history.detail_text : '';
          return `Pay #${history.pay_id} ${amount} ${details}`;
        }
        return history.detail_text;
      },
      cellRenderer: toolTipRenderer,
    },
    ...(showAllConf ? [{field: 'conf', headerName: 'Conf'}] : []),
  ];

  const defaultColDefs = {
    sortable: false,
    filter: false,
    resizable: true,
  }

  function quickFilterChanged(newValue) {
    setQuickFilter(newValue);
    if (gridRef?.current?.api) {
      gridRef?.current?.api.setGridOption('quickFilterText', newValue);
    } else {
      console.warn('cannot set quick filter as api is not available');
    }
  }

  return (
    <Fragment>

      {filterUserId &&
        <div className="mb-3">
          Showing activity for user_id={filterUserId} {filterUserData?.email}
        </div>
      }

      <div className='d-flex flex-wrap row-gap-2 mb-3'>
        <div className='ms-1 me-1'>
          <Form.Check
            type="checkbox"
            label="Show All Conf"
            id="show_all_conf"
            checked={showAllConf}
            onChange={e => setShowAllConf(e.target.checked)}
            style={{maxWidth: '120px'}}
          />
        </div>

        <div className="d-flex ms-1 me-1">
          <Form.Group controlId="date_filter_start" className="flex-grow-1 me-1">
            <FloatingLabel controlId="date_filter_start" label="Start">
              <Form.Control type="date"
                            placeholder="Start"
                            name="date_filter_start"
                            value={dateFilterStart}
                            onChange={e => setDateFilterStart(e.target.value)}/>
            </FloatingLabel>
          </Form.Group>
          <Form.Group controlId="date_filter_end" className="flex-grow-1 ms-1">
            <FloatingLabel controlId="date_filter_end" label="End">
              <Form.Control type="date"
                            placeholder="End"
                            name="date_filter_end"
                            value={dateFilterEnd}
                            onChange={e => setDateFilterEnd(e.target.value)}/>
            </FloatingLabel>
          </Form.Group>
        </div>

        <div className='ms-1 me-1'>
          <Form.Group controlId="reg_id">
            <FloatingLabel controlId="reg_id" label="Reg ID">
              <Form.Control type="text"
                            placeholder=""
                            name="reg_id"
                            style={{maxWidth: '100px'}}
                            value={regId}
                            onChange={e => setRegId(e.target.value)}/>
            </FloatingLabel>
          </Form.Group>
        </div>

        <div className='ms-1 me-1'>
          <Form.Group controlId="action">
            <Form.Control
              className="form-select"
              as="select"
              value={action || ''}
              onChange={e => setAction(e.target.value)}
            >
              <option value='' key=''>Filter by Action...</option>
              {actionList?.map(action => {
                return <option value={action} key={action}>{getHistoryActionDisplay(action)}</option>
              })}
            </Form.Control>
          </Form.Group>
        </div>

        <div className='ms-1 me-1'>
          <Form.Group controlId="page_num">
            <FloatingLabel controlId="page_num" label="Pg #">
              <Form.Control type="number"
                            step="1"
                            min="1"
                            placeholder="Pg #"
                            name="page_num"
                            style={{maxWidth: '80px'}}
                            value={pageNum}
                            onChange={e => setPageNum(e.target.value)}/>
            </FloatingLabel>
          </Form.Group>
        </div>

        <div className='ms-1 me-1'>
          <Form.Group controlId="page_size">
            <FloatingLabel controlId="page_size" label="Page Size">
              <Form.Control type="number"
                            step="1000"
                            min="1"
                            placeholder="Page Size"
                            name="page_size"
                            style={{maxWidth: '100px'}}
                            value={pageSize}
                            onChange={e => setPageSize(e.target.value)}/>
            </FloatingLabel>
          </Form.Group>
        </div>

        <div className='ms-1 me-1'>
          <Button onClick={() => loadHistory()}>Apply Filters </Button>
        </div>
      </div>

      {errors && errors.length > 0 &&
        <div className="text-center mb-3 usacm-error-message">
          {getErrorMessageForAllErrors(errors)}
        </div>
      }

      <div className='flex-grow-1 ms-1 mb-2'>
        <Form.Control type="text"
                      id="quick-filter"
                      placeholder='Search Results...'
                      name="quick-filter"
                      value={quickFilter}
                      onChange={e => quickFilterChanged(e.target.value)}
                      style={{minWidth: '150px'}}/>
      </div>
      <div className="ag-theme-alpine" style={{width: '100%', ...(hasFewRows ? {} : {height: '700px'})}}>
        <AgGridReact
          ref={gridRef}
          rowData={historyList}
          columnDefs={colDefs}
          defaultColDef={defaultColDefs}
          domLayout={hasFewRows ? "autoHeight" : "normal"}
          gridOptions={{enableCellTextSelection: true, ensureDomOrder: true}} // enable cell selection
          getRowId={(params) => params.data.id} // prevent re-rendering the whole table when data changes
        />
      </div>
    </Fragment>
  );
}

