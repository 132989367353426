import {Col, Row} from "react-bootstrap";
import React, {Fragment, useRef, useState} from "react";
import {HtmlEditor} from "../../shared/HtmlEditor";

export function LabelFieldEditor({config}) {
  const editorRef = useRef(null);
  const [refresh, setRefresh] = useState(0);
  // Setup default values
  if (typeof config.html === "undefined") {
    config.html = '';
  }

  function htmlChanged() {
    if (!editorRef.current) {
      console.warn("Unable to get editor ref component as page isn't fully loaded yet");
      return;
    }
    config.html = editorRef.current.getContent();
    setRefresh(refresh + 1);
  }

  return (
      <Fragment>
        <Row className="mb-3">
          <Col className="col-12 mt-2">
            <HtmlEditor
                initialValue={config.html || ''}
                onInit={(evt, editor) => editorRef.current = editor}
                onBlur={() => htmlChanged()}
            />
          </Col>
        </Row>
      </Fragment>
  );
}

