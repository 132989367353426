import {Button, Col, Container, FloatingLabel, Form, Row} from "react-bootstrap";
import {useState} from "react";
import {fieldHasErrors, getErrorMessageForField} from "../utils/formUtils";
import {PageHeader} from "../menu/PageHeader";
import {createConf} from "../api/ConfApi";
import {showSuccessToast} from "../utils/usacmUtils";


export function CreateConf() {
  const [host, setHost] = useState('');
  const [shortName, setShortName] = useState('');
  const [longName, setLongName] = useState('');
  const [errors, setErrors] = useState([]);

  function callCreateConf(event) {
    event.preventDefault();
    createConf(host, shortName, longName, (status, newErrors) => {
      if (status === 200) {
        showSuccessToast(`The conference ${shortName} has been created`);
        setHost('');
        setShortName('');
        setLongName('');
        setErrors([]);
      } else {
        setErrors(newErrors);
      }
    });
  }

  return (
      <div>
        <PageHeader pageTitle="Create Conference"/>
        <Form onSubmit={callCreateConf}>
          <Container fluid className="usacm-container-narrow">

              <Row>
                <Col className="mb-3">
                  <p>The host name cannot be changed once the conference has been created. The other fields are editable.</p>
                  <p>In order to create a new conference you will also need to setup the DNS to point the new conference to the server.</p>
                </Col>
              </Row>

              <Row>
                <Col className="mb-3">
                  <Form.Group controlId="host">
                    <FloatingLabel
                        controlId="host"
                        label="Host"
                    >
                      <Form.Control
                          type="text"
                          name="host"
                          placeholder="submissions.example.com"
                          value={host}
                          onChange={e => setHost(e.target.value)}
                          isInvalid={fieldHasErrors(errors, 'host')}/>
                      <Form.Control.Feedback type="invalid">
                        {getErrorMessageForField(errors, 'host')}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col className="mb-3">
                  <Form.Group controlId="shortname">
                    <FloatingLabel controlId="shortname" label="Short Name">
                      <Form.Control type="text"
                                    placeholder="Short Name"
                                    name="short_name"
                                    value={shortName}
                                    onChange={e => setShortName(e.target.value)}
                                    isInvalid={fieldHasErrors(errors, 'short_name')}/>
                      <Form.Control.Feedback type="invalid">
                        {getErrorMessageForField(errors, 'short_name')}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col className="mb-3">
                  <Form.Group controlId="longname">
                    <FloatingLabel controlId="longname" label="Long Name">
                      <Form.Control type="text"
                                    placeholder="Long Name"
                                    name="long_name"
                                    value={longName}
                                    onChange={e => setLongName(e.target.value)}
                                    isInvalid={fieldHasErrors(errors, 'long_name')}/>
                      <Form.Control.Feedback type="invalid">
                        {getErrorMessageForField(errors, 'long_name')}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
              </Row>

              {fieldHasErrors(errors, '') &&
              <Row>
                <Col className="text-center mb-3 usacm-error-message">
                  {getErrorMessageForField(errors, '')}
                </Col>
              </Row>
              }

              <Row>
                <Col className="text-center mb-3">
                  <Button type="submit" className="ms-3 me-3">Create</Button>
                </Col>
              </Row>

          </Container>
        </Form>
      </div>
  );

}
