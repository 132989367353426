import React, {useContext, useState} from "react";
import {createStripePayment} from "../api/StripeApi";
import {showSuccessToast} from "../utils/usacmUtils";
import {Button, Col, Form, Row} from "react-bootstrap";
import {forceHideBusy, forceShowBusy} from "../utils/busyUtils";
import {PaymentElement, useElements, useStripe} from "@stripe/react-stripe-js";
import {fieldHasErrors, getErrorMessageForField} from "../utils/formUtils";
import {UsacmContext} from "../App";
import {deleteReg} from "../api/RegApi";

export function RegCheckoutStripe({reg, pay, totalAmount, preview, onCancel, onComplete, redirectPath}) {
  const {conference} = useContext(UsacmContext);
  const [conf,] = conference;
  const stripe = useStripe();
  const elements = useElements();
  const [errors, setErrors] = useState([]);
  const [sending, setSending] = useState(false);

  function handleError(errMsg) {
    setErrors([{'message': errMsg, 'fields': ['']}]);
  }

  const submitPayment = async (e) => {
    setErrors([]);
    e.preventDefault();
    if (preview) {
      showSuccessToast('Preview Complete. No data is sent to Stripe for previews.');
      deleteReg(reg.id, (code, data, errors) => {
      });
      forceHideBusy();
      onComplete();
      return;
    }
    if (!stripe) {
      console.warn("Unable to submit pay as Stripe.js hasn't loaded yet");
      return;
    }
    // This prevents double clicks on the Pay button
    if (sending) {
      return;
    }
    setSending(true);
    forceShowBusy();
    const {error: submitError} = await elements.submit();
    if (submitError) {
      handleError(submitError.message);
      forceHideBusy();
      setSending(false);
      return;
    }

    createStripePayment(reg.id, pay.id, false, (code, data, errors) => {
      if (code === 200) {
        if (totalAmount !== data.total_amount) {
          console.warn('TotalAmounts do not match: ', totalAmount, ' !== ', data.total_amount);
          handleError('The invoice amount has changed since the page was loaded.  Please reload your page.');
          forceHideBusy();
          return;
        }
        const clientSecret = data.stripe_client_secret;
        stripe.confirmPayment({
          elements,
          clientSecret,
          confirmParams: {
            return_url: conf.conf_url + redirectPath,
          },
        }).then(response => {
          if (response && response.error) {
            handleError(response.error.message);
            forceHideBusy();
          } else {
            // We don't need to hide the busy here... Stripe should redirect us on success
          }
        });
      } else { // else the code for paymentIntent is not 200
        setErrors(errors);
        forceHideBusy();
      }
      setSending(false);
    });
  }

  return (
    <Form onSubmit={submitPayment}>
      <div>
        <PaymentElement/>
      </div>

      {fieldHasErrors(errors, '') &&
        <Row className='mt-3'>
          <Col className="text-center mb-3 usacm-error-message">
            {getErrorMessageForField(errors, '')}
          </Col>
        </Row>
      }

      <Row>
        <Col className="usacm-button-row">
          <Button variant="secondary" onClick={() => onCancel()}>Back</Button>
          <Button type="submit">Pay</Button>
        </Col>
      </Row>
    </Form>
  );

}
