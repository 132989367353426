import React from "react";
import {getAnswerDisplayForField} from "./regUtils";
import {FIELD_TYPE_FILE_UPLOAD, FIELD_TYPE_LABEL, FIELD_TYPE_SECTION} from "../constants";
import {Button} from "react-bootstrap";
import {downloadFile} from "../utils/networkUtils";

export function RegViewSection({reg, fields, parentFieldId = null}) {
  const sectionFields = fields.filter(f => f.parent_field_id === parentFieldId && f.visible);

  function getFieldJsx(field) {
    if (field.field_type === FIELD_TYPE_SECTION) {
      return (
          <div className='section-child' key={field.id}>
            <div className="section-header"> {field.name} </div>
            <div className='section-child-content'>
              <RegViewSection reg={reg} fields={fields} parentFieldId={field.id}/>
            </div>
            <div className='section-child-footer'/>
          </div>);
    }
    if (field.field_type === FIELD_TYPE_LABEL) {
      return <div className='p-2' key={field.id} dangerouslySetInnerHTML={{__html: field?.config?.html || ''}}/>
    }
    return (
        <div key={field.id} className='p-2'>
          <span className='fw-bold'>{field.name}:</span>
          <span className='ps-2'>{getAnswerDisplayForField(field, field.answer)}</span>
          {field.field_type === FIELD_TYPE_FILE_UPLOAD && field.answer?.value &&
          <Button onClick={() => downloadFile('reg/field/answer/file-download/' + field.id + '/', field?.answer?.value)}
                  className='ms-3'>
            View File
          </Button>
          }
        </div>);
  }

  return (
      <div className="section-container">
        {sectionFields.map(field => getFieldJsx(field))}
      </div>
  );

}
