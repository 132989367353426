import {Button, Col, Container, Form, Row} from "react-bootstrap";
import {PageHeader} from "../menu/PageHeader";
import React, {Fragment, useContext, useEffect, useRef, useState} from "react";
import {UsacmContext} from "../App";
import {countWords, getConfLabel, sentenceCase, titleCase} from "../utils/usacmUtils";
import {
  ABSTRACT_MAX_TEXT_WORDS,
  ABSTRACT_PRESENTATION_TYPE_ORAL,
  ABSTRACT_PRESENTATION_TYPE_POSTER,
  CONF_LABEL_KEY_SUBMIT_ABSTRACT_CONTENT,
  CONF_LABEL_KEY_SUBMIT_ABSTRACT_EARLY_CONTENT,
  CONF_LABEL_KEY_SUBMIT_ABSTRACT_EXPIRED_CONTENT,
  CONF_LABEL_KEY_SYMPOSIUM,
  PERMISSION_CONF_ADMIN,
  PERMISSION_STAFF,
  SYMPOSIUM_STATUS_APPROVED
} from "../constants";
import {getCurrentUser, hasPermission} from "../api/UserApi";
import {anyFieldHasErrors, fieldHasErrors, getErrorMessageForField} from "../utils/formUtils";
import {submitAbstract} from "../api/AbstractApi";
import {AuthorOrganizerEditor, TYPE_AUTHOR} from "../shared/AuthorOrganizerEditor";
import {getSymposiaBrief} from "../api/SymposiumApi";
import {ForceUpdateButton} from "../shared/ForceUpdateButton";


export function SubmitAbstract() {
  const {conference} = useContext(UsacmContext);
  const [conf,] = conference;
  const [confSymposia, setConfSymposia] = useState([]);
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [presentationType, setPresentationType] = useState(ABSTRACT_PRESENTATION_TYPE_ORAL);
  const [symposiumId, setSymposiumId] = useState('');
  const [authors, setAuthors] = useState([]);
  const authorEditorRef = useRef(null);
  const [errors, setErrors] = useState([]);
  const [submitComplete, setSubmitComplete] = useState(false);
  const symposiumLabel = getConfLabel(conf, CONF_LABEL_KEY_SYMPOSIUM);
  const isStaffOrAdmin = hasPermission(PERMISSION_STAFF) || hasPermission(PERMISSION_CONF_ADMIN);
  const beforeAbstractSubmitStart = !(conf?.deadlines?.before_abstract_submit_start === false); // default to true
  const pastAbstractSubmitEnd = !(conf?.deadlines?.past_abstract_submit_end === false); // default to true

  // Calculate the content (before start, during submissions, or after end)
  let htmlContent = getConfLabel(conf, CONF_LABEL_KEY_SUBMIT_ABSTRACT_CONTENT);
  let canSubmit = !submitComplete;
  if (!isStaffOrAdmin && beforeAbstractSubmitStart) {
    htmlContent = getConfLabel(conf, CONF_LABEL_KEY_SUBMIT_ABSTRACT_EARLY_CONTENT);
    canSubmit = false;
  }
  if (!isStaffOrAdmin && pastAbstractSubmitEnd) {
    htmlContent = getConfLabel(conf, CONF_LABEL_KEY_SUBMIT_ABSTRACT_EXPIRED_CONTENT);
    canSubmit = false;
  }

  useEffect(() => {
    if (conf) {
      getSymposiaBrief((status, data, newErrors) => {
        if (status === 200) {
          // We will only show approved symposia here (even for staff)
          setConfSymposia(data.filter(s => s.status === SYMPOSIUM_STATUS_APPROVED));
        }
      });
    }
  }, [conf]);

  /**
   * Removes errors for the specified field
   * @param field name of field to remove all errors for
   * NOTE: This will call setErrors() so you may need to refresh the new errorList will be returned
   */
  function clearErrors( field) {
    const newErrors = errors.filter(err => !err.fields.includes(field));
    setErrors(newErrors);
    return newErrors;
  }

  function callSubmitAbstract(force=false) {
    //  If we have some values in the new author fields we will save them first
    if (!authorEditorRef.current.hasValidOrganizers()) {
      // Could not validate authors - some errors will be on the page.
      return;
    }
    if (!isStaffOrAdmin && fieldHasErrors(errors, 'text')) {
      // FE validation has error
      return;
    }
    submitAbstract(force, symposiumId, title, text, presentationType, authors, (status, data, newErrors) => {
      if (status === 200) {
        setErrors([]);
        setSymposiumId('');
        setTitle('');
        setText('');
        setPresentationType(ABSTRACT_PRESENTATION_TYPE_ORAL);
        setAuthors([]);
        setSubmitComplete(true);
        // Reload the user (as permissions may have changed)
        getCurrentUser((userStatus, userData, userErrors) => { });
      } else {
        setErrors(newErrors);
      }
    });
  }

  function changeSymposiumId(newSymposiumId) {
    const newErrors = clearErrors('symposium_id');
    setSymposiumId(newSymposiumId);
    if (!newSymposiumId) {
      newErrors.push({
        'message': `You must select a ${symposiumLabel}.`,
        'fields': ['symposium_id']
      });
    }
  }

  function changeTitle(newTitle) {
    clearErrors('title');
    setTitle(newTitle);
  }

  function changeTextField(newValue) {
    const newErrors = clearErrors('text');
    // Not validated for staff/conf admin
    if (!isStaffOrAdmin) {
      const wordCount = countWords(newValue);
      if (wordCount > ABSTRACT_MAX_TEXT_WORDS) {
        newErrors.push({
          'message': 'Text must be ' + ABSTRACT_MAX_TEXT_WORDS + ' words or less. You have ' + wordCount + ' words.',
          'fields': ['text']
        });
      }
      if (wordCount === 0) {
        newErrors.push({
          'message': 'You must enter some text.',
          'fields': ['text']
        });
      }
      setErrors(newErrors);
    }
    setText(newValue);
  }

  return (
      <div>
        <PageHeader pageTitle="Submit an Abstract"/>
        <Container fluid className="usacm-container-wide">
          <Row className="mb-3">
            <div dangerouslySetInnerHTML={{__html: htmlContent}}/>
          </Row>

          {submitComplete &&
          <Row className="text-center mt-3">
            <Col>
              <Button onClick={() => setSubmitComplete(false)}> Submit Another Abstract </Button>
            </Col>
          </Row>
          }

          {canSubmit &&
          <Fragment>

            <Row>
              <Col className="mb-3">
                <div>Select {titleCase(symposiumLabel)}</div>
                <Form.Group controlId="symposium-select">
                  <Form.Control
                      className="form-select"
                      as="select"
                      value={symposiumId || ''}
                      onChange={e => changeSymposiumId(e.target.value)}
                      isInvalid={fieldHasErrors(errors, 'symposium_id')}
                  >
                    <option value='' key=''>Choose...</option>
                    {confSymposia?.map(s => {
                      return <option value={s.id} key={s.id}>{s.symposium_number} - {s.title}</option>
                    })}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {getErrorMessageForField(errors, 'symposium_id')}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col className="mb-3">
                <div>
                  Title
                  {isStaffOrAdmin &&
                  <Button onClick={() => setTitle(sentenceCase(title))} size="sm" className="ms-3 mb-1">Use Sentence Case</Button>
                  }
                </div>
                <Form.Group controlId="title">
                  <Form.Control type="text"
                                placeholder="Enter title..."
                                required
                                name="title"
                                value={title}
                                onChange={e => changeTitle(e.target.value)}
                                isInvalid={fieldHasErrors(errors, 'title')}/>
                  <Form.Control.Feedback type="invalid">
                    {getErrorMessageForField(errors, 'title')}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col className="mb-3">
                <div>Text <span>({ABSTRACT_MAX_TEXT_WORDS} word max)</span></div>
                <Form.Group controlId="text">
                  <Form.Control as="textarea"
                                rows={12}
                                placeholder="Enter abstract text..."
                                required
                                name="text"
                                value={text}
                                onChange={e => changeTextField(e.target.value)}
                                isInvalid={fieldHasErrors(errors, 'text')}/>
                  <Form.Control.Feedback type="invalid">
                    {getErrorMessageForField(errors, 'text')}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            {conf?.abstract_allow_poster &&
            <Row>
              <Col className="mb-3">
                <div>Presentation Type</div>
                <Form.Check
                    id="presentation_type_poster"
                    type="radio"
                    name="presentation_type"
                    label="Oral"
                    value={ABSTRACT_PRESENTATION_TYPE_ORAL}
                    inline
                    checked={!presentationType || presentationType === ABSTRACT_PRESENTATION_TYPE_ORAL}
                    onChange={e => setPresentationType(e.target.value)}
                />
                <Form.Check
                    id="presentation_type_oral"
                    type="radio"
                    name="presentation_type"
                    label="Poster"
                    value={ABSTRACT_PRESENTATION_TYPE_POSTER}
                    inline
                    checked={presentationType === ABSTRACT_PRESENTATION_TYPE_POSTER}
                    onChange={e => setPresentationType(e.target.value)}
                />
              </Col>
            </Row>
            }

            <AuthorOrganizerEditor
                ref={authorEditorRef}
                organizers={authors}
                setOrganizers={setAuthors}
                errors={errors}
                setErrors={setErrors}
                type={TYPE_AUTHOR}
            />

            {anyFieldHasErrors(errors, ['', 'symposium_id']) &&
            <Row>
              <Col className="text-center mb-3 usacm-error-message">
                {getErrorMessageForField(errors, '')}
              </Col>
            </Row>
            }

            <Row>
              <Col className="text-center mb-3">
                <Button type="button" className="ms-3 me-3" onClick={ () => callSubmitAbstract(false)}>Submit Abstract</Button>
                <ForceUpdateButton errors={errors} onClick={() => callSubmitAbstract(true)} />
              </Col>
            </Row>

          </Fragment>
          }

        </Container>
      </div>
  );

}
