import {Button, Col, Container, Form, Row} from "react-bootstrap";
import React, {useContext, useEffect, useRef, useState} from "react";
import {UsacmContext} from "../App";
import {getCurrentConf, updateConfLabels} from "../api/ConfApi";
import {showSuccessToast} from "../utils/usacmUtils";
import {fieldHasErrors, getErrorMessageForField} from "../utils/formUtils";
import {HtmlEditor} from "../shared/HtmlEditor";
import {CONF_LABEL_TYPE_HTML, PERMISSION_CONF_ADMIN, PERMISSION_STAFF} from "../constants";
import {hasPermission} from "../api/UserApi";

export function ConfSettingsContent() {
  const {conference} = useContext(UsacmContext);
  const [conf, setConf] = conference;
  const [labels, setLabels] = useState([]);
  const [selectedKey, setSelectedKey] = useState(null);
  const [errors, setErrors] = useState([]);
  const editorRef = useRef(null);
  const isStaffOrAdmin = hasPermission(PERMISSION_STAFF) || hasPermission(PERMISSION_CONF_ADMIN);

  useEffect(() => {
    if (conf) {
      const newLabels = conf.labels.filter(l => l.type === CONF_LABEL_TYPE_HTML);
      setLabels(newLabels);
      if (newLabels && !selectedKey) {
        selectKey(newLabels[0].key);
      }
    }
  }, [conf]);

  function selectKey(newKey) {
    setSelectedKey(newKey);
  }

  function getSelectedValue() {
    return labels?.find((l) => l.key === selectedKey)?.value || '';
  }

  function updateLabel() {
    if (editorRef.current) {
      const htmlValue = editorRef.current.getContent();
      const labelData = {[selectedKey]: htmlValue};
      updateConfLabels(labelData, (status, data, newErrors) => {
        if (status === 200) {
          setErrors([]);
          showSuccessToast("Conference content saved.");
          getCurrentConf((code, data, errors) => {
            if (code === 200) {
              setConf(data);
            } else {
              console.warn('Error loading conf', errors);
            }
          });
        } else {
          setErrors(newErrors);
        }
      });
    }
  }

  return (
      <Container fluid className="usacm-container-wide">

        <Row className="mb-3 mt-4">
          <Col className="col-auto mr-3 usacm-label">
            Content to {isStaffOrAdmin ? 'Edit' : 'View'}
          </Col>
          <Col className="text-left col-4">
            <Form.Group controlId="selectedKey">
              <Form.Control
                  className="form-select"
                  as="select"
                  value={selectedKey || ''}
                  onChange={e => selectKey(e.target.value)}
              >
                {labels?.map(label => {
                  return <option value={label.key} key={label.key}>{label.name}</option>
                })}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>

          <Row>
            <Col className="text-center mb-3">
              <HtmlEditor
                  initialValue={getSelectedValue()}
                  onInit={(evt, editor) => editorRef.current = editor}
                  disabled={!isStaffOrAdmin}
              />
            </Col>
          </Row>

        {fieldHasErrors(errors, '') &&
        <Row>
          <Col className="text-center mb-3 usacm-error-message">
            {getErrorMessageForField(errors, '')}
          </Col>
        </Row>
        }

        <Row>
          <Col className="text-center mb-3">
            {isStaffOrAdmin &&
            <Button type="button" className="ms-3 me-3" onClick={updateLabel}>Update Content</Button>
            }
          </Col>
        </Row>

      </Container>
  );
}
