import {Button, Col, Container, FloatingLabel, Form, Row} from "react-bootstrap";
import React, {useContext, useEffect, useState} from "react";
import {UsacmContext} from "../App";
import {fieldHasErrors, getErrorMessageForField} from "../utils/formUtils";
import {getCurrentConf, updateConfLists} from "../api/ConfApi";
import {getConfLabel, makePlural, showSuccessToast, titleCase} from "../utils/usacmUtils";
import {
  CONF_LABEL_KEY_EMPHASIS_AREA,
  CONF_LABEL_KEY_LOCATION,
  CONF_LABEL_KEY_SECONDARY_EMPHASIS_AREA,
  PERMISSION_CONF_ADMIN,
  PERMISSION_STAFF
} from "../constants";
import {hasPermission} from "../api/UserApi";

export function ConfSettingsLists() {
  const {conference} = useContext(UsacmContext);
  const [conf, setConf] = conference;
  const [useEmphasisArea, setUseEmphasisArea] = useState(false);
  const [useSecondaryEmphasisArea, setUseSecondaryEmphasisArea] = useState(false);
  const [newEmphasisArea, setNewEmphasisArea] = useState('');
  const [emphasisAreas, setEmphasisAreas] = useState([]);
  const [locations, setLocations] = useState([]);
  const [newLocation, setNewLocation] = useState('');
  const [errors, setErrors] = useState([]);
  const isStaffOrAdmin = hasPermission(PERMISSION_STAFF) || hasPermission(PERMISSION_CONF_ADMIN);
  const emphasisAreaLabel = titleCase(getConfLabel(conf, CONF_LABEL_KEY_EMPHASIS_AREA));
  const secondaryEmphasisAreaLabel = titleCase(getConfLabel(conf, CONF_LABEL_KEY_SECONDARY_EMPHASIS_AREA));
  const locationLabel = titleCase(getConfLabel(conf, CONF_LABEL_KEY_LOCATION));

  useEffect(() => {
    if (conf) {
      setUseEmphasisArea(!!conf.use_emphasis_area);
      setUseSecondaryEmphasisArea(!!conf.use_secondary_emphasis_area);
      setEmphasisAreas(conf.emphasis_areas || []);
      setLocations(conf.locations || []);
    }
  }, [conf]);

  function callUpdateConf(event) {
    event.preventDefault();
    if (newEmphasisArea) {
      addEmphasisArea();
    }
    if (newLocation) {
      addLocation();
    }
    updateConfLists(useEmphasisArea, useSecondaryEmphasisArea, emphasisAreas, locations, (status, data, newErrors) => {
      if (status === 200) {
        setErrors([]);
        showSuccessToast("List settings saved");
        getCurrentConf((code, data, errors) => {
          if (code === 200) {
            setConf(data);
          } else {
            console.warn('Error loading conf in settings', errors);
          }
        });
      } else {
        setErrors(newErrors);
      }
    });
  }

  function deleteEmphasisArea(index) {
    emphasisAreas.splice(index, 1);
    setEmphasisAreas([...emphasisAreas]); // cause new render
  }

  function editEmphasisArea(value, index) {
    emphasisAreas[index] = value;
    setEmphasisAreas([...emphasisAreas]); // cause new render
  }

  function addEmphasisArea() {
    emphasisAreas.push(newEmphasisArea);
    setNewEmphasisArea('');
  }

  function deleteLocation(index) {
    locations.splice(index, 1);
    setLocations([...locations]); // cause new render
  }

  function editLocation(value, index) {
    locations[index] = value;
    setLocations([...locations]); // cause new render
  }

  function addLocation() {
    locations.push(newLocation);
    setNewLocation('');
  }

  return (
    <Form onSubmit={callUpdateConf}>
      <Container fluid className="usacm-container-narrow">

        <Row>
          <Col className="mb-3">
            <Form.Check
              type="checkbox"
              label={"Use " + emphasisAreaLabel}
              id="use_emphasis_area"
              checked={useEmphasisArea}
              onChange={e => isStaffOrAdmin && setUseEmphasisArea(e.target.checked)}
            />
          </Col>
        </Row>

        {useEmphasisArea &&
          <Row>
            <Col className="mb-3">
              <Form.Check
                type="checkbox"
                label={"Use " + secondaryEmphasisAreaLabel}
                id="use_secondary_emphasis_area"
                checked={useSecondaryEmphasisArea}
                onChange={e => isStaffOrAdmin && setUseSecondaryEmphasisArea(e.target.checked)}
              />
            </Col>
          </Row>
        }

        {useEmphasisArea &&
          <Row>
            <Col className="mb-3">
              {emphasisAreas.map((eaValue, index) => {
                return (
                  <Row key={index}>
                    <Col className="col-6 mb-1">
                      <Form.Group controlId={'ea' + index}>
                        <Form.Control type="text"
                                      name={'ea' + index}
                                      value={emphasisAreas[index]}
                                      onChange={e => editEmphasisArea(e.target.value, index)}
                                      readOnly={!isStaffOrAdmin}
                        />
                      </Form.Group>
                    </Col>
                    <Col className="col-6 mb-1">
                      {isStaffOrAdmin &&
                        <Button size="sm" onClick={() => deleteEmphasisArea(index)}>Delete</Button>
                      }
                    </Col>
                  </Row>);
              })}

              {isStaffOrAdmin &&
                <Row>
                  <Col className="col-6">
                    <Form.Group controlId="ea-1">
                      <FloatingLabel controlId="newEmphasisArea" label={"New " + emphasisAreaLabel}>
                        <Form.Control type="text"
                                      name="newEmphasisArea"
                                      value={newEmphasisArea}
                                      onChange={e => setNewEmphasisArea(e.target.value)}
                        />
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                  <Col className="col-6">
                    <Button className="mt-1" onClick={addEmphasisArea}>Add</Button>
                  </Col>
                </Row>
              }
            </Col>
          </Row>
        }

        <Row>
          <Col className="mt-3 mb-3">
            {makePlural(locationLabel)}
          </Col>
        </Row>

        <Row>
          <Col className="mb-3">
            {locations.map((locationValue, index) => {
              return (
                <Row key={index}>
                  <Col className="col-6 mb-1">
                    <Form.Group controlId={'location' + index}>
                      <Form.Control type="text"
                                    name={'location' + index}
                                    value={locations[index]}
                                    onChange={e => editLocation(e.target.value, index)}
                                    readOnly={!isStaffOrAdmin}
                      />
                    </Form.Group>
                  </Col>
                  <Col className="col-6 mb-1">
                    {isStaffOrAdmin &&
                      <Button size="sm" onClick={() => deleteLocation(index)}>Delete</Button>
                    }
                  </Col>
                </Row>);
            })}
            {isStaffOrAdmin &&
              <Row>
                <Col className="col-6">
                  <Form.Group controlId="ea-1">
                    <FloatingLabel controlId="newLocation" label={"New " + locationLabel}>
                      <Form.Control type="text"
                                    name="newLocation"
                                    value={newLocation}
                                    onChange={e => setNewLocation(e.target.value)}
                      />
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col className="col-6 mt-auto mb-auto">
                  <Button onClick={addLocation}>Add</Button>
                </Col>
              </Row>
            }
          </Col>
        </Row>

        {fieldHasErrors(errors, '') &&
          <Row>
            <Col className="text-center mb-3 usacm-error-message">
              {getErrorMessageForField(errors, '')}
            </Col>
          </Row>
        }

        <Row>
          <Col className="text-center mb-3">
            {isStaffOrAdmin &&
              <Button type="submit" className="ms-3 me-3">Update Conference</Button>
            }
          </Col>
        </Row>

      </Container>
    </Form>
  );

}
