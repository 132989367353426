import {Accordion, Button, Col, Container, FloatingLabel, Form, Row} from "react-bootstrap";
import React, {useContext, useEffect, useState} from "react";
import {UsacmContext} from "../App";
import {fieldHasErrors, getErrorMessageForField} from "../utils/formUtils";
import {getCurrentConf, setPageTitle, updateConfTheme} from "../api/ConfApi";
import {getThemeValue, showSuccessToast} from "../utils/usacmUtils";
import {CompactPicker} from "react-color";
import "./ConfSettingsTheme.scss";
import {hasPermission} from "../api/UserApi";
import {LOGO_SHAPE_WIDE, PERMISSION_CONF_ADMIN, PERMISSION_STAFF} from "../constants";

const NO_COLOR = {hex: ''};

export function ConfSettingsTheme() {
  const {conference} = useContext(UsacmContext);
  const [conf, setConf] = conference;
  const [logoUrl, setLogoUrl] = useState('');
  const [logoShape, setLogoShape] = useState(LOGO_SHAPE_WIDE);
  const [headerUrl, setHeaderUrl] = useState('');
  const [faviconUrl, setFaviconUrl] = useState('');
  const [darkColor, setDarkColor] = useState(NO_COLOR);
  const [mediumDarkColor, setMediumDarkColor] = useState(NO_COLOR);
  const [lightColor, setLightColor] = useState(NO_COLOR);
  const [highlightColor, setHighlightColor] = useState(NO_COLOR);
  const [cardHeaderText, setCardHeaderText] = useState(NO_COLOR);
  const [cardHeaderSecondaryText, setCardHeaderSecondaryText] = useState(NO_COLOR);
  const [cardHeaderBackground, setCardHeaderBackground] = useState(NO_COLOR);
  const [linkColor, setLinkColor] = useState(NO_COLOR);
  const [primaryText, setPrimaryText] = useState(NO_COLOR);
  const [primaryBackground, setPrimaryBackground] = useState(NO_COLOR);
  const [primaryHoverText, setPrimaryHoverText] = useState(NO_COLOR);
  const [primaryHoverBackground, setPrimaryHoverBackground] = useState(NO_COLOR);
  const [secondaryText, setSecondaryText] = useState(NO_COLOR);
  const [secondaryBackground, setSecondaryBackground] = useState(NO_COLOR);
  const [secondaryHoverText, setSecondaryHoverText] = useState(NO_COLOR);
  const [secondaryHoverBackground, setSecondaryHoverBackground] = useState(NO_COLOR);
  const [disabledText, setDisabledText] = useState(NO_COLOR);
  const [disabledBackground, setDisabledBackground] = useState(NO_COLOR);
  const [errors, setErrors] = useState([]);
  const isStaffOrAdmin = hasPermission(PERMISSION_STAFF) || hasPermission(PERMISSION_CONF_ADMIN);

  useEffect(() => {
    if (conf) {
      const theme = conf.theme;
      if (theme) {
        setLogoUrl(theme.logo_url);
        setLogoShape(theme.logo_shape || LOGO_SHAPE_WIDE);
        setHeaderUrl(theme.header_url);
        setFaviconUrl(theme.favicon_url);
        // These are fake color objects - so the value looks like it's loaded
        setDarkColor({hex: theme.dark_color || getThemeValue('dark_color')});
        setMediumDarkColor({hex: theme.medium_dark_color || getThemeValue('medium_dark_color')});
        setLightColor({hex: theme.light_color || getThemeValue('light_color')});
        setHighlightColor({hex: theme.highlight_color || getThemeValue('highlight_color')});
        setCardHeaderText({hex: theme.card_header_text || getThemeValue('card_header_text')});
        setCardHeaderSecondaryText({hex: theme.card_header_secondary_text || getThemeValue('card_header_secondary_text')});
        setCardHeaderBackground({hex: theme.card_header_background || getThemeValue('card_header_background')});
        setLinkColor({hex: theme.link_color || getThemeValue('link_color')});
        setPrimaryText({hex: theme.primary_text || getThemeValue('primary_text')});
        setPrimaryBackground({hex: theme.primary_background || getThemeValue('primary_background')});
        setPrimaryHoverText({hex: theme.primary_hover_text || getThemeValue('primary_hover_text')});
        setPrimaryHoverBackground({hex: theme.primary_hover_background || getThemeValue('primary_hover_background')});
        setSecondaryText({hex: theme.secondary_text || getThemeValue('secondary_text')});
        setSecondaryBackground({hex: theme.secondary_background || getThemeValue('secondary_background')});
        setSecondaryHoverText({hex: theme.secondary_hover_text || getThemeValue('secondary_hover_text')});
        setSecondaryHoverBackground({hex: theme.secondary_hover_background || getThemeValue('secondary_hover_background')});
        setDisabledText({hex: theme.disabled_text || getThemeValue('disabled_text')});
        setDisabledBackground({hex: theme.disabled_background || getThemeValue('disabled_background')});
      }
    }
  }, [conf]);

  function callUpdateConf(event) {
    event.preventDefault();
    updateConfTheme(conf?.host, logoUrl, logoShape, headerUrl, faviconUrl,
        darkColor?.hex, mediumDarkColor?.hex, lightColor?.hex, highlightColor?.hex,
        cardHeaderText?.hex, cardHeaderSecondaryText?.hex, cardHeaderBackground?.hex,
        linkColor?.hex, primaryText?.hex, primaryBackground?.hex, primaryHoverText?.hex, primaryHoverBackground?.hex,
        secondaryText?.hex, secondaryBackground?.hex, secondaryHoverText?.hex, secondaryHoverBackground?.hex,
        disabledText?.hex, disabledBackground?.hex,
        (status, data, newErrors) => {
          if (status === 200) {
            setErrors([]);
            showSuccessToast("Conference theme saved.");
            getCurrentConf((code, data, errors) => {
              if (code === 200) {
                setConf(data);
                setPageTitle(data.short_name)
              } else {
                console.warn('Error loading conf in settings', errors);
              }
            });
          } else {
            setErrors(newErrors);
          }
        });
  }

  return (
      <Form onSubmit={callUpdateConf}>
        <Container fluid className="usacm-container-wide">

          <Accordion defaultActiveKey="general">
            <Accordion.Item eventKey="general">
              <Accordion.Header>General</Accordion.Header>
              <Accordion.Body>
                <Container id="general" fluid className="usacm-container-narrow">
                  <Row>
                    <Col className="mb-3">
                      <Form.Group controlId="logo_url">
                        <FloatingLabel controlId="logo_url" label="Logo URL">
                          <Form.Control type="text"
                                        placeholder="..."
                                        name="logo_url"
                                        value={logoUrl}
                                        onChange={e => setLogoUrl(e.target.value)}
                                        readOnly={!isStaffOrAdmin}
                                        isInvalid={fieldHasErrors(errors, 'logo_url')}/>
                          <Form.Control.Feedback type="invalid">
                            {getErrorMessageForField(errors, 'logo_url')}
                          </Form.Control.Feedback>
                        </FloatingLabel>
                      </Form.Group>
                      Logos have max height of 100px. Leave blank to not use a logo.
                    </Col>
                  </Row>

                  <Row>
                    <Col className="mb-3">
                      <Form.Group controlId="logo_shape">
                        <FloatingLabel controlId="logo_shape" label="Logo Shape">
                          <Form.Control
                              className="form-select"
                              as="select"
                              value={logoShape || LOGO_SHAPE_WIDE}
                              onChange={e => setLogoShape(e.target.value)}
                          >
                            <option value='wide' key='wide'>Wide</option>
                            <option value='square' key='square'>Square</option>
                          </Form.Control>
                          <Form.Control.Feedback type="invalid">
                            {getErrorMessageForField(errors, 'logo_shape')}
                          </Form.Control.Feedback>
                        </FloatingLabel>
                      </Form.Group>
                      Wide will cause the logo to have it's own line.
                    </Col>
                  </Row>

                  <Row>
                    <Col className="mb-3">
                      <Form.Group controlId="header_url">
                        <FloatingLabel controlId="logo_url" label="Page Header URL">
                          <Form.Control type="text"
                                        placeholder="..."
                                        name="header_url"
                                        value={headerUrl}
                                        onChange={e => setHeaderUrl(e.target.value)}
                                        readOnly={!isStaffOrAdmin}
                                        isInvalid={fieldHasErrors(errors, 'header_url')}/>
                          <Form.Control.Feedback type="invalid">
                            {getErrorMessageForField(errors, 'header_url')}
                          </Form.Control.Feedback>
                        </FloatingLabel>
                      </Form.Group>
                      Page header should be a very wide image.
                    </Col>
                  </Row>

                  <Row>
                    <Col className="mb-3">
                      <Form.Group controlId="header_url">
                        <FloatingLabel controlId="favicon_url" label="Favicon URL">
                          <Form.Control type="text"
                                        placeholder="..."
                                        name="favicon_url"
                                        value={faviconUrl}
                                        onChange={e => setFaviconUrl(e.target.value)}
                                        readOnly={!isStaffOrAdmin}
                                        isInvalid={fieldHasErrors(errors, 'favicon_url')}/>
                          <Form.Control.Feedback type="invalid">
                            {getErrorMessageForField(errors, 'favicon_url')}
                          </Form.Control.Feedback>
                        </FloatingLabel>
                      </Form.Group>
                      Favicon should be a square image, sized around 32-64px
                    </Col>
                  </Row>
                </Container>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="menu">
              <Accordion.Header>Menu</Accordion.Header>
              <Accordion.Body>
                <Container fluid className="usacm-container-narrow">
                  <Row className="mb-3">
                    <Col className="col-3">
                      Dark Color
                    </Col>
                    <Col className="col-3" style={{background: darkColor?.hex, minHeight: '100px'}}>,
                    </Col>
                    <Col className="col-6">
                      {isStaffOrAdmin &&
                      <CompactPicker
                          className="color-picker-input"
                          color={darkColor}
                          onChangeComplete={color => setDarkColor(color)}/>
                      }
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col className="col-3">
                      Medium Dark Color
                    </Col>
                    <Col className="col-3" style={{background: mediumDarkColor?.hex, minHeight: '100px'}}>
                    </Col>
                    <Col className="col-6">
                      {isStaffOrAdmin &&
                      <CompactPicker
                          className="color-picker-input"
                          color={mediumDarkColor}
                          onChangeComplete={color => setMediumDarkColor(color)}/>
                      }
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col className="col-3">
                      Light Color
                    </Col>
                    <Col className="col-3" style={{background: lightColor?.hex, minHeight: '100px'}}>
                    </Col>
                    <Col className="col-6">
                      {isStaffOrAdmin &&
                      <CompactPicker
                          className="color-picker-input"
                          color={lightColor}
                          onChangeComplete={color => setLightColor(color)}/>
                      }
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col className="col-3">
                      Highlight Color
                    </Col>
                    <Col className="col-3" style={{background: highlightColor?.hex, minHeight: '100px'}}>
                    </Col>
                    <Col className="col-6">
                      {isStaffOrAdmin &&
                      <CompactPicker
                          className="color-picker-input"
                          color={highlightColor}
                          onChangeComplete={color => setHighlightColor(color)}/>
                      }
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col className="col-3">
                      Preview
                    </Col>
                    <Col className="col-9 preview-menu" style={{background: darkColor?.hex}}>
                      <div className='preview-heading' style={{background: mediumDarkColor?.hex, color: lightColor?.hex}}>Heading</div>
                      <div className='preview-item' style={{background: darkColor?.hex, color: lightColor?.hex}}>Menu Item</div>
                      <div className='preview-item'
                           style={{background: darkColor?.hex, color: highlightColor?.hex, fontWeight: 'bold'}}>Selected Menu Item
                      </div>
                    </Col>
                  </Row>

                </Container>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="cards">
              <Accordion.Header>Display Cards</Accordion.Header>
              <Accordion.Body>
                <Container fluid className="usacm-container-narrow">
                  <Row className="mb-3">
                    <Col className="col-4">
                      Card Header Text
                    </Col>
                    <Col className="col-3" style={{background: cardHeaderText?.hex, minHeight: '100px'}}>
                    </Col>
                    <Col className="col-5">
                      {isStaffOrAdmin &&
                      <CompactPicker
                          className="color-picker-input"
                          color={cardHeaderText}
                          onChangeComplete={color => setCardHeaderText(color)}/>
                      }
                      </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col className="col-4">
                      Card Header Text Secondary
                    </Col>
                    <Col className="col-3" style={{background: cardHeaderSecondaryText?.hex, minHeight: '100px'}}>
                    </Col>
                    <Col className="col-5">
                      {isStaffOrAdmin &&
                      <CompactPicker
                          className="color-picker-input"
                          color={cardHeaderSecondaryText}
                          onChangeComplete={color => setCardHeaderSecondaryText(color)}/>
                      }
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col className="col-4">
                      Card Header Background
                    </Col>
                    <Col className="col-3" style={{background: cardHeaderBackground?.hex, minHeight: '100px'}}>
                    </Col>
                    <Col className="col-5">
                      {isStaffOrAdmin &&
                      <CompactPicker
                          className="color-picker-input"
                          color={cardHeaderBackground}
                          onChangeComplete={color => setCardHeaderBackground(color)}/>
                      }
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col className="col-4">
                      Preview
                    </Col>
                    <Col className="col-8">
                      <div style={{border: '2px solid' + cardHeaderBackground?.hex, borderRadius: '10px'}}>
                        <div style={{background: cardHeaderBackground?.hex, fontWeight: 'bold', textAlign: 'center', padding: '10px'}}>
                          <div style={{color: cardHeaderText?.hex}}> Card Header</div>
                          <div style={{color: cardHeaderSecondaryText?.hex, padding: '5px'}}> Card Secondary Header</div>
                        </div>
                        <div style={{padding: '20px'}}> Card body text here.</div>
                      </div>

                    </Col>
                  </Row>

                </Container>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="buttons">
              <Accordion.Header>Buttons and Links</Accordion.Header>
              <Accordion.Body>
                <Container fluid className="usacm-container-narrow">
                  <Row className="mb-3">
                    <Col className="col-4">
                      Link Color
                    </Col>
                    <Col className="col-3" style={{background: linkColor?.hex, minHeight: '100px'}}>
                    </Col>
                    <Col className="col-5">
                      {isStaffOrAdmin &&
                      <CompactPicker
                          className="color-picker-input"
                          color={linkColor}
                          onChangeComplete={color => setLinkColor(color)}/>
                      }
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col className="col-4">
                      Primary Button Text
                    </Col>
                    <Col className="col-3" style={{background: primaryText?.hex, minHeight: '100px'}}>
                    </Col>
                    <Col className="col-5">
                      {isStaffOrAdmin &&
                      <CompactPicker
                          className="color-picker-input"
                          color={primaryText}
                          onChangeComplete={color => setPrimaryText(color)}/>
                      }
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col className="col-4">
                      Primary Button Background
                    </Col>
                    <Col className="col-3" style={{background: primaryBackground?.hex, minHeight: '100px'}}>
                    </Col>
                    <Col className="col-5">
                      {isStaffOrAdmin &&
                      <CompactPicker
                          className="color-picker-input"
                          color={primaryBackground}
                          onChangeComplete={color => setPrimaryBackground(color)}/>
                      }
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col className="col-4">
                      Primary Button Text on Hover
                    </Col>
                    <Col className="col-3" style={{background: primaryHoverText?.hex, minHeight: '100px'}}>
                    </Col>
                    <Col className="col-5">
                      {isStaffOrAdmin &&
                      <CompactPicker
                          className="color-picker-input"
                          color={primaryHoverText}
                          onChangeComplete={color => setPrimaryHoverText(color)}/>
                      }
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col className="col-4">
                      Primary Button Background on Hover
                    </Col>
                    <Col className="col-3" style={{background: primaryHoverBackground?.hex, minHeight: '100px'}}>
                    </Col>
                    <Col className="col-5">
                      {isStaffOrAdmin &&
                      <CompactPicker
                          className="color-picker-input"
                          color={primaryHoverBackground}
                          onChangeComplete={color => setPrimaryHoverBackground(color)}/>
                      }
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col className="col-4">
                      Secondary Button Text
                    </Col>
                    <Col className="col-3" style={{background: secondaryText?.hex, minHeight: '100px'}}>
                    </Col>
                    <Col className="col-5">
                      {isStaffOrAdmin &&
                      <CompactPicker
                          className="color-picker-input"
                          color={secondaryText}
                          onChangeComplete={color => setSecondaryText(color)}/>
                      }
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col className="col-4">
                      Secondary Button Background
                    </Col>
                    <Col className="col-3" style={{background: secondaryBackground?.hex, minHeight: '100px'}}>
                    </Col>
                    <Col className="col-5">
                      {isStaffOrAdmin &&
                      <CompactPicker
                          className="color-picker-input"
                          color={secondaryBackground}
                          onChangeComplete={color => setSecondaryBackground(color)}/>
                      }
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col className="col-4">
                      Secondary Button Text on Hover
                    </Col>
                    <Col className="col-3" style={{background: secondaryHoverText?.hex, minHeight: '100px'}}>
                    </Col>
                    <Col className="col-5">
                      {isStaffOrAdmin &&
                      <CompactPicker
                          className="color-picker-input"
                          color={secondaryHoverText}
                          onChangeComplete={color => setSecondaryHoverText(color)}/>
                      }
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col className="col-4">
                      Secondary Button Background on Hover
                    </Col>
                    <Col className="col-3" style={{background: secondaryHoverBackground?.hex, minHeight: '100px'}}>
                    </Col>
                    <Col className="col-5">
                      {isStaffOrAdmin &&
                      <CompactPicker
                          className="color-picker-input"
                          color={secondaryHoverBackground}
                          onChangeComplete={color => setSecondaryHoverBackground(color)}/>
                      }
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col className="col-4">
                      Disabled Button Background
                    </Col>
                    <Col className="col-3" style={{background: disabledBackground?.hex, minHeight: '100px'}}>
                    </Col>
                    <Col className="col-5">
                      {isStaffOrAdmin &&
                      <CompactPicker
                          className="color-picker-input"
                          color={disabledBackground}
                          onChangeComplete={color => setDisabledBackground(color)}/>
                      }
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col className="col-4">
                      Disabled Button Text
                    </Col>
                    <Col className="col-3" style={{background: disabledText?.hex, minHeight: '100px'}}>
                    </Col>
                    <Col className="col-5">
                      {isStaffOrAdmin &&
                      <CompactPicker
                          className="color-picker-input"
                          color={disabledText}
                          onChangeComplete={color => setDisabledText(color)}/>
                      }
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col className="col-4">
                      Preview
                    </Col>
                    <Col className="col-8 mt-3">
                      <Button className="ms-2" variant="primary"
                              onClick={(e) => {
                                e.preventDefault()
                              }}
                              style={{color: primaryText?.hex, background: primaryBackground?.hex, border: primaryBackground?.hex}}
                              onMouseOver={(e) => {
                                e.target.style.color = primaryHoverText?.hex;
                                e.target.style.background = primaryHoverBackground?.hex;
                              }}
                              onMouseOut={(e) => {
                                e.target.style.color = primaryText?.hex;
                                e.target.style.background = primaryBackground?.hex;
                              }}
                      > Primary Button </Button>
                      <Button className="ms-2"
                              variant="secondary"
                              onClick={(e) => {
                                e.preventDefault()
                              }}
                              style={{color: secondaryText?.hex, background: secondaryBackground?.hex, border: secondaryBackground?.hex}}
                              onMouseOver={(e) => {
                                e.target.style.color = secondaryHoverText?.hex;
                                e.target.style.background = secondaryHoverBackground?.hex;
                              }}
                              onMouseOut={(e) => {
                                e.target.style.color = secondaryText?.hex;
                                e.target.style.background = secondaryBackground?.hex;
                              }}

                      > Secondary Button </Button>
                      <Button className="ms-2 mt-2"
                              variant="primary"
                              disabled
                              onClick={(e) => {
                                e.preventDefault()
                              }}
                              style={{color: disabledText?.hex, background: disabledBackground?.hex, border: disabledBackground?.hex}}
                      > Disabled Button </Button>

                      <span className="ms-2 mt-2" style={{color: linkColor?.hex}}> Link</span>

                    </Col>
                  </Row>

                </Container>
              </Accordion.Body>
            </Accordion.Item>

          </Accordion>

          {fieldHasErrors(errors, '') &&
          <Row>
            <Col className="text-center mb-3 usacm-error-message">
              {getErrorMessageForField(errors, '')}
            </Col>
          </Row>
          }

          <Row>
            <Col className="text-center mt-3 mb-3">
              {isStaffOrAdmin &&
              <Button type="submit" className="ms-3 me-3">Update Conference</Button>
              }
            </Col>
          </Row>

        </Container>
      </Form>
  );

}
