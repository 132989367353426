import React from 'react';
import './BusyOverlay.scss';
import {hideBusy} from "../utils/busyUtils";


export function BusyOverlay() {
  return (
      <div id="usacm-busy-container">
        <div className="busy-background"/>

        <div className="busy-foreground">
          <div className="busy-content">
            <div className="busy-close" onClick={() => hideBusy()}> X </div>
            <div className="busy-spinner spinner-border" role="status"/>
            <div className="busy-text">Data is loading. Please wait...</div>
          </div>
        </div>
      </div>
  );
}
