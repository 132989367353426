import {Button, Col, Container, Row} from "react-bootstrap";
import React, {Fragment, useEffect, useRef, useState} from "react";
import {toolTipRenderer} from "../utils/gridUtils";
import {AgGridReact} from "ag-grid-react";
import {getCoupons} from "../api/RegApi";
import {centsToDollarStrWSign, deepCopy} from "../utils/usacmUtils";
import {RegCouponEdit} from "./RegCouponEdit";
import {hasPermission} from "../api/UserApi";
import {PERMISSION_CONF_ADMIN, PERMISSION_STAFF} from "../constants";
import {RegList} from "./RegList";

const COUPON_TEMPLATE = {
  id: null,
  total_count: null,
  used_count: 0,
  code: null,
  allowed_users: [],
  start_date: null,
  end_date: null,
  invoice_key: '',
  invoice_percent: '',
  invoice_amount: '',
  invoice_text: '',
  invoice_order: 0,
  coupon_id: null,
  active: true,
};

export function RegCoupons({onCouponSelect}) {
  const [couponList, setCouponList] = useState([]);
  const hasFewRows = (couponList || []).length < 12;
  const gridRef = useRef();
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const [usedCouponId, setUsedCouponId] = useState(null);
  const isStaffOrAdmin = hasPermission(PERMISSION_STAFF) || hasPermission(PERMISSION_CONF_ADMIN);
  const actionColWidth = isStaffOrAdmin && !onCouponSelect ? 150 : 100;

  function loadCoupons() {
    getCoupons((code, data, errors) => {
      if (code === 200) {
        setCouponList(data);
      }
    });
  }

  // Check for parameters (to pre-load a particular symposium in view/edit mode)
  useEffect(() => {
    loadCoupons();
  }, []);

  function addCoupon() {
    const newCoupon = deepCopy(COUPON_TEMPLATE);
    setSelectedCoupon(newCoupon);
  }

  function doneEditing() {
    loadCoupons();
    setSelectedCoupon(null);
  }


  // Column setup
  const colDefs = [
    {field: 'id', cellRenderer: toolTipRenderer, headerName: 'ID', maxWidth: 80, minWidth: 80},
    {
      headerName: 'Status', width: 95, minWidth: 95, valueGetter: params => {
        if (!params.data?.active) {
          return 'Disabled';
        }
        if (!params.data.unlimited_count && ((params.data.used_count || 0) >= (params.data.total_count || 0))) {
          return 'Used';
        }
        return 'Active';
      }
    },
    {field: 'used_count', headerName: 'Used', headerTooltip: 'Used Count', flex: 1, minWidth: 70},
    {
      headerName: 'Total', headerTooltip: 'Total Count', flex: 1, minWidth: 100, valueGetter: params => {
        if (params.data.unlimited_count) {
          return 'Unlimited';
        }
        if (!params.data.total_count) {
          return 'None';
        }
        return params.data.total_count;
      }
    },
    {field: 'code', cellRenderer: toolTipRenderer, headerName: 'Code', flex: 2, minWidth: 100},
    {
      headerName: 'Date', flex: 3, minWidth: 100, cellRenderer: params => {
        const start = params.data.start_date || '';
        const end = params.data.end_date || '';
        if (!start && !end) {
          return 'Any';
        }
        const countStr = start + ' to ' + end;
        return <span title={countStr}>{countStr}</span>;
      }
    },
    {
      headerName: 'Users', flex: 3, minWidth: 100, cellRenderer: params => {
        const allowed_users = params.data.allowed_users || [];
        if (!allowed_users || !allowed_users.length) {
          return 'All';
        }
        if (allowed_users.length === 1) {
          const user = allowed_users[0];
          return <span title={user.first_name + ' ' + user.last_name + ', ' + user.email}>{user.email}</span>;
        }
        let userDetails = '';
        for (const user of allowed_users) {
          if (userDetails.length) {
            userDetails += ', ';
          }
          userDetails += user.first_name + ' ' + user.last_name + ', ' + user.email;
        }
        return <span title={userDetails}>{allowed_users.length + ' users'}</span>;
      }
    },
    {
      headerName: 'Invoice', flex: 4, minWidth: 120, cellRenderer: toolTipRenderer, valueGetter: params => {
        const coupon = params.data;
        const key = coupon?.invoice_key;
        const amount = coupon?.invoice_amount;
        const percent = coupon?.invoice_percent;
        const text = coupon?.invoice_text;
        const order = coupon?.invoice_order;
        const amountStr = percent === null ? centsToDollarStrWSign(amount) : percent + '%';
        return `${amountStr} "${text}" (${key}, ${order})`
      }
    },
    {
      headerName: 'Action',
      sortable: false, filter: false,
      maxWidth: actionColWidth, minWidth: actionColWidth,
      cellRenderer: params => {
        return <Fragment>
          {isStaffOrAdmin && !onCouponSelect &&
          <Button type="button" onClick={() => setSelectedCoupon(params.data)} size="sm" className="me-2">Edit</Button>
          }
          {!onCouponSelect &&
          <Button type="button" onClick={() => setUsedCouponId(params.data.id)} size="sm">Usage</Button>
          }
          {onCouponSelect &&
          <Button type="button" onClick={() => onCouponSelect(params.data)} size="sm">Select</Button>
          }
        </Fragment>;
      }
    },
  ];

  const defaultColDefs = {
    sortable: true,
    filter: false,
    resizable: true,
  }

  return (
      <div>
        {!selectedCoupon && !usedCouponId &&
        <Container fluid className="usacm-container-wide">
          <Row>
            <Col>
              <div className="ag-theme-alpine" style={{width: '100%', ...(hasFewRows ? {} : {height: '700px'})}}>
                <AgGridReact
                    ref={gridRef}
                    rowData={couponList}
                    columnDefs={colDefs}
                    defaultColDef={defaultColDefs}
                    gridOptions={{enableCellTextSelection:true, ensureDomOrder:true}} // enable cell selection
                    domLayout={hasFewRows ? "autoHeight" : "normal"}
                    getRowId={(params) => params.data.id} // prevent re-rendering the whole table when data changes
                />
              </div>
            </Col>
          </Row>

          {isStaffOrAdmin && !onCouponSelect &&
          <Row>
            <Col className="usacm-button-row mt-3">
              <Button type="button" onClick={() => addCoupon()}>Add Coupon</Button>
            </Col>
          </Row>
          }
        </Container>
        }

        {selectedCoupon &&
        <RegCouponEdit origCoupon={selectedCoupon} onClose={doneEditing}/>
        }

        {usedCouponId &&
        <RegList couponId={usedCouponId} onClose={() => setUsedCouponId(null)}/>
        }

      </div>
  );

}
