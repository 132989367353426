import React from 'react';
import {Button} from "react-bootstrap";
import {hasPermission} from "../api/UserApi";
import {PERMISSION_CONF_ADMIN, PERMISSION_STAFF} from "../constants";

export function ForceUpdateButton({errors, onClick}) {
  const isStaffOrAdmin = hasPermission(PERMISSION_STAFF) || hasPermission(PERMISSION_CONF_ADMIN);
  if (!isStaffOrAdmin) {
    // Not allowed to force
    return '';
  }
  if (!errors || !errors.length) {
    // Page has no errors yet
    return '';
  }
  // All errors must allow force saving
  if (!errors.every(e => e.can_force)) {
    return '';
  }

  return (
      <Button variant="danger" onClick={() => onClick()}>Force Save</Button>
  );
}
