import React, {useEffect, useState} from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import {Button} from "react-bootstrap";
import './CountrySelect.scss';
import {getAllUsers} from "../api/UserApi";
import {getCurrentConfUsersBrief} from "../api/ConfApi";

// The component display
// Dropdown needs forwardRef access to the DOM node in order to position the Menu
const UserSelectToggle = React.forwardRef(({children, onClick}, ref) => (
  <Button
    className="usacm-button-as-input"
    type="button"
    ref={ref}
    onClick={(e) => {
      onClick(e);
    }}
  >
    &#x25bc; &nbsp; {children}
  </Button>
));


// forwardRef required as Dropdown needs access to the DOM of the Menu to measure it
const UserSelectMenu = React.forwardRef(
  ({children, style, className, 'aria-labelledby': labeledBy, filterValue, setFilterValue}, ref) => {

    return (
      <div
        ref={ref}
        style={style}
        className={(className)}
        aria-labelledby={labeledBy}
      >
        <Form.Control
          autoFocus
          name="filter-input"
          type="text"
          placeholder="Type to filter..."
          onChange={(e) => setFilterValue(e.target.value)}
          value={filterValue}
        />
        <ul className="list-unstyled">
          {React.Children.toArray(children).filter(
            (child) =>
              !filterValue || child.props.children.toLowerCase().includes(filterValue.toLowerCase()),
          )}
        </ul>
      </div>
    );
  },
);

export function UserSelect({userId, onChange, isInvalid, usersInConf = true, noneSelectedText = 'Choose user...'}) {
  const [userData, setUserData] = useState([]);
  const [filterValue, setFilterValue] = useState('');

  useEffect(() => {
    if (usersInConf) {
      getCurrentConfUsersBrief((code, data, errors) => {
        if (code === 200) {
          setUserData(data);
        }
      });
    } else {
      getAllUsers((code, data, errors) => {
        if (code === 200) {
          setUserData(data);
        }
      });
    }
  }, []);

  function getSelectedUser(lookupUserId) {
    return userData?.find(u => ('' + u.id) === ('' + lookupUserId)) || null;
  }

  function userSelected(newUserId) {
    if (onChange) {
      onChange(getSelectedUser(newUserId));
    }
    setFilterValue('');
  }

  const selectedUser = getSelectedUser(userId);
  const selectedUserName = selectedUser ? `${selectedUser.first_name} ${selectedUser.last_name}, ${selectedUser.email}` : noneSelectedText;
  // This will mark the object so we get a red border and the form.control.feedback will show up
  const classes = isInvalid ? "form-control is-invalid" : "";

  return (
    <Dropdown onSelect={newUserId => userSelected(newUserId)} className={classes}>
      <Dropdown.Toggle as={UserSelectToggle} id="country-select-id">
        {selectedUserName}
      </Dropdown.Toggle>
      <Dropdown.Menu as={UserSelectMenu} filterValue={filterValue} setFilterValue={setFilterValue}>
        {userData.map(user => {
          //  Note: Dropdown.Item child must be a single string for the filter code in UserSelectMenu to work
          return <Dropdown.Item key={user.id}
                                eventKey={user.id}>{user.first_name + ' ' + user.last_name + ', ' + user.email}</Dropdown.Item>
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
}
