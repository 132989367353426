import {Accordion, Button, Col, Container, FloatingLabel, Form, Modal, Row} from "react-bootstrap";
import {PageHeader} from "../menu/PageHeader";
import React, {Fragment, useEffect, useState} from "react";
import {deleteSession, deleteTimeslot, getSessions, upsertTimeslot} from "../api/ScheduleApi";
import {SessionUpsert} from "./SessionUpsert";
import {FakeButton} from "../shared/FakeButton";
import {fieldHasErrors, getErrorMessageForField} from "../utils/formUtils";
import './SessionList.scss';
import {hasPermission} from "../api/UserApi";
import {PERMISSION_CONF_ADMIN, PERMISSION_STAFF} from "../constants";

const MODE_LIST_SESSION = 'list-session';
const MODE_EDIT_SESSION = 'edit-session';

const TIMESLOT_MODE_VIEW = 'timeslot-view';
const TIMESLOT_MODE_EDIT = 'timeslot-edit';
const TIMESLOT_MODE_CREATE = 'timeslot-create';
const TIMESLOT_MODE_DELETE = 'timeslot-delete';

export function SessionList() {
  const [sessionList, setSessionList] = useState([]);
  const [sessionId, setSessionId] = useState(null);
  const [timeslotId, setTimeslotId] = useState(null);
  const [timeslotStartTime, setTimeslotStartTime] = useState('');
  const [timeslotEndTime, setTimeslotEndTime] = useState('');
  const [timeslotMode, setTimeslotMode] = useState(TIMESLOT_MODE_VIEW);
  const [mode, setMode] = useState(MODE_LIST_SESSION);
  const [showingDelSessionConfirm, setShowingDelSessionConfirm] = useState(false);
  const [showingDelTimeslotConfirm, setShowingDelTimeslotConfirm] = useState(false);
  const [errors, setErrors] = useState([]);
  const isStaffOrAdmin = hasPermission(PERMISSION_STAFF) || hasPermission(PERMISSION_CONF_ADMIN);

  function loadSessionList() {
    getSessions((code, data, errors) => {
      if (code === 200) {
        setSessionList(data)
      }
    });
  }

  useEffect(() => {
    loadSessionList();
  }, []);

  function returnToList() {
    loadSessionList();
    setSessionId(null);
    setTimeslotId(null);
    setMode(MODE_LIST_SESSION);
  }

  function startUpsertSession(editSessionId) {
    setSessionId(editSessionId);
    setMode(MODE_EDIT_SESSION);
  }

  function callDeleteSession() {
    if (sessionId) {
      deleteSession(sessionId, (code, data, errors) => {
        if (code === 200) {
          loadSessionList();
        }
        setShowingDelSessionConfirm(false);
      });
    }
    setSessionId(null);
  }

  function showDeleteSessionConfirm(deleteSessionId) {
    setSessionId(deleteSessionId);
    setShowingDelSessionConfirm(true);
  }

  function addTimeslot(newSessionId) {
    const session = sessionList.find(s => s.id === newSessionId);
    if (!session || !session.timeslots) {
      console.warn('Cannot add timeslot without a session and timeslots');
    }
    const timeslots = session.timeslots;
    let defaultStartTime = '';
    if (timeslots.length > 0) {
      // Default the new start_time to the last end_time
      defaultStartTime = timeslots[timeslots.length - 1].end_time;
    }
    timeslots.push({
      id: '',
      start_time: defaultStartTime,
      end_time: ''
    });
    setTimeslotStartTime(defaultStartTime);
    setTimeslotEndTime('');
    setSessionId(newSessionId);
    setTimeslotId(null);
    setTimeslotMode(TIMESLOT_MODE_CREATE);
  }

  // Reset the state to view mode for all timeslots
  function cancelEdit() {
    // Remove any blank timeslots (in the process of being added)
    for (const session of sessionList) {
      const timeslots = session.timeslots || [];
      if (timeslots.length) {
        // Go backwards so we don't mess up the index while splicing things out
        for (let i = timeslots.length - 1; i >= 0; i--) {
          const timeslot = timeslots[i];
          if (!timeslot.id) {
            timeslots.splice(i, 1);
          }
        }
      }
    }
    setTimeslotStartTime('');
    setTimeslotEndTime('');
    setSessionId(null);
    setTimeslotId(null);
    setTimeslotMode(TIMESLOT_MODE_VIEW);
    setErrors([]);
  }

  function editTimeslot(newTimeslotId, newSessionId) {
    const session = sessionList?.find(s => s.id === newSessionId);
    const timeslot = session?.timeslots?.find(t => t.id === newTimeslotId);
    if (!timeslot) {
      console.warn('Could not find timeslot with id=', newTimeslotId, ' in session with id=', newSessionId);
      return;
    }
    setTimeslotStartTime(timeslot.start_time);
    setTimeslotEndTime(timeslot.end_time);
    setSessionId(newSessionId);
    setTimeslotId(newTimeslotId);
    setTimeslotMode(TIMESLOT_MODE_EDIT);
  }

  // Save the timeslot that is currently being edited
  function callUpsertTimeslot() {
    if (!sessionId) {
      console.warn('Unable to save without sessionId');
      return;
    }
    if (!timeslotId && timeslotMode === TIMESLOT_MODE_EDIT) {
      console.warn('Cannot update without a timeslotId');
      return;
    }
    setErrors([]);
    upsertTimeslot(timeslotId, sessionId, timeslotStartTime, timeslotEndTime, (code, data, errors) => {
      if (code === 200) {
        loadSessionList();
        cancelEdit();
      } else {
        setErrors(errors);
      }
    });
  }

  function showDeleteTimeslotConfirm(delTimeslotId, delSessionId) {
    setSessionId(delSessionId);
    setTimeslotId(delTimeslotId);
    setShowingDelTimeslotConfirm(true);
    setTimeslotMode(TIMESLOT_MODE_DELETE);
  }

  function hideDeleteTimeslotConfirm() {
    setSessionId(null);
    setTimeslotId(null);
    setShowingDelTimeslotConfirm(false);
    setTimeslotMode(TIMESLOT_MODE_VIEW);
  }

  function callDeleteTimeslot() {
    if (sessionId && timeslotId) {
      deleteTimeslot(timeslotId, (code, data, errors) => {
        if (code === 200) {
          loadSessionList();
        }
        setShowingDelTimeslotConfirm(false);
      });
    }
    cancelEdit();
  }

  function getTimeslotRange() {
    if (!timeslotId || !sessionId) {
      return '';
    }
    const session = sessionList?.find(s => sessionId);
    if (!session) {
      return '';
    }
    const timeslot = session.timeslots?.find(t => t.id === timeslotId);
    if (!timeslot) {
      return '';
    }
    return timeslot.start_time + ' - ' + timeslot.end_time;
  }


  return (
    <div>
      <PageHeader pageTitle="Sessions"/>
      <Container fluid className="usacm-container-medium" style={{display: mode === MODE_LIST_SESSION ? "block" : "none"}}>
        <Row>
          <Col>

            <Accordion defaultActiveKey='' alwaysOpen>
              {sessionList.map(session =>
                <Accordion.Item eventKey={session.id} key={session.id}>
                  <Accordion.Header>
                    <div className="w-100">
                          <span className="me-2">
                          {session.name}
                          </span>
                      <span className="fw-bold">
                            {session.session_number}
                          </span>
                      <span className="ms-3">
                            {session.date}
                          </span>
                      {isStaffOrAdmin &&
                        <span className="float-end me-3">
                            <FakeButton onClick={() => showDeleteSessionConfirm(session.id)} size="sm" className="me-3">Delete</FakeButton>
                            <FakeButton onClick={() => startUpsertSession(session.id)} size="sm">Edit</FakeButton>
                          </span>
                      }
                    </div>
                  </Accordion.Header>
                  <Accordion.Body className="session-accordion-body">
                    <Row className="mb-3 fw-bold">
                      <Col className="col-4">
                        Start Time
                      </Col>
                      <Col className="col-4">
                        End Time
                      </Col>
                      <Col className="col-4">
                      </Col>
                    </Row>
                    {session.timeslots?.map(timeslot => {
                      if ((timeslotMode === TIMESLOT_MODE_EDIT && timeslot.id === timeslotId) ||
                        (timeslotMode === TIMESLOT_MODE_CREATE && !timeslot.id)) {
                        return (
                          <Row key={timeslot.id} className="align-items-top">
                            <Col className="col-4">
                              <Form.Group controlId="start-time">
                                <FloatingLabel controlId="start-time" label="Start Time">
                                  <Form.Control type="text"
                                                placeholder="..."
                                                name="start_time"
                                                value={timeslotStartTime}
                                                onChange={e => setTimeslotStartTime(e.target.value)}
                                                isInvalid={fieldHasErrors(errors, 'start_time')}/>
                                  <Form.Control.Feedback type="invalid">
                                    {getErrorMessageForField(errors, 'start_time')}
                                  </Form.Control.Feedback>
                                </FloatingLabel>
                              </Form.Group>
                            </Col>
                            <Col className="col-4">
                              <Form.Group controlId="end-time">
                                <FloatingLabel controlId="end-time" label="End Time">
                                  <Form.Control type="text"
                                                placeholder="..."
                                                name="end_time"
                                                value={timeslotEndTime}
                                                onChange={e => setTimeslotEndTime(e.target.value)}
                                                isInvalid={fieldHasErrors(errors, 'end_time')}/>
                                  <Form.Control.Feedback type="invalid">
                                    {getErrorMessageForField(errors, 'end_time')}
                                  </Form.Control.Feedback>
                                </FloatingLabel>
                              </Form.Group>
                            </Col>
                            <Col className="col-4 usacm-button-row session-timeslot-buttons">
                              <Button type="button" variant="secondary" onClick={() => cancelEdit()}>Cancel</Button>
                              <Button type="button" onClick={() => callUpsertTimeslot()}>Save</Button>
                            </Col>
                          </Row>);
                      }
                      return (
                        <Row key={timeslot.id} className="align-items-center">
                          <Col className="col-4 ps-3">
                            {timeslot.start_time}
                          </Col>
                          <Col className="col-4 ps-3">
                            {timeslot.end_time}
                          </Col>
                          {isStaffOrAdmin &&
                            <Col className="col-4 usacm-button-row session-timeslot-buttons">
                              {[TIMESLOT_MODE_VIEW, TIMESLOT_MODE_DELETE].includes(timeslotMode) &&
                                <Fragment>
                                  <Button type="button" onClick={() =>
                                    showDeleteTimeslotConfirm(timeslot.id, session.id)} size="sm">Delete</Button>
                                  <Button type="button" onClick={() => editTimeslot(timeslot.id, session.id)} size="sm">Edit</Button>
                                </Fragment>
                              }
                            </Col>
                          }
                        </Row>);
                    })}
                    {[TIMESLOT_MODE_VIEW, TIMESLOT_MODE_DELETE].includes(timeslotMode) &&
                      <Row>
                        <Col className="mt-3 usacm-button-row">
                          {isStaffOrAdmin &&
                            <Button type="button" onClick={() => addTimeslot(session.id)} size="sm">Add Time Slot</Button>
                          }
                        </Col>
                      </Row>
                    }
                  </Accordion.Body>
                </Accordion.Item>
              )}
            </Accordion>

          </Col>
        </Row>

        <Row>
          <Col className="mt-3 usacm-button-row">
            {isStaffOrAdmin &&
              <Button type="button" onClick={() => startUpsertSession(null)}>Add Session</Button>
            }
          </Col>
        </Row>

        <div className='mt-3 center-block'>
          Note: Please use 24 hour time so the timeslots will sort correctly.
        </div>

      </Container>

      <Modal show={showingDelSessionConfirm}
             onHide={() => setShowingDelSessionConfirm(false)}
             size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete the session {sessionList.find(s => s.id === sessionId)?.session_number}?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowingDelSessionConfirm(false)}>Cancel</Button>
          <Button variant="primary" onClick={() => callDeleteSession()}>Delete Session</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showingDelTimeslotConfirm}
             onHide={() => setShowingDelTimeslotConfirm(false)}
             size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete the timeslot {getTimeslotRange()}?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => hideDeleteTimeslotConfirm()}>Cancel</Button>
          <Button variant="primary" onClick={() => callDeleteTimeslot()}>Delete Timeslot</Button>
        </Modal.Footer>
      </Modal>

      {mode === MODE_EDIT_SESSION && <SessionUpsert sessionId={sessionId} onClose={() => returnToList()}/>}

    </div>
  );
}
