import {Col, FloatingLabel, Form, Row} from "react-bootstrap";
import React, {Fragment, useState} from "react";
import {isNumeric, toNumber} from "../../utils/usacmUtils";

export function NumberFieldEditor({config}) {
  const [refresh, setRefresh] = useState(0);
  // Setup default values
  if (typeof config.min_value === "undefined") {
    config.min_value = 0;
  }
  if (typeof config.max_value === "undefined") {
    config.max_value = null;
  }
  if (typeof config.int_only === "undefined") {
    config.int_only = false;
  }

  function updateConfig(minValue, maxValue, intOnly) {
    config.min_value = toNumber(minValue);
    config.max_value = toNumber(maxValue);
    config.int_only = intOnly;
    setRefresh(refresh + 1);
  }

  return (
      <Fragment>

        <Row className="mb-3">
          <Col className="col-12">
            <Form.Check
                type="checkbox"
                label="Integer Only"
                id="int-only"
                checked={config.int_only || false}
                onChange={e => updateConfig(config.min_value, config.max_value, e.target.checked)}
            />
          </Col>
        </Row>

        <Row className="mb-3">
          <Col className="col-12 d-flex">
            <Form.Group controlId="minValue" className="flex-grow-1">
              <FloatingLabel controlId="minValue" label="Min Value">
                <Form.Control type="number"
                              placeholder="Min Value"
                              name="minValue"
                              value={isNumeric(config.min_value) ? config.min_value : ''}
                              onChange={e => updateConfig(e.target.value, config.max_value, config.int_only)}/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group controlId="maxValue" className="flex-grow-1 ms-1">
              <FloatingLabel controlId="maxValue" label="Max Value">
                <Form.Control type="number"
                              placeholder="Max Value"
                              name="maxValue"
                              value={isNumeric(config.max_value) ? config.max_value : ''}
                              onChange={e => updateConfig(config.min_value, e.target.value, config.int_only)}/>
              </FloatingLabel>
            </Form.Group>
          </Col>
        </Row>

      </Fragment>
  );
}

