import {Link} from "react-router-dom";
import React, {useContext} from "react";
import "./PageHeader.scss"
import {UsacmContext} from "../App";
import {getUserData, logout} from "../api/UserApi";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Dropdown from 'react-bootstrap/Dropdown';
import {useNavigate} from "react-router";


export function PageHeader({pageTitle}) {
  const navigate = useNavigate();
  const userData = getUserData();
  const {loggedIn} = useContext(UsacmContext);
  const [userLoggedIn, setUserLoggedIn] = loggedIn;

  function callLogout() {
    logout(navigate, setUserLoggedIn)
  }

  return (
      <div className="page-header">
        <div className="header-logo">
          <h2>{pageTitle}</h2>

          {userLoggedIn &&
          <Dropdown className="user-menu">
            <Dropdown.Toggle variant="dark" id="user-menu">
              Welcome {userData?.first_name}
            </Dropdown.Toggle>
            <Dropdown.Menu variant="dark">
              <Dropdown.Item as={Link} to="/"><FontAwesomeIcon icon="fa-house"/>Home</Dropdown.Item>
              <Dropdown.Item as={Link} to="/profile"><FontAwesomeIcon icon="fa-user"/>Profile</Dropdown.Item>
              <Dropdown.Item as={Link} to="/change-password">
                <FontAwesomeIcon icon="fa-key"/>Change Password
              </Dropdown.Item>
              <Dropdown.Divider/>
              <Dropdown.Item onClick={callLogout}>
                <FontAwesomeIcon icon="fa-right-from-bracket"/>
                Logout
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          }
        </div>
      </div>
  );
}
