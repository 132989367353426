import {Button, Col, FloatingLabel, Form, Modal, Row} from "react-bootstrap";
import {AgGridReact} from "ag-grid-react";
import React, {Fragment, useEffect, useRef, useState} from "react";
import {getTracking} from "../api/AdminApi";
import {dateTimeToStr} from "../utils/usacmUtils";
import {getErrorMessageForAllErrors} from "../utils/formUtils";
import {useLocation} from "react-router-dom";
import {getUser} from "../api/UserApi";


export function TrackingListEmail() {
  const [trackingList, setTrackingList] = useState([]);
  const hasFewRows = (trackingList || []).length < 12;
  const gridRef = useRef();
  const {search} = useLocation();
  const searchParams = new URLSearchParams(search);
  const filterUserId = searchParams.get('userid') || '';
  const [showingEmailDialog, setShowingEmailDialog] = useState(false);
  const [selectedTracking, setSelectedTracking] = useState(null);
  const [showAllConf, setShowAllConf] = useState(false);
  const [dateFilterStart, setDateFilterStart] = useState('');
  const [dateFilterEnd, setDateFilterEnd] = useState('');
  const [toEmail, setToEmail] = useState('');
  const [subjectBodySearch, setSubjectBodySearch] = useState('');
  const [pageNum, setPageNum] = useState('1');
  const [pageSize, setPageSize] = useState('1000');
  const [quickFilter, setQuickFilter] = useState('');
  const [errors, setErrors] = useState([]);

  /**
   * Load the tracking data
   * @param email if provided will not use toEmail, but will use the provided email
   */
  function loadTrackingData(email = null) {
    setErrors([]);
    const emailToUse = email ? email : toEmail;
    getTracking(showAllConf, '', false, dateFilterStart, dateFilterEnd,
      'email', '', pageNum, pageSize, emailToUse, subjectBodySearch, (code, data, errors) => {
        if (code === 200) {
          setTrackingList(data);
        } else {
          setTrackingList([]);
          setErrors(errors);
        }
      });
  }

  useEffect(() => {
    if (filterUserId) {
      getUser(filterUserId, (code, data, errors) => {
        if (code === 200) {
          setToEmail(data.email);
          loadTrackingData(data.email);
        } else {
          loadTrackingData();
        }
      });
    } else {
      loadTrackingData();
    }
  }, [filterUserId]);

  function showEmailDialog(trackingId) {
    const tracking = trackingList.find(t => t.id === trackingId);
    if (!tracking) {
      console.warn('Unable to find tracking with id ' + trackingId);
      return;
    }
    setSelectedTracking(tracking);
    setShowingEmailDialog(true);
  }

  function hideEmailDialog() {
    setSelectedTracking(null);
    setShowingEmailDialog(false);
  }

  // Column setup
  const colDefs = [
    {
      field: 'create_date',
      headerName: 'Date (browser timezone)',
      minWidth: 180,
      flex: 1,
      // This will parse the GMT date string into a JS Date in the browser's timezone
      valueGetter: params => dateTimeToStr(new Date(Date.parse(params.data?.create_date))),
    },
    {
      headerName: 'From',
      minWidth: 180,
      flex: 1,
      valueGetter: params => params.data?.data?.from,
    },
    {
      headerName: 'To',
      minWidth: 180,
      flex: 1,
      valueGetter: params => params.data?.data?.to,
    },
    {
      headerName: 'Subject',
      minWidth: 250,
      flex: 1,
      valueGetter: params => params.data?.data?.subject,
    },
    {
      headerName: 'Body',
      minWidth: 100,
      flex: 1,
      cellRenderer: params => {
        const tracking = params.data;
        return <Fragment>
          <Button type="button" onClick={() => showEmailDialog(tracking.id)} size="sm" className="ms-2">Body</Button>
        </Fragment>
      },
    },
    ...(showAllConf ? [{field: 'conf', headerName: 'Conf'}] : []),
  ];

  const defaultColDefs = {
    sortable: false,
    filter: false,
    resizable: true,
  }

  function quickFilterChanged(newValue) {
    setQuickFilter(newValue);
    if (gridRef?.current?.api) {
      gridRef?.current?.api.setGridOption('quickFilterText', newValue);
    } else {
      console.warn('cannot set quick filter as api is not available');
    }
  }

  return (
    <div>
      <Row className='mb-3'>
        <Col>
          <div className='d-flex flex-wrap row-gap-2'>
            <div className='ms-1 me-1'>
              <Form.Check
                type="checkbox"
                label="Show All Conf"
                id="show_all_conf"
                checked={showAllConf}
                onChange={e => setShowAllConf(e.target.checked)}
                style={{maxWidth: '120px'}}
              />
            </div>

            <div className="d-flex ms-1 me-1">
              <Form.Group controlId="date_filter_start" className="flex-grow-1 me-1">
                <FloatingLabel controlId="date_filter_start" label="Start">
                  <Form.Control type="date"
                                placeholder="Start"
                                name="date_filter_start"
                                value={dateFilterStart}
                                onChange={e => setDateFilterStart(e.target.value)}/>
                </FloatingLabel>
              </Form.Group>
              <Form.Group controlId="date_filter_end" className="flex-grow-1 ms-1">
                <FloatingLabel controlId="date_filter_end" label="End">
                  <Form.Control type="date"
                                placeholder="End"
                                name="date_filter_end"
                                value={dateFilterEnd}
                                onChange={e => setDateFilterEnd(e.target.value)}/>
                </FloatingLabel>
              </Form.Group>
            </div>

            <div className='ms-1 me-1'>
              <Form.Group controlId="to_email">
                <FloatingLabel controlId="to_email" label="To Email">
                  <Form.Control type="text"
                                placeholder=""
                                name="to_email"
                                style={{maxWidth: '250px', minWidth: '250px'}}
                                value={toEmail}
                                onChange={e => setToEmail(e.target.value)}/>
                </FloatingLabel>
              </Form.Group>
            </div>

            <div className='ms-1 me-1'>
              <Form.Group controlId="subject_body_search">
                <FloatingLabel controlId="subject_body_search" label="Subject or Body Contains">
                  <Form.Control type="text"
                                placeholder=""
                                name="subject_body_search"
                                style={{maxWidth: '250px', minWidth: '250px'}}
                                value={subjectBodySearch}
                                onChange={e => setSubjectBodySearch(e.target.value)}/>
                </FloatingLabel>
              </Form.Group>
            </div>

            <div className='ms-1 me-1'>
              <Form.Group controlId="page_num">
                <FloatingLabel controlId="page_num" label="Pg #">
                  <Form.Control type="number"
                                step="1"
                                min="1"
                                placeholder="Pg #"
                                name="page_num"
                                style={{maxWidth: '80px'}}
                                value={pageNum}
                                onChange={e => setPageNum(e.target.value)}/>
                </FloatingLabel>
              </Form.Group>
            </div>

            <div className='ms-1 me-1'>
              <Form.Group controlId="page_size">
                <FloatingLabel controlId="page_size" label="Page Size">
                  <Form.Control type="number"
                                step="1000"
                                min="1"
                                placeholder="Page Size"
                                name="page_size"
                                style={{maxWidth: '100px'}}
                                value={pageSize}
                                onChange={e => setPageSize(e.target.value)}/>
                </FloatingLabel>
              </Form.Group>
            </div>

            <div className='ms-1 me-1'>
              <Button onClick={() => loadTrackingData()}>Apply Filters </Button>
            </div>
          </div>
        </Col>
      </Row>

      {errors && errors.length > 0 &&
        <Row className='mb-3'>
          <Col className="text-center mb-3 usacm-error-message">
            {getErrorMessageForAllErrors(errors)}
          </Col>
        </Row>
      }

      <Row className='mb-1'>
        <Col>
          <div className='flex-grow-1 ms-1'>
            <Form.Control type="text"
                          id="quick-filter"
                          placeholder='Search Results...'
                          name="quick-filter"
                          value={quickFilter}
                          onChange={e => quickFilterChanged(e.target.value)}
                          style={{minWidth: '150px'}}/>
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="ag-theme-alpine" style={{width: '100%', ...(hasFewRows ? {} : {height: '800px'})}}>
            <AgGridReact
              ref={gridRef}
              rowData={trackingList}
              columnDefs={colDefs}
              defaultColDef={defaultColDefs}
              gridOptions={{enableCellTextSelection: true, ensureDomOrder: true}} // enable cell selection
              domLayout={hasFewRows ? "autoHeight" : "normal"}
              getRowId={(params) => params.data.id} // prevent re-rendering the whole table when data changes
            />
          </div>
        </Col>
      </Row>

      <Modal show={showingEmailDialog}
             onHide={() => hideEmailDialog()}
             size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Email Sent</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <b>Date:</b> {selectedTracking?.create_date}
          </div>
          <div className="mt-2">
            <b>To:</b> {selectedTracking?.data?.to}
          </div>
          <div className="mt-2">
            <b>Subject:</b> {selectedTracking?.data?.subject}
          </div>
          <div className="mt-3" dangerouslySetInnerHTML={{__html: selectedTracking?.data?.body}}/>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => hideEmailDialog()}>Close</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );

}
