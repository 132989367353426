import axios from "axios";
import {
  clearForceLogout,
  get,
  handleError,
  LOCAL_STORAGE_KEY_USER_DATA,
  LOCAL_STORAGE_KEY_USER_LOGGED_IN,
  LOCAL_STORAGE_KEY_USER_TOKEN,
  post,
  setCurrentUser,
  setToken,
  unsetCurrentUser
} from "../utils/networkUtils";
import {
  PERMISSION_CALC_ABSTRACT_AUTHOR,
  PERMISSION_CALC_ABSTRACT_PRESENTING_AUTHOR,
  PERMISSION_CALC_ABSTRACT_SUBMITTER,
  PERMISSION_CALC_SYMPOSIUM_MAIN_ORGANIZER,
  PERMISSION_CALC_SYMPOSIUM_ORGANIZER,
  PERMISSION_CALC_SYMPOSIUM_SUBMITTER,
  PERMISSION_CONF_ADMIN,
  PERMISSION_CONF_ORGANIZER,
  PERMISSION_STAFF
} from "../constants";


/**
 * Logs a user into the site. Gets their token and user info and sets it.
 */
export function login(usernameEmail, password, onComplete) {
  const data = {username_email: usernameEmail, password: password};
  const onSuccess = (response, onComplete) => {
    handleResponseToken(response, onComplete);
  };
  const onError = (error, onComplete) => {
    unsetCurrentUser();
    handleError(error, onComplete);
  };
  post("login/", data, onComplete, onSuccess, onError);
}

/**
 * This will handle receiving a new token from the server.
 * This calls getCurrentUser and onComplete is called after the new user is loaded.
 * The calling code needs to handle calling setUserLoggedIn(true) in the onComplete if you want the UI to update (left menu items etc..).
 * You may also need to call setEmailVerified(true)
 */
export function handleResponseToken(response, onComplete) {
  if (response.data?.status === 200) {
    // Successful login responding with a new token
    const token = response.data?.data?.token;
    if (token) {
      setToken(token);
      // Load the newly logged in user
      getCurrentUser(onComplete);
    } else {
      // Should not happen.. if the server sends a 200 it should include a token
      onComplete(400, null, [{message: "Did not receive token from server.", fields: []}]);
    }
  } else {
    // There was a validation error 400 etc..
    onComplete(response.data?.status, null, response.data?.errors);
  }
}


/**
 * This will set the auth headers with axios if the user is currently logged in and the header is missing
 * This is used if the user reloads the page, the localStorage has their login token, but the JS is reloaded
 */
export function setAuthHeaders() {
  if (!axios.defaults.headers.common["Authorization"]) {
    const token = localStorage.getItem(LOCAL_STORAGE_KEY_USER_TOKEN);
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Token " + token;
    }
  }
}

/**
 * Calls the /me endpoint and gets the user's data. This will store the user data in localStorage
 * onComplete : callback function with signature (errCode:number, [{message:"err msg here", fields:["fieldName"...]}...])
 *    This callback will be called after the endpoint responds with a success or failure.
 *    Check the errCode for 200 for success
 */
export function getCurrentUser(onComplete) {
  const onSuccess = (response, onComplete) => {
    setCurrentUser(response.data?.data);
    onComplete(200, null, []);
  };
  const onError = (error, onComplete) => {
    unsetCurrentUser();
    handleError(error, onComplete);
  };
  get("me/", onComplete, onSuccess, onError);
}

export function isUserLoggedIn() {
  const isLoggedIn = localStorage.getItem(LOCAL_STORAGE_KEY_USER_LOGGED_IN);
  return isLoggedIn === "yes";
}

export function getUserEmail() {
  return getUserData()?.email;
}

export function getUserId() {
  return getUserData()?.id;
}

export function isEmailVerified() {
  return !!(getUserData()?.email_verified);
}

export function hasPermission(permission) {
  // Everyone has no permission
  if (!permission) {
    return true;
  }
  const userData = getUserData();
  if (!userData || !userData.permissions) {
    return false;
  }
  return userData.permissions.includes(permission);
}

export function canViewAbstracts() {
  const isStaff = hasPermission(PERMISSION_STAFF);
  const isAdmin = hasPermission(PERMISSION_CONF_ADMIN);
  const isConfOrganizer = hasPermission(PERMISSION_CONF_ORGANIZER);
  const isStaffAdminOrg = isStaff || isAdmin || isConfOrganizer;
  const isAbstractSubmitter = hasPermission(PERMISSION_CALC_ABSTRACT_SUBMITTER)
  const isAbstractAuthor = hasPermission(PERMISSION_CALC_ABSTRACT_AUTHOR)
  const isAbstractPresentingAuthor = hasPermission(PERMISSION_CALC_ABSTRACT_PRESENTING_AUTHOR)
  const isSymposiumOrganizer = hasPermission(PERMISSION_CALC_SYMPOSIUM_ORGANIZER)
  const isSymposiumMainOrganizer = hasPermission(PERMISSION_CALC_SYMPOSIUM_MAIN_ORGANIZER)
  return isAbstractSubmitter || isAbstractAuthor || isAbstractPresentingAuthor || isStaffAdminOrg ||
    isSymposiumOrganizer || isSymposiumMainOrganizer;
}

export function canViewSymposia() {
  const isStaff = hasPermission(PERMISSION_STAFF);
  const isAdmin = hasPermission(PERMISSION_CONF_ADMIN);
  const isConfOrganizer = hasPermission(PERMISSION_CONF_ORGANIZER);
  const isStaffAdminOrg = isStaff || isAdmin || isConfOrganizer;
  const isSymposiumSubmitter = hasPermission(PERMISSION_CALC_SYMPOSIUM_SUBMITTER)
  const isSymposiumOrganizer = hasPermission(PERMISSION_CALC_SYMPOSIUM_ORGANIZER)
  const isSymposiumMainOrganizer = hasPermission(PERMISSION_CALC_SYMPOSIUM_MAIN_ORGANIZER)
  return isSymposiumSubmitter || isSymposiumOrganizer || isSymposiumMainOrganizer || isStaffAdminOrg;
}

/**
 * @returns The object with all the user data, or null if not logged in or the data can't be found
 *  eg. {id, username, email, email_verified, first_name, last_name...}
 */
export function getUserData() {
  const userDataStr = localStorage.getItem(LOCAL_STORAGE_KEY_USER_DATA);
  if (!userDataStr) {
    return null;
  }
  return JSON.parse(userDataStr);
}

/**
 * Logs the user out, then navigates to home
 */
export function logout(navigate, setUserLoggedIn) {
  // This will be called for onSuccess and onError (only one will be called)
  const logOut = () => {
    // we won't log or notify about errors logging out!  The network traffic will show the error anyway.
    unsetCurrentUser();
    setUserLoggedIn(false);
    clearForceLogout(); // if it's set (probably not)
    navigate("/");
  }
  get("logout/", null, logOut, logOut);
}

/**
 * Registers a new user on the site.
 * onComplete : callback function with signature (errCode:number, [{message:"err msg here", fields:["fieldName"...]}...])
 *    This callback will be called after the endpoint responds with a success or failure.
 *    Check the errCode for 200 for success
 */
export function createAccount(username, email, password, passwordConfirm, firstName, lastName, country, affiliation, onComplete) {
  const data = {
    username: username,
    email: email,
    password: password,
    password_confirm: passwordConfirm,
    first_name: firstName || '',
    last_name: lastName || '',
    country: country || '',
    affiliation: affiliation || '',
  };
  post("create-account/", data, onComplete);
}

/**
 * Updates the user profile
 * onComplete : callback function with signature (errCode:number, [{message:"err msg here", fields:["fieldName"...]}...])
 *    This callback will be called after the endpoint responds with a success or failure.
 *    Check the errCode for 200 for success
 */
export function updateProfile(email, firstName, lastName, country, affiliation, onComplete) {
  const userId = getUserId();
  if (!userId) {
    onComplete(400, null, [{message: "You are not logged in, cannot update profile. ", fields: []}]);
    return;
  }
  const data = {
    user_id: userId,
    email: email,
    first_name: firstName || '',
    last_name: lastName || '',
    country: country || '',
    affiliation: affiliation || '',
  };
  const onSuccess = (response, onComplete) => {
    const status = response.data?.status;
    if (status === 200) {
      // On success we need to get fresh user info
      getCurrentUser(onComplete);
    } else {
      onComplete(status, null, response.data?.errors);
    }
  };
  post("user/update/", data, onComplete, onSuccess);
}

/**
 * Change the current user's password
 */
export function changePassword(oldPassword, newPassword, newPasswordConfirm, onComplete) {
  const data = {
    old_password: oldPassword || '',
    new_password: newPassword || '',
    new_password_confirm: newPasswordConfirm || '',
  };
  const onSuccess = (response, onComplete) => {
    const status = response.data?.status;
    if (status === 200) {
      // On success we need to get fresh user info
      getCurrentUser(onComplete);
    } else {
      onComplete(status, null, response.data?.errors);
    }
  };
  post("me/password/", data, onComplete, onSuccess);
}

/**
 * Verify the user's email using a code
 */
export function verifyEmailCode(code, onComplete) {
  const data = {
    code: code || '',
  };
  const onSuccess = (response, onComplete) => {
    const status = response.data?.status;
    if (status === 200) {
      // On success we need to get fresh user info
      getCurrentUser(onComplete);
    } else {
      onComplete(status, null, response.data?.errors);
    }
  };
  post("me/verify/code/", data, onComplete, onSuccess);
}

/**
 * Verify the user's email using a hash for a possibly non-authenticated user
 */
export function verifyEmailHash(hash, onComplete) {
  const data = {
    hash: hash || '',
  };
  const onSuccess = (response, onComplete) => {
    const status = response.data?.status;
    if (status === 200) {
      // If we have a new token generated we will login with it!
      const token = response.data?.data?.token;
      if (token) {
        setToken(token);
      }
      // On success we need to get fresh user info
      getCurrentUser(onComplete);
    } else {
      onComplete(status, null, response.data?.errors);
    }
  };
  post("me/verify/hash/", data, onComplete, onSuccess);
}

/**
 * Request a new code be generated and sent to the logged in user's account
 */
export function resendVerificationCode(onComplete) {
  post("me/verify/resend/", null, onComplete);
}

/**
 * Send a forgot password email
 */
export function sendForgotPassword(email, onComplete) {
  const data = {email: email};
  post("forgotpassword/send/", data, onComplete);
}

/**
 * Resets a password based on a hash and logs the user in.
 */
export function forgotPasswordReset(hash, newPassword, newPasswordConfirm, onComplete) {
  const data = {
    hash: hash,
    new_password: newPassword,
    new_password_confirm: newPasswordConfirm,
  };
  const onSuccess = (response, onComplete) => {
    // This will log the user in with the new credentials
    handleResponseToken(response, onComplete);
  };
  post("forgotpassword/reset/", data, onComplete, onSuccess);
}

/**
 * Gets a single user (requires staff permission)
 */
export function getUser(userId, onComplete) {
  get(`user/${userId}/`, onComplete);
}

/**
 * Impersonate a user (must be staff)
 */
export function impersonateUser(userId, onComplete) {
  const onSuccess = (response, onComplete) => {
    // This will log the user in with the new credentials
    handleResponseToken(response, onComplete);
  };
  post("user/impersonate/", {user_id: userId}, onComplete, onSuccess);
}

/**
 * Gets info for a single user for the conf
 */
export function setEmailVerified(userId, onComplete) {
  const data = {
    'userid': userId,
  };
  post("user/verify/", data, onComplete);
}

/**
 * Sets the password for a user
 */
export function changeUserPassword(userId, newPassword, newPasswordConfirm, onComplete) {
  const data = {
    'userid': userId,
    'new_password': newPassword,
    'new_password_confirm': newPasswordConfirm,
  };
  post("user/password/", data, onComplete);
}

/**
 * Gets all the users this user has permissions to view
 */
export function getAllUsers(onComplete) {
  get("user/all/", onComplete);
}

/**
 * Gets all the users this user has permissions to view
 */
export function deleteUser(userId, onComplete) {
  const data = {
    'userid': userId,
  }
  post("user/delete/", data, onComplete);
}
