import {Button, Col, Container, FloatingLabel, Form, Modal, Row} from "react-bootstrap";
import React, {Fragment, useEffect, useState} from "react";
import {
  FIELD_TYPE_BOOLEAN,
  FIELD_TYPE_CHOICELIST,
  FIELD_TYPE_NUMBER, FIELD_TYPE_SECTION,
  FIELD_TYPE_TEXT,
  RESULT_TYPE_ADD_INVOICE,
  RESULT_TYPE_HIDE_CHOICE,
  RESULT_TYPE_HIDE_FIELD, RESULT_TYPE_HIDE_INVOICE,
  RESULT_TYPE_SET_ANSWER,
  RESULT_TYPE_SET_INVOICE,
  RESULT_TYPE_SET_NOT_REQUIRED,
  RESULT_TYPE_SET_REQUIRED,
  RESULT_TYPE_SHOW_CHOICE,
  RESULT_TYPE_SHOW_FIELD,
  RESULT_TYPES
} from "../constants";
import {anyFieldHasErrors, fieldHasErrors, getErrorMessageForAllFields, getErrorMessageForField} from "../utils/formUtils";
import {TextFieldInput} from "./fields/TextFieldInput";
import {BooleanFieldInput} from "./fields/BooleanFIeldInput";
import {centsToDollarStr, deepCopy, dollarsToCents, isNumeric, toNumber} from "../utils/usacmUtils";
import {NumberFieldInput} from "./fields/NumberFieldInput";
import {ChoicelistFieldInput} from "./fields/ChoicelistFIeldInput";
import {getAnswerDisplayForField} from "./regUtils";

const RESULT_TYPES_USING_FIELD = [
  RESULT_TYPE_SET_ANSWER,
  RESULT_TYPE_HIDE_FIELD,
  RESULT_TYPE_SHOW_FIELD,
  RESULT_TYPE_HIDE_CHOICE,
  RESULT_TYPE_SHOW_CHOICE,
  RESULT_TYPE_SET_REQUIRED,
  RESULT_TYPE_SET_NOT_REQUIRED,
];

const RESULT_TYPES_USING_INVOICE = [
  RESULT_TYPE_SET_INVOICE,
  RESULT_TYPE_ADD_INVOICE,
  RESULT_TYPE_HIDE_INVOICE,
];

const RESULT_TYPES_ONLY_CHOICELIST = [
  RESULT_TYPE_HIDE_CHOICE,
  RESULT_TYPE_SHOW_CHOICE,
];

const RESULT_TYPES_USING_ANSWER = [
  RESULT_TYPE_SET_ANSWER,
  RESULT_TYPE_HIDE_CHOICE,
  RESULT_TYPE_SHOW_CHOICE
];

export function RegActionResultEdit({fields, results, showingResultEditor, setShowingResultEditor, resultEditorIndex, isElse}) {
  const [resultType, setResultType] = useState(RESULT_TYPE_SET_ANSWER);
  const [fieldId, setFieldId] = useState('');
  const [invoiceKey, setInvoiceKey] = useState('');
  const [invoiceAmount, setInvoiceAmount] = useState('');
  const [invoicePercent, setInvoicePercent] = useState('');
  const [invoiceText, setInvoiceText] = useState('');
  const [invoiceOrder, setInvoiceOrder] = useState('');
  const [answerData, setAnswerData] = useState(null);
  const [showingAmount, setShowingAmount] = useState(true);
  const [errors, setErrors] = useState([]);
  // We make a copy here because the fieldInput components modify field.answer and field.errors
  const selectedField = fieldId ? deepCopy(fields?.find(f => '' + f.id === fieldId)) : null;
  if (selectedField) {
    selectedField.answer = answerData;
  }

  function setFieldsToDefault() {
    setResultType(RESULT_TYPE_SET_ANSWER)
    setFieldId('');
    setInvoiceKey('');
    setInvoiceAmount('');
    setInvoicePercent('');
    setInvoiceText('');
    setInvoiceOrder('');
    setAnswerData(null);
    setShowingAmount(true);
  }

  useEffect(() => {
    if (resultEditorIndex < 0) {
      setFieldsToDefault();
    } else {
      const result = results[resultEditorIndex];
      setResultType(result.result_type);
      setFieldId('' + result.field_id);
      if (RESULT_TYPES_USING_ANSWER.includes(result.result_type)) {
        // deepCopy so we don't modify parent result until we're done
        setAnswerData(deepCopy(result.action_data?.answer));
      }
      if (RESULT_TYPES_USING_INVOICE.includes(result.result_type)) {
        setInvoiceKey(result.action_data?.invoice_key);
        setInvoiceAmount(centsToDollarStr(result.action_data?.invoice_amount));
        setInvoicePercent(result.action_data?.invoice_percent || '');
        setInvoiceText(result.action_data?.invoice_text || '');
        setInvoiceOrder(result.action_data?.invoice_order || 0);
        setShowingAmount(!result.action_data?.invoice_percent);
      }
    }
    setErrors([]);
  }, [resultEditorIndex, showingResultEditor]);

  function changeResultType(newResultType) {
    setResultType(newResultType);
    changeFieldId('');
    setAnswerData(null);
    setErrors([]);
  }

  function changeFieldId(newFieldId) {
    setFieldId(newFieldId);
    setAnswerData(null);
    setErrors([]);
  }

  function changeAnswer(newAnswer) {
    setAnswerData(newAnswer);
    setErrors([]);
  }

  function changeToAmount() {
    setInvoicePercent('');
    setShowingAmount(true);
  }

  function changeToPercent() {
    setInvoiceAmount('');
    setShowingAmount(false);
  }

  // Returns [] or validation errors if current state is not valid
  function getValidationErrors(vResultType, vFieldId, vActionData) {
    const newErrors = [];
    if (RESULT_TYPES_USING_FIELD.includes(vResultType) && !vFieldId) {
      newErrors.push({'message': 'You must choose a field.', 'fields': ['field_id']});
    } else if ([RESULT_TYPE_HIDE_CHOICE, RESULT_TYPE_SHOW_CHOICE].includes(vResultType)) {
      const field = fields.find(f => '' + f.id === vFieldId);
      if (!getAnswerDisplayForField(field, vActionData?.answer)) {
        newErrors.push({'message': 'You must choose an option.', 'fields': ['answer']});
      }
    }
    if (RESULT_TYPES_USING_INVOICE.includes(vResultType)) {
      if (!vActionData?.invoice_key) {
        newErrors.push({
          'message': 'This is needed to reference this invoice item and is not visible the user.',
          'fields': ['invoice_key']
        });
      }
      if (vResultType !== RESULT_TYPE_HIDE_INVOICE) {
        // Don't check for !invoice_amount because 0 is valid
        if (showingAmount && !isNumeric(vActionData?.invoice_amount)) {
          newErrors.push({'message': 'You must enter an amount to show on the invoice.', 'fields': ['invoice_amount']});
        }
        if (!showingAmount && !isNumeric(vActionData?.invoice_percent)) {
          newErrors.push({'message': 'You must enter a percentage to show on the invoice.', 'fields': ['invoice_percent']});
        }
        if (!vActionData?.invoice_text) {
          newErrors.push({'message': 'You must enter the text to show on the invoice.', 'fields': ['invoice_text']});
        }
      }
    }
    return newErrors;
  }

  function doneEditing() {
    const actionData = {};
    if (RESULT_TYPES_USING_ANSWER.includes(resultType)) {
      actionData.answer = answerData;
    }
    if (RESULT_TYPES_USING_INVOICE.includes(resultType)) {
      actionData.invoice_key = invoiceKey;
      actionData.invoice_amount = dollarsToCents(invoiceAmount);
      actionData.invoice_percent = toNumber(invoicePercent);
      actionData.invoice_text = invoiceText;
      actionData.invoice_order = toNumber(invoiceOrder) || 0;
    }

    const newErrors = getValidationErrors(resultType, fieldId, actionData);
    setErrors(newErrors); // either [] or contains errors
    if (newErrors.length) {
      return; // if there are errors we won't continue saving the data
    }
    if (resultEditorIndex < 0) {
      const result = {
        id: null,
        result_type: resultType,
        field_id: toNumber(fieldId),
        action_data: actionData,
        else_clause: isElse,
      };
      results.push(result);
    } else {
      const result = results[resultEditorIndex];
      result.result_type = resultType;
      result.field_id = toNumber(fieldId);
      result.action_data = actionData;
      result.else_clause = isElse;
    }
    setFieldsToDefault();
    setShowingResultEditor(false);
  }

  // Filters the fields to match this resultType
  function getValidFields() {
    if (RESULT_TYPES_ONLY_CHOICELIST.includes(resultType)) {
      return fields.filter(f => f.field_type === FIELD_TYPE_CHOICELIST);
    }
    return fields;
  }

  return (
    <Modal show={showingResultEditor}
           onHide={() => setShowingResultEditor(false)}
           size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{resultEditorIndex < 0 ? 'Add' : 'Edit'} Result</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid className="usacm-container-wide">
          <Row>
            <Col>
              <Form.Control
                className="form-select"
                as="select"
                value={resultType || RESULT_TYPE_SET_ANSWER}
                onChange={e => changeResultType(e.target.value)}
              >
                {RESULT_TYPES.map(r => {
                  return <option value={r.key} key={r.key}>{r.display}</option>
                })}
              </Form.Control>
            </Col>
          </Row>

          {RESULT_TYPES_USING_FIELD.includes(resultType) &&
            <Row className="mt-3">
              <Col>
                <Form.Control
                  className="form-select"
                  as="select"
                  value={fieldId}
                  onChange={e => changeFieldId(e.target.value)}
                  isInvalid={fieldHasErrors(errors, 'field_id')}
                >
                  <option value=''>Choose Field...</option>
                  {getValidFields().map(f => {
                    return <option value={f.id} key={f.id}>
                      {f.name} ({f.field_key})
                      {f.field_type === FIELD_TYPE_SECTION && <Fragment> Section</Fragment>}
                    </option>
                  })}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {getErrorMessageForField(errors, 'field_id')}
                </Form.Control.Feedback>
              </Col>
            </Row>
          }

          {RESULT_TYPES_USING_INVOICE.includes(resultType) &&
            <Fragment>
              <Row className="mt-3">
                <Col>
                  <FloatingLabel controlId="invoice_key" label="Invoice Key">
                    <Form.Control type="text"
                                  placeholder="Invoice Key"
                                  name="invoice_key"
                                  value={invoiceKey}
                                  onChange={e => setInvoiceKey(e.target.value)}
                                  isInvalid={fieldHasErrors(errors, 'invoice_key')}/>
                    <Form.Control.Feedback type="invalid">
                      {getErrorMessageForField(errors, 'invoice_key')}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
              </Row>
              {resultType !== RESULT_TYPE_HIDE_INVOICE &&
                <Fragment>

                  {showingAmount &&
                    <Row className="mt-3">
                      <Col className='col-9'>
                        <FloatingLabel controlId="invoice_amount" label="Amount $">
                          <Form.Control type="number"
                                        placeholder="Amount $"
                                        name="invoice_amount"
                                        value={invoiceAmount}
                                        onChange={e => setInvoiceAmount(e.target.value)}
                                        isInvalid={fieldHasErrors(errors, 'invoice_amount')}/>
                          <Form.Control.Feedback type="invalid">
                            {getErrorMessageForField(errors, 'invoice_amount')}
                          </Form.Control.Feedback>
                        </FloatingLabel>
                      </Col>
                      <Col className='col-3 text-nowrap my-auto'>
                        <Button onClick={() => changeToPercent()}> Use Percent </Button>
                      </Col>
                    </Row>
                  }

                  {!showingAmount &&
                    <Row className="mt-3">
                      <Col>
                        <FloatingLabel controlId="invoice_percent" label="Percent %">
                          <Form.Control type="number"
                                        placeholder="Percent %"
                                        name="invoice_percent"
                                        value={invoicePercent}
                                        onChange={e => setInvoicePercent(e.target.value)}
                                        isInvalid={fieldHasErrors(errors, 'invoice_percent')}/>
                          <Form.Control.Feedback type="invalid">
                            {getErrorMessageForField(errors, 'invoice_percent')}
                          </Form.Control.Feedback>
                        </FloatingLabel>
                      </Col>
                      <Col className='col-3 text-nowrap my-auto'>
                        <Button onClick={() => changeToAmount()}> Use $ Amount </Button>
                      </Col>
                    </Row>
                  }

                  <Row className="mt-3">
                    <Col>
                      <Form.Control as="textarea"
                                    rows={5}
                                    placeholder="Invoice Text"
                                    name="invoice_text"
                                    value={invoiceText}
                                    onChange={e => setInvoiceText(e.target.value)}
                                    isInvalid={fieldHasErrors(errors, 'invoice_text')}/>
                      <Form.Control.Feedback type="invalid">
                        {getErrorMessageForField(errors, 'invoice_text')}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>

                  <Row className="mt-3">
                    <Col>
                      <FloatingLabel controlId="invoice_order" label="Invoice Order">
                        <Form.Control type="number"
                                      placeholder="Invoice Order"
                                      name="invoice_order"
                                      value={invoiceOrder}
                                      onChange={e => setInvoiceOrder(e.target.value)}
                                      isInvalid={fieldHasErrors(errors, 'invoice_order')}/>
                        <Form.Control.Feedback type="invalid">
                          {getErrorMessageForField(errors, 'invoice_order')}
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </Col>
                  </Row>
                </Fragment>
              }
            </Fragment>
          }

          {RESULT_TYPES_USING_ANSWER.includes(resultType) && selectedField &&
            <Row className="mt-3">
              <Col>
                {resultType === RESULT_TYPE_SET_ANSWER &&
                  <div className="mb-2">Set Answer to</div>
                }
                {selectedField.field_type === FIELD_TYPE_TEXT &&
                  <TextFieldInput field={selectedField} onAnswerChange={changeAnswer}/>
                }
                {selectedField.field_type === FIELD_TYPE_BOOLEAN &&
                  <BooleanFieldInput field={selectedField} onAnswerChange={changeAnswer}/>
                }
                {selectedField.field_type === FIELD_TYPE_NUMBER &&
                  <NumberFieldInput field={selectedField} onAnswerChange={changeAnswer}/>
                }
                {selectedField.field_type === FIELD_TYPE_CHOICELIST &&
                  <ChoicelistFieldInput field={selectedField} onAnswerChange={changeAnswer} showInvisible={true}/>
                }
              </Col>
            </Row>
          }

          {anyFieldHasErrors(errors, ['answer']) &&
            <Row>
              <Col className="text-center mt-3 usacm-error-message">
                {getErrorMessageForAllFields(errors, ['answer'])}
              </Col>
            </Row>
          }

        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowingResultEditor(false)}>Cancel</Button>
        <Button onClick={() => doneEditing()}> Done </Button>
      </Modal.Footer>
    </Modal>
  );
}

