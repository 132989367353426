import {Button, Col, Container, Row} from "react-bootstrap";
import React, {Fragment, useContext, useEffect, useState} from "react";
import {UsacmContext} from "../App";
import {getAbstract, getAbstractHtml} from "../api/AbstractApi";
import {getConfLabel, titleCase} from "../utils/usacmUtils";
import {CONF_LABEL_KEY_SYMPOSIUM} from "../constants";
import "./AbstractView.scss";
import {downloadFile} from "../utils/networkUtils";
import {getIconForAbstractStatus, getNameForAbstractStatus} from "../utils/displayUtils";

export function AbstractView({abstractId, onClose}) {
  const context = useContext(UsacmContext);
  const [conf,] = context.conference;
  const [abstract, setAbstract] = useState(null);
  const symposiumLabel = titleCase(getConfLabel(conf, CONF_LABEL_KEY_SYMPOSIUM));
  const [abstractHtml, setAbstractHtml] = useState('Loading...');
  const canSearchSchedule = (conf?.deadlines?.before_searchable_schedule_start === false);

  function loadAbstractData() {
    getAbstract(abstractId, (code, data, errors) => {
      if (code === 200) {
        setAbstract(data);
      }
    });
    getAbstractHtml(abstractId, (code, data, errors) => {
      if (code === 200) {
        setAbstractHtml(data.html);
      }
    });
  }

  useEffect(() => {
    loadAbstractData();
  }, [abstractId]);

  return (
    <Container fluid className="usacm-container-wide">
      <Row className="u-card-top u-card-title">
        <Col className="col-12">
          {abstract?.abstract_number_full} {abstract?.title}
          <div className="u-card-title-secondary" style={{paddingBottom: '0'}}>
            {symposiumLabel} {abstract?.symposium_number} - {abstract?.symposium_name}
          </div>
        </Col>
      </Row>

      <Row className="u-card-row u-card-wide-row">
        <Col className="col-2">
          <div className="u-card-subtitle">Number</div>
          <div className="u-card-text">{abstract?.abstract_number_full}</div>
        </Col>
        <Col className="col-2">
          <div className="u-card-subtitle">Status</div>
          <div className="u-card-text">
            {conf && abstract ? getNameForAbstractStatus(conf, abstract) : ""}
            <span className="ms-2">{conf && abstract ? getIconForAbstractStatus(conf, abstract) : ""}</span>
          </div>
        </Col>
        <Col className="col-2 ">
          <div className="u-card-subtitle">Submit Date</div>
          <div className="u-card-text">{abstract?.create_date}</div>
        </Col>
        <Col className="col-2">
          <div className="u-card-subtitle">Submitter</div>
          <div className="u-card-text">{abstract?.create_user_name}</div>
        </Col>
        <Col className="col-2">
          <div className="u-card-subtitle">Session</div>
          <div className="u-card-text">
            {canSearchSchedule &&
              <Fragment>
                {abstract?.session_number && <div>#{abstract?.session_number} {abstract?.session_name}</div>}
                {abstract?.session_date && <div>{abstract?.session_date}</div>}
              </Fragment>
            }
          </div>
        </Col>
        <Col className="col-2">
          <div className="u-card-subtitle">Schedule</div>
          <div className="u-card-text">
            {canSearchSchedule &&
              <Fragment>
                {abstract?.rst_start_time && <div>{abstract?.rst_start_time} - {abstract?.rst_end_time} </div>}
                {abstract?.room_number && <div> Room {abstract?.room_number} {abstract?.room_name}</div>}
              </Fragment>
            }
          </div>
        </Col>
      </Row>

      <Row className="u-card-left u-card-right u-card-bottom">
        <Col className="col-12">
          <div dangerouslySetInnerHTML={{__html: abstractHtml}} style={{maxWidth: '800px'}} className="m-auto"/>
        </Col>
      </Row>

      <Row>
        <Col className="usacm-button-row">
          <Button variant="secondary" onClick={() => onClose()}>Abstract List</Button>
          <Button onClick={() => downloadFile('abstract/' + abstractId + '/pdf/', 'abstract.pdf')}>Download PDF</Button>
        </Col>
      </Row>

    </Container>
  );
}
