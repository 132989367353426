import {
  CONDITION_OPERATORS,
  CONDITION_TYPES,
  FIELD_TYPE_BOOLEAN,
  FIELD_TYPE_CHOICELIST,
  FIELD_TYPE_SECTION,
  PAY_STATUS_NAMES,
  PAY_TYPE_NAMES,
  PERMISSION_CALC_HAS_REG,
  PERMISSION_CONF_ADMIN,
  PERMISSION_STAFF,
  REG_HISTORY_NAMES,
  REG_STATUS_NAMES,
  RESULT_TYPE_ADD_INVOICE,
  RESULT_TYPE_HIDE_CHOICE,
  RESULT_TYPE_HIDE_INVOICE,
  RESULT_TYPE_SET_ANSWER,
  RESULT_TYPE_SET_INVOICE,
  RESULT_TYPE_SHOW_CHOICE,
  RESULT_TYPES
} from "../constants";
import {centsToDollarStrWSign} from "../utils/usacmUtils";
import {hasPermission} from "../api/UserApi";


/**
 * Gets a string to display an answer
 * NOTE: If you're changing this you may also want to change the BE version in reg_utils.py -> get_answer_value
 */
export function getAnswerDisplayForField(field, answer) {
  if (!field || !answer) {
    return '';
  }
  if (field.field_type === FIELD_TYPE_BOOLEAN) {
    return answer.value ? 'Checked' : 'Not Checked';
  }
  if (answer.value) {
    return answer.value;
  }
  // Field may have not been answered
  return '';
}

/**
 * Gets a string to display an answer
 * @param fields all fields in the reg
 * @param result result object with field and action_data
 * @returns {string|string|*}
 */
export function getAnswerDisplayForResult(fields, result) {
  const field = fields?.find(f => f.id === result.field_id);
  const answer = result?.action_data?.answer;
  return getAnswerDisplayForField(field, answer);
}

/**
 * String display of a condition (not HTML)
 */
export function conditionToString(fields, condition) {
  let retVal = ''
  const conditionType = CONDITION_TYPES.find(ct => ct.key === condition.condition_type)?.display;
  if (conditionType) {
    retVal += conditionType + ' ';
  }
  const field = fields.find(f => f.id === condition.field_id);
  if (field) {
    retVal += field.name + ' '
  }
  const operator = CONDITION_OPERATORS.find(co => co.key === condition.operator)?.display;
  if (operator) {
    retVal += operator + ' ';
  }
  if (condition.compare_to) {
    if (field?.field_type === FIELD_TYPE_CHOICELIST) {
      const choice = (field?.config?.options || []).find(o => ('' + o.id) === condition?.compare_to);
      if (choice) {
        retVal += choice.value + ' ';
      }
    } else {
      retVal += condition.compare_to + ' ';
    }
  }
  return retVal;
}

/**
 * String display of a result (not HTML)
 */
export function resultToString(fields, result) {
  let retVal = RESULT_TYPES.find(r => r.key === result.result_type)?.display + ' ';

  if (result.field_id) {
    const field = fields?.find(f => f.id === result.field_id);
    if (field) {
      retVal += field.name + ' ';
      if (field.field_type === FIELD_TYPE_SECTION) {
        retVal += 'Section ';
      }
    }
  }
  if (result.result_type === RESULT_TYPE_SET_ANSWER) {
    retVal += 'to ';
    const answer = getAnswerDisplayForResult(fields, result);
    if (answer) {
      retVal += answer + ' ';
    } else {
      retVal += 'Blank ';
    }
  }
  if ([RESULT_TYPE_HIDE_CHOICE, RESULT_TYPE_SHOW_CHOICE].includes(result.result_type)) {
    const answer = getAnswerDisplayForResult(fields, result);
    if (answer) {
      retVal += '- ' + answer + ' ';
    }
  }
  if ([RESULT_TYPE_SET_INVOICE, RESULT_TYPE_ADD_INVOICE, RESULT_TYPE_HIDE_INVOICE].includes(result.result_type)) {
    retVal += result.action_data?.invoice_key + ' ';
  }
  if ([RESULT_TYPE_SET_INVOICE, RESULT_TYPE_ADD_INVOICE].includes(result.result_type)) {
    if (result.action_data?.invoice_percent) {
      retVal += 'to ' + result.action_data?.invoice_percent + '% ';
    } else {
      retVal += 'to ' + centsToDollarStrWSign(result.action_data?.invoice_amount) + ' ';
    }
    retVal += result.action_data?.invoice_text + ' ';
    retVal += result.action_data.invoice_order + ' ';
  }
  return retVal;
}

/**
 * String display of an action (not HTML)
 */
export function actionToString(fields, action) {
  if (!action || !fields) {
    return '';
  }

  let retVal = 'WHEN ';
  let index = 0;
  for (const condition of action.conditions) {
    if (index > 0) {
      retVal += action.condition_and ? 'AND ' : 'OR ';
    }
    retVal += conditionToString(fields, condition);
    index += 1;
  } // for condition

  retVal += 'THEN ';
  index = 0;
  for (const result of action.results) {
    if (index > 0) {
      retVal += 'AND THEN ';
    }
    retVal += resultToString(fields, result);
    index += 1;
  } // for results

  if (action.else_results && action.else_results.length) {
    retVal += 'ELSE ';
    index = 0;
    for (const elseResult of action.else_results) {
      if (index > 0) {
        retVal += 'AND THEN ';
      }
      retVal += resultToString(fields, elseResult);
      index += 1;
    } // for elseResults
  }

  return retVal;
}

/**
 * String display of a reg status
 */
export function getRegStatusDisplay(reg_status) {
  if (!reg_status) {
    return '';
  }
  return (REG_STATUS_NAMES[reg_status]) || reg_status;
}

/**
 * String display of a pay status
 */
export function getPayStatusDisplay(pay_status) {
  if (!pay_status) {
    return '';
  }
  return (PAY_STATUS_NAMES[pay_status]) || pay_status;
}

/**
 * String display of a pay type
 */
export function getPayTypeDisplay(payType) {
  if (!payType) {
    return '';
  }
  return (PAY_TYPE_NAMES[payType]) || payType;
}

export function getHistoryActionDisplay(historyAction) {
  if (!historyAction) {
    return '';
  }
  return REG_HISTORY_NAMES[historyAction] || historyAction;
}

/**
 * Calculates a total amount from a list of invoiceItems
 * NOTE : You should usually use the backend calculated value stored on pay
 * This logic should match reg_action_utils.py => update_pay_total()
 */
export function calculateTotalFromInvoiceItems(invoiceItems) {
  if (!invoiceItems) {
    return 0;
  }
  let total = 0;
  for (const invoiceItem of invoiceItems.filter(i => i.visible)) {
    if (invoiceItem.invoice_percent) {
      total += Math.round((invoiceItem.invoice_percent * total) / 100);
    } else if (invoiceItem.invoice_amount) {
      total += invoiceItem.invoice_amount;
    }
  } // for
  return total;
}

/**
 * @returns true if the user can create new registrations -
 *     False : if the deadline is past (and the user is not staff/admin)
 *     False : if the user cannot assign to others and a reg already exists and you can't create multiple
 *  NOTE: This will return True even if the user cannot create a registration for themselves, if they are able to create one for others
 *  NOTE: If the user has reg that are canceled, it is considered as not having any reg
 */
export function userCanCreateReg(conf) {
  const isStaffOrAdmin = hasPermission(PERMISSION_STAFF) || hasPermission(PERMISSION_CONF_ADMIN);
  const deadlineOK = !conf?.deadlines?.before_reg_start && !conf?.deadlines?.past_reg_end;
  const hasReg = hasPermission(PERMISSION_CALC_HAS_REG);
  const regNotAllowed = hasReg && !conf?.reg_allow_multiple && !conf?.reg_allow_assign;
  return isStaffOrAdmin || (deadlineOK && !regNotAllowed);
}

