import {Button, Col, Container, Form, Row} from "react-bootstrap";
import {PageHeader} from "../menu/PageHeader";
import {AgGridReact} from "ag-grid-react";
import React, {useContext, useEffect, useRef, useState} from "react";
import {getCurrentConfUsers,} from "../api/ConfApi";
import {UsacmContext} from "../App";
import {getAllUsers, hasPermission, impersonateUser, isEmailVerified} from "../api/UserApi";
import {CONF_LABEL_KEY_CONFERENCE, PERMISSION_CALC_HAS_REG, PERMISSION_CONF_ADMIN, PERMISSION_STAFF} from "../constants";
import {useNavigate} from "react-router";
import {getConfLabel, getPermissionName, showErrorToast, showSuccessToast, titleCase} from "../utils/usacmUtils";
import {downloadFile, getErrorMessages} from "../utils/networkUtils";
import {ConfUserEdit} from "./ConfUserEdit";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {naturalSort, naturalSortValues, toolTipRenderer} from "../utils/gridUtils";


export function ConfUserList() {
  const context = useContext(UsacmContext);
  const [userLoggedIn, setUserLoggedIn] = context.loggedIn;
  const [emailVerified, setEmailVerified] = context.verified;
  const [conf,] = context.conference;
  const [navChanged,] = context.navigationChanged;
  const [userList, setUserList] = useState([]);
  const gridRef = useRef();
  const isStaff = hasPermission(PERMISSION_STAFF);
  const isAdmin = hasPermission(PERMISSION_CONF_ADMIN);
  const isStaffOrAdmin = isStaff || isAdmin;
  const navigate = useNavigate();
  const confLabel = titleCase(getConfLabel(conf, CONF_LABEL_KEY_CONFERENCE));
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [showAllUsers, setShowAllUsers] = useState(false);
  const [quickFilter, setQuickFilter] = useState('');
  const componentMounted = useRef(false);

  // Reload all the user data (after we know something changed)
  function reloadUserList() {
    if (showAllUsers) {
      getAllUsers((code, data, errors) => {
        if (code === 200) {
          setUserList(data);
        }
      });
    } else {
      getCurrentConfUsers((code, data, errors) => {
        if (code === 200) {
          setUserList(data);
        }
      });
    }
  }

  // When we change showAllUsers we need to reload
  useEffect(() => {
    if (componentMounted.current) {
      reloadUserList();
    }
  }, [showAllUsers]);

  useEffect(() => {
    // We wait for the user data to be loaded (from App.js) because otherwise we get a network error with a denied permission
    if (emailVerified && userLoggedIn && !componentMounted.current) {
      componentMounted.current = true;
      reloadUserList();
    }
  }, [emailVerified, userLoggedIn]);

  // When the navUrl changes it means the left menu was clicked - we want to reset the component state
  useEffect(() => {
    setSelectedUserId(null);
  }, [navChanged]);

  // Draws the Action column
  const actionCellRenderer = props => {
    const rowUserId = props.data?.id;
    const permissions = props.data?.permissions || [];
    const userIsStaff = permissions.includes(PERMISSION_STAFF);
    const editUserClicked = () => {
      setSelectedUserId(rowUserId);
    }
    const impersonateUserClicked = () => {
      impersonateUser(rowUserId, (code, data, errors) => {
        if (code === 200) {
          showSuccessToast('Impersonation Successful');
          setUserLoggedIn(true);
          setEmailVerified(isEmailVerified());
          navigate("/"); // new user probably doesn't have permissions to the user list page.
        } else {
          showErrorToast('Impersonation Failed ' + getErrorMessages(errors));
        }
      });
    }
    return (
      <span>
          <Button onClick={() => editUserClicked()} size="sm">{isStaffOrAdmin ? 'Edit' : 'View'}</Button>
        {isStaff && !userIsStaff &&
          <Button onClick={() => impersonateUserClicked()} size="sm" className="ms-2">Impersonate</Button>
        }
        {isStaff &&
          <Button onClick={() => navigate('/tracking?userid=' + rowUserId)} size="sm" className="ms-2">Tracking</Button>
        }
       </span>
    );
  };

  function quickFilterChanged(newValue) {
    setQuickFilter(newValue);
    if (gridRef?.current?.api) {
      gridRef?.current?.api.setGridOption('quickFilterText', newValue);
    } else {
      console.warn('cannot set quick filter as api is not available');
    }
  }

  /**
   * Gets a display string for a list of permissions
   * @permissions : array of permission codes
   */
  function getPermissionString(permissions) {
    let permStr;
    if (permissions.includes(PERMISSION_STAFF)) {
      // Staff have all permissions so we don't need to display everything
      permStr = getPermissionName(PERMISSION_STAFF);
    } else {
      permStr = permissions.map(p => getPermissionName(p)).join(', ');
    }
    return permStr;
  }

  // Column setup
  const colDefs = [
    {field: 'id', headerName: 'ID', width: 80, minWidth: 60},
    {
      field: 'username',
      headerName: 'User Name',
      minWidth: 100,
      flex: 3,
      cellRenderer: params => {
        const username = params.data.username;
        return <span title={username}>{username}</span>;
      },
      comparator: naturalSort,
    },
    {
      field: 'email',
      headerName: 'Email',
      minWidth: 180,
      flex: 4,
      cellRenderer: params => {
        const email = params.data.email;
        if (params.data['email_verified']) {
          return <span title={email}>{email}</span>;
        }
        // Not verified
        return <span title={email + " (not verified)"} style={{color: 'red'}}>{email} (not verified)</span>;
      },
      comparator: naturalSort,
    },
    {
      headerName: 'Name',
      minWidth: 180,
      flex: 4,
      getQuickFilterText: params => {
        return params.data.first_name + ' ' + params.data.last_name;
      },
      cellRenderer: params => {
        return params.data.first_name + ' ' + params.data.last_name;
      },
      comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
        return naturalSortValues(
          nodeA.data.first_name + ' ' + nodeA.data.last_name,
          nodeB.data.first_name + ' ' + nodeB.data.last_name,
          isInverted);
      },
    },
    {field: 'country', headerName: 'Country', headerTooltip: 'Country', width: 90, minWidth: 60},
    {
      field: 'affiliation',
      headerName: 'Affiliation',
      headerTooltip: 'Affiliation',
      cellRenderer: toolTipRenderer,
      minWidth: 100,
      flex: 3,
      comparator: naturalSort,
    },
    {
      headerName: 'Registered', headerTooltip: 'Registered', width: 90, minWidth: 90,
      filter: true,
      valueGetter: params => {
        return params.data.permissions.includes(PERMISSION_CALC_HAS_REG) ? 'Yes' : 'No';
      },
    },
    {
      headerName: 'Permissions',
      headerTooltip: 'Permissions',
      minWidth: 140,
      flex: 3,
      sortable: false,
      getQuickFilterText: params => {
        return getPermissionString(params.data.permissions);
      },
      cellRenderer: params => {
        let permStr = getPermissionString(params.data.permissions);
        return <span title={permStr}>{permStr}</span>;
      },
    },
    {
      headerName: 'Action',
      cellRenderer: actionCellRenderer,
      width: (isStaff ? 270 : 100),
      filter: false,
      sortable: false,
    },
  ];

  const defaultColDefs = {
    sortable: true,
    filter: false,
    resizable: true,
  }

  // Gets the page Title if showAllUsers is the parameter
  function getPageTitleFor(showAll) {
    return showAll ? "All Users" : confLabel + " Users";
  }

  return (
    <div>
      <PageHeader pageTitle={getPageTitleFor(showAllUsers)}/>

      <Container fluid className="usacm-container-wide" style={{display: selectedUserId ? 'none' : 'block'}}>

        <Row className='d-flex mb-3 justify-content-end'>
          <Col className="flex-grow-1">
            <Form.Control type="text"
                          id="quick-filter"
                          placeholder='Search...'
                          name="quick-filter"
                          value={quickFilter}
                          onChange={e => quickFilterChanged(e.target.value)}
                          style={{minWidth: '150px'}}
            />
          </Col>
          <Col className='flex-grow-0'>
            <Button type="button" onClick={() => downloadFile('conf/users/excel/', 'user_list.xlsx')} className="text-nowrap">
              Download All <FontAwesomeIcon icon="fa-file-excel" className="ms-2"/>
            </Button>
          </Col>
        </Row>

        <Row>
          <div className="ag-theme-alpine" style={{width: '100%', height: 800}}>
            <AgGridReact
              ref={gridRef}
              rowData={userList}
              columnDefs={colDefs}
              defaultColDef={defaultColDefs}
              gridOptions={{enableCellTextSelection: true, ensureDomOrder: true}} // enable cell selection
            />
          </div>
        </Row>

        <Row>
          <Col className="usacm-button-row">
            {isStaff &&
              <Button onClick={() => setShowAllUsers(!showAllUsers)}>Show {getPageTitleFor(!showAllUsers)}</Button>
            }
          </Col>
        </Row>
      </Container>

      {selectedUserId &&
        <ConfUserEdit userId={selectedUserId} onClose={() => setSelectedUserId(null)} reloadUserList={reloadUserList}/>
      }
    </div>
  );

}
