import {Col, Form, Row} from "react-bootstrap";
import React, {Fragment, useState} from "react";

export function DateFieldEditor({config}) {
  const [refresh, setRefresh] = useState(0);
  // Setup default values
  if (typeof config.disallow_future === "undefined") {
    config.disallow_future = false;
  }
  if (typeof config.disallow_past === "undefined") {
    config.disallow_past = false;
  }

  function updateConfig(disallowFuture, disallowPast) {
    config.disallow_future = disallowFuture;
    config.disallow_past = disallowPast
    setRefresh(refresh + 1);
  }

  return (
      <Fragment>

        <Row className="mb-3">
          <Col className="col-12">
            <Form.Check
                type="checkbox"
                label="Disallow Future Dates"
                id="disallow_future"
                checked={config.disallow_future || false}
                onChange={e => updateConfig(e.target.checked, config.disallow_past)}
            />
          </Col>
        </Row>

        <Row className="mb-3">
          <Col className="col-12">
            <Form.Check
                type="checkbox"
                label="Disallow Past Dates"
                id="disallow_past"
                checked={config.disallow_past || false}
                onChange={e => updateConfig(config.disallow_future, e.target.checked)}
            />
          </Col>
        </Row>

      </Fragment>
  );
}

