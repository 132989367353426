import {Button, Container} from "react-bootstrap";
import React from "react";
import {clearCache} from "../api/AdminApi";
import {showSuccessToast} from "../utils/usacmUtils";

export function ToolsCache() {

  function callClearCache() {
    clearCache(null, (code, data, errors) => {
      if (code === 200) {
        showSuccessToast("Cached values cleared.");
      }
    });
  }

  return (
    <Container fluid className="usacm-container-medium">
      <div className='mt-3'>
        The site caches user permissions when drawing the front end. This cache is not used when doing permissions checks on the backend.
        The permissions cache may get out of sync with the DB so there is a daily job that resets the cache.
        If you want to reset the cache now you can use this function to clear all cached values.
      </div>

      <div className='mt-3'>
        Some symptoms that may indicate the cache is stale are :
        <ul>
          <li>Invalid left menu item : either missing or showing up in error based on their calculated permissions.</li>
          eg. Left menu shows Abstract List but the user has no abstracts
          <li>The user displays calculated permissions that are not correct. eg. user hasReg but the reg has been canceled.</li>
        </ul>
      </div>

      <div className='mt-3'>
        Note: After resetting the cache, clicking on the user list may take a long time if the conference has a lot of users.
      </div>

      <div className="mt-3 usacm-button-row">
        <Button type="button" onClick={callClearCache}>Clear Cached Values</Button>
      </div>
    </Container>
  );
}
