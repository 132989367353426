import {Button, Col, Container, Row} from "react-bootstrap";
import React, {useContext, useEffect, useState} from "react";
import {UsacmContext} from "../App";
import {getConfLabel, titleCase} from "../utils/usacmUtils";
import "./SymposiumView.scss";
import {getSymposium, getSymposiumHtml} from "../api/SymposiumApi";
import {CONF_LABEL_KEY_EMPHASIS_AREA, CONF_LABEL_KEY_SECONDARY_EMPHASIS_AREA, CONF_LABEL_KEY_SYMPOSIUM} from "../constants";
import {AbstractList} from "../abstract/AbstractList";
import {getIconForSymposiumStatus, getNameForSymposiumStatus} from "../utils/displayUtils";
import {downloadFile} from "../utils/networkUtils";

export function SymposiumView({symposiumId, onClose}) {
  const context = useContext(UsacmContext);
  const [conf,] = context.conference;
  const [symposium, setSymposium] = useState(null);
  const emphasisAreaLabel = titleCase(getConfLabel(conf, CONF_LABEL_KEY_EMPHASIS_AREA));
  const secondaryEmphasisAreaLabel = titleCase(getConfLabel(conf, CONF_LABEL_KEY_SECONDARY_EMPHASIS_AREA));
  const symposiumLabel = titleCase(getConfLabel(conf, CONF_LABEL_KEY_SYMPOSIUM));
  const [symposiumHtml, setSymposiumHtml] = useState('Loading...');

  function loadSymposiumData() {
    getSymposium(symposiumId, (code, data, errors) => {
      if (code === 200) {
        setSymposium(data);
      }
    });
    getSymposiumHtml(symposiumId, (code, data, errors) => {
      if (code === 200) {
        setSymposiumHtml(data.html);
      }
    });

  }

  useEffect(() => {
    loadSymposiumData();
  }, [symposiumId]);

  return (
    <Container fluid className="usacm-container-wide">
      <Row className="u-card-top u-card-title">
        <Col className="col-12">
          {symposium?.symposium_number} {symposium?.title}
        </Col>
      </Row>
      <Row className="u-card-row u-card-wide-row d-flex">
        <Col>
          <div className="u-card-subtitle">Status</div>
          <div className="u-card-text">
            {conf && symposium ? getNameForSymposiumStatus(conf, symposium) : ""}
            <span className="ms-2">{conf && symposium ? getIconForSymposiumStatus(conf, symposium) : ""}</span>
          </div>
        </Col>
        <Col>
          <div className="u-card-subtitle">Submitted</div>
          <div className="u-card-text">{symposium?.create_date}</div>
        </Col>
        {conf?.use_emphasis_area &&
          <Col>
            <div className="u-card-subtitle">{emphasisAreaLabel}</div>
            <div className="u-card-text">{symposium?.emphasis_area}</div>
          </Col>
        }
        {conf?.use_emphasis_area && conf.use_secondary_emphasis_area &&
          <Col>
            <div className="u-card-subtitle">{secondaryEmphasisAreaLabel}</div>
            <div className="u-card-text">{symposium?.secondary_emphasis_area}</div>
          </Col>
        }
        <Col>
          <div className="u-card-subtitle">Submitter</div>
          <div className="u-card-text">{symposium?.create_user_name}</div>
        </Col>
      </Row>

      <Row className="u-card-left u-card-right">
        <Col className="col-12">
          <div dangerouslySetInnerHTML={{__html: symposiumHtml}} style={{maxWidth: '800px'}} className="m-auto"/>
        </Col>
      </Row>

      <Row>
        <Col className="col-12 u-card-title">
          Abstracts
        </Col>
      </Row>
      <Row>
        <Col className="col-12 u-car-left u-card-right u-card-bottom">
          <AbstractList symposiumId={symposiumId} showHeader={false}/>
        </Col>
      </Row>

      <Row>
        <Col className="usacm-button-row">
          <Button variant="secondary" onClick={() => onClose()}>{symposiumLabel} List</Button>
          <Button onClick={() => downloadFile('symposium/' + symposiumId + '/pdf/', 'symposium.pdf')}>Download PDF</Button>
        </Col>
      </Row>

    </Container>
  );
}
