import React from "react";
import {HelpIcon} from "../../shared/HelpIcon";

export function LabelFieldInput({field}) {
  return (
      <div className="d-flex">
        <div className="flex-grow-1">
          <div dangerouslySetInnerHTML={{__html: field?.config?.html || ''}}/>
        </div>

        {field.help_text &&
        <div className='flex-grow-0 align-self-center ms-3'>
          <HelpIcon text={field.help_text}/>
        </div>
        }
      </div>
  );
}

