import {Button, Col, Container, FloatingLabel, Form, Modal, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {fieldHasErrors, getErrorMessageForField} from "../utils/formUtils";
import {
  CONDITION_OPERATOR_COMPLETED,
  CONDITION_OPERATOR_CONTAINS,
  CONDITION_OPERATOR_ENDS_WITH,
  CONDITION_OPERATOR_EQUALS,
  CONDITION_OPERATOR_GREATER_THAN, CONDITION_OPERATOR_GREATER_THAN_OR_EQUALS,
  CONDITION_OPERATOR_LESS_THAN, CONDITION_OPERATOR_LESS_THAN_OR_EQUALS,
  CONDITION_OPERATOR_NOT_COMPLETED,
  CONDITION_OPERATOR_NOT_EQUALS,
  CONDITION_OPERATOR_STARTS_WITH,
  CONDITION_OPERATORS,
  CONDITION_TYPE_ALWAYS,
  CONDITION_TYPE_CREATE,
  CONDITION_TYPE_DATE,
  CONDITION_TYPE_FIELD, CONDITION_TYPE_NEVER,
  CONDITION_TYPES, FIELD_TYPE_ADDRESS,
  FIELD_TYPE_BOOLEAN,
  FIELD_TYPE_CHOICELIST,
  FIELD_TYPE_FILE_UPLOAD,
  FIELD_TYPE_LABEL,
  FIELD_TYPE_NUMBER,
  FIELD_TYPE_SECTION,
  FIELD_TYPE_TEXT
} from "../constants";
import {isNumeric, toNumber} from "../utils/usacmUtils";

const DATE_OPERATORS = [
  CONDITION_OPERATOR_GREATER_THAN,
  CONDITION_OPERATOR_GREATER_THAN_OR_EQUALS,
  CONDITION_OPERATOR_LESS_THAN,
  CONDITION_OPERATOR_LESS_THAN_OR_EQUALS,
  CONDITION_OPERATOR_EQUALS,
  CONDITION_OPERATOR_NOT_EQUALS,
];

const NUM_OPERATORS = [
  CONDITION_OPERATOR_GREATER_THAN,
  CONDITION_OPERATOR_GREATER_THAN_OR_EQUALS,
  CONDITION_OPERATOR_LESS_THAN,
  CONDITION_OPERATOR_LESS_THAN_OR_EQUALS,
  CONDITION_OPERATOR_EQUALS,
  CONDITION_OPERATOR_NOT_EQUALS,
  CONDITION_OPERATOR_COMPLETED,
  CONDITION_OPERATOR_NOT_COMPLETED,
];

const CHOICE_OPERATORS = [
  CONDITION_OPERATOR_EQUALS,
  CONDITION_OPERATOR_NOT_EQUALS,
  CONDITION_OPERATOR_COMPLETED,
  CONDITION_OPERATOR_NOT_COMPLETED,
];

const TEXT_OPERATORS = [
  CONDITION_OPERATOR_EQUALS,
  CONDITION_OPERATOR_NOT_EQUALS,
  CONDITION_OPERATOR_STARTS_WITH,
  CONDITION_OPERATOR_ENDS_WITH,
  CONDITION_OPERATOR_CONTAINS,
  CONDITION_OPERATOR_COMPLETED,
  CONDITION_OPERATOR_NOT_COMPLETED,
];

const COMPLETE_OPERATORS = [
  CONDITION_OPERATOR_COMPLETED,
  CONDITION_OPERATOR_NOT_COMPLETED,
];

const FIELD_TYPE_LOOKUP = {
  [FIELD_TYPE_TEXT]: TEXT_OPERATORS,
  [FIELD_TYPE_CHOICELIST]: CHOICE_OPERATORS,
  [FIELD_TYPE_SECTION]: [],
  [FIELD_TYPE_BOOLEAN]: COMPLETE_OPERATORS,
  [FIELD_TYPE_NUMBER]: NUM_OPERATORS,
  [FIELD_TYPE_FILE_UPLOAD]: [],
  [FIELD_TYPE_LABEL]: [],
  [FIELD_TYPE_ADDRESS]: COMPLETE_OPERATORS,
}

// CompareTo is a required field for these operators
const COMPARE_TO_REQUIRED_FOR = [
  CONDITION_OPERATOR_EQUALS,
  CONDITION_OPERATOR_NOT_EQUALS,
  CONDITION_OPERATOR_CONTAINS,
  CONDITION_OPERATOR_STARTS_WITH,
  CONDITION_OPERATOR_ENDS_WITH,
  CONDITION_OPERATOR_LESS_THAN,
  CONDITION_OPERATOR_GREATER_THAN,
];

// What kind of input to use for the compare-to field
const COMPARE_TO_INPUT_DATE = "compare_date";
const COMPARE_TO_INPUT_TEXT = "compare_text";
const COMPARE_TO_INPUT_NUMBER = "compare_number";
const COMPARE_TO_INPUT_CHOICE = "compare_choice";
const COMPARE_TO_INPUT_NONE = "compare_none";

export function RegActionConditionEdit({fields, conditions, showingConditionEditor, setShowingConditionEditor, conditionEditorIndex}) {
  const [fieldId, setFieldId] = useState(CONDITION_TYPE_CREATE); // this var is the DB field_id OR condition_type if the type has no field
  const [operator, setOperator] = useState(null);
  const [compareTo, setCompareTo] = useState('');
  const [errors, setErrors] = useState([]);

  const fieldChoices = [
    ...CONDITION_TYPES.filter(ct => ct.key !== CONDITION_TYPE_FIELD),
    ...[{key: 'disabled', display: '────────── Fields ──────────'}],
    ...fields.filter(f => f.field_type !== FIELD_TYPE_SECTION).map(f => ({
      key: f.id,
      display: f.name + ' (' + f.field_key + ')',
    }))];
  const compareToInputType = getCompareToInputType();
  const operators = getOperators(fieldId);

  function setFieldsToDefault() {
    setFieldId(CONDITION_TYPE_CREATE);
    setOperator(null);
    setCompareTo('');
  }

  useEffect(() => {
    if (conditionEditorIndex < 0) {
      setFieldsToDefault();
    } else {
      const condition = conditions[conditionEditorIndex];
      if (isNumeric(condition.field_id)) {
        setFieldId('' + condition.field_id);
      } else {
        setFieldId(condition.condition_type);
      }
      setOperator(condition.operator);
      setCompareTo(condition.compare_to);
    }
    setErrors([]);
  }, [conditionEditorIndex, showingConditionEditor]);

  function changeFieldId(newFieldId) {
    setErrors([]);
    setFieldId(newFieldId);
    const operators = getOperators(newFieldId);
    if (operators.length) {
      let opIndex = operators.findIndex(o => o.key === operator);
      if (opIndex < 0) {
        opIndex = 0;
      }
      setOperator(operators[opIndex].key);
    } else {
      setOperator(null);
    }
    setCompareTo('');
    // If we are changing to a choicelist we need to choose a valid value for compare-to (blank is not valid)
    const field = fields.find(f => '' + f.id === newFieldId);
    if (field && (field.field_type === FIELD_TYPE_CHOICELIST) && field.config.options) {
      setCompareTo('' + field.config.options[0].id);
    }
  }

  function changeOperator(newOperator) {
    setOperator(newOperator);
    setErrors([]);
  }

  function getOperators(newFieldId) {
    let newOperators = [];
    if (newFieldId === CONDITION_TYPE_DATE) {
      newOperators = DATE_OPERATORS;
    } else if ([CONDITION_TYPE_CREATE, CONDITION_TYPE_ALWAYS, CONDITION_TYPE_NEVER].includes(newFieldId)) {
      newOperators = [];
    } else {
      const fieldType = fields.find(f => '' + f.id === newFieldId)?.field_type;
      if (fieldType) {
        newOperators = FIELD_TYPE_LOOKUP[fieldType] || [];
      } else {
        console.warn('Could not find matching condition_type or field_id for ' + newFieldId);
      }
    }
    return CONDITION_OPERATORS.filter(o => newOperators.includes(o.key));
  }

  function getCompareToInputType() {
    if (fieldId === CONDITION_TYPE_DATE) {
      return COMPARE_TO_INPUT_DATE;
    }
    if ([CONDITION_OPERATOR_COMPLETED, CONDITION_OPERATOR_NOT_COMPLETED].includes(operator)) {
      return COMPARE_TO_INPUT_NONE;
    }
    const field = fields.find(f => '' + f.id === fieldId);
    if (field?.field_type === FIELD_TYPE_CHOICELIST) {
      return COMPARE_TO_INPUT_CHOICE;
    }
    if (field?.field_type === FIELD_TYPE_TEXT) {
      return COMPARE_TO_INPUT_TEXT;
    }
    if (field?.field_type === FIELD_TYPE_NUMBER) {
      return COMPARE_TO_INPUT_NUMBER;
    }
    // Other things we don't have compare-to for (create, sections, labels, file-inputs...)
    return COMPARE_TO_INPUT_NONE;
  }

  function getFieldChoices() {
    const field = fields.find(f => '' + f.id === fieldId);
    if (!field || (field.field_type !== FIELD_TYPE_CHOICELIST)) {
      return [];
    }
    return field.config.options || [];
  }

  function doneEditing() {
    setErrors([]);
    // Validate compareTo
    if (COMPARE_TO_REQUIRED_FOR.includes(operator) && !compareTo) {
      setErrors([{'message': 'You must enter a value.', 'fields': ['compare_to']}]);
      return;
    }
    const conditionType = isNumeric(fieldId) ? CONDITION_TYPE_FIELD : fieldId;
    const newFieldId = conditionType === CONDITION_TYPE_FIELD ? toNumber(fieldId) : null;
    const newCompareTo = compareToInputType === COMPARE_TO_INPUT_NONE ? null : compareTo;
    if (conditionEditorIndex < 0) {
      const condition = {
        id: null,
        condition_type: conditionType,
        field_id: newFieldId,
        operator: operator,
        compare_to: newCompareTo,
      };
      conditions.push(condition);
    } else {
      const condition = conditions[conditionEditorIndex];
      condition.condition_type = conditionType;
      condition.field_id = newFieldId;
      condition.operator = operator;
      condition.compare_to = newCompareTo;
    }
    setFieldsToDefault();
    setShowingConditionEditor(false);
  }

  return (
    <Modal show={showingConditionEditor}
           onHide={() => setShowingConditionEditor(false)}
           size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{conditionEditorIndex < 0 ? 'Add' : 'Edit'} Condition</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid className="usacm-container-wide">
          <Row className="mb-3">
            <Col>
              <Form.Control
                className="form-select"
                as="select"
                value={fieldId}
                onChange={e => changeFieldId(e.target.value)}
              >
                {fieldChoices.map((ct, index) => {
                  return <option value={ct.key} key={index}
                                 {...(ct.key === 'disabled' ? {disabled: true} : {})} >
                    {ct.display}
                  </option>
                })}
              </Form.Control>
            </Col>
          </Row>

          {operators.length > 0 &&
            <Row className="mb-3">
              <Col>
                <Form.Control
                  className="form-select"
                  as="select"
                  value={operator || ''}
                  onChange={e => changeOperator(e.target.value)}
                >
                  {operators.map(o => {
                    return <option value={o.key} key={o.key}>{o.display}</option>
                  })}
                </Form.Control>
              </Col>
            </Row>
          }

          <Row className="mb-3">
            <Col>
              {compareToInputType !== COMPARE_TO_INPUT_NONE &&
                <div className="me-2">
                  {compareToInputType === COMPARE_TO_INPUT_TEXT &&
                    <FloatingLabel controlId="compare_to" label="Text">
                      <Form.Control type="text"
                                    placeholder="Text"
                                    name="compare_to"
                                    value={compareTo}
                                    onChange={e => setCompareTo(e.target.value)}
                                    isInvalid={fieldHasErrors(errors, 'compare_to')}/>
                      <Form.Control.Feedback type="invalid">
                        {getErrorMessageForField(errors, 'compare_to')}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  }
                  {compareToInputType === COMPARE_TO_INPUT_NUMBER &&
                    <FloatingLabel controlId="compare_to" label="Number">
                      <Form.Control type="number"
                                    placeholder="Number"
                                    name="compare_to"
                                    value={compareTo}
                                    onChange={e => setCompareTo(e.target.value)}
                                    isInvalid={fieldHasErrors(errors, 'compare_to')}/>
                      <Form.Control.Feedback type="invalid">
                        {getErrorMessageForField(errors, 'compare_to')}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  }
                  {compareToInputType === COMPARE_TO_INPUT_DATE &&
                    <FloatingLabel controlId="compare_to_date" label="Date">
                      <Form.Control type="date"
                                    placeholder="Date"
                                    name="compare_to"
                                    value={compareTo}
                                    onChange={e => setCompareTo(e.target.value)}
                                    isInvalid={fieldHasErrors(errors, 'compare_to')}/>
                      <Form.Control.Feedback type="invalid">
                        {getErrorMessageForField(errors, 'compare_to')}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  }
                  {compareToInputType === COMPARE_TO_INPUT_CHOICE &&
                    <Form.Control
                      className="form-select"
                      as="select"
                      value={compareTo}
                      onChange={e => setCompareTo(e.target.value)}
                    >
                      {getFieldChoices().map(o => {
                        return <option value={o.id} key={o.id}>{o.value}</option>
                      })}
                    </Form.Control>
                  }
                </div>
              }
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowingConditionEditor(false)}>Cancel</Button>
        <Button onClick={() => doneEditing()}> Done </Button>
      </Modal.Footer>
    </Modal>
  );
}

