import {Button, Col, Container, FloatingLabel, Form, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {fieldHasErrors, getErrorMessageForField} from "../utils/formUtils";
import {getSession, upsertSession} from "../api/ScheduleApi";
import {showSuccessToast} from "../utils/usacmUtils";

const MODE_EDIT = 'edit';
const MODE_CREATE = 'create';

export function SessionUpsert({sessionId, onClose}) {
  const [sessionNumber, setSessionNumber] = useState('');
  const [name, setName] = useState('');
  const [date, setDate] = useState('');
  const [errors, setErrors] = useState([]);
  const [mode, setMode] = useState(MODE_CREATE);

  useEffect(() => {
    if (sessionId) {
      setMode(MODE_EDIT);
      getSession(sessionId, (code, data, errors) => {
        if (code === 200) {
          setSessionNumber(data.session_number || '');
          setName(data.name || '');
          setDate(data.date || '');
        }
      });
    }
  }, []);

  function callUpsertSession() {
    upsertSession(sessionId, sessionNumber, name, date, (status, data, newErrors) => {
      if (status === 200) {
        setErrors([]);
        showSuccessToast("Session " + (mode === MODE_EDIT ? "updated" : "created"));
        onClose();
      } else {
        setErrors(newErrors);
      }
    });
  }

  return (
      <div>
        <Container fluid className="usacm-container-narrow">

          <Row>
            <Col className="mb-3 fw-bold text-center">
              {mode === MODE_EDIT ? "Update" : "Create"} Session
            </Col>
          </Row>

          <Row>
            <Col className="mb-3">
              <Form.Group controlId="name">
                <FloatingLabel controlId="name" label="Name">
                  <Form.Control type="text"
                                placeholder="Name"
                                name="name"
                                value={name}
                                onChange={e => setName(e.target.value)}
                                isInvalid={fieldHasErrors(errors, 'name')}/>
                  <Form.Control.Feedback type="invalid">
                    {getErrorMessageForField(errors, 'name')}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col className="mb-3">
              <Form.Group controlId="session_number">
                <FloatingLabel controlId="session_number" label="Session Number">
                  <Form.Control type="text"
                                placeholder="Number"
                                name="session_number"
                                value={sessionNumber}
                                onChange={e => setSessionNumber(e.target.value)}
                                isInvalid={fieldHasErrors(errors, 'session_number')}/>
                  <Form.Control.Feedback type="invalid">
                    {getErrorMessageForField(errors, 'session_number')}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col className="mb-3">
              <Form.Group controlId="date">
                <FloatingLabel controlId="date" label="Date">
                  <Form.Control type="date"
                                placeholder="Date"
                                name="date"
                                value={date}
                                onChange={e => setDate(e.target.value)}
                                isInvalid={fieldHasErrors(errors, 'date')}/>
                  <Form.Control.Feedback type="invalid">
                    {getErrorMessageForField(errors, 'date')}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
            </Col>
          </Row>

          {fieldHasErrors(errors, '') &&
              <Row>
                <Col className="text-center mb-3 usacm-error-message">
                  {getErrorMessageForField(errors, '')}
                </Col>
              </Row>
          }

          <Row>
            <Col className="usacm-button-row">
              <Button variant="secondary" onClick={() => onClose()}>Sessions</Button>
              <Button onClick={() => callUpsertSession()}>{mode === MODE_EDIT ? "Update" : "Create"} Session</Button>
            </Col>
          </Row>

        </Container>
      </div>
  );
}
