// Utilities for handling the busy overlay
import {globalVars} from "../App";

export const BUSY_SHOWS_AFTER_MS = 750;

export function showingBusy() {
  const busyContainer = document.getElementById('usacm-busy-container');
  const display = busyContainer.style.display;
  return display === 'block';
}

export function showBusy() {
  if (!showingBusy() && !globalVars.openConnectionsTimeout) {
    globalVars.openConnectionsTimeout = setTimeout(() => {
      const busyContainer = document.getElementById('usacm-busy-container');
      busyContainer.style.display = 'block';
      clearTimeout(globalVars.openConnectionsTimeout);
      globalVars.openConnectionsTimeout = null;
    }, BUSY_SHOWS_AFTER_MS);
  }
}

export function hideBusy() {
  if (globalVars.openConnectionsTimeout) {
    clearTimeout(globalVars.openConnectionsTimeout);
    globalVars.openConnectionsTimeout = null;
  }
  const busyContainer = document.getElementById('usacm-busy-container');
  busyContainer.style.display = 'none';
}

export function incTrafficCount() {
  globalVars.openConnections += 1;
  showBusy();
}

export function decTrafficCount() {
  globalVars.openConnections -= 1;
  if (globalVars.openConnections < 0) {
    globalVars.openConnections = 0;
  }
  if (globalVars.openConnections <= 0) {
    hideBusy();
  }
}

/**
 * This will force the busy icon to show up by incrementing a fake network traffic.
 * NOTE: If you call this you need to be responsible to call forceHideBusy as well or the busy won't go away.
 */
export function forceShowBusy() {
  incTrafficCount();
}

/**
 * This will reset ALL network traffic counters and remove the busy spinner.
 */
export function forceHideBusy() {
  globalVars.openConnections = 0;
  hideBusy();
}

/**
 * Waits until all network traffic has settled and then calls thenDo
 */
export function waitForTraffic(thenDo) {
  if (globalVars.openConnections <= 0) {
    thenDo();
  } else {
    // Using setTimeout here will allow other threads to run
    setTimeout(() => {
      waitForTraffic(thenDo);
    }, 100);
  }
}