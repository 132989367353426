import {Button, Col, Container, Row} from "react-bootstrap";
import {getUserData} from "../api/UserApi";
import {useNavigate} from "react-router";
import {PageHeader} from "../menu/PageHeader";


export function Profile() {
  const userData = getUserData();
  const navigate = useNavigate();

  function gotoEditProfile() {
    navigate("/edit-profile");
  }

  function gotoChangePassword() {
    navigate("/change-password");
  }

  return (
      <div>
        <PageHeader pageTitle= "Profile" />
        <Container fluid className="usacm-container-narrow">
          <Row className="mt-3 mb-3">
            <Col className="col-4 usacm-label">
              Name
            </Col>
            <Col className="col-8">
              {userData.first_name} {userData.last_name}
            </Col>
          </Row>

          <Row className="mt-3 mb-3">
            <Col className="col-4 usacm-label">
              Email
            </Col>
            <Col className="col-8">
              {userData.email}
            </Col>
          </Row>

          <Row className="mt-3 mb-3">
            <Col className="col-4 usacm-label">
              Username
            </Col>
            <Col className="col-8">
              {userData.username}
            </Col>
          </Row>

          <Row className="mt-3 mb-3">
            <Col className="col-4 usacm-label">
              Country
            </Col>
            <Col className="col-8">
              {userData.country}
            </Col>
          </Row>

          <Row className="mt-3 mb-3">
            <Col className="col-4 usacm-label">
              Affiliation
            </Col>
            <Col className="col-8">
              {userData.affiliation}
            </Col>
          </Row>

          <Row>
            <Col className="text-start mt-3 mb-3">
              <Button onClick={gotoChangePassword} className="ms-3 me-3">Change Password</Button>
              <Button onClick={gotoEditProfile} className="ms-3 me-3">Edit Profile</Button>
            </Col>
          </Row>

        </Container>
      </div>
  );

}
