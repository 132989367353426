import {Button, Col, Container, Form, Modal, Row} from "react-bootstrap";
import {PageHeader} from "../menu/PageHeader";
import {AgGridReact} from "ag-grid-react";
import React, {Fragment, useContext, useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router";
import {deleteRoom, getRooms} from "../api/RoomApi";
import {getConfLabel, titleCase} from "../utils/usacmUtils";
import {CONF_LABEL_KEY_LOCATION, PERMISSION_CONF_ADMIN, PERMISSION_STAFF} from "../constants";
import {UsacmContext} from "../App";
import {hasPermission} from "../api/UserApi";
import {naturalSort, toolTipRenderer} from "../utils/gridUtils";

export function RoomList() {
  const context = useContext(UsacmContext);
  const [conf,] = context.conference;
  const navigate = useNavigate();
  const [roomList, setRoomList] = useState([]);
  const hasFewRows = (roomList || []).length < 12;
  const [deleteRoomId, setDeleteRoomId] = useState(null);
  const [showingDeleteConfirm, setShowingDeleteConfirm] = useState(false);
  const locationLabel = titleCase(getConfLabel(conf, CONF_LABEL_KEY_LOCATION));
  const isStaffOrAdmin = hasPermission(PERMISSION_STAFF) || hasPermission(PERMISSION_CONF_ADMIN);
  const [quickFilter, setQuickFilter] = useState('');
  const gridRef = useRef();

  useEffect(() => {
    reloadRoomList();
  }, []);

  // Reload all the user data (after we know something changed)
  function reloadRoomList() {
    getRooms((code, data, errors) => {
      if (code === 200) {
        setRoomList(data);
      }
    });
  }

  function showDeleteConfirm(roomId) {
    setDeleteRoomId(roomId);
    setShowingDeleteConfirm(true);
  }

  function callDeleteRoom() {
    if (deleteRoomId) {
      deleteRoom(deleteRoomId, (code, data, errors) => {
        if (code === 200) {
          reloadRoomList();
        }
        setShowingDeleteConfirm(false);
      });
    }
    setDeleteRoomId(null);
  }

  function quickFilterChanged(newValue) {
    setQuickFilter(newValue);
    if (gridRef?.current?.api) {
      gridRef?.current?.api.setGridOption('quickFilterText', newValue);
    } else {
      console.warn('cannot set quick filter as api is not available');
    }
  }

  // Column setup
  const colDefs = [
    {field: 'room_number', headerName: 'Number', cellRenderer: toolTipRenderer, minWidth: 120, flex: 2, comparator: naturalSort},
    {field: 'name', headerName: 'Name', cellRenderer: toolTipRenderer, minWidth: 100, flex: 6, comparator: naturalSort},
    {field: 'location', headerName: locationLabel, cellRenderer: toolTipRenderer, minWidth: 120, flex: 6, comparator: naturalSort},
    {field: 'capacity', headerName: 'Capacity', cellRenderer: toolTipRenderer, minWidth: 100, flex: 2, comparator: naturalSort},
    ...(isStaffOrAdmin ? [
      {
        headerName: 'Action',
        sortable: false,
        filter: false,
        maxWidth: 160,
        minWidth: 160,
        cellRenderer: params => {
          const room = params.data;
          return <Fragment>
            <Button type="button" onClick={() => navigate('/room-list/upsert?roomid=' + room.id)} size="sm"
                    className="me-2">Edit</Button>
            <Button type="button" onClick={() => showDeleteConfirm(room.id)} size="sm" className="me-2">Delete</Button>
          </Fragment>;
        },
      }] : []),
  ]

  const defaultColDefs = {
    sortable: true,
    filter: false,
    resizable: true,
  }

  return (
    <div>
      <PageHeader pageTitle="Rooms"/>
      <Container fluid className="usacm-container-wide">

        <Row className='d-flex mb-3 justify-content-end'>
          <Col className="flex-grow-1">
            <Form.Control type="text"
                          id="quick-filter"
                          placeholder='Search...'
                          name="quick-filter"
                          value={quickFilter}
                          onChange={e => quickFilterChanged(e.target.value)}
                          style={{minWidth: '150px'}}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="ag-theme-alpine" style={{width: '100%', ...(hasFewRows ? {} : {height: '700px'})}}>
              <AgGridReact
                rowData={roomList}
                columnDefs={colDefs}
                defaultColDef={defaultColDefs}
                gridOptions={{enableCellTextSelection: true, ensureDomOrder: true}} // enable cell selection
                ref={gridRef}
                domLayout={hasFewRows ? "autoHeight" : "normal"}
                getRowId={(params) => params.data.id} // prevent re-rendering the whole table when data changes
              />
            </div>
          </Col>
        </Row>

        {isStaffOrAdmin &&
          <Row>
            <Col className="mt-3 usacm-button-row">
              <Button type="button" onClick={() => navigate('/room-list/upsert?roomid=new')} className="">Add Room</Button>
            </Col>
          </Row>
        }
      </Container>

      <Modal show={showingDeleteConfirm}
             onHide={() => setShowingDeleteConfirm(false)}
             size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete room {roomList.find(r => r.id === deleteRoomId)?.room_number}?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowingDeleteConfirm(false)}>Cancel</Button>
          <Button variant="primary" onClick={() => callDeleteRoom()}>Delete Room</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );

}
