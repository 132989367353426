/**
 * Gets a display of the presenting author
 * @param author  backend author object
 * @returns {string}
 */
export function getAuthorName(author) {
  if (!author) {
    return "";
  }
  let userName = ' (non-user)';
  if (author.username) {
    userName = ' (' + author.username + ')';
  }
  return author.first_name + ' ' + author.last_name + userName;
}

/**
 * Finds the presenting author in the abstract
 * @param abstract  backend object
 * @returns presenting author backend object
 */
export function getPresentingAuthor(abstract) {
  if (!abstract || !abstract.authors) {
    return null;
  }
  return abstract.authors.find(a => a.presenting_author);
}
