import {post} from '../utils/networkUtils';

/**
 * Creates a paymentIntent in Stripe
 * @param regId the reg to create a pay for (using the current active pay and invoice items)
 * @param payId the pay in the reg
 * @param payOffline if true no data is sent to Stripe - it doesn't really create a stripe pay...
 * @param onComplete callback
 */
export function createStripePayment(regId, payId, payOffline, onComplete) {
  const postData = {
    reg_id: regId,
    pay_id: payId,
    pay_offline: payOffline,
  }
  post('stripe/pay/create/', postData, onComplete);
}
