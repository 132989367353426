import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

/**
 * This generates a ? icon with a tooltip
 * @param text The help text to show in the tooltip
 * @param className class names to pass on to parent component
 * @returns {JSX.Element}
 * NOTE : see global_styles.scss under .usacm-tooltip for styling this component
 */
export function HelpIcon({text, className}) {
  const renderTooltip = (props) => (
      <Tooltip className="usacm-tooltip" {...props}  >
        {text}
      </Tooltip>
  );

  return (
      <div className={"d-inline-block "+className}>
        <OverlayTrigger
            placement="top"
            delay={{show: 0, hide: 500}}
            overlay={renderTooltip}
        >
          <FontAwesomeIcon size="2xl" icon="fa-circle-question" color="#006699"/>
        </OverlayTrigger>
      </div>
  );
}
