import {Button, Col, Container, Form, Modal, Row} from "react-bootstrap";
import React, {useContext, useEffect, useState} from "react";
import {
  ABSTRACT_STATUS_APPROVED,
  ABSTRACT_STATUS_DENIED,
  CONF_LABEL_KEY_SYMPOSIUM,
  CONF_LABEL_TYPE_ABSTRACT_STATUS,
  CONF_LABEL_TYPE_FLAG,
  FLAG_ABSTRACT_MOVE
} from "../constants";
import "./AbstractView.scss";
import {getAbstractHtml, setAbstractMove, setAbstractStatus} from "../api/AbstractApi";
import {getIconForAbstractStatus, getLabelValue, getNameForAbstractStatus} from "../utils/displayUtils";
import {UsacmContext} from "../App";
import {handleError} from "../utils/networkUtils";
import {anyFieldHasErrors, fieldHasErrors, getErrorMessageForField} from "../utils/formUtils";
import {getConfLabel, titleCase} from "../utils/usacmUtils";

export function AbstractApprove({abstracts, onClose}) {
  const context = useContext(UsacmContext);
  const [conf,] = context.conference;
  const [abstractIndex, setAbstractIndex] = useState(0);
  const [abstractHtml, setAbstractHtml] = useState('Loading...');
  const abstract = abstracts[abstractIndex];
  const [showMoveModal, setShowMoveModal] = useState(false);
  const [moveComment, setMoveComment] = useState('');
  const [errors, setErrors] = useState([]);
  const symposiumLabel = getConfLabel(conf, CONF_LABEL_KEY_SYMPOSIUM);


  function loadAbstractHtml(abstractIndex) {
    const abstractId = abstracts[abstractIndex]?.id;
    if (abstractId) {
      // clear all previous errors when loading a new abstract
      setErrors([]);
      getAbstractHtml(abstractId, (code, data, errors) => {
        if (code === 200) {
          setAbstractHtml(data.html);
        } else {
          setErrors(errors);
        }
      });
    } else {
      setAbstractHtml('Loading...');
    }
  }

  useEffect(() => {
    loadAbstractHtml(abstractIndex);
  }, [abstracts, abstractIndex]);


  function gotoNextAbstract() {
    // If this is the last abstract, we will return to the symposium list
    if (abstractIndex === abstracts.length - 1) {
      setAbstractIndex(0);
      onClose();
    } else {
      setAbstractIndex(abstractIndex + 1);
    }
  }

  function changeAbstractStatus(status) {
    setAbstractStatus(abstract.id, status, (code, data, errors) => {
      if (code === 200) {
        abstract.status = status;
        gotoNextAbstract();
      } else {
        handleError(errors);
      }
    });
  }

  function showMoveAbstractModal() {
    setMoveComment(abstract?.move_comment || '');
    setShowMoveModal(true);
  }

  function hideMoveAbstractModal() {
    setErrors([]);
    setShowMoveModal(false);
  }

  function requestMove() {
    if (!moveComment) {
      errors.length = 0; // clear prev errors
      errors.push({'message': 'Please enter the reason for requesting to move this abstract.', 'fields': ['move_comment']});
      setErrors([...errors]); // refresh
      return;
    }
    setAbstractMove(abstract.id, true, moveComment, (code, data, errors) => {
      if (code === 200) {
        abstract.move_comment = moveComment;
        abstract.move_requested = true;
        gotoNextAbstract();
      } else {
        handleError(errors);
      }
    });
    setShowMoveModal(false); // this will close before the update is finished (maybe a spinner is needed?)
  }

  // This will remove focus from the button so it looks like a new page
  function blur(element) {
    if (element) {
      element.blur();
    }
  }

  return (
      <Container fluid className="usacm-container-wide">
        <Row className="u-card-top u-card-title">
          <Col className="col-12">
            Abstract Approval ({abstractIndex + 1} of {abstracts.length})
          </Col>
        </Row>

        <Row className="u-card-row">
          <Col className="col-1"> </Col>
          <Col className="col-2">
            <div className="u-card-subtitle">Number</div>
            <div className="text-center">{abstract?.abstract_number_full}</div>
          </Col>
          <Col className="col-2">
            <div className="u-card-subtitle">Status</div>
            <div className="text-center">
              {conf && abstract ? getNameForAbstractStatus(conf, abstract): ""}
              <span className="ms-2">{conf && abstract ? getIconForAbstractStatus(conf, abstract) : ""}</span>
            </div>
          </Col>
          <Col className="col-2">
            <div className="u-card-subtitle">Submit Date</div>
            <div className="text-center">{abstract?.create_date}</div>
          </Col>
          <Col className="col-2">
            <div className="u-card-subtitle">Submitter</div>
            <div className="text-center">{abstract?.create_user_name}</div>
          </Col>
          <Col className="col-2">
            <div className="u-card-subtitle">Session / Room</div>
            <div className="text-center"> &nbsp; </div>
          </Col>
          <Col className="col-1"> </Col>
        </Row>

        <Row className="u-card-left u-card-right u-card-bottom">
          <Col className="col-12">
            <div dangerouslySetInnerHTML={{__html: abstractHtml}} style={{maxWidth: '800px'}} className="m-auto"/>
          </Col>
        </Row>

        <Row>
          <Col className="usacm-button-row">
            <Button variant="secondary" onClick={() => onClose()}>Symposium List</Button>

            {abstractIndex > 0 &&
            <Button onClick={(e) => {
              setAbstractIndex(abstractIndex - 1);
              blur(e.target);
            }}>
              Previous
            </Button>
            }

            <Button onClick={(e) => {
              changeAbstractStatus(ABSTRACT_STATUS_APPROVED);
              blur(e.target);
            }}>
              {getLabelValue(conf, CONF_LABEL_TYPE_ABSTRACT_STATUS, ABSTRACT_STATUS_APPROVED)}
            </Button>

            <Button onClick={(e) => {
              changeAbstractStatus(ABSTRACT_STATUS_DENIED);
              blur(e.target);
            }}>
              {getLabelValue(conf, CONF_LABEL_TYPE_ABSTRACT_STATUS, ABSTRACT_STATUS_DENIED)}
            </Button>

            <Button onClick={(e) => {
              showMoveAbstractModal();
              blur(e.target);
            }}>
              {getLabelValue(conf, CONF_LABEL_TYPE_FLAG, FLAG_ABSTRACT_MOVE)}
            </Button>

            {abstractIndex < abstracts.length - 1 &&
            <Button onClick={(e) => {
              gotoNextAbstract();
              blur(e.target);
            }}>
              Next
            </Button>
            }

          </Col>
        </Row>

        {anyFieldHasErrors(errors, ['']) &&
        <Row>
          <Col className="text-center mb-3 usacm-error-message">
            {getErrorMessageForField(errors, '')}
          </Col>
        </Row>
        }

        <Modal show={showMoveModal}
               onHide={() => hideMoveAbstractModal()}
               size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Move this Abstract to a Different {titleCase(symposiumLabel)}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>Move Comment</div>
            <Form.Group controlId="move_comment">
              <Form.Control as="textarea"
                            rows={8}
                            placeholder="Please enter the reason for requesting to move this abstract..."
                            required
                            name="text"
                            value={moveComment}
                            onChange={e => setMoveComment(e.target.value)}
                            isInvalid={fieldHasErrors(errors, 'move_comment')}/>
              <Form.Control.Feedback type="invalid">
                {getErrorMessageForField(errors, 'move_comment')}
              </Form.Control.Feedback>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => hideMoveAbstractModal()}>Cancel</Button>
            <Button variant="primary" onClick={() => requestMove()}>Request Move</Button>
          </Modal.Footer>
        </Modal>

      </Container>
  );
}
