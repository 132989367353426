import {canViewAbstracts, canViewSymposia, getUserData} from "../api/UserApi";
import {PageHeader} from "../menu/PageHeader";
import {Button, Col, Container, Row} from "react-bootstrap";
import React, {Fragment, useContext, useEffect, useState} from "react";
import {UsacmContext} from "../App";
import {CONF_LABEL_KEY_SYMPOSIUM, REG_STATUS_PAID, REG_STATUS_PENDING} from "../constants";
import {getConfLabel, makePlural, titleCase} from "../utils/usacmUtils";
import {AbstractList} from "../abstract/AbstractList";
import {SymposiumList} from "../symposium/SymposiumList";
import {useNavigate} from "react-router";
import {getRegsMe} from "../api/RegApi";
import {downloadFile} from "../utils/networkUtils";

export function Dashboard() {
  const navigate = useNavigate();
  const userData = getUserData();
  const context = useContext(UsacmContext);
  const [conf,] = context.conference;
  const symposiumLabel = titleCase(makePlural(getConfLabel(conf, CONF_LABEL_KEY_SYMPOSIUM)));
  const isAbstractViewer = canViewAbstracts();
  const isSymposiumViewer = canViewSymposia();
  const useSymposia = conf?.use_symposia === null ? true : conf?.use_symposia; // default true
  const useAbstracts = conf?.use_abstracts === null ? true : conf?.use_abstracts; // default true
  const useReg = !!conf?.use_registration;  // default false
  const [regs, setRegs] = useState([]);
  const [regsLoaded, setRegsLoaded] = useState(false);
  const afterRegStart = !conf?.deadlines?.before_reg_start
  const regUseAttendance = !!conf?.reg_use_attendance;

  function loadRegs() {
    if (useReg) {
      getRegsMe((code, data, errors) => {
        if (code === 200) {
          setRegs(data);
          setRegsLoaded(true);
        }
      });
    }
  }

  useEffect(() => {
    loadRegs();
  }, [conf]);

  return (
    <div>
      <PageHeader pageTitle="Dashboard"/>
      <Container fluid className="usacm-container-wide">
        <Row className="u-card-top u-card-title">
          <Col className="col-12">
            Profile
          </Col>
        </Row>
        <Row className="u-card-row u-card-wide-row">
          <Col className="col-3">
            <div className="u-card-subtitle">Name</div>
            <div className="u-card-text">{userData?.first_name + ' ' + userData?.last_name}</div>
          </Col>
          <Col className="col-3">
            <div className="u-card-subtitle">Email</div>
            <div className="u-card-text">{userData?.email}</div>
          </Col>
          <Col className="col-2">
            <div className="u-card-subtitle">Username</div>
            <div className="u-card-text">{userData?.username}</div>
          </Col>
          <Col className="col-2">
            <div className="u-card-subtitle">Country</div>
            <div className="u-card-text">{userData?.country}</div>
          </Col>
          <Col className="col-2">
            <div className="u-card-subtitle">Affiliation</div>
            <div className="u-card-text">{userData?.affiliation}</div>
          </Col>
        </Row>

        {useReg && (afterRegStart || (regs.length > 0)) &&
          <Fragment>
            <Row className="u-card-title u-card-row">
              <Col className="col-12">
                Registration
              </Col>
            </Row>
            <Row className="u-card-left u-card-right">
              <Col className="col-12">
                {!regsLoaded &&
                  <div className="mt-3 mb-3">
                    Loading registration data...
                  </div>
                }
                {regsLoaded && (regs.length === 0) &&
                  <div className="mt-3 mb-3">
                    You have not yet registered.
                    <Button className='ms-3' onClick={() => navigate('/reg-list')}>Register Now</Button>
                  </div>
                }
                {(regs.length === 1) &&
                  <div className="mt-3 mb-3">
                    {regs[0].reg_status === REG_STATUS_PENDING &&
                      <span>
                        You have registered but your registration status is pending. Your registration ID is {regs[0].id}.
                      </span>
                    }
                    {regs[0].reg_status === REG_STATUS_PAID &&
                      <span>
                        You have registered and payment has been received.
                      </span>
                    }
                    {regUseAttendance && regs[0].attended &&
                      <Button className='ms-3' onClick={() =>
                        downloadFile('reg/' + regs[0].id + '/cert-of-attend/pdf', 'certificate_of_attendance.pdf')}>
                        Certificate of Attendance
                      </Button>
                    }
                    <Button className='ms-3' onClick={() => navigate('/reg-list')}>Registration Details</Button>
                  </div>
                }
                {(regs.length > 1) &&
                  <div className="mt-3 mb-3">
                    You have multiple registrations.
                    <Button className='ms-3' onClick={() => navigate('/reg-list')}>View Registrations</Button>
                  </div>
                }
              </Col>
            </Row>
          </Fragment>
        }

        {isAbstractViewer && useAbstracts &&
          <Fragment>
            <Row className="u-card-title u-card-row">
              <Col className="col-12">
                My Abstracts
              </Col>
            </Row>
            <Row className="u-card-left u-card-right">
              <Col className="col-12">
                <AbstractList userId={userData?.id} showHeader={false}/>
              </Col>
            </Row>
          </Fragment>
        }

        {isSymposiumViewer && useSymposia &&
          <Fragment>
            <Row className="u-card-title u-card-row">
              <Col className="col-12">
                My {symposiumLabel}
              </Col>
            </Row>
            <Row className="u-card-left u-card-right">
              <Col className="col-12">
                <SymposiumList userId={userData?.id} showHeader={false}/>
              </Col>
            </Row>
          </Fragment>
        }

        <Row className='u-card-bottom'> </Row>

      </Container>
    </div>
  );
}
