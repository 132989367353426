import React from 'react';

/**
 * This generates a fake button - using regular div/span HTML tags and no form components
 * This is useful if you need to nest a button within a button which is not valid HTML
 * or if you need to make sure you don't mess up some form component, but you need something that looks
 * like a button and clicks like a button.
 * @param onClick called when clicked (event is NOT propagated)
 * @param children contents of the button
 * @param variant bootstrap "primary", "secondary"
 * @param size bootstrap "sm" "lg" or leave blank for normal
 * @param className additional classes set on the element
 * @returns {JSX.Element}
 */
export function FakeButton({onClick, children, variant, size, className}) {
  // This will prevent the click from propagating up (when FakeButton is inside a button or other clickable)
  function clicked(e) {
    e.stopPropagation();
    if (onClick) {
      onClick();
    }
  }

  return (
      <div onClick={clicked} style={{display: 'inline-block'}} className={
        "fake-button btn " +
        " btn-" + (variant || "primary") +
        (size ? (" btn-" + size) : "") +
        ' '+className
      }>
        {children}
      </div>
  );
}
