import React, {useState} from "react";
import {Button, FloatingLabel, Form} from "react-bootstrap";
import {fieldHasErrors, getErrorMessageForField} from "../utils/formUtils";
import {applyCouponCode} from "../api/RegApi";

export function RegCouponCodeEntry({pay, setPay}) {
  const [code, setCode] = useState('');
  const [errors, setErrors] = useState([]);

  function applyCode() {
    setErrors([]);
    applyCouponCode(pay?.id, code.trim(), (code, data, errors) => {
      if (code === 200) {
        setPay(data.pay);
      } else {
        setErrors(errors);
      }
    });
  }

  return (
      <div className="d-flex mb-3">
        <div className='flex-grow-1'>
          <Form.Group controlId="code">
            <FloatingLabel controlId="code" label="Coupon Code">
              <Form.Control type="text"
                            placeholder="Code"
                            name="code"
                            value={code}
                            onChange={e => setCode(e.target.value?.toUpperCase())}
                            isInvalid={fieldHasErrors(errors, 'code')}/>
              <Form.Control.Feedback type="invalid">
                {getErrorMessageForField(errors, 'code')}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </div>
        <div className='ms-3 my-auto'>
          <Button onClick={() => applyCode()}> Apply Code </Button>
        </div>
      </div>
  );

}
