import {Button, Col, Container, Row} from "react-bootstrap";
import React, {Fragment, useEffect, useState} from "react";
import {deepCopy, showSuccessToast} from "../utils/usacmUtils";
import {anyFieldHasErrors, getErrorMessageForAllFields} from "../utils/formUtils";
import {upsertAction} from "../api/RegApi";
import {RegActionConditionEdit} from "./RegActionConditionEdit";
import {RegActionResultEdit} from "./RegActionResultEdit";
import {conditionToString, resultToString} from "./regUtils";


export function RegActionEdit({origAction, fields, onClose}) {
  const [conditionAnd, setConditionAnd] = useState(true);
  const [actionOrder, setActionOrder] = useState(0);
  const [customAction, setCustomAction] = useState(true);
  const [conditions, setConditions] = useState([]);
  const [results, setResults] = useState([]);
  const [elseResults, setElseResults] = useState([]);
  const [showingConditionEditor, setShowingConditionEditor] = useState(false);
  const [conditionEditorIndex, setConditionEditorIndex] = useState(-1);
  const [showingResultEditor, setShowingResultEditor] = useState(false);
  const [resultEditorIndex, setResultEditorIndex] = useState(-1);
  const [showingElseResultEditor, setShowingElseResultEditor] = useState(false);
  const [elseResultEditorIndex, setElseResultEditorIndex] = useState(-1);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (origAction) {
      setConditionAnd(origAction.condition_and || false);
      setActionOrder(origAction.action_order || 0);
      setCustomAction(origAction.custom || true);
      setConditions(deepCopy(origAction.conditions) || []);
      setResults(deepCopy(origAction.results) || []);
      setElseResults(deepCopy(origAction.else_results) || []);
    }
  }, [origAction]);

  function callUpsertAction(force = false) {
    const newErrors = [];
    if (!conditions || !conditions.length) {
      newErrors.push({'message': 'You must have at least one condition (When clause).', 'fields': ['']});
    }
    if (!results || !results.length) {
      newErrors.push({'message': 'You must have at least one result (Then clause).', 'fields': ['']});
    }
    if (newErrors.length) {
      setErrors(newErrors);
      return;
    }
    upsertAction(origAction?.id, conditionAnd, customAction, actionOrder, conditions, results, elseResults, (code, data, errors) => {
      if (code === 200) {
        showSuccessToast('Saved action.');
        onClose();
      } else {
        setErrors(errors);
      }
    });
  }

  function showConditionEditor(index) {
    setShowingConditionEditor(true);
    setConditionEditorIndex(index);
    setErrors([]);
  }

  function deleteCondition(index) {
    conditions.splice(index, 1);
    setConditions([...conditions]); // refresh
  }

  function showResultEditor(index) {
    setShowingResultEditor(true);
    setResultEditorIndex(index);
    setErrors([]);
  }

  function deleteResult(index) {
    results.splice(index, 1);
    setResults([...results]); // refresh
  }

  function showElseResultEditor(index) {
    setShowingElseResultEditor(true);
    setElseResultEditorIndex(index);
    setErrors([]);
  }

  function deleteElseResult(index) {
    elseResults.splice(index, 1);
    setElseResults([...elseResults]); // refresh
  }

  return (
    <Container fluid className="usacm-container-medium">

      <Row className="mb-3">
        <Col className="fw-bold">
          When
          {conditions.length > 1 &&
            <Button className="ms-3" size="sm" onClick={() => setConditionAnd(!conditionAnd)}>
              Change to {conditionAnd ? "OR" : "AND"}
            </Button>
          }
        </Col>
      </Row>

      {conditions.map((cond, index) =>
        <Fragment key={index}>
          {index > 0 &&
            <Row className="mb-3" key={'conditionAnd_' + index}>
              <Col style={{marginLeft: '130px'}}>
                {conditionAnd ? 'AND' : 'OR'}
              </Col>
            </Row>
          }
          <Row className="mb-3" key={index}>
            <Col className="d-flex align-items-center">
              <Button size="sm" className="me-2" onClick={() => deleteCondition(index)}> Delete </Button>
              <Button size="sm" className="me-3" onClick={() => showConditionEditor(index)}> Edit </Button>
              {conditionToString(fields, cond)}
            </Col>
          </Row>
        </Fragment>
      )}

      <Row className="mb-3">
        <Col className="fw-bold">
          <Button size="sm" onClick={() => showConditionEditor(-1)}> Add Condition </Button>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col className="fw-bold">
          <hr/>
          Then
        </Col>
      </Row>

      {results.map((result, index) =>
        <Row className="mb-3" key={index}>
          <Col className="d-flex align-items-start">
            <Button size="sm" className="me-2" onClick={() => deleteResult(index)}> Delete </Button>
            <Button size="sm" className="me-3" onClick={() => showResultEditor(index)}> Edit </Button>
            {resultToString(fields, result)}
          </Col>
        </Row>
      )}

      <Row className="mb-3">
        <Col className="fw-bold">
          <Button size="sm" onClick={() => showResultEditor(-1)}> Add Result (then) </Button>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col className="fw-bold">
          <hr/>
          Else
        </Col>
      </Row>

      {elseResults.map((elseResult, index) =>
        <Row className="mb-3" key={index}>
          <Col className="d-flex align-items-start">
            <Button size="sm" className="me-2" onClick={() => deleteElseResult(index)}> Delete </Button>
            <Button size="sm" className="me-3" onClick={() => showElseResultEditor(index)}> Edit </Button>
            {resultToString(fields, elseResult)}
          </Col>
        </Row>
      )}

      <Row className="mb-3">
        <Col className="fw-bold">
          <Button size="sm" onClick={() => showElseResultEditor(-1)}> Add Result (else) </Button>
        </Col>
      </Row>

      {anyFieldHasErrors(errors, ['']) &&
        <Row>
          <Col className="text-center mb-3 usacm-error-message">
            {getErrorMessageForAllFields(errors, [''])}
          </Col>
        </Row>
      }

      <Row>
        <Col className="usacm-button-row">
          <Button variant="secondary" onClick={() => onClose()}>Cancel</Button>
          <Button onClick={() => callUpsertAction()}> Save </Button>
        </Col>
      </Row>

      <RegActionConditionEdit fields={fields}
                              conditions={conditions}
                              showingConditionEditor={showingConditionEditor}
                              setShowingConditionEditor={setShowingConditionEditor}
                              conditionEditorIndex={conditionEditorIndex}/>

      <RegActionResultEdit fields={fields}
                           results={results}
                           showingResultEditor={showingResultEditor}
                           setShowingResultEditor={setShowingResultEditor}
                           resultEditorIndex={resultEditorIndex}
                           isElse={false}/>

      <RegActionResultEdit fields={fields}
                           results={elseResults}
                           showingResultEditor={showingElseResultEditor}
                           setShowingResultEditor={setShowingElseResultEditor}
                           resultEditorIndex={elseResultEditorIndex}
                           isElse={true}/>
    </Container>
  );
}

