import React, {useEffect, useState} from 'react';
import './CountrySelect.scss';
import {Button, Col, Form, Row} from "react-bootstrap";
import {downloadFile} from "../utils/networkUtils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {CONF_LABEL_TYPE_ABSTRACT_STATUS, CONF_LABEL_TYPE_SYMPOSIUM_STATUS, REG_STATUS_NAMES, REG_STATUS_TYPE} from "../constants";
import {getLabelValue} from "../utils/displayUtils";
import './TableFilter.scss';

export const TABLE_FILTER_MODE_SYMPOSIUM = CONF_LABEL_TYPE_SYMPOSIUM_STATUS;
export const TABLE_FILTER_MODE_ABSTRACT = CONF_LABEL_TYPE_ABSTRACT_STATUS;
export const TABLE_FILTER_MODE_REG = 'reg_status';

/**
 * Display the table filter for statuses for Symposium and Abstract list tables
 * @param conf current conf
 * @param mode 'symposium' or 'abstract'
 * @param showFilters if false filter checkboxes will be hidden
 * @param filters get filter state (use constants.js {ABSTRACT|SYMPOSIUM}_DEFAULT_FILTER_STATE)
 * @param setFilters set parent state
 * @param showDownload Shows the download button
 * @param downloadUrl  URL to download the excel file from
 * @param downloadFilename name to use for the file download
 * @param gridApi ag-grid API used for quick-filter
 * @param statusCounts Object with format: {'pending':3, 'approved': 4,...}
 * @param onSearchChanged callback when filter changes parameter is new filter value
 * @param showOtherButton shows the other button (import/upload)
 * @param otherButtonText Text to show on upload button, If this is null/empty no upload button will be shown
 * @param otherButtonOnclick callback when upload button is clicked.
 * @returns {JSX.Element}
 */
export function TableFilter({
                              conf,
                              mode,
                              showFilters,
                              filters,
                              setFilters,
                              showDownload,
                              downloadUrl,
                              downloadFilename,
                              gridApi,
                              statusCounts,
                              onSearchChanged,
                              showOtherButton,
                              otherButtonText,
                              otherButtonOnclick,
                            }) {
  const [quickFilter, setQuickFilter] = useState('');

  // On startup clear the quickFilter text
  useEffect(() => {
    if (gridApi) {
      gridApi.setGridOption('quickFilterText', quickFilter);
    }
  }, [gridApi]);

  if (![TABLE_FILTER_MODE_SYMPOSIUM, TABLE_FILTER_MODE_ABSTRACT, TABLE_FILTER_MODE_REG].includes(mode)) {
    console.warn("Unknown table filter mode " + mode);
  }

  // Finds a suitable label for the filter key
  function getLabel(filter) {
    if (filter.key === 'all') {
      return 'All';
    }
    if (filter.type === REG_STATUS_TYPE) {
      return REG_STATUS_NAMES[filter.key] || '';
    }
    return getLabelValue(conf, filter.type, filter.key)
  }

  function changeFilter(filter, checked) {
    if (filter.key === 'all') {
      for (const filter of filters) {
        filter.showing = checked;
      }
    } else {
      filter.showing = checked;
      // If you changed a filter and now all are checked
      const allFilter = filters.find(f => f.key === 'all') || {};
      let allShowing = true;
      for (const filter of filters) {
        if (!(filter.key === 'all') && !filter.showing) {
          allShowing = false;
        }
      }
      allFilter.showing = allShowing;
    }
    setFilters([...filters]); // refresh
  }

  function quickFilterChanged(newValue) {
    setQuickFilter(newValue);
    if (gridApi) {
      gridApi.setGridOption('quickFilterText', newValue);
    } else {
      console.warn('cannot set quick filter as api is not available');
    }
    if (onSearchChanged) {
      onSearchChanged(newValue);
    }
  }

  return (
    <Row className="mb-2">
      <Col className={(showFilters ? 'col-7' : 'd-none') + " pt-2 my-auto"}>
        {showFilters && <div>
          Filter: &nbsp;
          {(filters || []).map(filter =>
            <Form.Check
              key={filter.key}
              type="checkbox"
              id={mode + "-" + filter.key}
              label={<span>{getLabel(filter)} ({statusCounts[filter.key] || '0'})</span>}
              inline={true}
              checked={filter.showing}
              onChange={e => changeFilter(filter, e.target.checked)}
              className='mt-1 mb-1'
            />
          )}
        </div>
        }
      </Col>

      <Col className={(showFilters ? 'col-5' : 'col-12') + " align-self-end"}>
        <div className={'d-flex ' + (showFilters ? 'flex-column' : 'flex-row-reverse')}>
          {(showDownload || showOtherButton) &&
            <div className="table-buttons flex-grow-0">
              {showOtherButton &&
                <Button type="button" onClick={() => otherButtonOnclick()} className="ms-2 text-nowrap">
                  {otherButtonText} <FontAwesomeIcon icon="fa-file-excel" className="ms-2"/>
                </Button>
              }
              {showDownload &&
                <Button type="button" onClick={() => downloadFile(downloadUrl, downloadFilename)} className="ms-2 text-nowrap">
                  Download All <FontAwesomeIcon icon="fa-file-excel" className="ms-2"/>
                </Button>
              }
            </div>
          }

          <div className="table-buttons flex-grow-1">
            <Form.Control type="text"
                          id="quick-filter"
                          placeholder='Search...'
                          name="quick-filter"
                          value={quickFilter}
                          onChange={e => quickFilterChanged(e.target.value)}
                          style={{minWidth: '150px'}}
            />
          </div>
        </div>
      </Col>
    </Row>
  );
}
