import {Button, Col, Container, FloatingLabel, Form, Row} from "react-bootstrap";
import React, {useContext, useEffect, useState} from "react";
import {UsacmContext} from "../App";
import {fieldHasErrors, getErrorMessageForField} from "../utils/formUtils";
import {getCurrentConf} from "../api/ConfApi";
import {showSuccessToast} from "../utils/usacmUtils";
import {hasPermission} from "../api/UserApi";
import {
  CONF_ADMINISTERED_BY_CONF_SERVICES,
  CONF_ADMINISTERED_BY_NAMES,
  CONF_ADMINISTERED_BY_USACM,
  PERMISSION_CONF_ADMIN,
  PERMISSION_STAFF
} from "../constants";
import {updateRegSettings} from "../api/RegApi";

export function RegSettings() {
  const {conference} = useContext(UsacmContext);
  const [conf, setConf] = conference;
  const [regStart, setRegStart] = useState('');
  const [regEnd, setRegEnd] = useState('');
  const [regAllowAssign, setRegAllowAssign] = useState(false);
  const [regAllowMultiple, setRegAllowMultiple] = useState(false);
  const [regAllowPayOffline, setRegAllowPayOffline] = useState(false);
  const [regUseAttendance, setRegUseAttendance] = useState(false);
  const [administeredBy, setAdministeredBy] = useState(CONF_ADMINISTERED_BY_USACM);
  const [wireTransferBank, setWireTransferBank] = useState('');
  const [wireTransferAccountHolder, setWireTransferAccountHolder] = useState('');
  const [wireTransferRouting, setWireTransferRouting] = useState('');
  const [wireTransferAccount, setWireTransferAccount] = useState('');

  const [errors, setErrors] = useState([]);
  const isStaffOrAdmin = hasPermission(PERMISSION_STAFF) || hasPermission(PERMISSION_CONF_ADMIN);

  useEffect(() => {
    if (conf) {
      setRegStart(conf.reg_start || '');
      setRegEnd(conf.reg_end || '');
      setRegAllowAssign(conf.reg_allow_assign || false);
      setRegAllowMultiple(conf.reg_allow_multiple || false);
      setRegAllowPayOffline(conf.reg_allow_pay_offline || false);
      setRegUseAttendance(conf.reg_use_attendance || false);
      setAdministeredBy(conf.administered_by || CONF_ADMINISTERED_BY_USACM);
      setWireTransferBank(conf.wire_transfer_bank || '');
      setWireTransferAccountHolder(conf.wire_transfer_account_holder || '');
      setWireTransferRouting(conf.wire_transfer_routing || '');
      setWireTransferAccount(conf.wire_transfer_account || '');
    }
  }, [conf]);

  function callUpdateConf(event) {
    event.preventDefault();
    setErrors([]);
    updateRegSettings(regStart, regEnd, regAllowAssign, regAllowMultiple, regAllowPayOffline, regUseAttendance,
      administeredBy, wireTransferBank, wireTransferAccountHolder, wireTransferRouting, wireTransferAccount,
      (status, data, newErrors) => {
        if (status === 200) {
          showSuccessToast("Registration settings saved");
          getCurrentConf((code, data, errors) => {
            if (code === 200) {
              setConf(data);
            } else {
              console.warn('Error loading conf in settings', errors);
            }
          });
        } else {
          setErrors(newErrors);
        }
      });
  }

  return (
    <Form onSubmit={callUpdateConf}>
      <Container fluid className="usacm-container-narrow">

        <Row>
          <Col className="mb-3 col-6">
            <Form.Group controlId="reg_start">
              <FloatingLabel controlId="reg_start" label="Registration Start Date">
                <Form.Control type="date"
                              placeholder="Start Date"
                              name="reg__start"
                              value={regStart}
                              onChange={e => setRegStart(e.target.value)}
                              readOnly={!isStaffOrAdmin}
                              isInvalid={fieldHasErrors(errors, 'reg_start')}/>
                <Form.Control.Feedback type="invalid">
                  {getErrorMessageForField(errors, 'reg_start')}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col className="mb-3 col-6">
            <Form.Group controlId="reg_end">
              <FloatingLabel controlId="reg_start" label="Registration End Date">
                <Form.Control type="date"
                              placeholder="End Date"
                              name="reg_end"
                              value={regEnd}
                              onChange={e => setRegEnd(e.target.value)}
                              readOnly={!isStaffOrAdmin}
                              isInvalid={fieldHasErrors(errors, 'reg_end')}/>
                <Form.Control.Feedback type="invalid">
                  {getErrorMessageForField(errors, 'reg_end')}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col className='mb-3 mt-2'>
            <Form.Check
              type="checkbox"
              label="Allow creating registrations for other users"
              id="reg_allow_assign"
              checked={regAllowAssign}
              onChange={e => isStaffOrAdmin && setRegAllowAssign(e.target.checked)}
              isInvalid={fieldHasErrors(errors, 'reg_allow_assign')}
            />
            <div className="usacm-invalid-feedback">
              {getErrorMessageForField(errors, 'reg_allow_assign')}
            </div>
          </Col>
        </Row>

        <Row>
          <Col className='mb-3'>
            <Form.Check
              type="checkbox"
              label="Allow creating multiple registrations for the same user"
              id="reg_allow_multiple"
              checked={regAllowMultiple}
              onChange={e => isStaffOrAdmin && setRegAllowMultiple(e.target.checked)}
              isInvalid={fieldHasErrors(errors, 'reg_allow_multiple')}
            />
            <div className="usacm-invalid-feedback">
              {getErrorMessageForField(errors, 'reg_allow_multiple')}
            </div>
          </Col>
        </Row>

        <Row>
          <Col className='mb-3'>
            <Form.Check
              type="checkbox"
              label="Allow paying offline"
              id="reg_allow_pay_offline"
              checked={regAllowPayOffline}
              onChange={e => isStaffOrAdmin && setRegAllowPayOffline(e.target.checked)}
              isInvalid={fieldHasErrors(errors, 'reg_allow_pay_offline')}
            />
            <div className="usacm-invalid-feedback">
              {getErrorMessageForField(errors, 'reg_allow_pay_offline')}
            </div>
          </Col>
        </Row>

        <Row>
          <Col className='mb-3'>
            <Form.Check
              type="checkbox"
              label="Use Attendance"
              id="reg_use_attendance"
              checked={regUseAttendance}
              onChange={e => isStaffOrAdmin && setRegUseAttendance(e.target.checked)}
              isInvalid={fieldHasErrors(errors, 'reg_use_attendance')}
            />
            <div className="usacm-invalid-feedback">
              {getErrorMessageForField(errors, 'reg_use_attendance')}
            </div>
          </Col>
        </Row>

        <Row>
          <Col className="mb-3">
            Invoice Report
          </Col>
        </Row>

        <Row>
          <Col className="mb-3 ms-3">
            <Form.Group controlId="administered_by">
              <FloatingLabel controlId="administered_by" label="Administered By">
                <Form.Control
                  className="form-select"
                  as="select"
                  value={administeredBy}
                  onChange={e => setAdministeredBy(e.target.value)}
                >
                  <option value={CONF_ADMINISTERED_BY_USACM}
                          key={CONF_ADMINISTERED_BY_USACM}>{CONF_ADMINISTERED_BY_NAMES[CONF_ADMINISTERED_BY_USACM]}</option>
                  <option value={CONF_ADMINISTERED_BY_CONF_SERVICES}
                          key={CONF_ADMINISTERED_BY_CONF_SERVICES}>{CONF_ADMINISTERED_BY_NAMES[CONF_ADMINISTERED_BY_CONF_SERVICES]}</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {getErrorMessageForField(errors, 'logo_shape')}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col className="mb-3 ms-3">
            <Form.Group controlId="wire_transfer_bank">
              <FloatingLabel controlId="wire_transfer_bank" label="Wire Transfer Bank Name and Address">
                <Form.Control type="text"
                              placeholder="Wire Transfer Bank Name and Address"
                              name="wire_transfer_bank"
                              value={wireTransferBank}
                              onChange={e => setWireTransferBank(e.target.value)}
                              readOnly={!isStaffOrAdmin}
                              isInvalid={fieldHasErrors(errors, 'wire_transfer_bank')}/>
                <Form.Control.Feedback type="invalid">
                  {getErrorMessageForField(errors, 'wire_transfer_bank')}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col className="mb-3 ms-3">
            <Form.Group controlId="wire_transfer_account_holder">
              <FloatingLabel controlId="wire_transfer_account_holder" label="Wire Transfer Account Holder Name and Address">
                <Form.Control type="text"
                              placeholder="Wire Transfer Account Holder Name and Address"
                              name="wire_transfer_account_holder"
                              value={wireTransferAccountHolder}
                              onChange={e => setWireTransferAccountHolder(e.target.value)}
                              readOnly={!isStaffOrAdmin}
                              isInvalid={fieldHasErrors(errors, 'wire_transfer_account_holder')}/>
                <Form.Control.Feedback type="invalid">
                  {getErrorMessageForField(errors, 'wire_transfer_account_holder')}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col className="mb-3 ms-3">
            <Form.Group controlId="wire_transfer_routing">
              <FloatingLabel controlId="wire_transfer_routing" label="Wire Transfer Routing Number">
                <Form.Control type="text"
                              placeholder="Wire Transfer Routing Number"
                              name="wire_transfer_routing"
                              value={wireTransferRouting}
                              onChange={e => setWireTransferRouting(e.target.value)}
                              readOnly={!isStaffOrAdmin}
                              isInvalid={fieldHasErrors(errors, 'wire_transfer_routing')}/>
                <Form.Control.Feedback type="invalid">
                  {getErrorMessageForField(errors, 'wire_transfer_routing')}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col className="mb-3 ms-3">
            <Form.Group controlId="wire_transfer_account">
              <FloatingLabel controlId="wire_transfer_account" label="Wire Transfer Account Number">
                <Form.Control type="text"
                              placeholder="Wire Transfer Account Number"
                              name="wire_transfer_account"
                              value={wireTransferAccount}
                              onChange={e => setWireTransferAccount(e.target.value)}
                              readOnly={!isStaffOrAdmin}
                              isInvalid={fieldHasErrors(errors, 'wire_transfer_account')}/>
                <Form.Control.Feedback type="invalid">
                  {getErrorMessageForField(errors, 'wire_transfer_account')}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
        </Row>

        {fieldHasErrors(errors, '') &&
          <Row>
            <Col className="text-center mb-3 usacm-error-message">
              {getErrorMessageForField(errors, '')}
            </Col>
          </Row>
        }

        <Row className='mt-3'>
          <Col className="text-center">
            {isStaffOrAdmin &&
              <Button type="submit" className="ms-3 me-3">Update Settings</Button>
            }
          </Col>
        </Row>

      </Container>
    </Form>
  );
}
