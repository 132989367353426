import {
  ABSTRACT_STATUS_APPROVED,
  ABSTRACT_STATUS_DENIED,
  ABSTRACT_STATUS_PENDING,
  AFFILIATION_SEPARATOR,
  COLOR_APPROVED,
  COLOR_ARCHIVED,
  COLOR_DENIED,
  COLOR_MOVE,
  COLOR_PENDING,
  CONF_LABEL_TYPE_ABSTRACT_STATUS,
  CONF_LABEL_TYPE_FLAG,
  CONF_LABEL_TYPE_SYMPOSIUM_STATUS,
  FLAG_ABSTRACT_ARCHIVED,
  FLAG_ABSTRACT_FINAL_APPROVED,
  FLAG_ABSTRACT_FINAL_DENIED,
  FLAG_ABSTRACT_MOVE,
  FLAG_SYMPOSIUM_APPROVED_EMAIL_SENT,
  FLAG_SYMPOSIUM_ARCHIVED,
  ICON_ARCHIVED,
  ICON_MOVE,
  ICON_REG,
  REG_STATUS_CANCELED,
  REG_STATUS_NAMES,
  REG_STATUS_PAID,
  REG_STATUS_PENDING,
  SYMPOSIUM_STATUS_APPROVED,
  SYMPOSIUM_STATUS_DENIED,
  SYMPOSIUM_STATUS_PENDING
} from "../constants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {Fragment} from "react";

/**
 * Gets a list of label keys for statuses that will display.
 * @param abstract
 * @returns [{type:'label-type', key:'label-key'}]
 */
export function getAbstractDisplayStatuses(abstract) {
  const statuses = [];
  if (!abstract) {
    return statuses;
  }
  if (abstract.archived) {
    statuses.push({type: CONF_LABEL_TYPE_FLAG, key: FLAG_ABSTRACT_ARCHIVED, icon: ICON_ARCHIVED, color: COLOR_ARCHIVED});
    return statuses;
  }
  if (abstract.approved_email_sent) {
    statuses.push({type: CONF_LABEL_TYPE_FLAG, key: FLAG_ABSTRACT_FINAL_APPROVED, icon: 'check-double', color: COLOR_APPROVED});
  }
  if (abstract.denied_email_sent) {
    statuses.push({type: CONF_LABEL_TYPE_FLAG, key: FLAG_ABSTRACT_FINAL_DENIED, icon: 'xmark', color: COLOR_DENIED});
  }
  // Only add status if none of the others are showing
  if (!statuses.length) {
    if (abstract.status === ABSTRACT_STATUS_PENDING) {
      statuses.push({type: CONF_LABEL_TYPE_ABSTRACT_STATUS, key: abstract.status, icon: 'circle-question', color: COLOR_PENDING});
    } else if (abstract.status === ABSTRACT_STATUS_APPROVED) {
      statuses.push({type: CONF_LABEL_TYPE_ABSTRACT_STATUS, key: abstract.status, icon: 'circle-check', color: COLOR_APPROVED});
    } else if (abstract.status === ABSTRACT_STATUS_DENIED) {
      statuses.push({type: CONF_LABEL_TYPE_ABSTRACT_STATUS, key: abstract.status, icon: 'circle-xmark', color: COLOR_DENIED});
    }
  }
  if (abstract.move_requested) {
    statuses.push({type: CONF_LABEL_TYPE_FLAG, key: FLAG_ABSTRACT_MOVE, icon: ICON_MOVE, color: COLOR_MOVE});
  }
  return statuses;
}

/**
 * Gets JSX icon to show based on the status
 * @param conf current conf data from backend
 * @param abstract backend abstract object
 * @returns {JSX.Element}
 */
export function getIconForAbstractStatus(conf, abstract) {
  if (!conf || !abstract) {
    return <Fragment> </Fragment>;
  }
  const displayStatuses = getAbstractDisplayStatuses(abstract);
  return (
    <Fragment>
      {displayStatuses.map(displayStatus =>
        <span title={getLabelValue(conf, displayStatus.type, displayStatus.key)} key={displayStatus.key} className="ms-1">
          <FontAwesomeIcon icon={displayStatus.icon} size={"2xl"} color={displayStatus.color}/>
        </span>
      )}
    </Fragment>);
}

/**
 * Gets a string to display the abstract status (may have multiple names)
 * @param conf current conf data from backend
 * @param abstract backend abstract object
 * @returns String with the name(s) of the statuses
 */
export function getNameForAbstractStatus(conf, abstract) {
  if (!conf || !abstract) {
    return '';
  }
  const displayStatuses = getAbstractDisplayStatuses(abstract);
  let names = "";
  for (const displayStatus of displayStatuses) {
    if (names.length > 0) {
      names += ", ";
    }
    names += getLabelValue(conf, displayStatus.type, displayStatus.key);
  }
  return names;
}

/**
 * @param abstract backend abstract object
 * @returns {number} a numeric score for comparing (lowest at top)
 */
export function scoreAbstractStatus(abstract) {
  if (!abstract) {
    return 0;
  }
  if (abstract.archived) {
    return 10;
  }
  if (abstract.approved_email_sent) {
    return 3;
  }
  if (abstract.denied_email_sent) {
    return 5;
  }
  if (abstract.status === ABSTRACT_STATUS_PENDING) {
    return 1;
  }
  if (abstract.status === ABSTRACT_STATUS_APPROVED) {
    return 2;
  }
  if (abstract.status === ABSTRACT_STATUS_DENIED) {
    return 4;
  }
}

export function filterAbstracts(abstractList, filters) {
  if (!abstractList) {
    return [];
  }
  // If all is selected then we return the entire list
  if (filters.find(f => f.key === 'all' && f.showing)) {
    return abstractList;
  }
  // Only get the active filters
  const showingFilters = filters.filter(f => f.showing);
  return abstractList.filter(abstract => {
    const displayKeys = getAbstractDisplayStatuses(abstract).map(s => s.key);
    for (const filter of showingFilters) {
      if (displayKeys.includes(filter.key)) {
        return true; // return from abstractList.filter
      }
    } // for filter
    return false; // return from abstractList.filter
  });
}

/**
 * Calculate the status counts from the abstractData
 * Format is : {'pending':3, 'approved':0,...}
 */
export function calcAbstractStatusCounts(abstractList) {
  const newCounts = {};
  for (const abstract of abstractList) {
    const displayKeys = getAbstractDisplayStatuses(abstract).map(s => s.key);
    for (const displayKey of displayKeys) {
      newCounts[displayKey] = (newCounts[displayKey] || 0) + 1;
    }
  }
  newCounts['all'] = abstractList.length;
  return newCounts;
}

/**
 * Gets the JSX for an abstract's reg status (a single icon)
 */
export function getIconForRegStatus(abstract) {
  const regStatus = abstract.reg_status;
  if (!regStatus) {
    return <Fragment> </Fragment>;
  }
  const regStatusName = 'Registration ' + REG_STATUS_NAMES[regStatus];
  const regStatusIcon = ICON_REG;
  let regStatusIconColor = COLOR_ARCHIVED;
  if (regStatus === REG_STATUS_PENDING) {
    regStatusIconColor = COLOR_PENDING;
  } else if (regStatus === REG_STATUS_PAID) {
    regStatusIconColor = COLOR_APPROVED;
  } else if (regStatus === REG_STATUS_CANCELED) {
    regStatusIconColor = COLOR_DENIED;
  }
  return <span title={regStatusName} key={regStatus}>
          <FontAwesomeIcon icon={regStatusIcon} size={"2xl"} color={regStatusIconColor}/>
        </span>
}

/**
 * Gets the text name of an abstracts reg status
 */
export function getNameForRegStatus(abstract) {
  const regStatus = abstract.reg_status;
  if (!regStatus) {
    return '';
  }
  return 'Registration ' + REG_STATUS_NAMES[regStatus];
}

/**
 * Gets a list of label keys for statuses that will display.
 * @param symposium backend object
 * @returns [{type:'label-type', key:'label-key'}]
 */
export function getSymposiumDisplayStatuses(symposium) {
  const statuses = [];
  if (!symposium) {
    return statuses;
  }
  if (symposium.archived) {
    statuses.push({type: CONF_LABEL_TYPE_FLAG, key: FLAG_SYMPOSIUM_ARCHIVED, icon: ICON_ARCHIVED, color: COLOR_ARCHIVED});
    return statuses;
  }
  if (symposium.approved_email_sent) {
    statuses.push({
      type: CONF_LABEL_TYPE_FLAG,
      key: FLAG_SYMPOSIUM_APPROVED_EMAIL_SENT,
      icon: 'envelope-circle-check',
      color: COLOR_APPROVED
    });
  }
  // Only use status if we haven't found one already
  if (!statuses.length) {
    if (symposium.status === SYMPOSIUM_STATUS_PENDING) {
      statuses.push({type: CONF_LABEL_TYPE_SYMPOSIUM_STATUS, key: symposium.status, icon: 'circle-question', color: COLOR_PENDING});
    } else if (symposium.status === SYMPOSIUM_STATUS_APPROVED) {
      statuses.push({type: CONF_LABEL_TYPE_SYMPOSIUM_STATUS, key: symposium.status, icon: 'circle-check', color: COLOR_APPROVED});
    } else if (symposium.status === SYMPOSIUM_STATUS_DENIED) {
      statuses.push({type: CONF_LABEL_TYPE_SYMPOSIUM_STATUS, key: symposium.status, icon: 'circle-xmark', color: COLOR_DENIED});
    }
  }
  return statuses;
}

/**
 * Calculate the status counts from the symposium dataq
 * Format is : {'pending':3, 'approved':0,...}
 */
export function calcSymposiumStatusCounts(symposiumList) {
  const newCounts = {};
  for (const symposium of symposiumList) {
    const displayKeys = getSymposiumDisplayStatuses(symposium).map(s => s.key);
    for (const displayKey of displayKeys) {
      newCounts[displayKey] = (newCounts[displayKey] || 0) + 1;
    }
  }
  newCounts['all'] = symposiumList.length;
  return newCounts;
}

/**
 * @param symposium backend symposium object
 * @returns {number} a numeric score for comparing
 */
export function scoreSymposiumStatus(symposium) {
  if (!symposium) {
    return 0;
  }
  if (symposium.archived) {
    return 10;
  }
  // This needs to be before the status calc as the method short-circuits
  if (symposium.approved_email_sent) {
    return 3;
  }
  if (symposium.status === SYMPOSIUM_STATUS_PENDING) {
    return 1;
  }
  if (symposium.status === SYMPOSIUM_STATUS_APPROVED) {
    return 2;
  }
  if (symposium.status === SYMPOSIUM_STATUS_DENIED) {
    return 4;
  }
}

export function filterSymposia(symposiumList, filters) {
  // If all is selected then we return the entire list
  if (filters.find(f => f.key === 'all' && f.showing)) {
    return symposiumList;
  }
  // Only get the active filters
  const showingFilters = filters.filter(f => f.showing);
  return symposiumList.filter(symposium => {
    const displayKeys = getSymposiumDisplayStatuses(symposium).map(s => s.key);
    for (const filter of showingFilters) {
      if (displayKeys.includes(filter.key)) {
        return true; // return from symposiumList.filter
      }
    } // for filter
    return false; // return from symposiumList.filter
  });
}

/**
 * Gets JSX icon to show based on the status
 * @param conf current conf data from backend
 * @param symposium backend symposium object
 * @returns {JSX.Element}
 */
export function getIconForSymposiumStatus(conf, symposium) {
  if (!conf || !symposium) {
    return <Fragment> </Fragment>;
  }
  const displayStatuses = getSymposiumDisplayStatuses(symposium);
  return (
    <Fragment>
      {displayStatuses.map(displayStatus =>
        <span title={getLabelValue(conf, displayStatus.type, displayStatus.key)} key={displayStatus.key} className="ms-1">
          <FontAwesomeIcon icon={displayStatus.icon} size={"2xl"} color={displayStatus.color}/>
        </span>
      )}
    </Fragment>);
}

/**
 * Gets a string to display the symposium status (may have multiple names)
 * @param conf current conf data from backend
 * @param symposium backendobject
 * @returns String with the name(s) of the statuses
 */
export function getNameForSymposiumStatus(conf, symposium) {
  if (!conf || !symposium) {
    return '';
  }
  const displayStatuses = getSymposiumDisplayStatuses(symposium);
  let names = "";
  for (const displayStatus of displayStatuses) {
    names += getLabelValue(conf, displayStatus.type, displayStatus.key);
  }
  return names;
}

/**
 * Gets a list of label keys of the specified type
 * @param conf  conf data from the server
 * @param labelType conf label type. See constants.py CONF_LABEL_TYPE_*
 * @returns {*[]}  Array of strings with label keys
 */
export function getLabelKeys(conf, labelType) {
  if (!conf || !conf.labels || !labelType) {
    return [];
  }
  const labelKeys = [];
  for (const label of conf.labels) {
    if (label.type === labelType) {
      labelKeys.push(label.key);
    }
  }
  return labelKeys;
}

/**
 * Gets a map of key-value pairs for the label type
 * @param conf  current conf data with labels
 * @param labelType conf label type. See constants.py CONF_LABEL_TYPE_*
 */
export function getLabelsOfType(conf, labelType) {
  const labels = {};
  if (!conf || !conf.labels || !labelType) {
    return labels;
  }
  for (const label of conf.labels) {
    if (label.type === labelType) {
      labels[label.key] = label.value;
    }
  }
  return labels;
}

/**
 * Gets the value for a single label key
 * @param conf current conf data with labels
 * @param labelType conf label type. See constants.py CONF_LABEL_TYPE_*
 * @param key unique key string
 * @return string with value
 */
export function getLabelValue(conf, labelType, key) {
  if (!conf || !conf.labels || !labelType || !key) {
    return '';
  }
  for (const label of conf.labels) {
    if ((label.type === labelType) && label.key === key) {
      return label.value;
    }
  }
  console.warn(`Could not find label with labelType=${labelType} and key=${key} in `, conf.labels);
  return '';
}

/**
 * Converts a DB affiliation value into a display value.
 * This will convert the AFFILIATION_SEPARATOR
 */
export function formatAffiliation(affiliation) {
  return affiliation.replaceAll(AFFILIATION_SEPARATOR, ', ');
}
