import {get, post} from "../utils/networkUtils";

/**
 * Submits a new abstract
 */
export function submitAbstract(force, symposiumId, title, text, presentationType, authors, onComplete) {
  const data = {
    force: force,
    symposium_id: symposiumId,
    title: title,
    text: text,
    presentation_type: presentationType,
    authors: authors,
  };
  post("abstract/create/", data, onComplete);
}

/**
 * Resets email approved sent field of an abstract
 * @param abstractNum String with abstract number, May include conf abstract_number_prefix or not  e.g. 'U0013' or '13'
 * @param abstractAction one of FLAG_ABSTRACT_FINAL_APPROVED or FLAG_ABSTRACT_FINAL_DENIED
 * @param onComplete callback
 */
export function resetEmailFieldForAbstract(abstractNum, abstractAction, onComplete) {
  const data = {
    abstract_num: abstractNum,
    abstract_action: abstractAction,
  };

  post("abstract/update/final/reset/", data, onComplete);
}

/**
 * Gets a list of abstracts :
 *  @param symposiumId is set then it gets the abstracts in the symposium
 *  @param userId will get submitted or primary author abstracts for the user (logged in user must have permission as well)
 *  If symposiumId and userId are blank - we will get all abstracts the logged in user can access
 *  @param onComplete callback
 *  NOTE: if there is both symposiumId and userId this will search for symposium (you should filter for user yourself)
 */
export function getAbstracts(symposiumId, userId, onComplete) {
  if (symposiumId) {
    get("symposium/" + symposiumId + "/abstracts/", onComplete);
  } else if (userId) {
    get("abstract/list/user/" + userId + "/", onComplete);
  } else {
    get("abstract/list/", onComplete);
  }
}

/**
 * Gets a list of abstracts the logged in user is presenting author for
 */
export function getAbstractExtendedList(onComplete) {
  get("abstract/extended/list/", onComplete);
}

/**
 * Deletes extended abstract data on the abstract
 */
export function deleteExtendedAbstract(abstractId, onComplete) {
  const data = {
    abstract_id: abstractId,
  }
  post("abstract/extended/delete/", data, onComplete);
}

/**
 * Gets a list of public abstract info
 *  This is an abbreviated version of the abstract with reduced personal info
 *  NOTE: This will return all abstracts in the conf - not just the ones the user has permissions to
 */
export function getPublicAbstracts(onComplete) {
  get("abstract/list/public/", onComplete);
}

/**
 * Gets data for an abstract
 */
export function getAbstract(abstractId, onComplete) {
  get("abstract/" + abstractId + "/", onComplete);
}

/**
 * Gets an HTML fragment for an abstract
 */
export function getAbstractHtml(abstractId, onComplete) {
  get("abstract/" + abstractId + "/html/", onComplete);
}

/**
 * Update an abstract
 */
export function updateAbstract(force, abstractId, status, symposiumId, moveRequested, adminNotes, title, text, presentationType, authors, onComplete) {
  const data = {
    force: force,
    abstract_id: abstractId,
    status: status,
    symposium_id: symposiumId,
    move_requested: moveRequested,
    admin_notes: adminNotes,
    title: title,
    text: text,
    presentation_type: presentationType,
    authors: authors,
  };
  post("abstract/update/", data, onComplete);
}

/**
 * Sets the status of an abstract.
 */
export function setAbstractStatus(abstractId, status, onComplete) {
  const data = {
    abstract_id: abstractId,
    status: status,
  };
  post("abstract/update/status/", data, onComplete);
}

/**
 * Sets the move_requested flag and move_comment for an abstract.
 */
export function setAbstractMove(abstractId, moveRequested, moveComment, onComplete) {
  const data = {
    abstract_id: abstractId,
    move_requested: moveRequested,
    move_comment: moveComment,
  };
  post("abstract/update/move/", data, onComplete);
}

/**
 * Sets the final status for a bunch of abstracts
 * @param abstractIds an array of abstract IDs
 * @param status new status, should be a FLAG_ABSTRACT_FINAL_*
 * @param onComplete callback
 */
export function setFinalStatusForAbstracts(abstractIds, status, onComplete) {
  const data = {
    abstract_ids: abstractIds,
    status: status,
  };
  post("abstract/update/final/status/", data, onComplete);
}

/**
 * Sets the archived flag for an abstract
 * @param abstractId  abstract to update
 * @param archived : true if archived, false to un-archive
 * @param onComplete callback
 */
export function setArchivedStatusForAbstract(abstractId, archived, onComplete) {
  const data = {
    abstract_id: abstractId,
    archived: archived,
  };
  post("abstract/update/archived/", data, onComplete);
}

/**
 * Gets the count of abstracts
 */
export function getAbstractCount(onComplete) {
  get("abstract/list/count/", onComplete);
}
