import {Button, Col, Container, FloatingLabel, Form, Modal, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {centsToDollarStr, dollarsToCents, showSuccessToast} from "../utils/usacmUtils";
import {deleteCoupon, upsertCoupon} from "../api/RegApi";
import {fieldHasErrors, getErrorMessageForField} from "../utils/formUtils";
import {UserSelect} from "../shared/UserSelect";


export function RegCouponEdit({origCoupon, onClose}) {
  const [active, setActive] = useState(true);
  const [totalCount, setTotalCount] = useState('');
  const [unlimitedCount, setUnlimitedCount] = useState(false);
  const [code, setCode] = useState('');
  const [allowedUsers, setAllowedUsers] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [invoiceKey, setInvoiceKey] = useState('');
  const [invoicePercent, setInvoicePercent] = useState('');
  const [invoiceAmount, setInvoiceAmount] = useState('');
  const [invoiceText, setInvoiceText] = useState('');
  const [invoiceOrder, setInvoiceOrder] = useState('');
  const [usingPercent, setUsingPercent] = useState(false);
  const [errors, setErrors] = useState([]);
  const [showingDeleteConfirm, setShowingDeleteConfirm] = useState(false);

  useEffect(() => {
    if (origCoupon) {
      setActive(!!origCoupon.active);
      setTotalCount(origCoupon.total_count || '');
      setUnlimitedCount(!!origCoupon.unlimited_count);
      setCode(origCoupon.code || '');
      setAllowedUsers(origCoupon.allowed_users || []);
      setStartDate(origCoupon.start_date || '');
      setEndDate(origCoupon.end_date || '');
      setInvoiceKey(origCoupon.invoice_key || '');
      setInvoicePercent(origCoupon.invoice_percent || '');
      setInvoiceAmount(centsToDollarStr(origCoupon.invoice_amount) || '');
      setInvoiceText(origCoupon.invoice_text || '');
      setInvoiceOrder(origCoupon.invoice_order || '');
      setUsingPercent(!!origCoupon.invoice_percent);
    }
  }, [origCoupon]);

  function callUpsertCoupon() {
    upsertCoupon(origCoupon?.id, active, totalCount, unlimitedCount, code, allowedUsers, startDate, endDate,
      invoiceKey, invoicePercent, dollarsToCents(invoiceAmount), invoiceText, invoiceOrder, (code, data, errors) => {
        if (code === 200) {
          showSuccessToast('Updated coupon.');
          onClose();
        } else {
          setErrors(errors);
        }
      });
  }

  function callDeleteCoupon() {
    deleteCoupon(origCoupon?.id, (code, data, errors) => {
      if (code === 200) {
        showSuccessToast('Deleted coupon.');
        onClose();
      } else {
        setErrors(errors);
        setShowingDeleteConfirm(false);
      }
    });
  }

  function changeCode(newCode) {
    setCode(newCode.toUpperCase());
  }

  function changeUsingPercent(newUsingPercent) {
    setUsingPercent(newUsingPercent);
    if (newUsingPercent) {
      setInvoiceAmount('');
    } else {
      setInvoicePercent('');
    }
  }

  function addAllowedUser(user) {
    if (!allowedUsers.find(u => u.id === user.id)) {
      allowedUsers.push(user);
      setAllowedUsers([...allowedUsers]);
    } // else user is already in the list, shouldn't be added multiple times
  }

  function removeAllowedUser(user) {
    const userIndex = allowedUsers.findIndex(au => au.id === user.id);
    if (userIndex < 0) {
      console.warn(`Failed to find user ${user} to delete in ${allowedUsers}`);
      return;
    }
    allowedUsers.splice(userIndex, 1);
    setAllowedUsers([...allowedUsers]); // refresh
  }

  function setUnlimited(newUnlimitedCount) {
    setUnlimitedCount(newUnlimitedCount);
    if (newUnlimitedCount) {
      setTotalCount('');
    }
  }

  return (
    <Container fluid className="usacm-container-narrow">

      <Row className="mb-3">
        <Col className="col-12 text-center fw-bold">
          {origCoupon?.id ? 'Update' : 'Add'} Coupon
        </Col>
      </Row>

      <Row className="mb-3">
        <Col>
          <Form.Group controlId="code">
            <FloatingLabel controlId="code" label="User Entered Code">
              <Form.Control type="text"
                            placeholder="Code"
                            name="code"
                            value={code}
                            onChange={e => changeCode(e.target.value)}
                            isInvalid={fieldHasErrors(errors, 'code')}/>
              <Form.Control.Feedback type="invalid">
                {getErrorMessageForField(errors, 'code')}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col className='flex-grow-1'>
          <Form.Group controlId="total_count">
            <FloatingLabel controlId="remaining_count" label="Total Count">
              <Form.Control type="number"
                            step='1'
                            placeholder='Total Count'
                            name='total_count'
                            value={totalCount}
                            disabled={unlimitedCount}
                            onChange={e => setTotalCount(e.target.value)}
                            isInvalid={fieldHasErrors(errors, 'total_count')}/>
              <Form.Control.Feedback type="invalid">
                {getErrorMessageForField(errors, 'total_count')}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>
        <Col className='flex-grow-0 my-auto'>
          <Form.Check
            type="checkbox"
            label="Unlimited"
            id="unlimited-count"
            checked={unlimitedCount}
            onChange={e => setUnlimited(e.target.checked)}
            isInvalid={fieldHasErrors(errors, 'unlimited_count')}
          />
          <div className="usacm-invalid-feedback">
            {getErrorMessageForField(errors, 'unlimited_count')}
          </div>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col>
          <div className='fw-bold mb-2'>
            Allowed Users:
          </div>
          {allowedUsers.map(user =>
            <div className='ms-2 mb-3 text-nowrap' key={user.id}>
              {user.first_name} {user.last_name}, {user.email}
              <Button onClick={() => removeAllowedUser(user)} size='sm' className='ms-2'>Delete</Button>
            </div>)}
          <UserSelect onChange={addAllowedUser} usersInConf={true} noneSelectedText='Add User...'/>
        </Col>
      </Row>

      <Row>
        <Col className="mb-3 col-6">
          <Form.Group controlId="start_date">
            <FloatingLabel controlId="start_date" label="Valid From Start Date">
              <Form.Control type="date"
                            placeholder="Start Date"
                            name="reg__start"
                            value={startDate}
                            onChange={e => setStartDate(e.target.value)}
                            isInvalid={fieldHasErrors(errors, 'start_date')}/>
              <Form.Control.Feedback type="invalid">
                {getErrorMessageForField(errors, 'start_date')}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>
        <Col className="mb-3 col-6">
          <Form.Group controlId="end_date">
            <FloatingLabel controlId="start_date" label="Valid to End Date">
              <Form.Control type="date"
                            placeholder="End Date"
                            name="end_date"
                            value={endDate}
                            onChange={e => setEndDate(e.target.value)}
                            isInvalid={fieldHasErrors(errors, 'end_date')}/>
              <Form.Control.Feedback type="invalid">
                {getErrorMessageForField(errors, 'end_date')}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col>
          <Form.Group controlId="invoice_key">
            <FloatingLabel controlId="invoice_key" label="Invoice Key">
              <Form.Control type="text"
                            placeholder="Invoice Key"
                            name="invoice_key"
                            value={invoiceKey}
                            onChange={e => setInvoiceKey(e.target.value)}
                            isInvalid={fieldHasErrors(errors, 'invoice_key')}/>
              <Form.Control.Feedback type="invalid">
                {getErrorMessageForField(errors, 'invoice_key')}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>
      </Row>

      {usingPercent &&
        <Row className="mb-3">
          <Col className='d-flex'>
            <Form.Group controlId="invoice_percent" className='flex-grow-1'>
              <FloatingLabel controlId="invoice_percent" label="Invoice Amount %">
                <Form.Control type="number"
                              step='1'
                              placeholder='Invoice Amount %'
                              name='invoice_percent'
                              value={invoicePercent}
                              onChange={e => setInvoicePercent(e.target.value)}
                              isInvalid={fieldHasErrors(errors, 'invoice_percent')}/>
                <Form.Control.Feedback type="invalid">
                  {getErrorMessageForField(errors, 'invoice_percent')}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
            <Button onClick={() => changeUsingPercent(false)} className='ms-2'>Use $ Amt</Button>
          </Col>
        </Row>
      }

      {!usingPercent &&
        <Row className="mb-3">
          <Col className='d-flex'>
            <Form.Group controlId="invoice_amount" className='flex-grow-1'>
              <FloatingLabel controlId="invoice_percent" label="Invoice Amount $">
                <Form.Control type="number"
                              step='1'
                              placeholder='Invoice Amount $'
                              name='invoice_amount'
                              value={invoiceAmount}
                              onChange={e => setInvoiceAmount(e.target.value)}
                              isInvalid={fieldHasErrors(errors, 'invoice_amount')}/>
                <Form.Control.Feedback type="invalid">
                  {getErrorMessageForField(errors, 'invoice_amount')}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
            <Button onClick={() => changeUsingPercent(true)} className='ms-2'>Use % Amt</Button>
          </Col>
        </Row>
      }

      <Row className="mb-3">
        <Col>
          <Form.Group controlId="invoice_text">
            <FloatingLabel controlId="invoice_text" label="Invoice Text">
              <Form.Control type="text"
                            placeholder="Invoice Text"
                            name="invoice_text"
                            value={invoiceText}
                            onChange={e => setInvoiceText(e.target.value)}
                            isInvalid={fieldHasErrors(errors, 'invoice_text')}/>
              <Form.Control.Feedback type="invalid">
                {getErrorMessageForField(errors, 'invoice_text')}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col>
          <Form.Group controlId="invoice_order">
            <FloatingLabel controlId="invoice_order" label="Order in Invoice">
              <Form.Control type="number"
                            step='1'
                            placeholder='Order in Invoice'
                            name='invoice_order'
                            value={invoiceOrder}
                            onChange={e => setInvoiceOrder(e.target.value)}
                            isInvalid={fieldHasErrors(errors, 'invoice_order')}/>
              <Form.Control.Feedback type="invalid">
                {getErrorMessageForField(errors, 'invoice_order')}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>
      </Row>

      <Row className='mb-3'>
        <Col>
          <Form.Check
            type="checkbox"
            label="Active"
            id="active"
            checked={active}
            onChange={e => setActive(e.target.checked)}
            isInvalid={fieldHasErrors(errors, 'active')}
          />
          <div className="usacm-invalid-feedback">
            {getErrorMessageForField(errors, 'active')}
          </div>
        </Col>
      </Row>


      {fieldHasErrors(errors, '') &&
        <Row>
          <Col className="text-center mb-3 usacm-error-message">
            {getErrorMessageForField(errors, '')}
          </Col>
        </Row>
      }

      <Row>
        <Col className="usacm-button-row">
          <Button variant="secondary" onClick={() => onClose()}>Cancel</Button>
          {origCoupon?.id && !origCoupon?.used_count &&
            <Button variant='danger' onClick={() => setShowingDeleteConfirm(true)}> Delete </Button>
          }
          <Button onClick={() => callUpsertCoupon()}> {origCoupon?.id ? 'Update Coupon' : 'Create Coupon'} </Button>
        </Col>
      </Row>


      <Modal show={showingDeleteConfirm}
             onHide={() => setShowingDeleteConfirm(false)}
             size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this coupon?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowingDeleteConfirm(false)}>Cancel</Button>
          <Button variant="danger" onClick={() => callDeleteCoupon()}>Delete Coupon</Button>
        </Modal.Footer>
      </Modal>

    </Container>
  );
}

