import {Button, Col, Container, Form, Modal, Row} from "react-bootstrap";
import {PageHeader} from "../menu/PageHeader";
import React, {Fragment, useContext, useEffect, useState} from "react";
import {getScheduleRoomSessions, upsertRoomSession} from "../api/ScheduleApi";
import './Schedule.scss';
import {getSymposiaBrief} from "../api/SymposiumApi";
import {getConfLabel, showSuccessToast, titleCase} from "../utils/usacmUtils";
import {CONF_LABEL_KEY_SYMPOSIUM, PERMISSION_CONF_ADMIN, PERMISSION_STAFF} from "../constants";
import {UsacmContext} from "../App";
import {downloadFile, uploadFile} from "../utils/networkUtils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {hasPermission} from "../api/UserApi";
import {fieldHasErrors, getErrorMessageForField} from "../utils/formUtils";

export function Schedule() {
  const context = useContext(UsacmContext);
  const [conf,] = context.conference;
  const [schedule, setSchedule] = useState([]);
  const [showingSymposiumDialog, setShowingSymposiumDialog] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [selectedSymposiumId, setSelectedSymposiumId] = useState(null);
  const [selectedSession, setSelectedSession] = useState(null);
  const [confSymposia, setConfSymposia] = useState([]);
  const symposiumLabel = getConfLabel(conf, CONF_LABEL_KEY_SYMPOSIUM);
  const [showingUploadDialog, setShowingUploadDialog] = useState(false);
  const [fileToUpload, setFileToUpload] = useState(null);
  const [errors, setErrors] = useState([]);
  const [cautions, setCautions] = useState([]);
  const isStaffOrAdmin = hasPermission(PERMISSION_STAFF) || hasPermission(PERMISSION_CONF_ADMIN);

  function loadSchedule() {
    getScheduleRoomSessions((code, data, errors) => {
      if (code === 200) {
        setSchedule(data);
        calculateCautions(data);
      }
    });
  }

  useEffect(() => {
    loadSchedule();
    getSymposiaBrief((status, data, newErrors) => {
      if (status === 200) {
        setConfSymposia(data);
      }
    });
  }, []);

  // Goes through the scheduleData and adds caution messages if any are needed
  function calculateCautions(scheduleData) {
    if (!scheduleData || !scheduleData.roomsessions) {
      return;
    }
    const cautions = [];
    const counts = {};
    for (const roomsession of scheduleData.roomsessions) {
      if (roomsession.symposium_id) { // blanks can be scheduled anywhere!
        const key = roomsession.session_id + '~' + roomsession.symposium_id;
        const count = counts[key] || 0;
        if (count === 1) {
          const session_number = scheduleData?.sessions?.find(s => s.id === roomsession.session_id)?.session_number;
          cautions.push(`${titleCase(symposiumLabel)}  ${roomsession.symposium_number} is scheduled in session ${session_number} more than once.`);
        }
        counts[key] = count + 1;
      }
    }
    setCautions(cautions);
  }

  function getRoomSession(room, session) {
    if (!room || !session || !schedule || !schedule.roomsessions) {
      return null;
    }
    return schedule.roomsessions.find(rs => rs.room_id === room.id && rs.session_id === session.id);
  }

  function startShowingUpdateDialog(room, session) {
    if (!isStaffOrAdmin) {
      // only staff and admin can edit
      return;
    }
    const selectedRoomSession = getRoomSession(room, session);
    setSelectedSymposiumId(selectedRoomSession?.symposium_id || null);
    setSelectedRoom(room);
    setSelectedSession(session);
    setShowingSymposiumDialog(true);
    setErrors([]);
  }

  function stopShowingUpdateDialog() {
    setSelectedSymposiumId(null);
    setSelectedRoom(null);
    setSelectedSession(null);
    setShowingSymposiumDialog(false);
    setErrors([]);
  }

  function callUpsertRoomSession() {
    if (!selectedRoom || !selectedSession) {
      console.warn('Cannot upsert, missing room and/or session.');
      return;
    }
    upsertRoomSession(selectedRoom.id, selectedSession.id, selectedSymposiumId, (code, data, errors) => {
      if (code === 200) {
        loadSchedule();
        stopShowingUpdateDialog();
      } else {
        setErrors(errors)
      }
    });
  }

  function beginFileUpload(e) {
    e.preventDefault();
    const url = "schedule/roomsession/excel/upload/";
    uploadFile(url, 'schedule', fileToUpload, null, (code, data, errors) => {
      if (code === 200) {
        loadSchedule();
        showSuccessToast("Schedule uploaded");
        resetFileUpload();
      } else {
        loadSchedule();
        setErrors(errors);
      }
    });
  }

  function resetFileUpload() {
    setErrors([]);
    setFileToUpload(null);
    setShowingUploadDialog(false);
  }

  return (
    <div>
      <PageHeader pageTitle="Schedule"/>
      <Container fluid className="usacm-container-wide" style={{paddingTop: '15px'}}>
        <div className="sch-button-container">
          {isStaffOrAdmin &&
            <Button type="button" onClick={() => setShowingUploadDialog(true)}>
              Import Schedule <FontAwesomeIcon icon="fa-file-excel" className="ms-2"/>
            </Button>
          }
          <Button type="button" onClick={() => downloadFile('schedule/roomsession/excel/download/', 'schedule.xls')}>
            Download Schedule XLS <FontAwesomeIcon icon="fa-file-excel" className="ms-2"/>
          </Button>
        </div>
        <table className="sch-table">
          <thead>
          <tr className="sch-row">
            <th className="sch-first-col">Room / Session</th>
            {schedule?.sessions?.map(session =>
              <th key={"s" + session.id} className="sch-col text-nowrap"
                  title={"#" + session.session_number + " " + session.name + " " + session.date}>
                {session.session_number}
              </th>
            )}
          </tr>
          </thead>
          <tbody>
          {schedule?.rooms?.map(room =>
            <tr key={room.id} className="sch-row">
              <td className="sch-first-col">
                <span className="fw-bold">#{room.room_number}</span> {room.name}
              </td>
              {schedule?.sessions?.map(session => {
                const roomSession = getRoomSession(room, session);
                return <td key={"rs" + session.id}
                           className={'sch-col' +
                             (roomSession?.has_scheduled_abstracts ? ' sch-has-abstracts' : ' sch-has-no-abstracts') +
                             (roomSession?.scheduling_complete ? ' sch-scheduling-complete' : '')}
                           onClick={() => startShowingUpdateDialog(room, session)}>
                  {roomSession?.symposium_number || ' - '}
                </td>
              })}
            </tr>
          )}
          </tbody>
        </table>

        {cautions.map(caution =>
          <div className="mt-4 text-center" key={caution}>
            <span className="bg-warning p-3 d-inline-block"><b>Caution!</b> {caution}</span>
          </div>
        )}

        <div className='mt-4'>
          <div className='sch-legend fw-bold'>Legend</div>
          <div className='sch-legend sch-has-no-abstracts'> No Abstracts Scheduled </div>
          <div className='sch-legend sch-has-abstracts'> Has Abstracts Scheduled </div>
          <div className='sch-legend sch-scheduling-complete'> Symposium Scheduling Complete </div>

        </div>

      </Container>

      <Modal show={showingSymposiumDialog}
             onHide={() => stopShowingUpdateDialog()}
             size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Choose {titleCase(symposiumLabel)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            Select a {symposiumLabel} to put in session <span
            className="fw-bold">#{selectedSession?.session_number}</span> {selectedSession?.name}
            {selectedSession?.date && <span> on {selectedSession?.date}</span>} in
            room <span className="fw-bold">#{selectedRoom?.room_number}</span> {selectedRoom?.name}
          </div>
          <Form.Group controlId="symposium-select" className="mt-3 mb-1">
            <Form.Control
              className="form-select"
              as="select"
              value={selectedSymposiumId || ''}
              onChange={e => setSelectedSymposiumId(e.target.value ? parseInt(e.target.value, 10) : 0)}
            >
              <option value="0">Choose {titleCase(symposiumLabel)}...</option>
              {confSymposia?.map(s => {
                return <option value={s.id} key={s.id}>{s.symposium_number} - {s.title}</option>
              })}
            </Form.Control>
          </Form.Group>

          {fieldHasErrors(errors, '') &&
            <Row>
              <Col className="text-center mb-3 usacm-error-message">
                {getErrorMessageForField(errors, '')}
              </Col>
            </Row>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => stopShowingUpdateDialog()}>Cancel</Button>
          <Button variant="primary" onClick={() => callUpsertRoomSession()}>Save</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showingUploadDialog}
             onHide={() => resetFileUpload()}
             backdrop='static' // prevent closing on click outside
             size="lg">
        <Form onSubmit={beginFileUpload}>
          <Modal.Header closeButton>
            <Modal.Title>Upload Schedule</Modal.Title>
          </Modal.Header>
          {errors.length === 0 &&
            <Fragment>
              <Modal.Body>
                <Form.Control
                  type="file"
                  id="schedule"
                  name="schedule"
                  label="Choose file to upload..."
                  onChange={e => setFileToUpload(e.target.files[0])}
                />
                <div>
                  <span className="text-warning fw-bold mt-3 me-2 d-inline-block">Caution!</span>
                  Uploading a file will set or clear {symposiumLabel} data for all sessions in all rooms.
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => resetFileUpload()}>Cancel</Button>
                <Button variant="primary" type="submit">Upload</Button>
              </Modal.Footer>
            </Fragment>
          }
          {errors.length > 0 &&
            <Fragment>
              <Modal.Body>
                <div className="mt-2">
                  There were errors uploading the file. Some parts of the upload may have succeeded. Please review
                  the changes, modify the spreadsheet and try uploading again.
                </div>
                {errors.map(error =>
                  <div className="usacm-error-message mt-3" key={error.message}>{error.message}</div>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => resetFileUpload()}>Close</Button>
              </Modal.Footer>
            </Fragment>
          }
        </Form>
      </Modal>
    </div>
  );
}
