import {Button, Col, Container, Form, Modal, Row} from "react-bootstrap";
import {PageHeader} from "../menu/PageHeader";
import {AgGridReact} from "ag-grid-react";
import React, {Fragment, useCallback, useContext, useEffect, useRef, useState} from "react";
import {UsacmContext} from "../App";
import {
  CONF_LABEL_KEY_EMPHASIS_AREA,
  CONF_LABEL_KEY_SYMPOSIUM,
  MODE_APPROVE,
  MODE_EDIT,
  MODE_LIST,
  MODE_SCHEDULE,
  MODE_VIEW,
  PERMISSION_CONF_ADMIN,
  PERMISSION_STAFF,
  SYMPOSIUM_FILTER_DEFAULT_STATE
} from "../constants";
import {deepCopy, getConfLabel, makePlural, titleCase} from "../utils/usacmUtils";
import {getSymposia, setArchivedStatusForSymposium} from "../api/SymposiumApi";
import {naturalSort, naturalSortValues, toolTipRenderer} from "../utils/gridUtils";
import {SymposiumEdit} from "./SymposiumEdit";
import {SymposiumView} from "./SymposiumView";
import {TABLE_FILTER_MODE_SYMPOSIUM, TableFilter} from "../shared/TableFilter";
import {
  calcSymposiumStatusCounts,
  filterSymposia,
  getIconForSymposiumStatus,
  getNameForSymposiumStatus,
  scoreSymposiumStatus
} from "../utils/displayUtils";
import {useLocation} from "react-router-dom";
import {getAbstract, getAbstracts} from "../api/AbstractApi";
import {AbstractApprove} from "../abstract/AbstractApprove";
import {handleError, uploadFile} from "../utils/networkUtils";
import {getFirstMainOrganizer, getOrganizerName} from "./symposiumUtils";
import {hasPermission} from "../api/UserApi";
import {ScheduleAbstract} from "../schedule/ScheduleAbstract";
import useWindowDimensions from "../shared/useWindowDImensions";
import {useNavigate} from "react-router";


export function SymposiumList({userId = null, showHeader = true}) {
  const navigate = useNavigate();
  const context = useContext(UsacmContext);
  const [conf,] = context.conference;
  const [navChanged,] = context.navigationChanged;
  const isStaffOrAdmin = hasPermission(PERMISSION_STAFF) || hasPermission(PERMISSION_CONF_ADMIN);
  const [symposiumList, setSymposiumList] = useState([]);
  const [mode, setMode] = useState(MODE_LIST);
  const [selectedSymposiumId, setSelectedSymposiumId] = useState(null);
  const [abstracts, setAbstracts] = useState([]);
  const gridRef = useRef();
  const symposiumLabel = titleCase(getConfLabel(conf, CONF_LABEL_KEY_SYMPOSIUM));
  const excelFilename = symposiumLabel + '_List.xlsx';
  const [filters, setFilters] = useState(deepCopy(SYMPOSIUM_FILTER_DEFAULT_STATE));
  const [statusCounts, setStatusCounts] = useState({}); // contains {'pending':3, 'approved':0,...}
  const [showingUploadDialog, setShowingUploadDialog] = useState(false);
  const [fileToUpload, setFileToUpload] = useState(null);
  const [errors, setErrors] = useState([]);
  const [windowWidth,] = useWindowDimensions();
  const filteredSymposiumList = filterSymposia(symposiumList, filters);
  const emphasisAreaLabel = titleCase(getConfLabel(conf, CONF_LABEL_KEY_EMPHASIS_AREA));
  const hasFewRows = (symposiumList || []).length < 12;
  // Get the query parameters
  const {search} = useLocation();
  const searchParams = new URLSearchParams(search);
  const paramMode = searchParams.get('mode') || MODE_LIST;
  const symposiumId = searchParams.get('symposiumid') || '';
  const approveAbstractId = searchParams.get('approveabstractid') || '';
  const [actionColWidth, actionColShowButtons] = getActionColWidth();

  // When the navUrl changes it means the left menu was clicked - we want to reset the component state
  // NOTE: this should be defined before the other useEffect so it runs first and can be overridden
  useEffect(() => {
    // This is almost the same as showList but we don't want to change the URL (as this may be shown inside of Dashboard)
    setSelectedSymposiumId(null);
    setMode(MODE_LIST);
  }, [navChanged]);

  // Check for parameters (to pre-load a particular symposium in view/edit mode)
  useEffect(() => {
    if (paramMode === MODE_EDIT && symposiumId) {
      showEdit(symposiumId);
    } else if (paramMode === MODE_VIEW && symposiumId) {
      showView(symposiumId);
    } else if (paramMode === MODE_APPROVE && symposiumId) {
      showApprove(symposiumId, approveAbstractId);
    } else if (paramMode === MODE_SCHEDULE && symposiumId) {
      showSchedule(symposiumId);
    }
    reloadSymposiumList();
  }, [paramMode, symposiumId, approveAbstractId]);

  // Returns [actionColWidth, actionColShowButtons]
  function getActionColWidth() {
    const canEditAny = (filteredSymposiumList || []).some(s => s.can_edit);
    const canArchiveAny = isStaffOrAdmin;
    const canApproveAny = (filteredSymposiumList || []).some(s => s.can_approve_abstracts);
    const canScheduleAny = (filteredSymposiumList || []).some(s => s.can_schedule);
    let actionColShowButtons = (!canEditAny && !canArchiveAny && !canApproveAny && !canScheduleAny) || (windowWidth > 1500);
    let actionColWidth = 150;
    if (actionColShowButtons) {
      // Calculate width to fit all the buttons
      actionColWidth = 90 + (canEditAny ? 50 : 0) + (canArchiveAny ? 100 : 0) + (canApproveAny ? 80 : 0) + (canScheduleAny ? 90 : 0);
    }
    return [actionColWidth, actionColShowButtons];
  }

  // Reload all the user data (after we know something changed)
  function reloadSymposiumList() {
    getSymposia(userId, (code, data, errors) => {
      if (code === 200) {
        setSymposiumList(data);
        setStatusCounts(calcSymposiumStatusCounts(data));
      }
    });
  }

  function showList() {
    setSelectedSymposiumId(null);
    setMode(MODE_LIST);
    navigate(`/symposium-list?mode=${MODE_LIST}`, {replace: true});
  }

  function showEdit(symposiumId) {
    if (!showHeader) {
      // We are in an embedded view (use a link to jump to the symposium list)
      navigate("/symposium-list?mode=" + MODE_EDIT + "&symposiumid=" + symposiumId);
    } else {
      setSelectedSymposiumId(symposiumId);
      setMode(MODE_EDIT);
      navigate(`/symposium-list?mode=${MODE_EDIT}&symposiumid=${symposiumId}`, {replace: true});
    }
  }

  function showView(symposiumId) {
    if (!showHeader) {
      // We are in an embedded view (use a link to jump to the symposium list)
      navigate("/symposium-list?mode=" + MODE_VIEW + "&symposiumid=" + symposiumId);
    } else {
      setSelectedSymposiumId(symposiumId);
      setMode(MODE_VIEW);
      navigate(`/symposium-list?mode=${MODE_VIEW}&symposiumid=${symposiumId}`, {replace: true});
    }
  }


  function showApprove(symposiumId, approveAbstractId = null) {
    if (!showHeader) {
      // We are in an embedded view (use a link to jump to the symposium list)
      navigate("/symposium-list?mode=" + MODE_APPROVE + "&symposiumid=" + symposiumId);
    } else {
      if (approveAbstractId) {
        getAbstract(approveAbstractId, (code, data, errors) => {
          if (code === 200) {
            setAbstracts([data]);
            setMode(MODE_APPROVE);
            navigate(`/symposium-list?mode=${MODE_APPROVE}&symposiumid=${symposiumId}&approveabstractid=${approveAbstractId}`, {replace: true});
          } else {
            handleError(errors);
          }
        });
      } else {
        getAbstracts(symposiumId, null, (code, data, errors) => {
          if (code === 200) {
            // Remove archived abstracts
            const abstracts = data.filter(a => !a.archived);
            setAbstracts(abstracts);
            setMode(MODE_APPROVE);
            navigate(`/symposium-list?mode=${MODE_APPROVE}&symposiumid=${symposiumId}`, {replace: true});
          } else {
            handleError(errors);
          }
        });
      }
    }
  }

  function showSchedule(symposiumId) {
    if (!showHeader) {
      // We are in an embedded view (use a link to jump to the schedule screen)
      navigate("/symposium-list?mode=" + MODE_SCHEDULE + "&symposiumid=" + symposiumId);
    } else {
      setSelectedSymposiumId(symposiumId);
      setMode(MODE_SCHEDULE);
      navigate(`/symposium-list?mode=${MODE_SCHEDULE}&symposiumid=${symposiumId}`, {replace: true});
    }
  }

  function setSymposiumArchived(symposiumId, archived) {
    setArchivedStatusForSymposium(symposiumId, archived, (code, data, errors) => {
      if (code === 200) {
        reloadSymposiumList();
      }
    });
  }

  function showUploadDialog() {
    setShowingUploadDialog(true);
  }

  function beginFileUpload(e) {
    e.preventDefault();
    const url = "symposium/upload/excel/";
    uploadFile(url, 'symposium', fileToUpload, null, (code, data, errors) => {
      // The "errors" are success messages as well... there should always be some sort of message
      reloadSymposiumList();
      setErrors(errors);
    });
  }

  function resetFileUpload() {
    setErrors([]);
    setFileToUpload(null);
    setShowingUploadDialog(false);
  }

  // Resize the grid to fit the screen
  const onGridReady = useCallback((params) => {
    // What a strange and difficult way to set the default sort!
    const columnState = {
      state: [
        {colId: 'symposium_number', sort: 'asc', sortIndex: 1},
        {colId: 'id', sort: 'asc', sortIndex: 2}]
    }
    params.api.applyColumnState(columnState);
  }, []);

  // Column setup
  const colDefs = [
    {field: 'id', headerName: 'ID', headerTooltip: 'ID', width: 80, minWidth: 60},
    {
      field: 'symposium_number',
      headerName: 'Number',
      headerTooltip: 'Symposium Number',
      width: 80, minWidth: 80,
      comparator: naturalSort,
    },
    {
      field: 'title',
      cellRenderer: toolTipRenderer,
      headerName: 'Title',
      minWidth: 140, flex: 5,
      comparator: naturalSort,
    },
    ...(conf?.use_emphasis_area ? [
      {
        field: 'emphasis_area',
        cellRenderer: toolTipRenderer,
        headerName: emphasisAreaLabel,
        minWidth: 100, flex: 3,
        comparator: naturalSort,
      }
    ] : []),
    {
      field: 'create_user_name',
      cellRenderer: toolTipRenderer,
      headerName: 'Submitter',
      minWidth: 100, flex: 3,
      comparator: naturalSort,
    },
    {
      headerName: 'Main Organizer',
      minWidth: 120, flex: 3,
      cellRenderer: params => {
        const names = getOrganizerName(getFirstMainOrganizer(params.data));
        return <span title={names}>{names}</span>;
      },
      getQuickFilterText: params => {
        return getOrganizerName(getFirstMainOrganizer(params.data));
      },
      comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
        return naturalSortValues(
          getOrganizerName(getFirstMainOrganizer(nodeA.data)),
          getOrganizerName(getFirstMainOrganizer(nodeB.data)),
          isInverted);
      },
    },
    {field: 'abstract_count', headerName: 'Abstracts', headerTooltip: 'Abstract Count', width: 60},
    {
      headerName: 'Status',
      filter: false,
      minWidth: 80, width: 80,
      cellRenderer: params => {
        return getIconForSymposiumStatus(conf, params.data);
      },
      getQuickFilterText: params => {
        return getNameForSymposiumStatus(conf, params.data);
      },
      comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
        return scoreSymposiumStatus(nodeA.data) - scoreSymposiumStatus(nodeB.data);
      },
    },
    {
      headerName: 'Action',
      sortable: false,
      filter: false,
      minWidth: actionColWidth,
      maxWidth: actionColWidth,
      cellRenderer: params => {
        const symposium = params.data;
        const symposiumId = symposium.id;
        if (actionColShowButtons) {
          return <Fragment>
            <Button type="button" onClick={() => showView(symposiumId)} size="sm" className="">View</Button>
            {params.data.can_edit &&
              <Button type="button" onClick={() => showEdit(symposiumId)} size="sm" className="ms-2">Edit</Button>}
            {isStaffOrAdmin && !symposium.archived &&
              <Button type="button" onClick={() => setSymposiumArchived(symposiumId, true)} size="sm" className="ms-2">Archive</Button>
            }
            {isStaffOrAdmin && symposium.archived &&
              <Button type="button" onClick={() => setSymposiumArchived(symposiumId, false)} size="sm" className="ms-2">UnArchive</Button>
            }
            {params.data.can_approve_abstracts &&
              <Button type="button" onClick={() => showApprove(symposiumId)} size="sm" className="ms-2">Approve</Button>}
            {params.data.can_schedule &&
              <Button type="button" onClick={() => showSchedule(symposiumId)} size="sm" className="ms-2">Schedule</Button>}
          </Fragment>;
        } else {
          // showing dropdown
          return <Fragment>
            <Form.Control
              className='form-select'
              as='select'
              defaultValue=''
              onChange={e => {
                const action = e.target.value;
                if (action === "view") {
                  showView(symposiumId);
                }
                if (action === "edit") {
                  showEdit(symposiumId);
                }
                if (action === "archive") {
                  setSymposiumArchived(symposiumId, true);
                }
                if (action === "unarchive") {
                  setSymposiumArchived(symposiumId, false);
                }
                if (action === "approve") {
                  showApprove(symposiumId);
                }
                if (action === "schedule") {
                  showSchedule(symposiumId);
                }
              }}
            >
              <option value=''>Choose...</option>
              <option value='view'>View</option>
              {params.data.can_edit && <option value='edit'>Edit</option>}
              {isStaffOrAdmin && !symposium.archived && <option value='archive'>Archive</option>}
              {isStaffOrAdmin && symposium.archived && <option value='unarchive'>UnArchive</option>}
              {params.data.can_approve_abstracts && <option value='approve'>Approve</option>}
              {params.data.can_schedule && <option value='schedule'>Schedule</option>}
            </Form.Control>
          </Fragment>;
        }
      },
    }
  ];

  const defaultColDefs = {
    sortable: true,
    filter: false,
    resizable: true,
  }

  return (
    <div>
      {showHeader && <PageHeader pageTitle={symposiumLabel + " List"}/>}
      <Container fluid className="usacm-container-wide" style={{display: mode === MODE_LIST ? 'block' : 'none'}}>

        <TableFilter conf={conf}
                     mode={TABLE_FILTER_MODE_SYMPOSIUM}
                     showFilters={showHeader}
                     filters={filters}
                     setFilters={setFilters}
                     showDownload={showHeader}
                     downloadUrl={userId ? `symposium/list/user/${userId}/excel/` : 'symposium/list/excel/'}
                     downloadFilename={excelFilename}
                     gridApi={gridRef?.current?.api}
                     statusCounts={statusCounts}
                     onSearchChanged={null}
                     showOtherButton={showHeader}
                     otherButtonText={isStaffOrAdmin ? "Import" : null}
                     otherButtonOnclick={showUploadDialog}
        />

        <Row>
          <Col>
            <div className="ag-theme-alpine" style={{width: '100%', ...(hasFewRows ? {} : {height: '700px'})}}>
              <AgGridReact
                ref={gridRef}
                rowData={filteredSymposiumList}
                columnDefs={colDefs}
                defaultColDef={defaultColDefs}
                gridOptions={{enableCellTextSelection: true, ensureDomOrder: true}} // enable cell selection
                onGridReady={onGridReady}
                domLayout={hasFewRows ? "autoHeight" : "normal"}
                getRowId={(params) => params.data.id} // prevent re-rendering the whole table when data changes
              />
            </div>
          </Col>
        </Row>

        <Modal show={showingUploadDialog}
               onHide={() => resetFileUpload()}
               size="lg">
          <Form onSubmit={beginFileUpload}>
            <Modal.Header closeButton>
              <Modal.Title>Upload {makePlural(symposiumLabel)}</Modal.Title>
            </Modal.Header>
            {errors.length === 0 &&
              <Fragment>
                <Modal.Body>
                  <Form.Control
                    type="file"
                    id="schedule"
                    name="schedule"
                    label="Choose file to upload..."
                    onChange={e => setFileToUpload(e.target.files[0])}
                  />
                  <div>
                    <span className="fw-bold mt-3 me-2 d-inline-block">Note:</span>
                    This will update the {symposiumLabel} number, and the approved/archived status along with sending an email for
                    all {makePlural(symposiumLabel)} with 'Y'.
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={() => resetFileUpload()}>Cancel</Button>
                  <Button variant="primary" type="submit">Upload</Button>
                </Modal.Footer>
              </Fragment>
            }
            {errors.length > 0 &&
              <Fragment>
                <Modal.Body>
                  <div className="mt-2">
                    Your file was uploaded. The following is a list of the actions taken and errors encountered.
                  </div>
                  {errors.map(error =>
                    <div className={(error.type === "success" ? '' : 'usacm-error-message ') + 'mt-3'}
                         key={error.message}>{error.message}</div>
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={() => resetFileUpload()}>Close</Button>
                </Modal.Footer>
              </Fragment>
            }
          </Form>
        </Modal>
      </Container>

      {mode === MODE_EDIT &&
        <SymposiumEdit symposiumId={selectedSymposiumId} onClose={() => showList()}
                       reloadSymposiumList={reloadSymposiumList}/>
      }

      {mode === MODE_VIEW && selectedSymposiumId &&
        <SymposiumView symposiumId={selectedSymposiumId} onClose={() => showList()}/>
      }

      {mode === MODE_APPROVE && abstracts &&
        <AbstractApprove abstracts={abstracts} onClose={() => showList()}/>
      }

      {mode === MODE_SCHEDULE &&
        <ScheduleAbstract symposiumId={selectedSymposiumId} onClose={() => showList()}/>
      }

    </div>
  );

}
