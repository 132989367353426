import {Col, Row} from "react-bootstrap";
import React, {Fragment} from "react";
import {
  FIELD_TYPE_ABSTRACT,
  FIELD_TYPE_ADDRESS,
  FIELD_TYPE_BOOLEAN,
  FIELD_TYPE_CHOICELIST,
  FIELD_TYPE_DATE,
  FIELD_TYPE_FILE_UPLOAD,
  FIELD_TYPE_LABEL,
  FIELD_TYPE_NUMBER,
  FIELD_TYPE_SECTION,
  FIELD_TYPE_TEXT
} from "../constants";
import {TextFieldInput} from "./fields/TextFieldInput";
import {ChoicelistFieldInput} from "./fields/ChoicelistFIeldInput";
import {LabelFieldInput} from "./fields/LabelFieldInput";
import {NumberFieldInput} from "./fields/NumberFieldInput";
import {BooleanFieldInput} from "./fields/BooleanFIeldInput";
import {DateFieldInput} from "./fields/DateFieldInput";
import {AbstractFieldInput} from "./fields/AbstractFieldInput";
import {FileUploadFieldInput} from "./fields/FileUploadFieldInput";
import {AddressFieldInput} from "./fields/AddressFieldInput";

export function RegFieldSectionInput({parentField, fields, setFields, setPay}) {
  const sectionFields = fields.filter(f => f.parent_field_id === (parentField ? parentField.id : null));

  function getFieldAnswerJsx(field) {
    if (field.field_type === FIELD_TYPE_TEXT) {
      return <TextFieldInput field={field} setFields={setFields} setPay={setPay}/>;
    }
    if (field.field_type === FIELD_TYPE_CHOICELIST) {
      return <ChoicelistFieldInput field={field} setFields={setFields} setPay={setPay}/>;
    }
    if (field.field_type === FIELD_TYPE_NUMBER) {
      return <NumberFieldInput field={field} setFields={setFields} setPay={setPay}/>;
    }
    if (field.field_type === FIELD_TYPE_BOOLEAN) {
      return <BooleanFieldInput field={field} setFields={setFields} setPay={setPay}/>;
    }
    if (field.field_type === FIELD_TYPE_SECTION) {
      return <div className='section-child'>
        <div className="section-header"> {field.name} </div>
        <div className='section-child-content'>
          <RegFieldSectionInput parentField={field} fields={fields} setFields={setFields} setPay={setPay}/>
        </div>
        <div className='section-child-footer'/>
      </div>;
    }
    if (field.field_type === FIELD_TYPE_LABEL) {
      return <LabelFieldInput field={field}/>;
    }
    if (field.field_type === FIELD_TYPE_DATE) {
      return <DateFieldInput field={field} setFields={setFields} setPay={setPay}/>;
    }
    if (field.field_type === FIELD_TYPE_ABSTRACT) {
      return <AbstractFieldInput field={field} setFields={setFields} setPay={setPay}/>;
    }
    if (field.field_type === FIELD_TYPE_FILE_UPLOAD) {
      return <FileUploadFieldInput field={field} setFields={setFields} setPay={setPay}/>;
    }
    if (field.field_type === FIELD_TYPE_ADDRESS) {
      return <AddressFieldInput field={field} setFields={setFields} setPay={setPay}/>;
    }
    console.warn('RegFieldSectionInput cannot handle field_type ' + field.field_type);
    return <Fragment/>;
  }

  return (
    <div className="section-container">
      {sectionFields.map((field, index) =>
        <Fragment key={field.id}>
          {field.visible &&
            <Row className="mb-3">
              <Col>
                {getFieldAnswerJsx(field)}
              </Col>
            </Row>
          }
        </Fragment>
      )}
    </div>
  );

}
