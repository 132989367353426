import {Button, Col, Container, Form, Row} from "react-bootstrap";
import React, {useContext, useEffect, useRef, useState} from "react";
import {
  CONF_LABEL_KEY_EMPHASIS_AREA,
  CONF_LABEL_KEY_SECONDARY_EMPHASIS_AREA,
  CONF_LABEL_KEY_SYMPOSIUM,
  CONF_LABEL_TYPE_SYMPOSIUM_STATUS,
  PERMISSION_CONF_ADMIN,
  PERMISSION_STAFF,
  SYMPOSIUM_STATUS_APPROVED
} from "../constants";
import {getSymposium, updateSymposium} from "../api/SymposiumApi";
import {getConfLabel, sentenceCase, showErrorToast, showSuccessToast, titleCase} from "../utils/usacmUtils";
import {UsacmContext} from "../App";
import {anyFieldHasErrors, fieldHasErrors, getErrorMessageForAllFields, getErrorMessageForField} from "../utils/formUtils";
import {HtmlEditor} from "../shared/HtmlEditor";
import {AuthorOrganizerEditor, TYPE_ORGANIZER} from "../shared/AuthorOrganizerEditor";
import {hasPermission} from "../api/UserApi";
import {getLabelKeys, getLabelsOfType, getNameForSymposiumStatus} from "../utils/displayUtils";
import {ForceUpdateButton} from "../shared/ForceUpdateButton";

export function SymposiumEdit({symposiumId, onClose, reloadSymposiumList}) {
  const isStaffOrAdmin = hasPermission(PERMISSION_STAFF) || hasPermission(PERMISSION_CONF_ADMIN);
  const context = useContext(UsacmContext);
  const [conf,] = context.conference;
  const [symposiumData, setSymposiumData] = useState(null);
  const [status, setStatus] = useState('');
  const statusKeys = getLabelKeys(conf, CONF_LABEL_TYPE_SYMPOSIUM_STATUS);
  const statusLabels = getLabelsOfType(conf, CONF_LABEL_TYPE_SYMPOSIUM_STATUS);
  const [symposiumNumber, setSymposiumNumber] = useState('');
  const [title, setTitle] = useState('');
  const editorRef = useRef(null);
  const [emphasisArea, setEmphasisArea] = useState('');
  const [secondaryEmphasisArea, setSecondaryEmphasisArea] = useState('');
  const [organizers, setOrganizers] = useState([]);
  const organizerEditorRef = useRef(null);
  const [adminNotes, setAdminNotes] = useState('');
  const [errors, setErrors] = useState([]);
  const symposiumLabel = titleCase(getConfLabel(conf, CONF_LABEL_KEY_SYMPOSIUM));
  const emphasisAreasLabel = titleCase(getConfLabel(conf, CONF_LABEL_KEY_EMPHASIS_AREA));
  const secondaryEmphasisAreasLabel = titleCase(getConfLabel(conf, CONF_LABEL_KEY_SECONDARY_EMPHASIS_AREA));
  const showApproveButton = status === SYMPOSIUM_STATUS_APPROVED && !symposiumData?.approved_email_sent && !symposiumData?.archived;
  const showEmphasisArea = conf?.use_emphasis_area && (conf?.emphasis_areas || []).length > 0;
  const showSecondaryEmphasisArea = showEmphasisArea && conf?.use_secondary_emphasis_area;

  function loadSymposiumData() {
    getSymposium(symposiumId, (code, data, errors) => {
      if (code === 200) {
        setSymposiumData(data);
        setStatus(data.status);
        setSymposiumNumber(data.symposium_number);
        setTitle(data.title);
        setEmphasisArea(data.emphasis_area);
        setSecondaryEmphasisArea(data.secondary_emphasis_area || '');
        setOrganizers(data.organizers);
        if (editorRef.current) {
          editorRef.current.setContent(data.description);
        }
        setAdminNotes(data.admin_notes);
      } else {
        setErrors(errors);
      }
    });
  }

  useEffect(() => {
    loadSymposiumData();
  }, [symposiumId]);

  function callUpdateSymposium(sendApprovalEmail, force = false) {
    if (!organizerEditorRef.current.hasValidOrganizers()) {
      // Could not validate organizers - some errors will be on the page.
      return;
    }
    if (!editorRef.current) {
      showErrorToast('Unable to update symposium as there is no HTML editor loaded');
      return;
    }
    const description = editorRef.current.getContent();
    updateSymposium(force, symposiumId, status, sendApprovalEmail, symposiumNumber, title, description,
      emphasisArea, secondaryEmphasisArea, organizers, adminNotes,
      (code, data, errors) => {
        if (code === 200) {
          showSuccessToast('Updated ' + symposiumLabel + '.');
          // Update the parent list data (values have changed)
          reloadSymposiumList();
          setErrors([]);
          onClose();
        } else {
          setErrors(errors);
        }
      });
  }


  return (
    <Container fluid className="usacm-container-wide">

      <Row className="mb-3">
        <Col className="col-12">
          Status
          {isStaffOrAdmin && !symposiumData?.approved_email_sent &&
            <Form.Group controlId="formBasicSelect" style={{maxWidth: '200px'}}>
              <Form.Control
                className="form-select"
                as="select"
                value={status || ''}
                onChange={e => setStatus(e.target.value)}
              >
                {statusKeys.map(s => {
                  return <option value={s} key={s}>{statusLabels[s]}</option>
                })}
              </Form.Control>
            </Form.Group>
          }
          {(!isStaffOrAdmin || symposiumData?.approved_email_sent) &&
            <span className="ms-3 fw-bold">
              {getNameForSymposiumStatus(conf, symposiumData)}
            </span>}
        </Col>
      </Row>

      <Row className="mb-3">
        <Col className="col-12">
          Number
          {isStaffOrAdmin &&
            <Form.Group controlId="symposium_number" style={{maxWidth: '200px'}}>
              <Form.Control type="text"
                            placeholder=''
                            required
                            name="symposium_number"
                            value={symposiumNumber || ''}
                            onChange={e => setSymposiumNumber(e.target.value)}
                            isInvalid={fieldHasErrors(errors, 'symposium_number')}/>
              <Form.Control.Feedback type="invalid">
                {getErrorMessageForField(errors, 'symposium_number')}
              </Form.Control.Feedback>
            </Form.Group>
          }
          {!isStaffOrAdmin && <span className="ms-3 fw-bold">{symposiumNumber}</span>}
        </Col>
      </Row>

      <Row className="mb-3">
        <Col className="col-12">
          Title of the {symposiumLabel}
          {isStaffOrAdmin &&
            <Button onClick={() => setTitle(sentenceCase(title))} size="sm" className="ms-3 mb-1">Use Sentence Case</Button>
          }
          <Form.Group controlId="title">
            <Form.Control type="text"
                          placeholder=''
                          required
                          name="title"
                          value={title || ''}
                          onChange={e => setTitle(e.target.value)}
                          isInvalid={fieldHasErrors(errors, 'title')}/>
            <Form.Control.Feedback type="invalid">
              {getErrorMessageForField(errors, 'title')}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col>
          <Form.Group controlId="description">
            <div>Description of the {symposiumLabel}</div>
            <div style={fieldHasErrors(errors, 'description') ? {border: '1px solid red'} : {}}>
              <HtmlEditor
                initialValue={symposiumData?.description || ''}
                onInit={(evt, editor) => editorRef.current = editor}
              />
            </div>
            {fieldHasErrors(errors, 'description') &&
              <div className="text-center mb-3 usacm-error-message">
                {getErrorMessageForField(errors, 'description')}
              </div>
            }
          </Form.Group>
        </Col>
      </Row>

      {showEmphasisArea &&
        <Row className="mb-3">
          <Col className="col-12">
            {emphasisAreasLabel}
            <Form.Group controlId="formBasicSelect" style={{maxWidth: '400px'}}>
              <Form.Control
                className="form-select"
                as="select"
                value={emphasisArea || ''}
                onChange={e => setEmphasisArea(e.target.value)}
                isInvalid={fieldHasErrors(errors, 'emphasis_area')}
              >
                {[''].concat(conf?.emphasis_areas)?.map(ea => {
                  return <option value={ea} key={ea}>{ea === '' ? 'Choose...' : ea}</option>
                })}
              </Form.Control>
              {fieldHasErrors(errors, 'emphasis_area') &&
                <div className="text-center mb-3 usacm-error-message">
                  {getErrorMessageForField(errors, 'emphasis_area')}
                </div>
              }
            </Form.Group>
          </Col>
        </Row>
      }

      {showSecondaryEmphasisArea &&
        <Row className="mb-3">
          <Col className="col-12">
            {secondaryEmphasisAreasLabel}
            <Form.Group controlId="formBasicSelect" style={{maxWidth: '400px'}}>
              <Form.Control
                className="form-select"
                as="select"
                value={secondaryEmphasisArea || ''}
                onChange={e => setSecondaryEmphasisArea(e.target.value)}
              >
                {[''].concat(conf?.emphasis_areas)?.map(ea => {
                  return <option value={ea} key={ea}>{ea === '' ? 'Choose...' : ea}</option>
                })}
              </Form.Control>
              {fieldHasErrors(errors, 'secondary_emphasis_area') &&
                <div className="text-center mb-3 usacm-error-message">
                  {getErrorMessageForField(errors, 'secondary_emphasis_area')}
                </div>
              }
            </Form.Group>
          </Col>
        </Row>
      }

      <AuthorOrganizerEditor
        ref={organizerEditorRef}
        organizers={organizers}
        setOrganizers={setOrganizers}
        errors={errors}
        setErrors={setErrors}
        type={TYPE_ORGANIZER}
      />

      {isStaffOrAdmin &&
        <Row>
          <Col className="mb-3">
            <div>Admin Notes</div>
            <Form.Group controlId="text">
              <Form.Control as="textarea"
                            rows={6}
                            placeholder="Enter admin notes here..."
                            name="admin_notes"
                            value={adminNotes || ''}
                            onChange={e => setAdminNotes(e.target.value)}
                            isInvalid={fieldHasErrors(errors, 'admin_notes')}/>
              <Form.Control.Feedback type="invalid">
                {getErrorMessageForField(errors, 'admin_notes')}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
      }

      {anyFieldHasErrors(errors, ['', 'symposium_id']) &&
        <Row>
          <Col className="text-center mb-3 usacm-error-message">
            {getErrorMessageForAllFields(errors, ['', 'symposium_id'])}
          </Col>
        </Row>
      }

      <Row>
        <Col className="usacm-button-row">
          <Button variant="secondary" onClick={() => onClose()}>{symposiumLabel} List</Button>
          <Button onClick={() => callUpdateSymposium(false, false)}>Update {symposiumLabel}</Button>
          {showApproveButton && <Button onClick={() => callUpdateSymposium(true, true)}>Update and Send Approval Email</Button>}
          <ForceUpdateButton errors={errors} onClick={() => callUpdateSymposium(false, true)}/>
        </Col>
      </Row>

    </Container>
  );
}

