import {Button, Col, Container, Modal, Row} from "react-bootstrap";
import React, {Fragment, useState} from "react";
import {FIELD_TYPE_SECTION, FIELD_TYPE_TEXT, FIELD_TYPES, PERMISSION_CONF_ADMIN, PERMISSION_STAFF} from "../constants";
import {fieldHasErrors, getErrorMessageForField} from "../utils/formUtils";
import {deleteField, swapFieldOrder} from "../api/RegApi";
import {deepCopy} from "../utils/usacmUtils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {hasPermission} from "../api/UserApi";

const NEW_FIELD_DATA = {
  id: null,
  reg: null,
  parent_field_id: null,
  name: '',
  help_text: '',
  field_type: FIELD_TYPE_TEXT,
  required: false,
  visible: true,
  disabled: false,
  config: {},
  field_order: 0,
};

export function RegFieldSectionEdit({parentFieldId, parentFieldName, fields, setSelectedFieldData, loadTemplateFields}) {
  const [errors, setErrors] = useState([]);
  const [showingDeleteFieldConfirm, setShowingDeleteFieldConfirm] = useState(false);
  const [selectedDeleteField, setSelectedDeleteField] = useState(null);
  const sectionFields = fields.filter(f => f.parent_field_id === parentFieldId);
  const isStaffOrAdmin = hasPermission(PERMISSION_STAFF) || hasPermission(PERMISSION_CONF_ADMIN);

  function createNewFieldId() {
    if (!sectionFields || !sectionFields.length) {
      return 1;
    }
    return Math.max(...sectionFields.map(f => f.field_order || 0)) + 1
  }

  function addField(isSection) {
    const newFieldData = deepCopy(NEW_FIELD_DATA);
    newFieldData.parent_field_id = parentFieldId;
    newFieldData.field_order = createNewFieldId();
    if (isSection) {
      newFieldData.field_type = FIELD_TYPE_SECTION;
    }
    setSelectedFieldData(newFieldData);
  }

  function deleteFieldConfirm(field) {
    setShowingDeleteFieldConfirm(true);
    setSelectedDeleteField(field);
  }

  function callDeleteField() {
    setErrors([]);
    deleteField(selectedDeleteField.id, (status, data, newErrors) => {
      if (status === 200) {
        loadTemplateFields();
      } else {
        setErrors(newErrors);
      }
    });
    setSelectedDeleteField(null);
    setShowingDeleteFieldConfirm(false);
  }

  function callSwapFieldOrder(indexA, indexB) {
    const fieldA = sectionFields[indexA];
    const fieldB = sectionFields[indexB];
    if (!fieldA || !fieldB) {
      console.warn('Unable to swap... cannot find items at index a=', indexA, ' b=', indexB);
      return;
    }
    swapFieldOrder(fieldA.id, fieldB.id, (status, data, newErrors) => {
      if (status === 200) {
        loadTemplateFields();
      } else {
        setErrors(newErrors);
      }
    });
  }

  return (
      <div className='border border-1 mt-3'>
        <Container fluid className="usacm-container-medium">

          <Row className="mb-3">
            <Col className="fw-bold text-center">
              Fields {parentFieldId ? 'in ' + parentFieldName : ''}
            </Col>
          </Row>

          {sectionFields.map((field, index) =>
              <Row key={field.id} className="mb-3">
                <Col>
                  {isStaffOrAdmin &&
                  <Fragment>
                    <Button type="buttn" size="sm" className="me-2" onClick={() => deleteFieldConfirm(field)}>Delete</Button>
                    <Button type="button" size="sm" className="me-2" onClick={() => setSelectedFieldData(field)}>Edit</Button>
                    <Button type="button" size="sm" className="me-1"
                            onClick={() => callSwapFieldOrder(index, index - 1)}
                            disabled={index === 0}>
                      <FontAwesomeIcon icon="fa-angle-up"/>
                    </Button>
                    <Button type="button" size="sm" className="me-3"
                            onClick={() => callSwapFieldOrder(index, index + 1)}
                            disabled={index + 1 === sectionFields.length}>
                      <FontAwesomeIcon icon="fa-angle-down"/>
                    </Button>
                  </Fragment>
                  }
                  {field.name} ({FIELD_TYPES.filter(ft => ft.field_type === field.field_type)[0].display}, {field.field_key})

                  {field.field_type === FIELD_TYPE_SECTION &&
                  <RegFieldSectionEdit parentFieldId={field.id}
                                       parentFieldName={field.name}
                                       fields={fields}
                                       setSelectedFieldData={setSelectedFieldData}
                                       loadTemplateFields={loadTemplateFields}/>
                  }
                </Col>
              </Row>
          )}

          {fieldHasErrors(errors, '') &&
          <Row className="mb-3">
            <Col className="text-center usacm-error-message">
              {getErrorMessageForField(errors, '')}
            </Col>
          </Row>
          }

          {isStaffOrAdmin &&
          <Row>
            <Col className="text-center">
              <Button type="button" className="ms-3 me-3" onClick={() => addField(false)}>Add Field</Button>
              <Button type="button" className="ms-3 me-3" onClick={() => addField(true)}>Add Section</Button>
            </Col>
          </Row>
          }
        </Container>

        <Modal show={showingDeleteFieldConfirm}
               onHide={() => setShowingDeleteFieldConfirm(false)}
               size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Confirm Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to delete the field "{selectedDeleteField?.name}"?</p>
            <p className="mt-2">This will not remove the field from any registrations that have already been started.</p>
            {selectedDeleteField?.field_type === FIELD_TYPE_SECTION &&
            <p className="mt-2" style={{color: 'red'}}>This will delete all fields within this section.</p>
            }
            <p>This will delete all actions that use this field.</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowingDeleteFieldConfirm(false)}>Cancel</Button>
            <Button variant="primary"
                    onClick={() => callDeleteField()}>Delete {selectedDeleteField?.field_type === FIELD_TYPE_SECTION ? 'Section' : 'Field'}</Button>
          </Modal.Footer>
        </Modal>

      </div>
  );

}
