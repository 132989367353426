import {Col, FloatingLabel, Form, Row} from "react-bootstrap";
import React, {Fragment, useState} from "react";

export function TextFieldEditor({config}) {
  const [refresh, setRefresh] = useState(0);
  // Setup default values
  if (typeof config.long_text === "undefined") {
    config.long_text = false;
  }
  if (typeof config.row_count === "undefined") {
    config.row_count = '5';
  }

  function updateLongText(longText) {
    config.long_text = longText;
    setRefresh(refresh + 1);
  }

  function updateRowCount(newCount) {
    config.row_count = newCount;
    setRefresh(refresh + 1);
  }

  return (
      <Fragment>
        <Row className="mb-3">
          <Col className="col-12 mt-2">
            <Form.Check
                type="checkbox"
                label="Long Text (multi-line)"
                id='long-text'
                checked={config.long_text || false}
                onChange={e => updateLongText(e.target.checked)}
            />
          </Col>
        </Row>

        {config?.long_text &&
        <Row className="mb-3">
          <Col className="col-12 mt-2">
            <Form.Group controlId="row-count">
              <FloatingLabel controlId="row-count" label="Number of Rows Visible">
                <Form.Control type="number"
                              placeholder="Number of Rows Visible"
                              name="row_count"
                              value={config.row_count || '5'}
                              onChange={e => updateRowCount(e.target.value)}/>
              </FloatingLabel>
            </Form.Group>
          </Col>
        </Row>
        }
      </Fragment>
  );
}

