import {Button, Col, Container, Modal, Row} from "react-bootstrap";
import {PageHeader} from "../menu/PageHeader";
import {AgGridReact} from "ag-grid-react";
import React, {useEffect, useRef, useState} from "react";
import {deleteConf, getAllConf} from "../api/ConfApi";
import {useNavigate} from "react-router";
import {toolTipRenderer} from "../utils/gridUtils";
import {showSuccessToast} from "../utils/usacmUtils";
import {getErrorMessages} from "../utils/networkUtils";

export function ConfList() {
  const [confList, setConfList] = useState([]);
  const hasFewRows = (confList || []).length < 12;
  const gridRef = useRef();
  const navigate = useNavigate();
  const [deleteConfId, setDeleteConfId] = useState(null);
  const [showingDeleteConfirm, setShowingDeleteConfirm] = useState(false);
  const [errors, setErrors] = useState([]);

  function reloadConfList() {
    getAllConf((code, data, errors) => {
      if (code === 200) {
        setConfList(data);
      }
    });
  }

  // Check for parameters (to pre-load a particular symposium in view/edit mode)
  useEffect(() => {
    reloadConfList();
  }, []);

  function showDeleteConfirm(confId) {
    setDeleteConfId(confId);
    setShowingDeleteConfirm(true);
    setErrors([]);
  }

  function callDeleteConf() {
    if (deleteConfId) {
      setErrors([]);
      deleteConf(deleteConfId, (code, data, errors) => {
        if (code === 200) {
          reloadConfList();
          showSuccessToast('Conference deleted.');
          setShowingDeleteConfirm(false);
        } else {
          setErrors(errors);
        }
      });
    }
    setDeleteConfId(null);
  }

  // Column setup
  const colDefs = [
    {field: 'id', cellRenderer: toolTipRenderer, headerName: 'ID', minWidth: 80, maxWidth: 80},
    {field: 'host', cellRenderer: toolTipRenderer, headerName: 'Host', flex: 1, minWidth: 160},
    {field: 'url', cellRenderer: toolTipRenderer, headerName: 'URL', flex: 2, minWidth: 200},
    {field: 'short_name', cellRenderer: toolTipRenderer, headerName: 'Short Name', flex: 1, minWidth: 120},
    {field: 'long_name', cellRenderer: toolTipRenderer, headerName: 'Long Name', flex: 2, minWidth: 120},
    {
      headerName: 'Action',
      sortable: false,
      filter: false,
      maxWidth: 160,
      minWidth: 160,
      cellRenderer: params => {
        const conf = params.data;
        return <Button type="button"
                       variant="danger"
                       size="sm"
                       className="me-2"
                       onClick={() => showDeleteConfirm(conf.id)}
        > Delete </Button>;
      },
    },
  ];

  const defaultColDefs = {
    sortable: true,
    filter: false,
    resizable: true,
  }

  return (
    <div>
      <PageHeader pageTitle="Conference List"/>
      <Container fluid className="usacm-container-wide">
        <Row>
          <Col>
            <div className="ag-theme-alpine" style={{width: '100%', ...(hasFewRows ? {} : {height: '700px'})}}>
              <AgGridReact
                ref={gridRef}
                rowData={confList}
                columnDefs={colDefs}
                defaultColDef={defaultColDefs}
                gridOptions={{enableCellTextSelection: true, ensureDomOrder: true}} // enable cell selection
                domLayout={hasFewRows ? "autoHeight" : "normal"}
                getRowId={(params) => params.data.id} // prevent re-rendering the whole table when data changes
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col className="usacm-button-row mt-3">
            <Button type="button" onClick={() => navigate('/create-conf')}>Create Conference</Button>
          </Col>
        </Row>
      </Container>

      <Modal show={showingDeleteConfirm}
             onHide={() => setShowingDeleteConfirm(false)}
             size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete {confList?.find(c => c.id === deleteConfId)?.short_name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className='mt-1'>Are you sure you want to delete the conference {confList?.find(c => c.id === deleteConfId)?.short_name}?</p>
          <p className='mt-1'>
            This cannot be undone and will delete ALL symposia, abstracts, registrations, rooms, sessions and the schedule.
            This will NOT delete users who attended the conference, but all conference related data will be removed.
          </p>
          <p className='mt-1'>
            You may want to download the registration, abstract and symposia exports before deleting, in case you need the information
            later.
          </p>
          {errors &&
            <p className='mt-3 usacm-error-message'>
              {getErrorMessages(errors)}
            </p>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowingDeleteConfirm(false)}>Cancel</Button>
          <Button variant="danger" onClick={() => callDeleteConf()}>Delete Conf</Button>
        </Modal.Footer>
      </Modal>

    </div>
  );

}
