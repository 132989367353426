/**
 * Gets a display for the organizer (name and username)
 * @param organizer backend object
 * @returns {string} formatted display
 */
export function getOrganizerName(organizer) {
  if (!organizer) {
    return '';
  }
  let userName = ' (non-user)';
  if (organizer.username) {
    userName = '(' + organizer.username + ')';
  }
  return organizer.first_name + ' ' + organizer.last_name + userName;
}

/**
 * @param symposium backend object
 * @returns backend object for the main organizer in the symposium
 */
export function getFirstMainOrganizer(symposium) {
  if (!symposium || !symposium.organizers) {
    return null;
  }
  return symposium.organizers.find(o => o.main_organizer);
}
