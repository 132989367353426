import {FloatingLabel, Form} from "react-bootstrap";
import React, {Fragment, useState} from "react";
import {fieldHasErrors, getErrorMessageForField} from "../../utils/formUtils";
import {updateFieldAnswer} from "../../api/RegApi";
import {HelpIcon} from "../../shared/HelpIcon";

/**
 *
 * @param field  Field object
 * @param onAnswerChange  if not null this will be called instead of the backend
 * @param showInvisible shows non-visible fields
 * @param setFields called when field data is received from backend
 * @param setPay called when pay data received from backend
 */
export function ChoicelistFieldInput({
                                       field,
                                       onAnswerChange = null,
                                       showInvisible = false,
                                       setFields = null,
                                       setPay = null
                                     }) {
  const [refresh, setRefresh] = useState(0);
  const [otherValue, setOtherValue] = useState('');
  const controlId = 'choicelist_input_' + field.id;
  const options = field.config?.options || [];
  const errors = field.errors || [];
  if (!field.answer) {
    field.answer = {};
  }

  // Add "Other..." option, only if the last option isn't already Other
  if (field.config?.showOther && field.config?.options[field.config?.options?.length - 1]?.value !== 'Other...') {
    options.push({id: -1, value: 'Other...', visible: true, desc: ''});
  }

  function callUpdateFieldAnswer() {
    updateFieldAnswer(field.id, field.answer, (code, data, errors) => {
      if (code === 200) {
        // If there is new field data, that means something changed, so we need to update the entire reg
        if (data) {
          if (setFields) {
            setFields(data.fields);
          }
          if (setPay) {
            setPay(data.pay);
          }
        }
      } else {
        field.errors = errors;
        setRefresh(refresh + 1);
      }
    });
  }

  // The user changed their choice
  function changeChoice(newId) {
    field.answer.id = newId;
    let fieldAnswer = options.find(o => o.id === newId)?.value || '';
    if (newId === -1) {
      fieldAnswer = otherValue ? otherValue : 'Other';
    } else {
      setOtherValue('');
    }
    field.answer.value = fieldAnswer;
    setRefresh(refresh + 1);
    if (onAnswerChange) {
      onAnswerChange(field.answer);
    } else {
      callUpdateFieldAnswer();
    }
  }

  return (
    <div>
      <div className="d-flex">
        {!field.config.useRadio &&
          <Fragment>
            <Form.Group controlId={controlId} className="flex-grow-1">
              <FloatingLabel controlId={controlId} label={field.name + (field.required ? " *" : "")}>
                <Form.Control
                  className="form-select"
                  as="select"
                  value={field.answer?.id || ''}
                  onChange={e => changeChoice(e.target.value ? parseInt(e.target.value, 10) : 0)}
                  {...(field.disabled ? {disabled: true} : {})}
                  isInvalid={fieldHasErrors(errors, '')}
                >
                  <option value='0'>Choose...</option>
                  {options.filter(o => showInvisible || o.visible).map(o => {
                    return <option value={o.id} key={o.id}>{o.value}</option>
                  })}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {getErrorMessageForField(errors, '')}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
            {field.help_text &&
              <div className='flex-grow-0 align-self-center ms-3'>
                <HelpIcon text={field.help_text}/>
              </div>
            }
          </Fragment>
        }

        {field.config.useRadio &&
          <div className="flex-grow-1">
            <div>
              {field.name}
              {(field.required ? " *" : "")}
              {field.help_text &&
                <HelpIcon text={field.help_text} className="ms-3"/>
              }
            </div>
            <div className={fieldHasErrors(errors, '') ? "usacm-error-box" : ""}>
              {options.filter(o => showInvisible || o.visible).map(o =>
                <Fragment key={o.id}>
                  <label htmlFor={controlId + o.id} className='d-block'>
                    <div className='d-flex align-items-start'>
                      <div>
                        <Form.Check
                          className="mt-2 ms-3"
                          key={controlId + o.id}
                          type="radio"
                          id={controlId + o.id}
                          checked={o.id === field.answer?.id}
                          onChange={e => changeChoice(o.id)}
                        />
                      </div>
                      <div className='ms-3 pt-2'>
                        {o.value}
                        {o.desc &&
                          <div className="" dangerouslySetInnerHTML={{__html: o.desc}}/>
                        }
                      </div>
                    </div>
                  </label>
                </Fragment>
              )}
            </div>
            <div className="usacm-invalid-feedback">
              {getErrorMessageForField(errors, '')}
            </div>
          </div>
        }
      </div>

      {field.answer?.id === -1 &&
        <Form.Group controlId={controlId + '-other'} className="flex-grow-1 mt-1">
          <Form.Control type="text"
                        className='p-3'
                        placeholder='Enter another option here'
                        name={controlId + '-other'}
                        {...(field.disabled ? {disabled: true} : {})}
                        value={otherValue || ''}
                        onChange={e => setOtherValue(e.target.value)}
                        onBlur={e => changeChoice(-1)}
          />
        </Form.Group>
      }
    </div>
  );
}

