import {Button, Col, Container, FloatingLabel, Form, Row} from "react-bootstrap";
import {Fragment, useState} from "react";
import {useNavigate} from "react-router";
import {fieldHasErrors, getErrorMessageForField} from "../utils/formUtils";
import {SUPPORT_EMAIL} from "../constants";
import {sendForgotPassword} from "../api/UserApi";
import {PageHeader} from "../menu/PageHeader";


export function ForgotPasswordSend() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState([]);
  const [emailSent, setEmailSent] = useState('');
  const [sending, setSending] = useState(false);

  function callSendForgotPassword(event) {
    event.preventDefault();
    if (sending) {
      return;
    }
    setSending(true);
    sendForgotPassword(email, (status, data, newErrors) => {
      if (status === 200) {
        setErrors([]);
        setEmailSent(email);
      } else {
        setErrors(newErrors);
      }
      setSending(false);
    });
  }

  return (
    <div>
      <PageHeader pageTitle="Forgot Password"/>
      <Form onSubmit={callSendForgotPassword}>
        <Container fluid className="usacm-container-narrow">

          {!emailSent &&
            <Fragment>
              <Row>
                <Col className="text-start mt-3 mb-3">
                  <p> Please enter the email address you used to sign up with. An email will be sent to this address with a link to reset
                    your password. </p>
                </Col>
              </Row>

              <Row>
                <Col className="mb-3">
                  <Form.Group controlId="email">
                    <FloatingLabel
                      controlId="email"
                      label="Email"
                    >
                      <Form.Control
                        type="email"
                        name="email"
                        placeholder="name@example.com"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        isInvalid={fieldHasErrors(errors, 'email')}/>
                      <Form.Control.Feedback type="invalid">
                        {getErrorMessageForField(errors, 'email')}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
              </Row>

              {fieldHasErrors(errors, '') &&
                <Row>
                  <Col className="text-center mb-3 usacm-error-message">
                    {getErrorMessageForField(errors, '')}
                  </Col>
                </Row>
              }

              <Row>
                <Col className="text-center usacm-button-row mb-3">
                  <Button variant="secondary" onClick={() => navigate("/")}>Back to Login</Button>
                  <Button type="submit">Send Reset Password Email</Button>
                </Col>
              </Row>
            </Fragment>
          }

          {emailSent &&
            <Row>
              <Col className="text-start mt-3 mb-3">
                <p> An email has been sent to <b>{emailSent}</b>. Please check your email and click on the link.
                  If you cannot find the email try checking in your spam folder.
                  If you still cannot find the email please contact us at {SUPPORT_EMAIL}</p>
              </Col>
            </Row>
          }

        </Container>
      </Form>
    </div>
  );

}
