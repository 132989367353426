import {Button, Col, Container, FloatingLabel, Form, Row} from "react-bootstrap";
import {fieldHasErrors, getErrorMessageForField} from "../utils/formUtils";
import {useContext, useEffect, useState} from "react";
import {UsacmContext} from "../App";
import {deepCopy, showErrorToast, showSuccessToast} from "../utils/usacmUtils";
import {getCurrentConf, updateConfLabels} from "../api/ConfApi";
import {CONF_LABEL_TYPE_TEXT, PERMISSION_CONF_ADMIN, PERMISSION_STAFF} from "../constants";
import {hasPermission} from "../api/UserApi";


export function ConfSettingsLabels() {
  const {conference} = useContext(UsacmContext);
  const [conf, setConf] = conference;
  const [labels, setLabels] = useState([])
  const [errors, setErrors] = useState([]);
  const isStaffOrAdmin = hasPermission(PERMISSION_STAFF) || hasPermission(PERMISSION_CONF_ADMIN);

  function filterLabels(allLabels) {
    return deepCopy(allLabels.filter(l => l.type === CONF_LABEL_TYPE_TEXT))
  }


  useEffect(() => {
    if (conf) {
      setLabels(filterLabels(conf.labels));
    }
  }, [conf]);


  function setLabel(label, newValue) {
    const newLabel = labels.find(l => l.key === label.key);
    if (!newLabel) {
      showErrorToast("Unable to find label", label);
      return;
    }
    // Mutate the existing array
    newLabel.value = newValue;
    setLabels([...labels]); // shallow copy to force a re-render
  }

  function callUpdateLabels(event) {
    event.preventDefault();
    const labelValues = labels.reduce((acc, curr) => ({...acc, [curr.key]: curr.value}), {});
    updateConfLabels(labelValues, (status, data, newErrors) => {
      if (status === 200) {
        setErrors([]);
        showSuccessToast("Conference labels saved");
        getCurrentConf((code, data, errors) => {
          if (code === 200) {
            setConf(data);
          } else {
            console.warn('Error loading conf', errors);
          }
        });
      } else {
        setErrors(newErrors);
      }
    });
  }

  return (
    <Form onSubmit={callUpdateLabels}>
      <Container fluid className="usacm-container-narrow">

        <Row className="mt-3 mb-3">
          <Col>
            These are the names that will be displayed through the site. <br/>
            Use lowercase unless the name should always be capitalized. <br/>
            Use the singular form as the label will be pluralized if needed. <br/>
          </Col>
        </Row>

        {labels.map(label => {
          return (<Row key={label.key}>
            <Col className="mb-3">
              <Form.Group controlId={label.key}>
                <FloatingLabel controlId={label.key} label={label.name}>
                  <Form.Control type="text"
                                placeholder={label.name}
                                name={label.key}
                                value={label.value}
                                onChange={e => setLabel(label, e.target.value)}
                                readOnly={!isStaffOrAdmin}
                                isInvalid={fieldHasErrors(errors, label.key)}/>
                  <Form.Control.Feedback type="invalid">
                    {getErrorMessageForField(errors, label.key)}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
            </Col>
          </Row>)
        })}

        {fieldHasErrors(errors, '') &&
          <Row>
            <Col className="text-center mb-3 usacm-error-message">
              {getErrorMessageForField(errors, '')}
            </Col>
          </Row>
        }

        <Row>
          <Col className="text-center mb-3">
            {isStaffOrAdmin &&
              <Button type="submit" className="ms-3 me-3">Update Labels</Button>
            }
          </Col>
        </Row>

      </Container>
    </Form>
  );

}
