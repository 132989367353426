import {Button, Col, Container, Form, Row} from "react-bootstrap";
import React, {useState} from "react";
import {prettyPrintObject} from "../utils/usacmUtils";
import {transferUserData} from "../api/AdminApi";
import {UserSelect} from "../shared/UserSelect";
import {fieldHasErrors, getErrorMessageForField} from "../utils/formUtils";

export function UserTransfer() {
  const [fromUser, setFromUser] = useState(null);
  const [toUser, setToUser] = useState(null);
  const [transferSymposia, setTransferSymposia] = useState(false);
  const [transferAbstracts, setTransferAbstracts] = useState(false);
  const [transferRegs, setTransferRegs] = useState(false);
  const [errors, setErrors] = useState([]);
  const [successData, setSuccessData] = useState(null);


  function callTransfer(isPreview) {
    setErrors([]);
    setSuccessData(null);
    transferUserData(isPreview, fromUser?.id, toUser?.id, transferSymposia, transferAbstracts, transferRegs,
      (code, data, errors) => {
        if (code === 200) {
          setSuccessData(data);
        } else {
          setErrors(errors);
        }
      });
  }

  return (
    <Container fluid className="usacm-container-medium">
      <div className='mt-3'>
        This function will allow you to move objects from one user to another within this conference.
      </div>

      <Row className='mt-3'>
        <Col className="col-1 d-flex fw-bold align-items-center">
          From
        </Col>
        <Col className="col-11">
          <UserSelect userId={fromUser?.id}
                      onChange={(user) => setFromUser(user)}
                      isInvalid={fieldHasErrors(errors, 'from_user_id')}
                      usersInConf={true}
                      noneSelectedText='From User...'/>
          <div className="usacm-invalid-feedback">
            {getErrorMessageForField(errors, 'from_user_id')}
          </div>
        </Col>
      </Row>

      <Row className='mt-3'>
        <Col className="col-1 d-flex fw-bold align-items-center">
          To
        </Col>
        <Col className="col-11">
          <UserSelect userId={toUser?.id}
                      onChange={(user) => setToUser(user)}
                      isInvalid={fieldHasErrors(errors, 'to_user_id')}
                      usersInConf={true}
                      noneSelectedText='To User...'/>
          <div className="usacm-invalid-feedback">
            {getErrorMessageForField(errors, 'to_user_id')}
          </div>
        </Col>
      </Row>

      <Row className='mt-3'>
        <Col>
          <Form.Check
            type="checkbox"
            label="Transfer Symposium data (create user and organizers)"
            id="transfer_symposia"
            checked={transferSymposia}
            onChange={e => setTransferSymposia(e.target.checked)}
          />
        </Col>
      </Row>

      <Row className='mt-3'>
        <Col>
          <Form.Check
            type="checkbox"
            label="Transfer Abstract data (create user and authors)"
            id="transfer_abstracts"
            checked={transferAbstracts}
            onChange={e => setTransferAbstracts(e.target.checked)}
          />
        </Col>
      </Row>

      <Row className='mt-3'>
        <Col>
          <Form.Check
            type="checkbox"
            label="Transfer Reg data (create user and assigned user)"
            id="transfer_regs"
            checked={transferRegs}
            onChange={e => setTransferRegs(e.target.checked)}
          />
        </Col>
      </Row>

      <Row className='mt-3'>
        <Col className="mt-3 usacm-button-row">
          <Button type="button" onClick={() => callTransfer(true)}>Preview</Button>
          <Button type="button" onClick={() => callTransfer(false)}>Transfer</Button>
        </Col>
      </Row>

      {fieldHasErrors(errors, '') &&
        <Row className="mt-3">
          <Col className="text-center usacm-error-message">
            {getErrorMessageForField(errors, '')}
          </Col>
        </Row>
      }

      {successData &&
        <Row className="mt-3">
          <Col>
            <div className={'fw-bold ' + (successData.is_preview ? '' : ' text-success')}>
              {successData.is_preview ?
                'Here is a preview of the data that will be changed if you initiate a transfer.'
                : 'The transfer completed successfully.'}
            </div>

            <div className='mt-3'>
              These are the object ids : <br/>
              Symposia : {prettyPrintObject(successData.symposium_ids)} <br/>
              Organizers : {prettyPrintObject(successData.organizer_ids)} <br/>
              Abstracts : {prettyPrintObject(successData.abstract_ids)} <br/>
              Authors : {prettyPrintObject(successData.author_ids)} <br/>
              Regs : {prettyPrintObject(successData.reg_ids)} <br/>
            </div>
          </Col>
        </Row>
      }

    </Container>
  );
}
