import {Button, Form, Modal} from "react-bootstrap";
import React, {useContext, useRef, useState} from "react";
import {getFieldsWithAbstracts, updateFieldAnswer} from "../../api/RegApi";
import {HelpIcon} from "../../shared/HelpIcon";
import {getPublicAbstracts} from "../../api/AbstractApi";
import {AgGridReact} from "ag-grid-react";
import {toolTipRenderer} from "../../utils/gridUtils";
import {getConfLabel, titleCase} from "../../utils/usacmUtils";
import {CONF_LABEL_KEY_SYMPOSIUM} from "../../constants";
import {UsacmContext} from "../../App";
import {getErrorMessageForField} from "../../utils/formUtils";

export function AbstractFieldInput({field, onAnswerChange = null, setFields = null, setPay = null}) {
  const context = useContext(UsacmContext);
  const [conf,] = context.conference;
  const [refresh, setRefresh] = useState(0);
  const [showingAbstractModal, setShowingAbstractModal] = useState(false);
  const [abstractList, setAbstractList] = useState([]);
  const [quickFilter, setQuickFilter] = useState('');
  const hasFewRows = (abstractList || []).length < 12;
  const gridRef = useRef();
  const symposiumLabel = titleCase(getConfLabel(conf, CONF_LABEL_KEY_SYMPOSIUM));
  if (!field.errors) {
    field.errors = [];
  }
  if (!field.answer) {
    field.answer = {};
  }

  function callUpdateFieldAnswer() {
    updateFieldAnswer(field.id, field.answer, (code, data, errors) => {
      if (code === 200) {
        // If there is new field data, that means something besides this field changed, so we need to update the entire reg
        if (data) {
          if (setFields) {
            setFields(data.fields);
          }
          if (setPay) {
            setPay(data.pay);
          }
        }
      } else {
        field.errors = errors;
      }
      setRefresh(refresh + 1);
    });
  }

  // This will augment the abstract list with the used abstract ID info
  function augmentAbstractList(abstractData, fieldData) {
    const usedAbstractIds = fieldData?.map(a => a.abstract_id) || [];
    abstractData.forEach(a => {
      a['used_in_reg'] = usedAbstractIds.includes(a.id);
    });
  }

  function showDialog() {
    getPublicAbstracts((code, abstractData, errors) => {
      if (code === 200) {
        setAbstractList(abstractData);
        getFieldsWithAbstracts((code, fieldData, errors) => {
          if (code === 200) {
            augmentAbstractList(abstractData, fieldData);
            setShowingAbstractModal(true);
            setQuickFilter('');
          }
        });
      }
    });
  }

  function selectAbstract(abstract) {
    if (abstract) {
      field.answer.value = abstract.abstract_number_full;
      field.answer.abstract_id = abstract.id;
      field.answer.abstract_title = abstract.title;
      field.answer.abstract_number = abstract.abstract_number;
    } else {
      field.answer.value = '';
      field.answer.abstract_id = '';
      field.answer.abstract_title = '';
      field.answer.abstract_number = '';
    }
    callUpdateFieldAnswer();
    setShowingAbstractModal(false);
  }

  function quickFilterChanged(newValue) {
    const gridApi = gridRef?.current?.api;
    setQuickFilter(newValue);
    if (gridApi) {
      gridApi.setGridOption('quickFilterText', newValue);
    } else {
      console.warn('cannot set quick filter as api is not available');
    }
  }

  // Column setup
  const colDefs = [
    {field: 'abstract_number_full', headerName: 'Number', cellRenderer: toolTipRenderer, width: 130, minWidth: 130},
    {field: 'title', headerName: 'Title', cellRenderer: toolTipRenderer, flex: 4, minWidth: 200},
    {
      headerName: symposiumLabel,
      minWidth: 200,
      flex: 4,
      valueGetter: params => (params.data.symposium_number || '') + ' ' + params.data.symposium_name,
      cellRenderer: toolTipRenderer,
    },
    {
      headerName: 'Action',
      sortable: false,
      filter: false,
      width: 110,
      cellRenderer: params => {
        const usedInReg = params.data?.used_in_reg;
        if (usedInReg) {
          return 'Assigned';
        }
        return <Button type="button" onClick={() => selectAbstract(params.data)} size="sm" className="me-2">Choose</Button>;
      },
    }
  ];

  const defaultColDefs = {
    sortable: true,
    filter: false,
    resizable: true,
  }

  return (
    <div>
      <div className="d-flex">
        <div className='my-auto'>
          <Button type="button"
                  className='text-nowrap'
                  {...(field.disabled ? {disabled: true} : {})}
                  onClick={() => showDialog()}>{field.name}</Button>
        </div>

        {field.help_text &&
          <div className='align-self-center ms-3'>
            <HelpIcon text={field.help_text}/>
          </div>
        }

        {field?.answer?.value &&
          <div className='ms-3 my-auto'>
            Chosen :
            <span className='fw-bold'> {field?.answer?.value} </span>
            <span className='ms-2'> {field?.answer?.abstract_title} </span>
          </div>
        }
      </div>

      <div className="usacm-invalid-feedback">
        {getErrorMessageForField(field.errors, '')}
      </div>

      <Modal show={showingAbstractModal}
             onHide={() => setShowingAbstractModal(false)}
             size="xl" className='usacm-modal-fullscreen'>
        <Modal.Header closeButton>
          <Modal.Title>Choose an Abstract</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div className="mb-3">
            <Form.Control type="text"
                          id="quick-filter"
                          placeholder='Search...'
                          name="quick-filter"
                          value={quickFilter}
                          onChange={e => quickFilterChanged(e.target.value)}
                          style={{minWidth: '150px'}}
            />
          </div>

          <div className="ag-theme-alpine" style={{width: '100%', ...(hasFewRows ? {} : {height: '700px'})}}>
            <AgGridReact
              ref={gridRef}
              rowData={abstractList}
              columnDefs={colDefs}
              defaultColDef={defaultColDefs}
              gridOptions={{enableCellTextSelection: true, ensureDomOrder: true}} // enable cell selection
              domLayout={hasFewRows ? "autoHeight" : "normal"}
              getRowId={(params) => params.data.id} // prevent re-rendering the whole table when data changes
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          {field?.answer?.value &&
            <Button variant="primary" onClick={() => selectAbstract(null)}>Clear Chosen Abstract</Button>
          }
          <Button variant="secondary" onClick={() => setShowingAbstractModal(false)}>Close</Button>
        </Modal.Footer>
      </Modal>

    </div>
  );
}

