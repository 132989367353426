import {Button, Col, Row} from "react-bootstrap";
import React, {Fragment, useEffect, useRef, useState} from "react";
import {getRegHistory} from "../api/RegApi";
import {toolTipRenderer} from "../utils/gridUtils";
import {AgGridReact} from "ag-grid-react";
import {getHistoryActionDisplay, getPayStatusDisplay, getPayTypeDisplay} from "./regUtils";
import {PAY_STATUS_IN_PROGRESS} from "../constants";
import {centsToDollarStrWSign, dateTimeToStr} from "../utils/usacmUtils";

export function RegHistory({regId, onFinished}) {
  const [historyList, setHistoryList] = useState([]);
  const gridRef = useRef();
  const hasFewRows = (historyList || []).length < 12;

  function loadHistory() {
    getRegHistory(regId, (code, data, errors) => {
      if (code === 200) {
        setHistoryList(data);
      }
    });
  }

  useEffect(() => {
    loadHistory();
  }, [regId]);

  const colDefs = [
    {
      headerName: 'Date (browser timezone)',
      width: 200,
      minWidth: 200,
      // This will parse the GMT date string into a JS Date in the browser's timezone
      valueGetter: params => dateTimeToStr(new Date(Date.parse(params.data?.create_date))),
    },
    {
      headerName: 'User', minWidth: 200, flex: 3,
      valueGetter: params => {
        return params.data.create_user_id ? params.data.create_user_name + " (" + params.data.create_user_email + ")" : "Stripe";
      },
      cellRenderer: toolTipRenderer,
    },
    {
      headerName: 'Action', minWidth: 230, flex: 2,
      valueGetter: params => {
        return getHistoryActionDisplay(params.data.history_action);
      },
      cellRenderer: toolTipRenderer,
    },
    {
      headerName: 'Pay Status', minWidth: 120, flex: 2,
      valueGetter: params => {
        return getPayStatusDisplay(params.data.pay_status);
      },
      cellRenderer: toolTipRenderer,
    },
    {
      headerName: 'Pay Type', minWidth: 120, flex: 2,
      valueGetter: params => {
        return getPayTypeDisplay(params.data.pay_type);
      },
      cellRenderer: toolTipRenderer,
    },
    {
      headerName: 'Details', minWidth: 110, flex: 6,
      valueGetter: params => {
        const history = params.data;
        if (history.pay_id) {
          const amount = (history.amount !== null && history.pay_status !== PAY_STATUS_IN_PROGRESS) ?
            centsToDollarStrWSign(history.amount) : '';
          const details = history.detail_text ? ' - ' + history.detail_text : '';
          return `Pay #${history.pay_id} ${amount} ${details}`;
        }
        return history.detail_text;
      },
      cellRenderer: toolTipRenderer,
    },
  ];

  const defaultColDefs = {
    sortable: false,
    filter: false,
    resizable: true,
  }

  return (
    <Fragment>
      <div className="section-header"> Registration History (#{regId})</div>

      <div className="ag-theme-alpine" style={{width: '100%', ...(hasFewRows ? {} : {height: '700px'})}}>
        <AgGridReact
          ref={gridRef}
          rowData={historyList}
          columnDefs={colDefs}
          defaultColDef={defaultColDefs}
          domLayout={hasFewRows ? "autoHeight" : "normal"}
          gridOptions={{enableCellTextSelection: true, ensureDomOrder: true}} // enable cell selection
          getRowId={(params) => params.data.id} // prevent re-rendering the whole table when data changes
        />
      </div>

      <Row className='mt-2'>
        <Col className="usacm-button-row">
          <Button variant="secondary" onClick={() => onFinished()}>Back</Button>
        </Col>
      </Row>

    </Fragment>
  );
}

