import {Button, Col, Container, Form, Modal, Row} from "react-bootstrap";
import React, {Fragment, useEffect, useState} from "react";
import {getPay, updatePay} from "../api/RegApi";
import {calculateTotalFromInvoiceItems, getPayStatusDisplay, getPayTypeDisplay} from "./regUtils";
import {PAY_STATUS_PENDING, PAY_STATUSES, PAY_TYPE_CARD, PAY_TYPE_OFFLINE} from "../constants";
import {centsToDollarStr, centsToDollarStrWSign, dollarsToCents, showSuccessToast} from "../utils/usacmUtils";
import {fieldHasErrors, getErrorMessageForField} from "../utils/formUtils";
import './RegPayEdit.scss';
import {RegCoupons} from "./RegCoupons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export function RegPayEdit({payId, onFinished}) {
  const [pay, setPay] = useState(null);
  const [payType, setPayType] = useState(PAY_TYPE_OFFLINE);
  const [payStatus, setPayStatus] = useState(PAY_STATUS_PENDING);
  const [amount, setAmount] = useState('');
  const [adminComments, setAdminComments] = useState('');
  const [refresh, setRefresh] = useState(0);
  const [errors, setErrors] = useState([]);
  const [showingConfirmModal, setShowingConfirmModal] = useState(false);
  const [showingCouponModal, setShowingCouponModal] = useState(false);
  const [chooseCouponInvoiceIndex, setChooseCouponInvoiceIndex] = useState(null);

  const visibleInvoiceItems = (pay?.invoice_items || []).filter(i => i.visible);
  const canEdit = !pay?.stripe_payment_id;
  const payTypeOptions = [PAY_TYPE_OFFLINE, PAY_TYPE_CARD].map(pt => ({id: pt, value: getPayTypeDisplay(pt)}));
  const payStatusOptions = PAY_STATUSES.map(ps => ({id: ps, value: getPayStatusDisplay(ps)}));
  const invoiceItemTotal = calculateTotalFromInvoiceItems(pay?.invoice_items);

  function loadPay() {
    if (payId) {
      getPay(payId, (code, data, errors) => {
        if (code === 200) {
          setPay(data);
          setPayType(data?.pay_type);
          setPayStatus(data?.pay_status);
          setAmount(centsToDollarStr(data?.amount));
          setAdminComments(data?.admin_comments || '');
          setInvoiceItemAmounts(data);
        }
      });
    }
  }

  useEffect(() => {
    loadPay();
  }, [payId]);

  function setSingleInvoiceItemAmounts(invoiceItem) {
    if (!invoiceItem) {
      return;
    }
    invoiceItem.dollarAmountStr = centsToDollarStr(invoiceItem.invoice_amount);
    invoiceItem.usingPercent = !!invoiceItem.invoice_percent;
  }

  /**
   * Goes through a pay and for each invoiceItem sets dollarAmountStr from the amount
   * dollarAmountStr is a front-end only variable used to back the react inputs
   */
  function setInvoiceItemAmounts(pay) {
    if (!pay || !pay.invoice_items) {
      return;
    }
    for (const invoice_item of pay.invoice_items) {
      setSingleInvoiceItemAmounts(invoice_item);
    }
  }

  /**
   * Goes through a pay and for each invoiceItem sets invoice_amount  from the dollarAmountStr
   * This should be called to update the value before sending to the backend
   */
  function setInvoiceItemAmountsFromDollarAmountStr(pay) {
    if (!pay || !pay.invoice_items) {
      return;
    }
    for (const invoice_item of pay.invoice_items) {
      invoice_item.invoice_amount = dollarsToCents(invoice_item.dollarAmountStr);
    }
  }

  function setCalculatedTotal() {
    setAmount(centsToDollarStr(invoiceItemTotal));
    setShowingConfirmModal(false);
  }

  function callUpdatePay(force = false) {
    setErrors([]);
    const newAmount = dollarsToCents(amount);
    if (newAmount === null) {
      setErrors([{'message': 'Invalid total amount.', 'fields': ['amount']}]);
      return;
    }
    setInvoiceItemAmountsFromDollarAmountStr(pay);
    const invoiceTotal = calculateTotalFromInvoiceItems(pay.invoice_items);
    if (!force && (invoiceTotal !== dollarsToCents(amount))) {
      setShowingConfirmModal(true);
      return;
    }
    updatePay(payId, payType, payStatus, newAmount, adminComments, pay.invoice_items, (code, data, errors) => {
      if (code === 200) {
        showSuccessToast('Updated pay successfully.');
        if (onFinished) {
          onFinished();
        }
      } else {
        setErrors(errors);
        setShowingConfirmModal(false);
      }
    });
  }

  function getInvoiceItemByIndex(invoiceIndex) {
    if (!pay || !pay.invoice_items) {
      console.warn('Cannot add invoice item when pay and items have not been loaded');
      return null;
    }
    if (invoiceIndex < 0 || invoiceIndex > pay.invoice_items.length - 1) {
      console.warn('Invalid index ', invoiceIndex);
    }
    return pay.invoice_items[invoiceIndex];
  }

  function setInvoiceAmount(invoiceIndex, newAmount) {
    const invoiceItem = getInvoiceItemByIndex(invoiceIndex);
    if (invoiceItem) {
      if (invoiceItem.usingPercent) {
        invoiceItem.invoice_percent = newAmount;
      } else {
        invoiceItem.dollarAmountStr = newAmount;
      }
      setRefresh(refresh + 1);
    }
  }

  function setInvoiceUsingPercent(invoiceIndex, newUsingPercent) {
    const invoiceItem = getInvoiceItemByIndex(invoiceIndex);
    if (invoiceItem) {
      invoiceItem.usingPercent = newUsingPercent;
      if (newUsingPercent) {
        invoiceItem.dollarAmountStr = '';
      } else {
        invoiceItem.invoice_percent = '';
      }
      setRefresh(refresh + 1);
    }
  }

  function setInvoiceText(invoiceIndex, newText) {
    const invoiceItem = getInvoiceItemByIndex(invoiceIndex);
    if (invoiceItem) {
      invoiceItem.invoice_text = newText;
      setRefresh(refresh + 1);
    }
  }

  function setInvoiceKey(invoiceIndex, newKey) {
    const invoiceItem = getInvoiceItemByIndex(invoiceIndex);
    if (invoiceItem) {
      invoiceItem.invoice_key = newKey;
      setRefresh(refresh + 1);
    }
  }

  function addInvoiceItem() {
    if (!pay || !pay.invoice_items) {
      console.warn('Cannot add invoice item when pay and items have not been loaded');
    }
    const max_order = pay.invoice_items.length ? Math.max(...pay.invoice_items.map(i => i.invoice_order || 0)) : 1;
    pay.invoice_items.push({
      id: null,
      invoice_key: 'key_' + (max_order + 1), // some unique string
      invoice_amount: 0,
      invoice_percent: '',
      dollarAmountStr: '', // used by the front-end
      usingPercent: false, // front-end only
      invoice_text: '',
      invoice_order: (max_order + 1),
      coupon_id: null,
      visible: true,
    });
    setRefresh(refresh + 1);
  }

  function deleteInvoiceItem(invoiceIndex) {
    if (!pay || !pay.invoice_items) {
      console.warn('Cannot delete invoice item when pay and items have not been loaded');
    }
    pay.invoice_items.splice(invoiceIndex, 1);
    setRefresh(refresh + 1);
  }

  function startChooseCoupon(newInvoiceIndex) {
    setChooseCouponInvoiceIndex(newInvoiceIndex);
    setShowingCouponModal(true);
  }

  function stopChooseCoupon() {
    setChooseCouponInvoiceIndex(null);
    setShowingCouponModal(false);
  }

  function chooseCoupon(coupon) {
    const invoiceItem = getInvoiceItemByIndex(chooseCouponInvoiceIndex);
    if (!coupon || !invoiceItem) {
      console.warn('Failed to select coupon coupon=', coupon, ' invoiceItem=', invoiceItem, ' index=', chooseCouponInvoiceIndex);
      return;
    }
    invoiceItem.invoice_key = coupon.invoice_key;
    invoiceItem.invoice_amount = coupon.invoice_amount;
    invoiceItem.invoice_percent = coupon.invoice_percent;
    invoiceItem.invoice_text = coupon.invoice_text;
    // Don't set invoice_order as the coupon may be inserted in a different place by the admin doing the editing
    invoiceItem.coupon_id = coupon.id;
    setSingleInvoiceItemAmounts(invoiceItem);
    stopChooseCoupon();
  }

  function swapOrder(indexA, indexB) {
    const invoiceItemA = getInvoiceItemByIndex(indexA);
    const invoiceItemB = getInvoiceItemByIndex(indexB);
    if (!invoiceItemA || !invoiceItemB) {
      console.warn('Unable to find item indexA=', indexA, ' itemA=', invoiceItemA, ' indexB=', indexB, ' itemB=', invoiceItemB);
      return;
    }
    const tempOrder = invoiceItemA.invoice_order;
    invoiceItemA.invoice_order = invoiceItemB.invoice_order;
    invoiceItemB.invoice_order = tempOrder;
    pay.invoice_items.sort((a, b) => a.invoice_order - b.invoice_order);
    setRefresh(refresh + 1);
  }

  return (
    <Container fluid className="usacm-container-wide">
      <div className="section-header"> Edit Payment</div>
      <div className='mt-3'><b>ID:</b> #{pay?.id}</div>
      <div className='mt-3'><b>Date:</b> {pay?.create_date}</div>

      {!canEdit &&
        <div className='mt-3'><b>Payment Type:</b> {getPayTypeDisplay(pay?.pay_type)}</div>
      }
      {canEdit &&
        <div className='mt-3'>
          <span className='fw-bold'>Payment Type:</span>
          <span className='ms-2 d-inline-block'>
             <Form.Group controlId='payment-type'>
              <Form.Control
                className="form-select"
                as="select"
                value={payType}
                onChange={e => setPayType(e.target.value)}
              >
                {payTypeOptions.map(o => {
                  return <option value={o.id} key={o.id}>{o.value}</option>
                })}
              </Form.Control>
            </Form.Group>
          </span>
        </div>
      }

      {!canEdit &&
        <div className='mt-3'><b>Payment Status:</b> {getPayStatusDisplay(pay?.pay_status)} </div>
      }
      {canEdit &&
        <div className='mt-3'>
          <span className='fw-bold'>Status:</span>
          <span className='ms-2 d-inline-block'>
             <Form.Group controlId='status-change'>
              <Form.Control
                className="form-select"
                as="select"
                value={payStatus}
                onChange={e => setPayStatus(e.target.value)}
              >
                {payStatusOptions.map(o => {
                  return <option value={o.id} key={o.id}>{o.value}</option>
                })}
              </Form.Control>
            </Form.Group>
          </span>
        </div>
      }

      {pay?.stripe_payment_id &&
        <div className='mt-3'><b>Stripe Payment ID:</b> {pay?.stripe_payment_id}</div>
      }

      <div className='mt-3 fw-bold'>Invoice Items:</div>
      {!canEdit &&
        <Fragment>
          <div className='ps-3 mt-2'>
            {visibleInvoiceItems.map(invoiceItem => <div key={invoiceItem.id + " " + invoiceItem.invoice_order}>
              <b>{centsToDollarStrWSign(invoiceItem.invoice_amount)}</b> &nbsp; {invoiceItem.invoice_text}
            </div>)}
          </div>
          <div className='mt-3'><b>Total Amount:</b> {centsToDollarStrWSign(pay?.amount)}</div>
        </Fragment>
      }
      {canEdit &&
        <Fragment>
          <div className='ps-3 mt-2'>
            <Row className='invoice-row'>
              <Col className='col-2 invoice-header invoice-amt'>&nbsp; &nbsp;Amount</Col>
              <Col className='col-3 invoice-header invoice-key'>&nbsp;Key</Col>
              <Col className='col-6 invoice-header invoice-text'>&nbsp;Text</Col>
              <Col className='col-1 invoice-header invoice-action'>&nbsp; </Col>
            </Row>
            {visibleInvoiceItems.map((invoiceItem, invoiceIndex) =>
              <Row key={invoiceIndex} className='invoice-row'>
                <Col className='invoice-amt'>
                  <div className='d-flex align-items-center'>
                    <div className='me-1'>
                      {invoiceItem.usingPercent ? '%' : '$'}
                    </div>
                    <Form.Control type="number"
                                  step='1'
                                  placeholder='Amount'
                                  name={invoiceItem.id + '-invoice-item-amount'}
                                  value={(invoiceItem.usingPercent ? invoiceItem.invoice_percent : invoiceItem.dollarAmountStr) || ''}
                                  onChange={e => setInvoiceAmount(invoiceIndex, e.target.value)}/>
                  </div>
                </Col>
                <Col className='col invoice-key'>
                  <Form.Control type="text"
                                placeholder='Invoice Key'
                                name={invoiceItem.id + '-invoice-item-key'}
                                value={invoiceItem.invoice_key || ''}
                                onChange={e => setInvoiceKey(invoiceIndex, e.target.value)}/>
                </Col>
                <Col className='col invoice-text'>
                  <Form.Control type="text"
                                placeholder='Invoice Text'
                                name={invoiceItem.id + '-invoice-item-text'}
                                value={invoiceItem.invoice_text || ''}
                                onChange={e => setInvoiceText(invoiceIndex, e.target.value)}/>
                </Col>
                <Col className='col invoice-action'>
                  <Button type="button" size="sm" className="me-1"
                          onClick={() => swapOrder(invoiceIndex, invoiceIndex - 1)}
                          disabled={invoiceIndex === 0}>
                    <FontAwesomeIcon icon="fa-angle-up"/>
                  </Button>
                  <Button type="button" size="sm" className="me-3"
                          onClick={() => swapOrder(invoiceIndex, invoiceIndex + 1)}
                          disabled={invoiceIndex + 1 === visibleInvoiceItems.length}>
                    <FontAwesomeIcon icon="fa-angle-down"/>
                  </Button>
                  <Button onClick={() => deleteInvoiceItem(invoiceIndex)}>Delete</Button>
                  <Button onClick={() => startChooseCoupon(invoiceIndex)} className='ms-1'>Coupon</Button>
                  {invoiceItem.usingPercent &&
                    <Button onClick={() => setInvoiceUsingPercent(invoiceIndex, false)} className='ms-1'>$ Amt</Button>
                  }
                  {!invoiceItem.usingPercent &&
                    <Button onClick={() => setInvoiceUsingPercent(invoiceIndex, true)} className='ms-1'>% Amt</Button>
                  }
                </Col>
              </Row>)}
            <div className='mt-3 text-center'>
              <Button onClick={() => addInvoiceItem()}>Add Invoice Item</Button>
            </div>
          </div>

          <div className='mt-3 d-flex align-items-start'>
            <div className='fw-bold mt-2'>Total Amount:</div>
            <div className='d-flex align-items-start ms-2'>
              <span className='me-1 mt-1'>$</span>
              <Form.Group controlId="amount">
                <Form.Control type="number"
                              step='1'
                              placeholder='Amount'
                              name='amount'
                              value={amount}
                              onChange={e => setAmount(e.target.value)}
                              isInvalid={fieldHasErrors(errors, 'amount')}/>
                <Form.Control.Feedback type="invalid">
                  {getErrorMessageForField(errors, 'amount')}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
          </div>
        </Fragment>
      }

      <div className='mt-3'>
        <div className='fw-bold text-nowrap'>Admin Comments:</div>
        <div className='w-100 mt-1'>
          <Form.Control as="textarea"
                        rows={5}
                        placeholder='Admin Comments...'
                        name='admin_comments'
                        value={adminComments}
                        onChange={e => setAdminComments(e.target.value)}/>
        </div>
      </div>

      {fieldHasErrors(errors, '') &&
        <Row className='mt-3'>
          <Col className="text-center mb-3 usacm-error-message">
            {getErrorMessageForField(errors, '')}
          </Col>
        </Row>
      }

      <Row>
        <Col className="usacm-button-row">
          <Button variant="secondary" onClick={() => onFinished()}>Cancel</Button>
          <Button onClick={() => callUpdatePay()}>Save Payment</Button>
        </Col>
      </Row>

      <Modal show={showingConfirmModal}
             onHide={() => setShowingConfirmModal(false)}
             size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Confirm Save</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            The total amount ${amount} does not match the sum of the invoice items which
            is {centsToDollarStrWSign(invoiceItemTotal)}.
          </p>
          <p className='mt-3'>Are you sure you want to save with this discrepancy?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowingConfirmModal(false)}>Cancel</Button>
          <Button variant="primary" onClick={() => setCalculatedTotal()}>Correct Total</Button>
          <Button variant="primary" onClick={() => callUpdatePay(true)}>Save</Button>
        </Modal.Footer>
      </Modal>


      <Modal show={showingCouponModal}
             onHide={() => stopChooseCoupon()}
             size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Choose Coupon</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <RegCoupons onCouponSelect={chooseCoupon}/>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => stopChooseCoupon()}>Cancel</Button>
        </Modal.Footer>
      </Modal>

    </Container>
  );
}
