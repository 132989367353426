import {Button, Col, Container, FloatingLabel, Form, Row} from "react-bootstrap";
import {useContext, useState} from "react";
import {useNavigate} from "react-router";
import {getCurrentUser, getUserData, getUserEmail, isEmailVerified, updateProfile} from "../api/UserApi";
import {fieldHasErrors, getErrorMessageForField} from "../utils/formUtils";
import {UsacmContext} from "../App";
import {PageHeader} from "../menu/PageHeader";
import {CountrySelect} from "../shared/CountrySelect";


export function EditProfile() {
  const navigate = useNavigate();
  const userData = getUserData();
  const [email, setEmail] = useState(userData.email);
  const [firstName, setFirstName] = useState(userData.first_name);
  const [lastName, setLastName] = useState(userData.last_name);
  const [country, setCountry] = useState(userData.country);
  const [affiliation, setAffiliation] = useState(userData.affiliation);
  const [errors, setErrors] = useState([]);
  const currentEmail = getUserEmail();
  const {verified} = useContext(UsacmContext);
  const [, setEmailVerified] = verified;

  function callUpdateProfile(event) {
    event.preventDefault();
    updateProfile(email, firstName, lastName, country, affiliation, (status, data, newErrors) => {
      if (status === 200) {
        if (currentEmail !== email) {
          // Email changed we need to refresh the user info
          getCurrentUser(() => {
            const emailVerified = isEmailVerified();
            setEmailVerified(emailVerified);
            if (!emailVerified) {
              // Go to the verify code page
              navigate("/");
            } else {
              navigate("/profile");
            }
          });
        } else {
          // Email didn't change
          navigate("/profile");
        }
      } else {
        setErrors(newErrors);
      }
    });
  }

  return (
      <div>
        <PageHeader pageTitle="Update Profile"/>
        <Form onSubmit={callUpdateProfile}>
          <Container fluid className="usacm-container-narrow">
            <Row>
              <Col className="mb-3">
                <Form.Group controlId="email">
                  <FloatingLabel
                      controlId="email"
                      label="Email"
                  >
                    <Form.Control
                        type="email"
                        name="email"
                        placeholder="name@example.com"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        isInvalid={fieldHasErrors(errors, 'email')}/>
                    <Form.Control.Feedback type="invalid">
                      {getErrorMessageForField(errors, 'email')}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col className="mb-3">
                <Form.Group controlId="firstname">
                  <FloatingLabel controlId="firstname" label="First Name">
                    <Form.Control type="firstname"
                                  placeholder="First Name"
                                  name="firstname"
                                  value={firstName}
                                  onChange={e => setFirstName(e.target.value)}
                                  isInvalid={fieldHasErrors(errors, 'first_name')}/>
                    <Form.Control.Feedback type="invalid">
                      {getErrorMessageForField(errors, 'first_name')}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col className="mb-3">
                <Form.Group controlId="lastname">
                  <FloatingLabel controlId="lastName" label="Last Name">
                    <Form.Control type="lastName"
                                  placeholder="Last Name"
                                  name="lastName"
                                  value={lastName}
                                  onChange={e => setLastName(e.target.value)}
                                  isInvalid={fieldHasErrors(errors, 'last_name')}/>
                    <Form.Control.Feedback type="invalid">
                      {getErrorMessageForField(errors, 'last_name')}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col className="mb-3">
                <Form.Group controlId="country">
                  <CountrySelect value={country}
                                 onChange={countryCode => setCountry(countryCode)}
                                 isInvalid={fieldHasErrors(errors, 'country')}/>
                  <Form.Control.Feedback type="invalid">
                    {getErrorMessageForField(errors, 'country')}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col className="mb-3">
                <Form.Group controlId="affiliation">
                  <FloatingLabel controlId="affiliation" label="Affiliation">
                    <Form.Control type="affiliation"
                                  placeholder="Affiliation"
                                  name="affiliation"
                                  value={affiliation}
                                  onChange={e => setAffiliation(e.target.value)}
                                  isInvalid={fieldHasErrors(errors, 'affiliation')}/>
                    <Form.Control.Feedback type="invalid">
                      {getErrorMessageForField(errors, 'affiliation')}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>
              </Col>
            </Row>

            {fieldHasErrors(errors, '') &&
            <Row>
              <Col className="text-center mb-3 usacm-error-message">
                {getErrorMessageForField(errors, '')}
              </Col>
            </Row>
            }

            <Row>
              <Col className="text-center mb-3">
                <Button variant="secondary" onClick={() => {
                  navigate("/profile")
                }} className="ms-3 me-3">Cancel</Button>
                <Button type="submit" className="ms-3 me-3">Update Profile</Button>
              </Col>
            </Row>

          </Container>
        </Form>
      </div>
  );

}
