import React, {Fragment, useContext, useEffect, useState} from "react";
import {UsacmContext} from "../App";
import {Elements} from "@stripe/react-stripe-js";
import {RegCheckoutStripe} from "./RegCheckoutStripe";
import {loadStripe} from "@stripe/stripe-js";
import {RegInvoiceDisplay} from "./RegInvoiceDisplay";
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import {fieldHasErrors, getErrorMessageForField} from "../utils/formUtils";
import {centsToDollarStrWSign, getConfLabel} from "../utils/usacmUtils";
import {createStripePayment} from "../api/StripeApi";
import {CONF_LABEL_REG_OFFLINE_COMPLETE_CONTENT, CONF_LABEL_REG_OFFLINE_SELECTED_CONTENT, PERMISSION_CALC_HAS_REG} from "../constants";
import {getCurrentUser, hasPermission} from "../api/UserApi";

export function RegCheckout({reg, pay, preview, onCancel, onComplete, redirectPath, onFinished}) {
  const totalAmount = pay?.amount || 0;
  const context = useContext(UsacmContext);
  const [conf,] = context.conference;
  const [stripePromise, setStripePromise] = useState(null);
  const [payOffline, setPayOffline] = useState(totalAmount === 0);
  const [paidOffline, setPaidOffline] = useState(false);
  const [errors, setErrors] = useState([]);
  const offlineSelectedHtml = getConfLabel(conf, CONF_LABEL_REG_OFFLINE_SELECTED_CONTENT);
  const offlineCompleteHtml = getConfLabel(conf, CONF_LABEL_REG_OFFLINE_COMPLETE_CONTENT);
  const allowPayOffline = !(conf?.reg_allow_pay_offline === false); // default to true

  function handleError(errMsg) {
    setErrors([{'message': errMsg, 'fields': ['']}]);
  }

  useEffect(() => {
    if (conf) {
      // Load the Stripe front-end API
      setStripePromise(loadStripe(conf.stripe_public_api_key));
    }
  }, [conf]);

  const stripeOptions = {
    mode: 'payment',
    amount: totalAmount,
    currency: 'usd',
  };

  function createOfflinePayment() {
    createStripePayment(reg.id, pay.id, true, (code, data, errors) => {
      if (code === 200) {
        if (totalAmount !== data.total_amount) {
          console.warn('TotalAmounts do not match: ', totalAmount, ' !== ', data.total_amount);
          handleError('The invoice amount has changed since the page was loaded.  Please reload your page.');
          return;
        }
        setPaidOffline(data.paid_offline);
        if (!hasPermission(PERMISSION_CALC_HAS_REG)) {
          // This will reload the user permissions as they now have a reg
          getCurrentUser(() => {
          });
        }
      }
    });
  }

  return (
    <Container fluid className="usacm-container-wide">

      <RegInvoiceDisplay pay={pay} titlePrefixText="Checkout Total"/>

      <div className="section-header"> Payment Details</div>

      {!paidOffline &&
        <Fragment>
          {(totalAmount > 0) && allowPayOffline &&
            <div className="mb-3">
              <Form.Check
                type="checkbox"
                label="Pay without using a credit card."
                id="pay_offline"
                checked={payOffline}
                onChange={e => setPayOffline(e.target.checked)}
              />
            </div>
          }

          {payOffline && (totalAmount > 0) &&
            <div className="mb-3">
              <div className="" dangerouslySetInnerHTML={{__html: offlineSelectedHtml}}/>
            </div>
          }

          {(totalAmount === 0) &&
            <div className="mb-3">
              There is no cost for you to register.
            </div>
          }

          {stripePromise && (totalAmount > 0) && !payOffline &&
            <Elements stripe={stripePromise} options={stripeOptions}>
              <RegCheckoutStripe reg={reg}
                                 pay={pay}
                                 totalAmount={totalAmount}
                                 preview={preview}
                                 onCancel={onCancel}
                                 onComplete={onComplete}
                                 redirectPath={redirectPath}/>
            </Elements>
          }

          {fieldHasErrors(errors, '') && (totalAmount === 0) &&
            <Row className='mt-3'>
              <Col className="text-center mb-3 usacm-error-message">
                {getErrorMessageForField(errors, '')}
              </Col>
            </Row>
          }

          {(totalAmount === 0 || payOffline) &&
            <Row>
              <Col className="usacm-button-row">
                <Button variant="secondary" onClick={() => onCancel()}>Back</Button>
                <Button onClick={() => createOfflinePayment()}> Submit </Button>
              </Col>
            </Row>
          }

        </Fragment>
      }

      {paidOffline && (totalAmount > 0) &&
        <div>
          <div>
            <b>Registration ID:</b> {reg?.id} <br/>
            <b>Registration Amount :</b> {centsToDollarStrWSign(totalAmount)} <br/>
          </div>
          <div className="mt-3 mb-3" dangerouslySetInnerHTML={{__html: offlineCompleteHtml}}/>
        </div>
      }

      {paidOffline && (totalAmount === 0) &&
        <div>
          Your registration has been completed.
        </div>
      }

      {(totalAmount < 0) &&
        <Fragment>
          <div className='mb-3'>
            You cannot register with a negative total. Please contact registration at {conf?.from_email}.
          </div>
          <Row>
            <Col className="usacm-button-row">
              <Button variant="secondary" onClick={() => onCancel()}>Back</Button>
            </Col>
          </Row>
        </Fragment>
      }

      {onFinished && paidOffline &&
        <Row>
          <Col className="usacm-button-row">
            <Button variant="secondary" onClick={() => onFinished()}>Done</Button>
          </Col>
        </Row>
      }

    </Container>
  );

}
