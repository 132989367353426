import {FloatingLabel, Form} from "react-bootstrap";
import React, {useState} from "react";
import {fieldHasErrors, getErrorMessageForField} from "../../utils/formUtils";
import {updateFieldAnswer} from "../../api/RegApi";
import {HelpIcon} from "../../shared/HelpIcon";

export function TextFieldInput({field, onAnswerChange = null, setFields = null, setPay = null}) {
  const [refresh, setRefresh] = useState(0);
  const [dirty, setDirty] = useState(false);
  const controlId = 'text_input_' + field.id;
  const longText = !!field.config?.long_text;
  const errors = field.errors || [];
  if (!field.answer) {
    field.answer = {};
  }

  function updateAnswer(newValue) {
    field.answer.value = newValue;
    setRefresh(refresh + 1);
    setDirty(true);
  }

  function callUpdateFieldAnswer() {
    updateFieldAnswer(field.id, field.answer, (code, data, errors) => {
      if (code === 200) {
        if (data) {
          if (setFields) {
            setFields(data.fields);
          }
          if (setPay) {
            setPay(data.pay);
          }
        }
      } else {
        field.errors = errors;
      }
      setDirty(false); // This will also refresh with errors
    });
  }

  function leavingField() {
    if (dirty) {
      if (onAnswerChange) {
        onAnswerChange(field.answer);
      } else {
        callUpdateFieldAnswer();
      }
    }
  }

  return (
      <div className="d-flex">
        {!longText &&
        <Form.Group controlId={controlId} className="flex-grow-1">
          <FloatingLabel controlId={controlId} label={field.name + (field.required ? ' *' : '')}>
            <Form.Control type="text"
                          placeholder={field.name}
                          name={controlId}
                          {...(field.disabled ? {disabled: true} : {})}
                          value={field.answer.value || ''}
                          onChange={e => updateAnswer(e.target.value)}
                          onBlur={e => leavingField()}
                          isInvalid={fieldHasErrors(errors, '')}/>
            <Form.Control.Feedback type="invalid">
              {getErrorMessageForField(errors, '')}
            </Form.Control.Feedback>
          </FloatingLabel>
        </Form.Group>
        }

        {longText &&
        <Form.Group controlId={controlId} className="flex-grow-1">
          <Form.Control as="textarea"
                        rows={field.config?.row_count || '5'}
                        placeholder={field.name + (field.required ? ' *' : '')}
                        name={controlId}
                        {...(field.disabled ? {disabled: true} : {})}
                        value={field.answer.value || ''}
                        onChange={e => updateAnswer(e.target.value)}
                        onBlur={e => leavingField()}
                        isInvalid={fieldHasErrors(errors, '')}/>
          <Form.Control.Feedback type="invalid">
            {getErrorMessageForField(errors, '')}
          </Form.Control.Feedback>
        </Form.Group>
        }

        {field.help_text &&
        <div className='flex-grow-0 align-self-center ms-3'>
          <HelpIcon text={field.help_text}/>
        </div>
        }
      </div>
  );
}

