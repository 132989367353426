import React, {useState} from "react";
import {centsToDollarStrWSign, showSuccessToast} from "../utils/usacmUtils";
import {getPayStatusDisplay, getPayTypeDisplay} from "./regUtils";
import {hasPermission} from "../api/UserApi";
import {PAY_STATUS_IN_PROGRESS, PERMISSION_CONF_ADMIN, PERMISSION_CONF_ORGANIZER, PERMISSION_STAFF} from "../constants";
import {Button, Modal} from "react-bootstrap";
import './RegPayDisplay.scss';
import {fieldHasErrors, getErrorMessageForField} from "../utils/formUtils";
import {deletePay} from "../api/RegApi";

/**
 * Detailed display of a pay
 * @param pay the pay to show
 * @param onEdit function to run when edit button is clicked, if null no edit button will be displayed
 * @param onDeleteFinished called after the pay delete has succeeded (set to null for no delete option)
 * @param onCheckout function to run when user clicks Pay with Stripe (checkout) button, use null to hide the button
 */
export function RegPayDisplay({pay, onEdit = null, onDeleteFinished = null, onCheckout = null}) {
  const [showingDeleteConfirm, setShowingDeleteConfirm] = useState(false);
  const [errors, setErrors] = useState([]);

  const invoiceItems = pay?.invoice_items || [];
  const visibleInvoiceItems = invoiceItems.filter(i => i.visible);
  const payTotalStr = centsToDollarStrWSign(pay?.amount);
  const statusDisplay = getPayStatusDisplay(pay?.pay_status);
  const title = payTotalStr + ' ' + statusDisplay + ' Payment '
  const isStaffOrAdmin = hasPermission(PERMISSION_STAFF) || hasPermission(PERMISSION_CONF_ADMIN);
  const isStaffOrAdminOrOrg = isStaffOrAdmin || hasPermission(PERMISSION_CONF_ORGANIZER);
  const showCheckoutButton = onCheckout && pay && (pay.pay_status === PAY_STATUS_IN_PROGRESS);

  function callDeletePay() {
    deletePay(pay.id, (code, data, errors) => {
      if (code === 200) {
        showSuccessToast('Pay deleted.');
        if (onDeleteFinished) {
          onDeleteFinished();
        }
      } else {
        setErrors(errors);
      }
    });
  }

  return (
    <div className="section-container">
      <div className="section-header">
        {title}
        {isStaffOrAdmin && onEdit && <Button className='ms-3' onClick={() => onEdit()}>Edit</Button>}
        {showCheckoutButton && <Button className='ms-3' onClick={() => onCheckout()}>Checkout</Button>}
        {isStaffOrAdmin && onDeleteFinished && !pay?.stripe_payment_id && // Stripe associated pays should not be deleted
          <Button variant='danger' className='ms-3' onClick={() => setShowingDeleteConfirm(true)}>Delete Pay</Button>
        }
      </div>
      <div className='pay-cols'>
        <div className='pay-col'>
          <div className='mt-2'><b>ID:</b> {pay?.id}</div>
          <div className='mt-2'><b>Date:</b> {pay?.create_date}</div>
          <div className='mt-2'><b>Payment Type:</b> {getPayTypeDisplay(pay?.pay_type)}</div>
          <div className='mt-2'><b>Payment Status:</b> {statusDisplay}</div>
          {isStaffOrAdminOrOrg && pay?.stripe_payment_id &&
            <div className='mt-2'><b>Stripe Payment ID:</b> {pay?.stripe_payment_id}</div>
          }
          {isStaffOrAdminOrOrg &&
            <div className='mt-2'><b>Create User ID:</b> {pay?.create_user_id}</div>
          }
        </div>
        <div className='pay-col'>
          <div className='mt-2'>
            <b>Invoice Items:</b>
            <div className='ms-3'>
              {visibleInvoiceItems.map((invoiceItem) =>
                <div key={invoiceItem.id}>
                      <span className='fw-bold d-inline-block' style={{width: '75px'}}>
                      {invoiceItem.invoice_percent ? invoiceItem.invoice_percent + '%' : centsToDollarStrWSign(invoiceItem.invoice_amount)}
                      </span>
                  &nbsp; {invoiceItem.invoice_text}
                </div>
              )}
            </div>
          </div>
          <div className='mt-2 fw-bold'>
            Total Amount: {payTotalStr}
          </div>
          {isStaffOrAdminOrOrg &&
            <div className='mt-2'><b>Admin Comments:</b> {pay?.admin_comments}</div>
          }
        </div>
      </div>

      <Modal show={showingDeleteConfirm} onHide={() => setShowingDeleteConfirm(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            Are you sure you want to delete payment with ID {pay?.id}?
          </div>
          <div className='mb-3'>
            This action cannot be undone. You will permanently lose this payment and all associated invoice items.
          </div>
          {fieldHasErrors(errors, '') &&
            <div className="usacm-error-message">
              {getErrorMessageForField(errors, '')}
            </div>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowingDeleteConfirm(false)}>Cancel</Button>
          <Button variant="danger" onClick={() => callDeletePay()}>Delete Payment</Button>
        </Modal.Footer>
      </Modal>

    </div>
  );

}
