// noinspection JSUnusedGlobalSymbols

import {toast} from "react-toastify";
import {PERMISSION_NAMES} from "../constants";
import $ from "jquery"


export function getToastOptions() {
  return {
    position: "top-right",
    hideProgressBar: true,
    autoClose: 3000, // ms
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: "light",
  };
}

export function showSuccessToast(message) {
  toast.success(message, getToastOptions());
}

export function showErrorToast(message) {
  toast.error(message, getToastOptions());
}

export function showInfoToast(message) {
  toast.info(message, getToastOptions());
}

export function showWarningToast(message) {
  toast.warning(message, getToastOptions());
}

/**
 * Makes a deep copy of the object (not including functions, and funny stuff... just JSON data)
 */
export function deepCopy(obj) {
  if (!obj) {
    return null;
  }
  return JSON.parse(JSON.stringify(obj));
}

/**
 * Removes all properties in the object (leaving prototype properties)
 */
export function clearObject(obj) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      delete obj[key];
    }
  }
}

/**
 * Get a label from the conf data
 */
export function getConfLabel(conf, labelKey) {
  if (!labelKey || !conf || !conf.labels) {
    return '';
  }
  return (conf.labels.find(l => l.key === labelKey))?.value || '';
}

/**
 * Changes string from "a string here" to "A String Here"
 */
export function titleCase(str) {
  if (!str) {
    return '';
  }
  let retVal = '';
  // Split on whitespace
  const tokens = str.split(/\s+/);
  for (let token of tokens) {
    // First token is always capital, but some words are not capitalized when in the middle
    if (retVal.length > 0 && ['the', 'of', 'a', 'an', 'in', 'if', 'and', 'or', 'is', 'but', 'not', 'nor',
      'yet', 'so', 'at', 'by', 'for', 'on', 'to'].includes(token.toLowerCase())) {
      retVal += ' ' + token
    } else {
      if (token) {
        retVal += ' ' + token[0].toUpperCase() + token.substring(1, token.length);
      }
    }
  }
  return retVal.trim();
}

/**
 * Changes string from "a string HERE" to "A string here"
 * NOTE: this may lowercase things you don't want lowercased
 */
export function sentenceCase(str) {
  if (!str) {
    return '';
  }
  let retVal = str.trim().toLowerCase();
  retVal = retVal[0].toUpperCase() + retVal.substring(1, str.length);
  return retVal;
}

/**
 * Returns true if the string ends with any of the endings
 * @param str string to compare
 * @param endings array of strings
 */
export function endsWithAny(str, endings) {
  if (!str || !endings) {
    return false;
  }
  for (const ending of endings) {
    if (str.endsWith(ending)) {
      return true;
    }
  }
  return false;
}

/**
 * Removes the 's' from the end of the string (maybe making a plural into a singular)
 * This won't work on many words, this may have to be some sort of lookup if we want it general.
 */
export function makeSingular(str) {
  if (!str) {
    return '';
  }
  if (str.toLowerCase().endsWith('s')) {
    return str.substring(0, str.length - 1);
  }
  return str;
}

/**
 * Attempt to make a singular string plural.
 * NOTE: We will probably need to add more special cases here, this is not easy to get right.
 */
export function makePlural(str) {
  if (!str) {
    return '';
  }
  // Change 'symposium' to 'symposia'
  if (str.toLowerCase().endsWith('ium')) {
    return str.substring(0, str.length - 3) + 'ia';
  }
  // Change 'class' to 'classes'
  if (endsWithAny(str.toLowerCase(), ['s', 'sh', 'ch', 'x', 'z', 'o'])) {
    return str + 'es';
  }
  // Change 'ellipsis' to 'ellipses'
  if (str.toLowerCase().endsWith('is')) {
    return str.substring(0, str.length - 2) + 'es';
  }
  // Change 'cat' to 'cats'
  return str + 's';
}

/**
 * Converts a BE theme key name into a front-end CSS variable name
 */
export function getThemeFeFromBe(backendName) {
  if (!backendName) {
    return '';
  }
  return '--usacm-' + backendName.replaceAll('_', '-')  // convert py to js
}

/**
 * Sets all the theme variables for this conference on the global styles
 */
export function setThemeVariables(conf) {
  const style = document.documentElement?.style;
  const theme = conf?.theme;
  if (!style || !theme) {
    return;
  }
  for (let backendName in theme) {
    if (Object.prototype.hasOwnProperty.call(theme, backendName)) {
      setThemeVariable(theme, style, backendName);
    }
  }
}

function setThemeVariable(theme, style, backendName) {
  const frontend_name = getThemeFeFromBe(backendName);
  let themeValue = theme[backendName];
  if (!themeValue) {
    return;
  }
  // Favicon is handled differently (needs to be replaced in the DOM not just CSS)
  if (backendName === 'favicon_url') {
    setFavicon(themeValue);
  }
  if (backendName.endsWith('url')) {
    // Convert from string to CSS url
    themeValue = 'url("' + themeValue + '")';
  }
  style.setProperty(frontend_name, themeValue);
}

function setFavicon(newUrl) {
  const faviconLink = document.getElementById('favicon_link');
  if (!faviconLink) {
    console.warn('Unable to find favicon link');
    return;
  }
  faviconLink.href = newUrl;
}


/**
 * Returns the current value of a CSS variable or "" if none is found
 */
export function getThemeValue(backendName) {
  const style = window.getComputedStyle(document.documentElement);
  if (style) {
    return style.getPropertyValue(getThemeFeFromBe(backendName));
  }
  return '';
}

/**
 * returns the display name for the permission (see constants.js PERMISSION_*)
 */
export function getPermissionName(permission) {
  return PERMISSION_NAMES[permission] || '';
}

/**
 * Returns the intersection of two arrays (in a new array)
 * intersect([1, 2, 3], [2,4,1,1]) => [1,2]
 */
export function intersect(a, b) {
  if (!a || !b || a.length === 0 || b.length === 0) {
    return [];
  }
  return a.filter(aVal => b.indexOf(aVal) > -1)
  .filter((e, i, c) => c.indexOf(e) === i); // remove duplicates
}

/**
 * @returns a number with the count of words in the string
 */
export function countWords(str) {
  if (!str) {
    return 0;
  }
  return str.trim().split(/\s+/).length;
}

/**
 * This will determine the number of words in an HTML string
 * @param html  HTML markup - This might be an injection attack point
 * @returns {number} number of text words in the HTML fragment
 */
export function countWordsInHtml(html) {
  if (!html) {
    return 0;
  }
  const text = $(html).text();
  return countWords(text);
}

export function scrollToTop() {
  // NOTE: This doesn't always work on mobile all the time (android Chrome)
  // If there are network calls pending, then the page height may be small, and the page is not at the top after the data has loaded.
  // We could possibly add listeners (global var or axios Promise.all) to all the network calls and then wait for the final render
  setTimeout(() => {
    document.getElementById('menu-top').scrollIntoView({behavior: "smooth"})
  }, 300);
}

/**
 * Home-made debounce function.
 * @param timeMs MS to delay before calling function
 * @param func  callback funtion to run
 * @returns a debounced function
 *
 * In React we have to make sure we don't create a new debounce function on every render so you need to do something like this :
 const myFunction = useCallback(debounce(500, () => {
 // Do something here
 }), []);
 */
export function debounce(timeMs, func) {
  let timer;
  return function (...args) {
    const context = this;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      func.apply(context, args);
    }, timeMs);
  };
}

/**
 * @returns {boolean} true if val is a number or a string containing a number
 * NOTE : We want null and empty strings to return false and 0 to return true
 *  Input => Returns
 *  0 => true
 *  1 => true
 *  '0' => true
 *  '1' => true
 *  '1.1' => true
 *  '1e10' => true
 *  1.1 => true
 *  'X' => false
 *  '' => false
 *  ' ' => false
 *  'e' => false
 *  null => false
 *  true => false
 *  false => false
 *  undefined => false
 *  [0] => false
 *  {} => false
 * If you need to make changes here is some test code:
 for (const val of [0, 1, '0', '1', '1.1', '1e10', 0.0, -0, 1.1, // true
 'X', '', ' ', 'e', null, true, false, undefined, [0], {}]) {
 console.log(val+' => '+isNumeric(val));
 }
 */
export function isNumeric(val) {
  if ((val === null) || (typeof val === "undefined") || (typeof val === "boolean") || Array.isArray(val)) {
    return false;
  }
  if ((typeof val === "string") && val.trim() === '') {
    return false
  }
  return !isNaN(val);
}

/***
 * Converts val to a number or returns null if it's not a numeric type or a string containing a number.
 * NOTE: See isNumeric for what is considered a number
 */
export function toNumber(val) {
  if (!isNumeric(val)) {
    return null;
  }
  return +val;
}

/**
 * returns true if val is an integer, false otherwise
 * NOTE: This will use isNumeric and return false if val is not a number or a string containing a number
 * If you're changing this function, try not to break too many edge cases:
 for (const val of [0, 1, 1e7, -2, '0', '1', '-3', 0.0, -0, // should return true
 '1e7',  // returns false (though this is debatable)
 '0.0', // returns false (though maybe this could be true)
 0.99999999999999999, // returns true but I don't know how to make this false, as it really is a 1
 1.1, -2.2, '-', '-3-', '1.1', 0.5, '0.99999999999999999', 'X', '', ' ', 'e', null, true, false, undefined, [0], {}]) {
 console.log(val+' => '+isInteger(val));
 }
 */
export function isInteger(val) {
  if (!isNumeric(val)) {
    return false;
  }
  return /^[-0-9]+$/.test('' + val);
}


const UNIQUE_ID_CHARS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

/**
 * Returns a (mostly) unique string - can be used for HTML ids or non-secure purposes
 * @param len {number} length of string to generate
 * @returns {string}
 */
export function generateUniqueId(len) {
  let result = '';
  for (let i = 0; i < len; i++) {
    result += UNIQUE_ID_CHARS.charAt(Math.floor(Math.random() * UNIQUE_ID_CHARS.length));
  }
  return result;
}

/**
 * Converts val from dollars (user input float) to cents (DB storage int)
 * @returns an integer with the cent value
 * NOTES: If there are too many decimal places this will round 12.345 => 12.35
 *        If val doesn't look like a number this will return null
 *        See isNumeric for what is considered a number
 * Test Cases
 for (const val of [12.34, 4, 0, '123.4', '123.45', '12.345', // valid
 'x', null]) { // null
 console.log(val+' => '+dollarsToCents(val));
 }
 */
export function dollarsToCents(val) {
  const dollars = toNumber(val);
  if (dollars === null) {
    return null;
  }
  return Math.round(dollars * 100);
}

/**
 * Converts val from cents (DB storage int) to dollars (user display float)
 * @returns a string with the dollar amount (for display or user editing, don't do math with this)
 for (const val of [1, 12, 123, 1234, 12345, 1200, 0, 0.0, '-0.0', '12', '123', '123456', '1001', 1230,
 1.25, 'x', '', null]) { // null
 console.log(val+' => '+centsToDollarStr(val));
 }
 */
export function centsToDollarStr(val) {
  const cents = toNumber(val);
  if (cents === null) {
    return null;
  }
  let dollarStr = (Math.round(cents) / 100) + '';
  // If the string looks like '12.3' then we want to add a zero on the end
  if (dollarStr[dollarStr.length - 2] === '.') {
    dollarStr += '0';
  }
  return dollarStr;
}


/**
 * Converts the cents to a dollar string including the dollar sign.
 * This will handle negative numbers properly "-$1.23"
 */
export function centsToDollarStrWSign(cents) {
  const dollarStr = centsToDollarStr(cents);
  if (!dollarStr) {
    return "$0";
  }
  if (dollarStr.startsWith('-')) {
    return '-$' + dollarStr.substring(1);
  }
  return '$' + dollarStr;
}

/**
 * Converts string with a date in format 2022-12-31 to a JS date object
 * @return null if anything went wrong
 **/
export function parseDateStr(dateStr) {
  if (!dateStr) {
    return null;
  }
  const dateParts = dateStr.split(/\D/);
  if (dateParts.length < 3) {
    return null;
  }
  const newDate = new Date(dateParts[0], dateParts[1] - 1, dateParts[2], 0, 0, 0);
  // Don't return NaN
  if (!newDate) {
    return null;
  }
  return newDate
}

/**
 * Return true if the date (as a string) is in the future
 * @param dateStr string with format yyyy-mm-dd
 * @return true if the date is in the future (no hours portion) The server may disagree based on time-zone conversions
 Test:
 ['2023-05-25', null, '', '2023-06-27'].map(dateStr => console.log(dateStr+' => ', isDateStrInFuture(dateStr)));
 */

export function isDateStrInFuture(dateStr) {
  const date = parseDateStr(dateStr);
  if (!date) {
    return false;
  }
  return date.setHours(0, 0, 0, 0) > new Date().setHours(0, 0, 0, 0);
}

/**
 * Return true if the date (as a string) is in the past
 * @param dateStr string with format yyyy-mm-dd
 * @return true if the date is in the past (no hours portion) The server may disagree based on time-zone conversions
 */
export function isDateStrInPast(dateStr) {
  const date = parseDateStr(dateStr);
  if (!date) {
    return false;
  }
  return date.setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0);
}

/**
 * Get today's date as a string in the format '2023-01-31'
 * This is useful for populating a date picker with today's date
 */
export function todaysDateStr() {
  const today = new Date();
  const year = today.getFullYear();
  const month = ("0" + (today.getMonth() + 1)).slice(-2); // pad with 0
  const day = ("0" + today.getDate()).slice(-2); // pad with 0
  return `${year}-${month}-${day}`;
}

/**
 * Convert a JS Date() object to a string
 */
export function dateTimeToStr(datetime) {
  const year = datetime.getFullYear();
  const month = ('0' + (datetime.getMonth() + 1)).slice(-2); // pad with 0
  const day = ('0' + datetime.getDate()).slice(-2); // pad with 0
  const hour = ('0' + datetime.getHours()).slice(-2);
  const min = ('0' + datetime.getMinutes()).slice(-2);
  const sec = ('0' + datetime.getSeconds()).slice(-2);
  return `${year}-${month}-${day} ${hour}:${min}.${sec}`;
}

/**
 * Converts an object to a pretty printed string for the user.
 * NOTE: This will not handle all types of data, should be used mostly with JSON-style objects
 * probably ones that came from the backend.
 */
export function prettyPrintObject(obj) {
  if ((typeof obj === "boolean") || (typeof obj === "string") || (typeof obj === "number")) {
    return '' + obj;
  }
  if (!obj) {
    return '';
  }
  let str = '';
  // Iterate the object (array, or obj with properties)
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const val = obj[key];
      const keyStr = isNumeric(key) ? '' : key + '=';
      if (str) {
        str += ', ';
      }
      str += keyStr + prettyPrintObject(val);
    }
  }
  return str;
}
