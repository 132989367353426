import {Button, Col, Container, Form, Row} from "react-bootstrap";
import {PageHeader} from "../menu/PageHeader";
import React, {useContext, useState} from "react";
import './Schedule.scss';
import {UsacmContext} from "../App";
import {getConfLabel, titleCase} from "../utils/usacmUtils";
import {CONF_LABEL_KEY_CONFERENCE, CONF_LABEL_KEY_SYMPOSIUM} from "../constants";
import {searchSchedule} from "../api/ScheduleApi";
import {fieldHasErrors, getErrorMessageForField} from "../utils/formUtils";
import {formatAffiliation} from "../utils/displayUtils";
import './SearchSchedule.scss';

export function SearchSchedule() {
  const context = useContext(UsacmContext);
  const [conf,] = context.conference;
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const confLabel = getConfLabel(conf, CONF_LABEL_KEY_CONFERENCE);
  const symposiumLabel = getConfLabel(conf, CONF_LABEL_KEY_SYMPOSIUM);
  const symposiumLabelTitle = titleCase(symposiumLabel);
  const [errors, setErrors] = useState([]);
  const [hasRunSearch, setHasRunSearch] = useState(false);

  function runSearch() {
    setErrors([]);
    setSearchResults([]);
    searchSchedule(searchTerm,(code, data, errors) => {
      setHasRunSearch(true);
      if (code === 200) {
        setSearchResults(data);
      } else {
        setErrors(errors);
      }
    });
  }

  return (
      <div>
        <PageHeader pageTitle="Search Schedule"/>
        <Container fluid className="usacm-container-wide">

          <Row className="mb-3">
            <Col>
              <div className="text-center mb-3">
                Search the {confLabel} schedule for an abstract, author or {symposiumLabel}.
              </div>
              <div className="d-flex align-items-start" style={{maxWidth: '800px', marginLeft: 'auto', marginRight: 'auto'}}>
                <Form.Group controlId="search_term" className="ms-2 flex-grow-1">
                  <Form.Control type="text"
                                placeholder='Search...'
                                required
                                name="search_term"
                                value={searchTerm}
                                onChange={e => setSearchTerm(e.target.value)}
                                onKeyPress={e => (e.key === 'Enter') && runSearch() }
                  />
                  {fieldHasErrors(errors, 'search_term') &&
                  <div className="text-center mt-3 mb-3 usacm-error-message">
                    {getErrorMessageForField(errors, 'search_term')}
                  </div>
                  }
                </Form.Group>
                <Button onClick={() => runSearch()} className="ms-2">Search</Button>
              </div>
            </Col>
          </Row>

          {searchResults.map(result =>
          <Row key={result.roomsessiontimeslot_id} className="mb-3">
            <Col>
              <hr className="mb-4" />
              <div className='search-result-container'>
                <div className='search-result-schedule'>
                  <b>Scheduled</b> on {result.session_date} <br/>
                  {result.timeslot_start_time} to {result.timeslot_end_time} in room {result.room_number} {result.room_name}
                </div>
              <p className="fw-bold">{result.abstract_number} {result.abstract_title} </p>
              <p className='mt-1'><b>{symposiumLabelTitle}</b> {result.symposium_number} {result.symposium_title}</p>
              <p className='mt-1'><b>Authors:</b> {result.authors.map(author => <span key={author.id} className="me-3">
                {author.first_name} {author.last_name}{author.presenting_author ? '*' : ''} ({formatAffiliation(author.affiliation)})
              </span> )}</p>
              <p className='mt-1'><b>Text:</b> {result.abstract_text}</p>
              </div>
            </Col>
          </Row>
          )}

          {!searchResults?.length && hasRunSearch && !errors.length &&
              <Row>
                <Col className="text-center mt-3">
                  No search results found.
                </Col>
              </Row>
          }

        </Container>
      </div>
  );
}
