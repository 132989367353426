import {Button, Col, Container, FloatingLabel, Form, Row} from "react-bootstrap";
import React, {useContext, useEffect, useRef, useState} from "react";
import {UsacmContext} from "../App";
import {getConfEmails, updateConfEmail} from "../api/ConfApi";
import {showErrorToast, showSuccessToast} from "../utils/usacmUtils";
import {fieldHasErrors, getErrorMessageForField} from "../utils/formUtils";
import "./ConfSettingsEmail.scss"
import {HtmlEditor} from "../shared/HtmlEditor";
import {hasPermission} from "../api/UserApi";
import {PERMISSION_CONF_ADMIN, PERMISSION_STAFF} from "../constants";

export function ConfSettingsEmail() {
  const {conference} = useContext(UsacmContext);
  const [conf,] = conference;
  const [emails, setEmails] = useState([]);
  const [selectedKey, setSelectedKey] = useState(null);
  const editorRef = useRef(null);
  const [subject, setSubject] = useState('');
  const [errors, setErrors] = useState([]);
  const isStaffOrAdmin = hasPermission(PERMISSION_STAFF) || hasPermission(PERMISSION_CONF_ADMIN);

  function loadEmails() {
    getConfEmails((code, data, errors) => {
      if (code === 200) {
        setEmails(data);
        if (data && !selectedKey) {
          setSelectedKey(data[0]?.key);
          setSubject(data[0]?.subject);
        }
      } else {
        showErrorToast('Failed to load emails');
      }
    });
  }

  useEffect(() => {
    if (conf) {
      loadEmails();
    }
    // eslint-disable-next-line
  }, [conf]);

  function getSelectedEmail() {
    if (!emails || !selectedKey) {
      return null;
    }
    return emails.find((e) => e.key === selectedKey);
  }

  function getVariableNames() {
    return (getSelectedEmail()?.variables || []).map(e => '{' + e + '}').join(', ');
  }

  function selectEmail(newKey) {
    const newEmail = emails?.find((e) => e.key === newKey);
    if (!newEmail) {
      showErrorToast('Unable to find email with key ', newKey);
      return;
    }
    setSelectedKey(newKey);
    setSubject(newEmail.subject);
  }

  function updateEmail() {
    if (editorRef.current) {
      const bodyHtml = editorRef.current.getContent();
      updateConfEmail(selectedKey, subject, bodyHtml, (status, data, newErrors) => {
        if (status === 200) {
          setErrors([]);
          showSuccessToast("Email template saved.");
          loadEmails();
        } else {
          setErrors(newErrors);
        }
      });
    }
  }

  return (
      <Container fluid className="usacm-container-wide">
        <Row className="usacm-fake-input">
          You can {isStaffOrAdmin ? 'edit' : 'view'} the content of emails sent by the server for this conference. <br/>
          {isStaffOrAdmin && <span>There are substitution variables in the email templates, be careful not to break them.</span>}
        </Row>

        <Row className="mb-3 mt-3">
          <Col className="text-start col-auto mr-3 usacm-label ">
            Email Template to {isStaffOrAdmin ? 'Edit' : 'View'}
          </Col>
          <Col className="text-left col-4">
            <Form.Group controlId="formBasicSelect">
              <Form.Control
                  className="form-select"
                  as="select"
                  value={selectedKey || ''}
                  onChange={e => selectEmail(e.target.value)}
              >
                {emails?.map(email => {
                  return <option value={email.key} key={email.key}>{email.name}</option>
                })}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>

        <div className="email-content-box">
          <Row>
            <Col className="mb-3">
              <Form.Group controlId="subject">
                <FloatingLabel controlId="subject" label="Subject">
                  <Form.Control type="text"
                                placeholder="Subject"
                                name="subject"
                                value={subject}
                                onChange={e => setSubject(e.target.value)}
                                readOnly={!isStaffOrAdmin}
                                isInvalid={fieldHasErrors(errors, 'subject')}/>
                  <Form.Control.Feedback type="invalid">
                    {getErrorMessageForField(errors, 'subject')}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
            </Col>
          </Row>

          <Row className="usacm-fake-input">
            Variables available in this template are : {getVariableNames()}
          </Row>

          <Row>
            <Col className="text-center mb-3">
              <HtmlEditor
                  initialValue={getSelectedEmail()?.body_html}
                  onInit={(evt, editor) => editorRef.current = editor}
                  disabled={!isStaffOrAdmin}
              />
            </Col>
          </Row>
        </div>

        {fieldHasErrors(errors, '') &&
        <Row>
          <Col className="text-center mb-3 usacm-error-message">
            {getErrorMessageForField(errors, '')}
          </Col>
        </Row>
        }

        <Row>
          <Col className="text-center mb-3">
            {isStaffOrAdmin &&
            <Button type="button" className="ms-3 me-3" onClick={updateEmail}>Update Email</Button>
            }
          </Col>
        </Row>

      </Container>
  );

}
