import React, {useState} from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import {countryData} from "./CountrySelectCountryList";
import {Button} from "react-bootstrap";
import './CountrySelect.scss';

// The component display
// Dropdown needs forwardRef access to the DOM node in order to position the Menu
const CountrySelectToggle = React.forwardRef(({children, onClick}, ref) => (
    <Button
        className="usacm-button-as-input"
        type="button"
        ref={ref}
        onClick={(e) => {
          onClick(e);
        }}
    >
      &#x25bc; &nbsp; {children}
    </Button>
));


// forwardRef required as Dropdown needs access to the DOM of the Menu to measure it
const CountrySelectMenu = React.forwardRef(
    ({children, style, className, 'aria-labelledby': labeledBy}, ref) => {
      const [value, setValue] = useState('');

      return (
          <div
              ref={ref}
              style={style}
              className={(className + " country-select-menu")}
              aria-labelledby={labeledBy}
          >
            <Form.Control
                autoFocus
                name="filter-input"
                type="text"
                className="country-select-filter-input"
                placeholder="Type to filter..."
                onChange={(e) => setValue(e.target.value)}
                value={value}
            />
            <ul className="list-unstyled">
              {React.Children.toArray(children).filter(
                  (child) =>
                      !value || child.props.children.toLowerCase().includes(value.toLowerCase()),
              )}
            </ul>
          </div>
      );
    },
);

export function CountrySelect({value, onChange, isInvalid}) {

  const selectedCountryName = (countryData.find(c => c.code === (value || '').toUpperCase()) || {name: 'Choose Country...'}).name;
  // This will mark the object so we get a red border and the form.control.feedback will show up
  const classes = isInvalid ? "form-control is-invalid" : "";

  return (
      <Dropdown onSelect={newValue => onChange(newValue)} className={classes}>
        <Dropdown.Toggle as={CountrySelectToggle} id="country-select-id">
          {selectedCountryName}
        </Dropdown.Toggle>
        <Dropdown.Menu as={CountrySelectMenu}>
          {countryData.map(country => {
            return <Dropdown.Item key={country.code} eventKey={country.code} className="country-select-item">{country.name}</Dropdown.Item>
          })}
        </Dropdown.Menu>
      </Dropdown>
  );
}
