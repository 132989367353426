import {Button, Col, Container, FloatingLabel, Form, Row} from "react-bootstrap";
import {Fragment, useContext, useState} from "react";
import {fieldHasErrors, getErrorMessageForField} from "../utils/formUtils";
import {forgotPasswordReset, isEmailVerified, isUserLoggedIn} from "../api/UserApi";
import {UsacmContext} from "../App";
import {PageHeader} from "../menu/PageHeader";


export function ForgotPasswordReset() {
  const params = new URLSearchParams(window.location.search);
  const hash = params.get('hash');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('');
  const [errors, setErrors] = useState([]);
  const {loggedIn, verified} = useContext(UsacmContext);
  const [, setUserLoggedIn] = loggedIn;
  const [, setEmailVerified] = verified;
  const [resetDone, setResetDone] = useState(false);

  function callChangePassword(event) {
    event.preventDefault();
    forgotPasswordReset(hash, newPassword, newPasswordConfirm, (status, data, newErrors) => {
      if (status === 200) {
        setUserLoggedIn(isUserLoggedIn());
        setEmailVerified(isEmailVerified());
        setResetDone(true);
        setErrors([]);
      } else {
        setErrors(newErrors);
      }
    });
  }

  return (
      <div>
        <PageHeader pageTitle= "Reset Password" />
        <form onSubmit={callChangePassword}>
          <Container fluid className="usacm-container-narrow">
            {!resetDone &&
            <Fragment>
              <Row>
                <Col className="mb-3">
                  <FloatingLabel controlId="newPassword" label="New Password">
                    <Form.Control type="password"
                                  placeholder="New Password"
                                  name="newPassword"
                                  required
                                  value={newPassword}
                                  onChange={e => setNewPassword(e.target.value)}
                                  isInvalid={fieldHasErrors(errors, 'new_password')}/>
                    <Form.Control.Feedback type="invalid">
                      {getErrorMessageForField(errors, 'new_password')}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
              </Row>

              <Row>
                <Col className="mb-3">
                  <FloatingLabel controlId="newPasswordConfirm" label="Confirm New Password">
                    <Form.Control type="password"
                                  placeholder="Confirm New Password"
                                  name="newPasswordConfirm"
                                  required
                                  value={newPasswordConfirm}
                                  onChange={e => setNewPasswordConfirm(e.target.value)}
                                  isInvalid={fieldHasErrors(errors, 'new_password_confirm')}/>
                    <Form.Control.Feedback type="invalid">
                      {getErrorMessageForField(errors, 'new_password_confirm')}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
              </Row>

              {fieldHasErrors(errors, '') &&
              <Row>
                <Col className="text-center mb-3 usacm-error-message">
                  {getErrorMessageForField(errors, '')}
                </Col>
              </Row>
              }

              <Row>
                <Col className="text-center mb-3">
                  <Button type="submit" className="ms-3 me-3">Change Password</Button>
                </Col>
              </Row>
            </Fragment>
            }

            {resetDone &&
            <Row>
              <Col className="text-start mt-3 mb-3">
                <p> Congratulations! Your password has been updated successfully. <br/>
                  <br/>
                  You will need to use your new password the next time you log in. </p>
              </Col>
            </Row>
            }

          </Container>
        </form>
      </div>
  );
}
