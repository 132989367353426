import {isEmailVerified, isUserLoggedIn} from "../api/UserApi";
import {Dashboard} from "./Dashboard";
import {Login} from "./Login";
import {VerifyEmail} from "./VerifyEmail";

export function Home() {
  const loggedIn = isUserLoggedIn();
  const verified = isEmailVerified();

  let content = loggedIn ? <Dashboard/> : <Login/>;
  if (loggedIn && !verified) {
    content = <VerifyEmail/>;
  }

  return content;
}
