/* eslint-disable react-hooks/exhaustive-deps */
import {Button, Col, Container, FloatingLabel, Form, Row} from "react-bootstrap";
import {fieldHasErrors, getErrorMessageForField} from "../utils/formUtils";
import {Fragment, useContext, useEffect, useState} from "react";
import {UsacmContext} from "../App";
import {
  getUserEmail,
  isEmailVerified,
  isUserLoggedIn,
  resendVerificationCode,
  setAuthHeaders,
  verifyEmailCode,
  verifyEmailHash
} from "../api/UserApi";
import {PageHeader} from "../menu/PageHeader";
import {useNavigate} from "react-router";


export function VerifyEmail() {
  const navigate = useNavigate();
  const [code, setCode] = useState('');
  const [errors, setErrors] = useState([]);
  const [codeResent, setCodeResent] = useState(false); // stops user from clicking resend too many times
  const {loggedIn, verified} = useContext(UsacmContext);
  const [, setUserLoggedIn] = loggedIn;
  const [emailVerified, setEmailVerified] = verified;
  const email = getUserEmail();

  function emailHasBeenVerified() {
    setEmailVerified(isEmailVerified());
    setErrors([]); // clear any old errors
  }

  // Check for parameters to auto-login and submit the code
  // Caution: this may be called twice on dev servers
  useEffect(() => {
    setAuthHeaders(); // this is run in App.js but this one runs first!
    const params = new URLSearchParams(window.location.search);
    const hash = params.get('hash');
    if (hash) {
      verifyEmailHash(hash, (status, data, newErrors) => {
        if (status === 200) {
          // they may already be logged in, but after this they definitely are
          setUserLoggedIn(isUserLoggedIn());
          emailHasBeenVerified();
        } else {
          // status is not 200, code is not good
          console.warn("Error using hash");
          if (!isUserLoggedIn()) {
            navigate("/");
          }
          // if the user is logged in they can enter the code on the screen
        }
      });
    }
  }, [setUserLoggedIn, setEmailVerified]);


  function callVerify(event) {
    event.preventDefault();
    verifyEmailCode(code, (status, data, newErrors) => {
      if (status === 200) {
        emailHasBeenVerified();
      } else { // status is not 200, code is not good
        setErrors(newErrors);
      }
    });
  }

  function callResendCode(event) {
    event.preventDefault();
    resendVerificationCode((status, data, newErrors) => {
      if (status === 200) {
        setCodeResent(true);
        setErrors([]); // clear any old errors
      } else { // status is not 200, code is not good
        setErrors(newErrors);
      }
    });
  }

  return (
      <div>
        <PageHeader pageTitle= "Verify Email" />
        <Form onSubmit={callVerify}>
          <Container fluid className="usacm-container-narrow">
            {!emailVerified &&
            <Fragment>
              <Row>
                <Col className="text-start mt-3 mb-3">
                  <p>An email has been sent to <b>{email}</b>. Please check your email and click the link or enter the code.</p>
                  <p>If this is not your email address, you can change it by editing your profile in the top right menu. </p>
                  {!codeResent &&
                  <p>
                    If you have not received an email check your spam folder.
                    If you still cannot find it click "Resend Email" to send a new email.
                  </p>
                  }
                </Col>
              </Row>

              <Row>
                <Col className="mb-3">
                  <Form.Group controlId="code">
                    <FloatingLabel
                        controlId="code"
                        label="Verify Code (XXXX-XXXX)"
                    >
                      <Form.Control
                          type="text"
                          name="code"
                          placeholder="XXXX-XXXX"
                          required
                          value={code}
                          onChange={e => setCode(e.target.value)}
                          isInvalid={fieldHasErrors(errors, 'code')}/>
                      <Form.Control.Feedback type="invalid">
                        {getErrorMessageForField(errors, 'code')}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
              </Row>

              {fieldHasErrors(errors, '') &&
              <Row>
                <Col className="text-center mb-3 usacm-error-message">
                  {getErrorMessageForField(errors, '')}
                </Col>
              </Row>
              }

              <Row>
                <Col className="text-center mb-3">
                  {!codeResent &&
                  <Button type="button" onClick={callResendCode} className="ms-3 me-3"> Resend Email </Button>
                  }
                  <Button type="submit" className="ms-3 me-3">Verify Code</Button>
                </Col>
              </Row>

              {codeResent &&
              <Row>
                <Col className="text-start mt-3 mb-3">
                  A new code has been sent to <b>{email}</b>. If you have not received it, please check your spam folder and if you still
                  cannot find it, please contact us at admin@conferences-usacm.org
                </Col>
              </Row>
              }

            </Fragment>
            }

            {emailVerified &&
            <Row>
              <Col className="text-center mb-3">
                <p>Congratulations!</p>
                <p>Your email address has been verified.</p>
                <p><b>{email}</b></p>
              </Col>
            </Row>
            }

          </Container>
        </Form>
      </div>
  );

}
