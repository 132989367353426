import {get, post} from "../utils/networkUtils";

/**
 * Gets a list of rooms
 */
export function getRooms(onComplete) {
  get("room/list/", onComplete);
}

/**
 * Gets a single room
 */
export function getRoom(roomId, onComplete) {
  get(`room/${roomId}/`, onComplete);
}

/**
 * creates or updates a room
 */
export function upsertRoom(roomId, roomNumber, name, location, capacity, onComplete) {
  const data = {
    room_id: roomId,
    room_number: roomNumber,
    name: name,
    location: location,
    capacity: capacity,
  };
  post("room/upsert/", data, onComplete);
}

/**
 * Deletes a room
 */
export function deleteRoom(roomId, onComplete) {
  const data = {
    room_id: roomId,
  }
  post('room/delete/', data, onComplete);
}
