import {FloatingLabel, Form} from "react-bootstrap";
import React, {useState} from "react";
import {fieldHasErrors, getErrorMessageForField} from "../../utils/formUtils";
import {updateFieldAnswer} from "../../api/RegApi";
import {HelpIcon} from "../../shared/HelpIcon";

export function DateFieldInput({field, onAnswerChange = null, setFields = null, setPay = null}) {
  const [refresh, setRefresh] = useState(0);
  const [dirty, setDirty] = useState(false);
  const controlId = 'date_input_' + field.id;
  if (!field.errors) {
    field.errors = [];
  }
  if (!field.answer) {
    field.answer = {};
  }

  function callUpdateFieldAnswer() {
    updateFieldAnswer(field.id, field.answer, (code, data, errors) => {
      if (code === 200) {
        // If there is new field data, that means something besides this field changed, so we need to update the entire reg
        if (data) {
          if (setFields) {
            setFields(data.fields);
          }
          if (setPay) {
            setPay(data.pay);
          }
        }
      } else {
        field.errors = errors;
      }
      setDirty(false); // This will also refresh with errors
    });
  }

  function leavingField() {
    field.errors.length = 0;
    if (dirty) {
      if (onAnswerChange) {
        onAnswerChange(field.answer);
      } else {
        callUpdateFieldAnswer();
      }
    }
  }

  function updateAnswer(newValue) {
    field.errors.length = 0;
    field.answer.value = newValue;
    setRefresh(refresh + 1);
    setDirty(true);
  }

  return (
      <div className="d-flex">
        <Form.Group controlId={controlId}>
          <FloatingLabel controlId={controlId} label={field.name + (field.required ? ' *' : '')}>
            <Form.Control type="date"
                          placeholder={field.name}
                          name={controlId}
                          value={field.answer.value || ''}
                          onChange={e => updateAnswer(e.target.value)}
                          onBlur={e => leavingField()}
                          {...(field.disabled ? {disabled: true} : {})}
                          isInvalid={fieldHasErrors(field.errors, '')}/>
            <Form.Control.Feedback type="invalid">
              {getErrorMessageForField(field.errors, '')}
            </Form.Control.Feedback>
          </FloatingLabel>
        </Form.Group>

        {field.help_text &&
        <div className='ms-3 my-auto'>
          <HelpIcon text={field.help_text}/>
        </div>
        }
      </div>
  );
}

