import {Container, Tab, Tabs} from "react-bootstrap";
import {PageHeader} from "../menu/PageHeader";
import {TrackingList} from "./TrackingList";
import {TrackingListEmail} from "./TrackingListEmail";
import {TrackingRegHistory} from "./TrackingRegHistory";


export function TrackingTabs() {

  return (
    <div>
      <PageHeader pageTitle={"Tracking"}/>
      <Container fluid className="usacm-container-wide">
        <Tabs
          defaultActiveKey="general"
          id="tracking-tabs"
          className="mb-3"
          mountOnEnter={true}
          unmountOnExit={true}
        >
          <Tab eventKey="general" title="General">
            <TrackingList/>
          </Tab>
          <Tab eventKey="email" title="Email">
            <TrackingListEmail/>
          </Tab>
          <Tab eventKey="reg" title="Registration">
            <TrackingRegHistory/>
          </Tab>
        </Tabs>
      </Container>
    </div>
  );

}
