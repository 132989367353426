import React, {Fragment, useContext, useEffect, useState} from "react";
import {createReg, deleteReg, getCouponCodesExist, getReg, validateReg} from "../api/RegApi";
import {RegFieldSectionInput} from "./RegFieldSectionInput";
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import {RegInvoiceDisplay} from "./RegInvoiceDisplay";
import {RegCheckout} from "./RegCheckout";
import {
  CONF_LABEL_REG_START_CONTENT,
  PAY_STATUS_IN_PROGRESS,
  PERMISSION_CALC_HAS_REG,
  PERMISSION_CONF_ADMIN,
  PERMISSION_STAFF,
  REGISTER_MODE_CANCELED,
  REGISTER_MODE_CHECKOUT,
  REGISTER_MODE_COMPLETE,
  REGISTER_MODE_FORM
} from "../constants";
import {fieldHasErrors, getErrorMessageForField} from "../utils/formUtils";
import {waitForTraffic} from "../utils/busyUtils";
import {RegCouponCodeEntry} from "./RegCouponCodeEntry";
import {UsacmContext} from "../App";
import {RegAssignUser} from "./RegAssignUser";
import {hasPermission} from "../api/UserApi";
import {getConfLabel, showSuccessToast, todaysDateStr} from "../utils/usacmUtils";
import {HelpIcon} from "../shared/HelpIcon";

export function Register({preview, regId = null, onFinished = null, showCheckout = true}) {
  const context = useContext(UsacmContext);
  const [conf,] = context.conference;
  const [reg, setReg] = useState(null);
  const [fields, setFields] = useState([]);
  const [pay, setPay] = useState(null); // in-progress (active) pay
  const [hasCouponCodes, setHasCouponCodes] = useState(false);
  const [mode, setMode] = useState(REGISTER_MODE_FORM);
  const [errors, setErrors] = useState([]);
  const hasReg = hasPermission(PERMISSION_CALC_HAS_REG);
  const isStaffOrAdmin = hasPermission(PERMISSION_STAFF) || hasPermission(PERMISSION_CONF_ADMIN);
  const regStartHtml = getConfLabel(conf, CONF_LABEL_REG_START_CONTENT);
  const [timeTravelDate, setTimeTravelDate] = useState(todaysDateStr());

  /**
   *  Gets the first active pay.
   */
  function getActivePay(pays) {
    for (const thisPay of pays) {
      if (thisPay.pay_status === PAY_STATUS_IN_PROGRESS) {
        return thisPay;
      }
    } // for pay
    return null;
  }

  // Sanity check - In dev mode double loading the component can cause fields to be updated to a different reg
  if (reg && fields && fields.length && (reg.id !== fields[0]?.reg_id)) {
    console.warn(`Error condition!  The regId=${reg.id} does not match the field.reg_id=${fields[0].reg_id}`);
  }

  function loadRegData() {
    let currRegId = regId;
    if (!regId && reg) {
      currRegId = reg.id;
    }
    if (!currRegId) {
      console.warn('Unable to loadRegData without regId or reg');
      return;
    }
    getReg(currRegId, (code, data, errors) => {
      if (code === 200) {
        setReg(data.reg);
        setFields(data.fields);
        setPay(getActivePay(data.pays));
        getCodesExist(data.reg.id);
      }
    });
  }

  // Called when a new reg is created in the backend, will setup the state from the data
  function newRegCreated(data) {
    setReg(data.reg);
    setFields(data.fields);
    setPay(getActivePay(data.pays));
    getCodesExist(data.reg.id);
  }

  useEffect(() => {
    if (!reg) {
      // In dev mode the component loads twice, this causes two registrations to be created
      // But the problem is that after creation some fields update themselves (required choicelists or boolean)
      // And when a required choicelist updates it sets all the fields, and may do so for the wrong reg.
      // This should only happen in dev mode, but this waitForTraffic doesn't cost much (basically free for the first call) and
      // it seems to resolve the problem so I'll leave it in here.
      waitForTraffic(() => {
        if (regId) {
          loadRegData();
        } else {
          createReg(preview, null, (code, data, errors) => {
            if (code === 200) {
              newRegCreated(data);
            }
          });
        }
      });
    }
  }, [regId]);

  function getCodesExist(regId) {
    if (!regId) {
      return;
    }
    getCouponCodesExist(regId, (code, data, errors) => {
      if (code === 200) {
        setHasCouponCodes(data.has_coupon_codes);
      }
    });
  }

  function cancelRegistration() {
    setMode(REGISTER_MODE_CANCELED);
    // We will only delete if we created the reg
    if (!regId) {
      deleteReg(reg.id, (code, data, errors) => {
        if (onFinished) {
          onFinished();
        }
      });
    }
  }

  function checkout() {
    if (!showCheckout) {
      return;
    }
    if (!reg) {
      console.warn('Cannot checkout without reg created');
      return;
    }
    waitForTraffic(() => {
      validateReg(reg.id, (code, data, errors) => {
        setFields(data.fields);
        setPay(data.pay);
        setErrors(errors);
        if (!errors.length) {
          setMode(REGISTER_MODE_CHECKOUT);
        }
      });
    });
  }

  function callTimeTravel() {
    if (!preview) {
      console.warn('Can only time travel in preview mode');
      return;
    }
    if (!timeTravelDate) {
      console.warn('Cannot time travel without a date');
      return;
    }
    createReg(true, timeTravelDate, (code, data, errors) => {
      if (code === 200) {
        newRegCreated(data);
        showSuccessToast("Time travel to "+timeTravelDate);
      }
    });
  }

  return (
    <div>
      {mode === REGISTER_MODE_FORM &&
        <Fragment>
          <Container fluid className="usacm-container-wide">

            {preview &&
              <div className="d-flex  justify-content-end mb-3 ">
                <div className='me-2'>
                  <Form.Control type="date"
                                placeholder="Destination Date"
                                name="time_travel_date"
                                value={timeTravelDate}
                                onChange={e => setTimeTravelDate(e.target.value)}
                                isInvalid={fieldHasErrors(errors, 'time_travel_date')}/>
                </div>
                <Button onClick={() => callTimeTravel()}>Time Travel</Button>
                <div className='ms-2 my-auto'>
                  <HelpIcon text='This will create a new preview reg on the date specified. Be aware that coupons do NOT time travel.'/>
                </div>
              </div>
            }

            {regStartHtml &&
              <div className="mb-3" dangerouslySetInnerHTML={{__html: regStartHtml}}/>
            }
            <div className="section-header"> Registration</div>

            {(conf?.reg_allow_assign || isStaffOrAdmin) &&
              <RegAssignUser reg={reg} setReg={setReg} onUserChange={() => loadRegData()}/>
            }

            <RegFieldSectionInput parentField={null} fields={fields} setFields={setFields} setPay={setPay}/>

            {hasCouponCodes &&
              <RegCouponCodeEntry pay={pay} setPay={setPay}/>
            }

            <RegInvoiceDisplay pay={pay}/>

            {fieldHasErrors(errors, '') &&
              <Row>
                <Col className="text-center mb-3 usacm-error-message">
                  {getErrorMessageForField(errors, '')}
                </Col>
              </Row>
            }
            <Row>
              <Col className="usacm-button-row">
                {showCheckout &&
                  <Fragment>
                    {(hasReg || preview) &&
                      <Button variant="secondary" onClick={() => cancelRegistration()}>Cancel</Button>
                    }
                    <Button onClick={() => checkout()}>Checkout</Button>
                  </Fragment>
                }
                {!showCheckout &&
                  <Button onClick={() => onFinished()}>Done</Button>
                }
              </Col>
            </Row>
          </Container>
        </Fragment>
      }

      {mode === REGISTER_MODE_CHECKOUT && showCheckout &&
        <RegCheckout reg={reg}
                     pay={pay}
                     preview={preview}
                     onCancel={() => setMode(REGISTER_MODE_FORM)}
                     onComplete={() => setMode(REGISTER_MODE_COMPLETE)}
                     redirectPath='/reg-list/'
                     onFinished={onFinished || (() => setMode(REGISTER_MODE_COMPLETE))}/>
      }

      {mode === REGISTER_MODE_COMPLETE &&
        <div className='p-3'>
          Registration complete.
        </div>
      }

      {mode === REGISTER_MODE_CANCELED &&
        <div className='p-3'>
          Registration canceled.
        </div>
      }

    </div>
  );

}
