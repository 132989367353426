import {Tabs, Tab, Container} from "react-bootstrap";
import {PageHeader} from "../menu/PageHeader";
import {ConfSettingsGeneral} from "./ConfSettingsGeneral";
import {ConfSettingsLabels} from "./ConfSettingsLabels";
import {ConfSettingsEmail} from "./ConfSettingsEmail";
import {ConfSettingsContent} from "./ConfSettingsContent";
import {getConfLabel, titleCase} from "../utils/usacmUtils";
import {CONF_LABEL_KEY_CONFERENCE} from "../constants";
import {useContext} from "react";
import {UsacmContext} from "../App";
import {ConfSettingsTheme} from "./ConfSettingsTheme";
import {ConfSettingsAbstract} from "./ConfSettingsAbstract";
import {ConfSettingsLists} from "./ConfSettingsLists";


export function ConfSettingsTabs() {
  const {conference} = useContext(UsacmContext);
  const [conf,] = conference;
  const confLabel = titleCase(getConfLabel(conf, CONF_LABEL_KEY_CONFERENCE));

  return (
      <div>
        <PageHeader pageTitle={confLabel + " Settings"}/>
        <Container fluid className="usacm-container-wide">
          <Tabs
              defaultActiveKey="general"
              id="conf-settings-tabs"
              className="mb-3"
              mountOnEnter={true}
              unmountOnExit={true}
          >
            <Tab eventKey="general" title="General">
              <ConfSettingsGeneral />
            </Tab>
            <Tab eventKey="theme" title="Theme">
              <ConfSettingsTheme />
            </Tab>
            <Tab eventKey="abstract" title="Abstracts">
              <ConfSettingsAbstract />
            </Tab>
            <Tab eventKey="lists" title="Lists">
              <ConfSettingsLists />
            </Tab>
            <Tab eventKey="labels" title="Labels">
              <ConfSettingsLabels />
            </Tab>
            <Tab eventKey="content" title="Content">
              <ConfSettingsContent />
            </Tab>
            <Tab eventKey="email" title="Email">
              <ConfSettingsEmail />
            </Tab>
          </Tabs>
        </Container>
      </div>
  );

}
