import {Button, Col, Container, Form, Row} from "react-bootstrap";
import {PageHeader} from "../menu/PageHeader";
import React, {useContext, useRef, useState} from "react";
import {UsacmContext} from "../App";
import {countWordsInHtml, getConfLabel, sentenceCase, titleCase} from "../utils/usacmUtils";
import {
  CONF_LABEL_KEY_EMPHASIS_AREA,
  CONF_LABEL_KEY_PROPOSE_SYMPOSIUM_CONTENT,
  CONF_LABEL_KEY_PROPOSE_SYMPOSIUM_EXPIRED_CONTENT,
  CONF_LABEL_KEY_PROPOSE_SYMPOSIUM_RECEIVED_CONTENT,
  CONF_LABEL_KEY_SECONDARY_EMPHASIS_AREA,
  CONF_LABEL_KEY_SYMPOSIUM,
  PERMISSION_CONF_ADMIN,
  PERMISSION_STAFF,
  SYMPOSIUM_MAX_WORDS
} from "../constants";
import {fieldHasErrors, getErrorMessageForField} from "../utils/formUtils";
import {proposeSymposium} from "../api/SymposiumApi";
import {HtmlEditor} from "../shared/HtmlEditor";
import {getCurrentUser, hasPermission} from "../api/UserApi";
import {AuthorOrganizerEditor, TYPE_ORGANIZER} from "../shared/AuthorOrganizerEditor";
import {ForceUpdateButton} from "../shared/ForceUpdateButton";

export function SymposiumPropose() {
  const {conference} = useContext(UsacmContext);
  const [conf,] = conference;
  const [title, setTitle] = useState('');
  const [emphasisArea, setEmphasisArea] = useState('');
  const [secondaryEmphasisArea, setSecondaryEmphasisArea] = useState('');
  const [errors, setErrors] = useState([]);
  const editorRef = useRef(null);
  const [organizers, setOrganizers] = useState([]);
  const organizerEditorRef = useRef(null);
  const [proposalReceived, setProposalReceived] = useState(false);
  // calculated values
  const symposiumLabel = getConfLabel(conf, CONF_LABEL_KEY_SYMPOSIUM);
  const emphasisAreaLabel = titleCase(getConfLabel(conf, CONF_LABEL_KEY_EMPHASIS_AREA));
  const secondaryEmphasisAreaLabel = titleCase(getConfLabel(conf, CONF_LABEL_KEY_SECONDARY_EMPHASIS_AREA));
  const isStaffOrAdmin = hasPermission(PERMISSION_STAFF) || hasPermission(PERMISSION_CONF_ADMIN);
  const pastDeadline = !(conf?.deadlines?.past_symposium_proposal_deadline === false); // default to true
  const canSubmit = !proposalReceived && (!pastDeadline || isStaffOrAdmin);

  function callSubmitProposal(force) {
    if (!organizerEditorRef.current.hasValidOrganizers()) {
      // Could not validate organizers - some errors will be on the page.
      return;
    }
    if (!editorRef.current) {
      console.warn('Unable to submit proposal as there is no HTML editor loaded');
      return;
    }
    const descHtml = editorRef.current.getContent();
    if (!isStaffOrAdmin && validateDescription(errors, descHtml, true)) {
      // Description has an error, we won't continue saving
      return;
    }
    proposeSymposium(force, title, descHtml, emphasisArea, secondaryEmphasisArea, organizers, (status, data, newErrors) => {
      if (status === 200) {
        setErrors([]);
        setTitle('');
        editorRef.current.setContent('');
        setEmphasisArea('');
        setSecondaryEmphasisArea('');
        setOrganizers([]);
        organizerEditorRef.current.resetOrganizers();
        setProposalReceived(true);
        // Reload the user (as permissions may have changed)
        getCurrentUser((userStatus, userData, userErrors) => {
        });
      } else {
        // We won't clear any existing errors (they came from the backend!)
        validateDescription(newErrors, descHtml, false);
      }
    });
  }

  function descTooLong(html) {
    return countWordsInHtml(html) > SYMPOSIUM_MAX_WORDS;
  }

  function validateDescription(errorList, descHtml, clearExistingErrors) {
    let hasError = false;
    // clear errors for the description field
    const newErrors = clearExistingErrors ? errorList.filter(err => !err.fields.includes('description')) : errorList;
    if (descTooLong(descHtml)) {
      hasError = true;
      const wordCount = countWordsInHtml(descHtml);
      newErrors.push({
        'message': 'Description must be ' + SYMPOSIUM_MAX_WORDS + ' words or less. You have ' + wordCount + ' words.',
        'fields': ['description']
      });
    }
    setErrors(newErrors);
    return hasError;
  }

  function descHtmlChanged(descHtml) {
    if (!descHtml) {
      return;
    }
    validateDescription(errors, descHtml, true);
  }

  return (
    <div className="mb-3">
      <PageHeader pageTitle={"Propose a " + titleCase(symposiumLabel)}/>
      {!canSubmit &&
        <Container fluid className="usacm-container-narrow">
          <Row>
            <Col>
              <div dangerouslySetInnerHTML={{
                __html: getConfLabel(conf,
                  proposalReceived ?
                    CONF_LABEL_KEY_PROPOSE_SYMPOSIUM_RECEIVED_CONTENT :
                    CONF_LABEL_KEY_PROPOSE_SYMPOSIUM_EXPIRED_CONTENT)
              }}/>
            </Col>
          </Row>
          {proposalReceived &&
            <Row className="text-center mt-3">
              <Col>
                <Button onClick={() => setProposalReceived(false)}> Submit Another Symposium Proposal</Button>
              </Col>
            </Row>
          }
        </Container>
      }

      {canSubmit &&
        <Container fluid className="usacm-container-wide">
          <Row>
            <div dangerouslySetInnerHTML={{__html: getConfLabel(conf, CONF_LABEL_KEY_PROPOSE_SYMPOSIUM_CONTENT)}}/>
          </Row>

          <Row>
            <Col className="mt-3 mb-3">
              Title
              {isStaffOrAdmin &&
                <Button onClick={() => setTitle(sentenceCase(title))} size="sm" className="ms-3 mb-1">Use Sentence Case</Button>
              }
              <Form.Group controlId="title">
                <Form.Control type="text"
                              placeholder=''
                              required
                              name="title"
                              value={title}
                              onChange={e => setTitle(e.target.value)}
                              isInvalid={fieldHasErrors(errors, 'title')}/>
                <Form.Control.Feedback type="invalid">
                  {getErrorMessageForField(errors, 'title')}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col className="mb-3">
              <Form.Group controlId="description">
                <div>Description of the {symposiumLabel} ({SYMPOSIUM_MAX_WORDS} word max)</div>
                <div style={fieldHasErrors(errors, 'description') ? {border: '1px solid red'} : {}}>
                  <HtmlEditor
                    initialValue=""
                    onInit={(evt, editor) => editorRef.current = editor}
                    onChange={(evt, editor) => descHtmlChanged(editor?.getContent())}
                  />
                </div>
                {fieldHasErrors(errors, 'description') &&
                  <div className="text-center mb-3 usacm-error-message">
                    {getErrorMessageForField(errors, 'description')}
                  </div>
                }
              </Form.Group>
            </Col>
          </Row>

          {conf?.use_emphasis_area && (conf?.emphasis_areas || []).length > 0 &&
            <Row>
              <Col className="col-auto">
                {emphasisAreaLabel}
              </Col>
              <Col className="mb-3" style={{maxWidth: '400px'}}>
                <Form.Group controlId="formBasicSelect">
                  <Form.Control
                    className="form-select"
                    as="select"
                    value={emphasisArea || ''}
                    onChange={e => setEmphasisArea(e.target.value)}
                    isInvalid={fieldHasErrors(errors, 'emphasis_area')}
                  >
                    {[''].concat(conf?.emphasis_areas)?.map(ea => {
                      return <option value={ea} key={ea}>{ea === '' ? 'Choose...' : ea}</option>
                    })}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {getErrorMessageForField(errors, 'emphasis_area')}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
          }

          {conf?.use_secondary_emphasis_area && (conf?.emphasis_areas || []).length > 0 &&
            <Row>
              <Col className="col-auto">
                {secondaryEmphasisAreaLabel}
              </Col>
              <Col className="mb-3" style={{maxWidth: '400px'}}>
                <Form.Group controlId="formBasicSelect">
                  <Form.Control
                    className="form-select"
                    as="select"
                    value={secondaryEmphasisArea || ''}
                    onChange={e => setSecondaryEmphasisArea(e.target.value)}
                  >
                    {[''].concat(conf?.emphasis_areas)?.map(ea => {
                      return <option value={ea} key={ea}>{ea === '' ? 'Choose...' : ea}</option>
                    })}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
          }

          <AuthorOrganizerEditor
            ref={organizerEditorRef}
            organizers={organizers}
            setOrganizers={setOrganizers}
            errors={errors}
            setErrors={setErrors}
            type={TYPE_ORGANIZER}
          />

          {fieldHasErrors(errors, '') &&
            <Row>
              <Col className="text-center mb-3 usacm-error-message">
                {getErrorMessageForField(errors, '')}
              </Col>
            </Row>
          }

          <Row>
            <Col className="text-center mb-3">
              <Button type="button" className="ms-3 me-3"
                      onClick={() => callSubmitProposal(false)}>Submit {titleCase(symposiumLabel)} Proposal</Button>
              <ForceUpdateButton errors={errors} onClick={() => callSubmitProposal(true)}/>
            </Col>
          </Row>

        </Container>
      }
    </div>
  );

}
