import {Button, Form, Modal} from "react-bootstrap";
import React, {useState} from "react";
import {fieldHasErrors, getErrorMessageForField} from "../../utils/formUtils";
import {HelpIcon} from "../../shared/HelpIcon";
import {downloadFile, uploadFile} from "../../utils/networkUtils";
import {FIELD_FILE_UPLOAD_POST_KEY} from "../../constants";

export function FileUploadFieldInput({field, onAnswerChange = null, setFields = null, setPay = null}) {
  const [showingUploadModal, setShowingUploadModal] = useState(false);
  const [fileToUpload, setFileToUpload] = useState(null);
  const [errors, setErrors] = useState([]);
  const controlId = 'file_upload_input_' + field.id;
  if (!field.answer) {
    field.answer = {};
  }

  function showDialog() {
    setFileToUpload(null);
    setShowingUploadModal(true);
    setErrors([]);
  }

  function stopShowingDialog() {
    setShowingUploadModal(false);
    setFileToUpload(null);
    setErrors([]);
  }

  function startUpload() {
    const url = "reg/field/answer/file-upload/";
    const postData = {'field_id': field.id};
    uploadFile(url, FIELD_FILE_UPLOAD_POST_KEY, fileToUpload, postData, (code, data, errors) => {
      if (code === 200) {
        if (data) {
          if (setFields) {
            setFields(data.fields);
          }
          if (setPay) {
            setPay(data.pay);
          }
        }
        stopShowingDialog();
      } else {
        // These are errors that mean the field failed to update
        // Different from field.errors which are validation related
        setErrors(errors);
      }
    });
  }

  return (
      <div>
        <div className="d-flex">
          <div className='my-auto'>
            {field.name} {(field.required ? ' *' : '')}
          </div>
          <div className='my-auto ms-3'>
            <Button type="button"
                    className='text-nowrap'
                    {...(field.disabled ? {disabled: true} : {})}
                    onClick={() => showDialog()}>Upload File</Button>
          </div>

          {field.help_text &&
          <div className='align-self-center ms-3'>
            <HelpIcon text={field.help_text}/>
          </div>
          }

          {field?.answer?.value &&
          <div className='ms-3 my-auto'>
            File Uploaded : {field?.answer?.value}
            <Button onClick={() =>
                downloadFile('reg/field/answer/file-download/' + field.id + '/', field?.answer?.value || 'file')}
                    className='ms-3'>
              View File
            </Button>
          </div>
          }
        </div>

        <div className="usacm-invalid-feedback">
          {getErrorMessageForField(field.errors, '')}
        </div>

        <Modal show={showingUploadModal}
               onHide={() => stopShowingDialog()}
               size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Upload File: {field.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId={controlId} className="">
              <Form.Control type="file"
                            placeholder={field.name}
                            name={controlId}
                            label="Choose file to upload..."
                            onChange={e => setFileToUpload(e.target.files[0])}
                            isInvalid={fieldHasErrors(errors, '')}/>
              <Form.Control.Feedback type="invalid">
                {getErrorMessageForField(errors, '')}
              </Form.Control.Feedback>
            </Form.Group>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => stopShowingDialog()}>Close</Button>
            <Button variant="primary" onClick={() => startUpload()}>Upload</Button>
          </Modal.Footer>
        </Modal>

      </div>
  );
}

