import {PageHeader} from "../menu/PageHeader";
import {Col, Container, Row} from "react-bootstrap";
import React from "react";
import {Link} from "react-router-dom";

export function PageNotFound() {
  return (
      <div>
        <PageHeader pageTitle= "Page Not Found - 404" />
        <Container fluid className="usacm-container-wide">
          <Row  className="mb-3">
            <Col>
              The page you are requesting does not exist.
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              You can click <Link to="/"> here </Link> to continue.
            </Col>
          </Row>
        </Container>
      </div>
  );
}
