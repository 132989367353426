import {Button, Col, Container, Form, Modal, Row} from "react-bootstrap";
import React, {Fragment, useContext, useEffect, useState} from "react";
import "./AbstractView.scss";
import {UsacmContext} from "../App";
import {PageHeader} from "../menu/PageHeader";
import {getConfLabel, showSuccessToast} from "../utils/usacmUtils";
import {
  CONF_LABEL_KEY_EXTENDED_ABSTRACT_CONTENT,
  CONF_LABEL_KEY_EXTENDED_ABSTRACT_EXPIRED_CONTENT,
  EXTENDED_ABSTRACT_FILE_KEY
} from "../constants";
import {anyFieldHasErrors, fieldHasErrors, getErrorMessageForAllFields, getErrorMessageForField} from "../utils/formUtils";
import {uploadFile} from "../utils/networkUtils";
import {deleteExtendedAbstract, getAbstractExtendedList} from "../api/AbstractApi";

export function ExtendedAbstract() {
  const context = useContext(UsacmContext);
  const [conf,] = context.conference;
  const extendedAbstractHtml = getConfLabel(conf, CONF_LABEL_KEY_EXTENDED_ABSTRACT_CONTENT);
  const extendedAbstractExpiredHtml = getConfLabel(conf, CONF_LABEL_KEY_EXTENDED_ABSTRACT_EXPIRED_CONTENT);
  const pastDeadline = conf?.deadlines?.past_extended_abstract_end === true;
  const [abstractList, setAbstractList] = useState([]);
  const [selectedAbstract, setSelectedAbstract] = useState(null);
  const [extendedAbstractFile, setExtendedAbstractFile] = useState('');
  const [errors, setErrors] = useState([]);
  const [extFileInputKey, setExtFileInputKey] = useState(1);
  const [showingDeleteConfirm, setShowingDeleteConfirm] = useState(false);


  function loadAbstracts() {
    getAbstractExtendedList((code, data, errors) => {
      if (code === 200) {
        setAbstractList(data);
        if (data.length > 0) {
          if (selectedAbstract) {
            // Keep the currently selected abstract
            const abstract = data.find(a => '' + a.id === '' + selectedAbstract.id);
            setSelectedAbstract(abstract);
          } else {
            // Default to the first abstract in the list
            setSelectedAbstract(data[0]);
          }
        }
      }
    });
  }

  // Check for parameters (to pre-load a particular abstract in view/edit)
  useEffect(() => {
    loadAbstracts();
  }, []);

  function selectAbstract(newAbstractId) {
    const abstract = abstractList.find(a => '' + a.id === '' + newAbstractId);
    setSelectedAbstract(abstract);
    setErrors([]);
    setExtFileInputKey(extFileInputKey + 1); // will render a new file Input clearing the old value
  }

  function shortenTitle(title) {
    if (!title) {
      return '';
    }
    if (title.length < 75) {
      return title;
    }
    return title.slice(0, 75) + '...';
  }

  // Called when the user changes the file chosen to upload
  function changeExtUpload(newFile) {
    setExtendedAbstractFile(newFile);
    setErrors([]);
  }

  // Called when the user clicks the upload button, and sends the data to the server.
  function uploadExtendedAbstract() {
    if (!extendedAbstractFile) {
      setErrors([{'message': 'Choose a file to upload', 'fields': ['extended_abstract'], 'can_force': true}]);
      return;
    }
    if (!selectedAbstract) {
      setErrors([{'message': 'You must choose an abstract to upload the extended abstract for', 'fields': ['']}]);
      return;
    }
    setErrors([]);
    const url = 'abstract/extended/upload/'
    const data = {
      'abstract_id': selectedAbstract.id,
    }
    uploadFile(url, EXTENDED_ABSTRACT_FILE_KEY, extendedAbstractFile, data, (code, data, errors) => {
      if (code === 200) {
        showSuccessToast('Uploaded extended abstract.');
        setExtendedAbstractFile('');
        setExtFileInputKey(extFileInputKey + 1); // will render a new file Input clearing the old value
        loadAbstracts(); // reload the abstract list
      } else {
        setErrors(errors);
      }
    });
  }

  function callDeleteExtendedAbstract() {
    if (!selectedAbstract) {
      console.warn('Unable to delete extended abstract without a selected abstract');
      return;
    }
    setShowingDeleteConfirm(false);
    deleteExtendedAbstract(selectedAbstract.id, (code, data, errors) => {
      setErrors([]);
      if (code === 200) {
        showSuccessToast('Deleted extended abstract');
        loadAbstracts();
      } else {
        setErrors(errors);
      }
    });
  }

  return (
    <div>
      <PageHeader pageTitle="Extended Abstract"/>
      <Container fluid className="usacm-container-medium">
        {pastDeadline &&
          <div className="" dangerouslySetInnerHTML={{__html: extendedAbstractExpiredHtml}}/>
        }
        {!pastDeadline &&
          <div className="" dangerouslySetInnerHTML={{__html: extendedAbstractHtml}}/>
        }

        <Row className="mt-3 mb-3">
          <Col className="col-12">
            {abstractList?.length === 1 && selectedAbstract &&
              <div className='fw-bold'>
                {selectedAbstract.abstract_number_full} : {selectedAbstract.title}
              </div>
            }
            {abstractList?.length > 1 &&
              <Fragment>
                <div className='mb-2'>
                  {pastDeadline ? "Viewing" : "Uploading"} extended abstract for
                </div>
                <Form.Group controlId="abstract-select">
                  <Form.Control
                    className="form-select"
                    as="select"
                    value={selectedAbstract?.id || ''}
                    onChange={e => selectAbstract(e.target.value)}
                  >
                    {abstractList?.map(a => {
                      return <option value={a.id} key={a.id}>{a.abstract_number_full} : {shortenTitle(a.title)}</option>
                    })}
                  </Form.Control>
                </Form.Group>
              </Fragment>
            }
          </Col>
        </Row>

        {selectedAbstract?.extended_abstract &&
          <Row>
            <Col>
              <div className='mt-3 mb-3'>
                <div className='mt-3 mb-3 fw-bold'>Your extended abstract has been received.</div>
                <span> Download and view your extended abstract : </span>
                <a href={selectedAbstract.extended_abstract.url}>
                  {selectedAbstract.extended_abstract.file_name}
                </a>
                {!pastDeadline &&
                  <Button className='ms-3' size='sm' variant='danger' onClick={() => setShowingDeleteConfirm(true)}>X</Button>
                }
              </div>
              {!pastDeadline &&
                <div className='pt-3'>Upload a new file to replace your extended abstract :</div>
              }
            </Col>
          </Row>
        }

        {!pastDeadline &&
          <Row>
            <Col className="mb-3 pt-2">
              <div className='d-flex align-items-start'>
                <Form.Group controlId='extended_abstract' className="">
                  <Form.Control type="file"
                                key={extFileInputKey}
                                placeholder='Extended Abstract'
                                name='extended_abstract'
                                label="Choose..."
                                onChange={e => changeExtUpload(e.target.files[0])}
                                isInvalid={fieldHasErrors(errors, 'extended_abstract')}/>
                  <Form.Control.Feedback type="invalid">
                    {getErrorMessageForField(errors, 'extended_abstract')}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
            </Col>
          </Row>
        }

        {anyFieldHasErrors(errors, ['', 'abstract_id']) &&
          <Row>
            <Col className="text-center mb-3 usacm-error-message">
              {getErrorMessageForAllFields(errors, ['', 'abstract_id'])}
            </Col>
          </Row>
        }

        {!pastDeadline &&
          <Row>
            <Col className="usacm-button-row">
              <Button className='ms-2' onClick={() => uploadExtendedAbstract()}>Upload Extended Abstract</Button>
            </Col>
          </Row>
        }
      </Container>

      <Modal show={showingDeleteConfirm}
             onHide={() => setShowingDeleteConfirm(false)}
             size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete the extended abstract file? <br/>
          You will be able to upload a new file later.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowingDeleteConfirm(false)}>Cancel</Button>
          <Button variant="danger" onClick={() => callDeleteExtendedAbstract()}>Delete Extended Abstract</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
