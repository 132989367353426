import {Button, Col, Container, Row} from "react-bootstrap";
import React, {Fragment, useContext, useEffect, useState} from "react";
import {getReg} from "../api/RegApi";
import {getRegStatusDisplay, userCanCreateReg} from "./regUtils";
import {RegViewSection} from "./RegViewSection";
import {hasPermission} from "../api/UserApi";
import {PERMISSION_CONF_ADMIN, PERMISSION_CONF_ORGANIZER, PERMISSION_STAFF} from "../constants";
import {RegPayDisplay} from "./RegPayDisplay";
import {RegHistory} from "./RegHistory";
import {UsacmContext} from "../App";
import {downloadFile} from "../utils/networkUtils";
import {RegCheckout} from "./RegCheckout";

const MODE_MAIN = "regView main";
const MODE_HISTORY = "regView history";
const MODE_CHECKOUT = "regView checkout"

export function RegView({regId, onClose, onCreate}) {
  const context = useContext(UsacmContext);
  const [conf,] = context.conference;
  const [reg, setReg] = useState(null);
  const [fields, setFields] = useState([]);
  const [pays, setPays] = useState([]);
  const [selectedPay, setSelectedPay] = useState(null);
  const [mode, setMode] = useState(MODE_MAIN);
  const isStaffOrAdmin = hasPermission(PERMISSION_STAFF) || hasPermission(PERMISSION_CONF_ADMIN);
  const isStaffOrAdminOrOrg = isStaffOrAdmin || hasPermission(PERMISSION_CONF_ORGANIZER);
  const canCreateReg = userCanCreateReg(conf);
  const regUseAttendance = !!conf?.reg_use_attendance;

  function loadReg() {
    getReg(regId, (code, data, errors) => {
      if (code === 200) {
        setReg(data?.reg);
        setFields(data?.fields || []);
        setPays(data?.pays || []);
      }
    });
  }

  useEffect(() => {
    loadReg();
  }, [regId]);

  function enterCheckoutMode(payId) {
    const pay = pays.find(p => p.id === payId);
    if (!pay) {
      console.warn('Unable to find pay with id ' + payId + ' in ', pays);
      return;
    }
    setSelectedPay(pay);
    setMode(MODE_CHECKOUT);
  }

  function backToMain() {
    setMode(MODE_MAIN);
    setSelectedPay(null);
    loadReg();
  }

  return (
    <Container fluid className="usacm-container-wide">
      {mode === MODE_MAIN &&
        <Fragment>

          <div className="section-header"> Registration</div>
          <div className='p-2'>
            <span className='fw-bold'>Registration ID:</span>
            <span className='ps-2'>{reg?.id}</span>
          </div>

          <div className='p-2'>
            <span className='fw-bold'>Registration Status:</span>
            <span className='ps-2'>{getRegStatusDisplay(reg?.reg_status)}</span>
          </div>

          {(reg?.create_user_id !== reg?.assigned_user_id) &&
            <Fragment>
              <div className='p-2'>
                <span className='fw-bold'>Created By:</span>
                <span className='ps-2'>{reg?.create_user_name}, {reg?.create_user_email}</span>
              </div>
              <div className='p-2'>
                <span className='fw-bold'>For:</span>
                <span className='ps-2'>{reg?.assigned_user_name}, {reg?.assigned_user_email}</span>
              </div>
            </Fragment>
          }

          {
            isStaffOrAdminOrOrg && (reg?.create_user_id === reg?.assigned_user_id) &&
            <div className='p-2'>
              <span className='fw-bold'>User:</span>
              <span className='ps-2'>{reg?.assigned_user_name}, {reg?.assigned_user_email}</span>
            </div>
          }

          {regUseAttendance &&
            <div>
              <div className='p-2'>
                <span className='fw-bold'>Attended:</span>
                <span className='ps-2'>{reg?.attended ? 'Yes' : 'No'}</span>
              </div>
            </div>
          }

          {
            isStaffOrAdminOrOrg &&
            <div className='p-2'>
              <span className='fw-bold'>Admin Comments:</span>
              <span className='ps-2'>{reg?.admin_comments}</span>
            </div>
          }

          <RegViewSection reg={reg} fields={fields} parentFieldId={null}/>

          {
            pays.map(pay =>
              <div key={pay.id} className='mb-3'>
                <RegPayDisplay pay={pay} onCheckout={() => enterCheckoutMode(pay.id)}/>
              </div>
            )
          }

          <Row>
            <Col className="usacm-button-row">
              {onClose &&
                <Button variant="secondary" onClick={() => onClose()}>Back</Button>
              }
              {isStaffOrAdminOrOrg &&
                <Button variant="primary" onClick={() => setMode(MODE_HISTORY)}>Show History</Button>
              }
              {isStaffOrAdmin &&
                <Button onClick={() => downloadFile('reg/' + regId + '/invoice-pdf/', 'invoice.pdf')}>Invoice</Button>
              }
              {regUseAttendance && reg?.attended &&
                <Button onClick={() => downloadFile('reg/' + regId + '/cert-of-attend/pdf', 'certificate_of_attendance.pdf')}>
                  Certificate of Attendance
                </Button>
              }
              {onCreate && canCreateReg &&
                <Button onClick={() => onCreate()}>Add Registration</Button>
              }
            </Col>
          </Row>
        </Fragment>
      }

      {
        mode === MODE_HISTORY && isStaffOrAdminOrOrg &&
        <div>
          <RegHistory regId={reg?.id} onFinished={() => setMode(MODE_MAIN)}/>
        </div>
      }

      {
        mode === MODE_CHECKOUT && selectedPay &&
        <RegCheckout reg={reg}
                     pay={selectedPay}
                     preview={false}
                     onCancel={backToMain}
                     onComplete={backToMain}
                     redirectPath='/reg-list/'
                     onFinished={backToMain}/>
      }

    </Container>
  )
    ;

}
