import {PageHeader} from "../menu/PageHeader";
import {Container} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {AUDIO_ERROR, AUDIO_SUCCESS, TEST_QR_IMG} from "../constants";
import {regAttended} from "../api/RegApi";
import './RegAttendanceEntry.scss';

const globals = {
  buffer: '',
};

export function RegAttendanceEntry() {
  const [listening, setListening] = useState(false);
  const [hasFocus, setHasFocus] = useState(true);
  const [errors, setErrors] = useState([]);
  const [regList, setRegList] = useState([]);
  const [testOk, setTestOk] = useState(false);
  const [refresh, setRefresh] = useState(0);

  function processString() {
    const newErrors = [];
    const newRegList = [];
    setErrors(newErrors); // Clear old errors
    setRegList(newRegList);
    setTestOk(false);
    const strVal = globals.buffer.toLowerCase();
    // Clear the buffer, so we can collect new key events while the data is processing
    globals.buffer = '';
    const matches = [...strVal.matchAll(/start([0-9]+)end/g)];
    if ((matches.length === 0) && (strVal.length > 0)) {
      newErrors.push({'message': `No reg found in string "${strVal}"`, 'fields': ['']});
    }
    for (const match of matches) {
      const regId = match[1]; // first capturing group in the regex
      // Scanner Test QR code has 0 for regId
      if (regId === '0') {
        new Audio(AUDIO_SUCCESS).play().catch();
        setTestOk(true);
        continue; // don't send test scans to the server (but skip to the next item if one is found)
      }
      regAttended(regId, (code, data, err) => {
        if (code === 200) {
          new Audio(AUDIO_SUCCESS).play().catch();
          // Augment the reg object with attendance updated status
          data.reg.updated = data.updated;
          newRegList.push(data.reg);
        } else {
          new Audio(AUDIO_ERROR).play().catch();
          newErrors.push(...err);
        }
        // Refresh will update the UI for changes to newErrors or newRegList
        setRefresh(refresh + Math.random()); // using refresh var to avoid linter warnings
      });
    }
  }

  useEffect(() => {
    function handleKeyDown(e) {
      if (e.keyCode === 13) {
        processString();
      } else {
        // e.key returns "Shift" and capital letters are peculiar, but it works for what we need
        globals.buffer += e.key;
      }
    }

    function handleFocus(e) {
      setHasFocus(true);
    }

    function handleBlur(e) {
      setHasFocus(false);
      // When focusing elsewhere clear all the errors and the last successful reg
      setErrors([]);
      setRegList([]);
      setTestOk(false);
    }

    document.addEventListener('keydown', handleKeyDown);
    setListening(true);
    window.addEventListener('focus', handleFocus);
    window.addEventListener('blur', handleBlur);

    return function cleanup() {
      document.removeEventListener('keydown', handleKeyDown);
      setListening(false);
      window.removeEventListener('focus', handleFocus);
      window.removeEventListener('blur', handleBlur);
    }
  }, []);

  return (
    <div>
      <PageHeader pageTitle="Attendance Entry"/>
      <Container fluid className="usacm-container-medium">

        {listening && hasFocus &&
          <div className='ratt-ready'>
            Ready to Scan
          </div>
        }

        {!listening &&
          <div className='ratt-not-ready'>
            Not listening. Please reload the page.
          </div>
        }

        {!hasFocus &&
          <div className='ratt-not-ready'>
            Not Focused. Please click on this page
          </div>}

        {regList.map((reg) =>
          <div className='ratt-success-box' key={reg.id}>
            <div className='ratt-success-name'>{reg.assigned_user_name}</div>
            {!reg.updated &&
              <div className='ratt-success-no-update'>Already marked as attended</div>
            }
            <div className='ratt-success-data'>{reg.assigned_user_email} has attended with registration ID {reg.id}</div>
          </div>
        )}

        {testOk &&
          <div className='ratt-success-box'>
            <div className='ratt-success-name'>Scanner Test</div>
            <div className='ratt-success-no-update'>The scanner is working</div>
          </div>
        }

        {errors && errors.map(error =>
          <div className="ratt-errors">
            <div className='ratt-errors-header'>Error!</div>
            <div className='ratt-errors-text'>{error.message}</div>
          </div>
        )}

        <div className='ratt-test-qr'>
          You can test your scanner setup <br/>
          using the below QR code. <br/>
          <img src={TEST_QR_IMG} alt="test qr"/>
        </div>

      </Container>
    </div>
  );
}
